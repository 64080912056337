import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import swal from "sweetalert";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import NewContentDrawer from "../../components/Drawers/NewContentDrawer";
import CustomerInformationWidget from "components/Widgets/CustomerInformationWidget";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import NoteBody from "components/NoteBody";
import Spinner from "reactstrap/lib/Spinner";
import LeadContactInformationWidget from "components/Widgets/LeadContactInformationWidget";
import AffiliateAboutWidget from "components/Widgets/AffiliateAboutWidget";
import LinkUtils from "lib/LinkUtils";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";
import FileViewer from "react-file-viewer";
import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import ReactPlayer from "react-player";
import { SocialSharing } from "@awesome-cordova-plugins/social-sharing";

const FilePreview = ({ file, url }) => {
  return (
    <>
      {!file || !url ? (
        <>
          <div
            style={{
              height: 300,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="m-0">Loading preview...</p>
          </div>
        </>
      ) : (
        <>
          {file?.mimetype?.includes("image/") ? (
            <>
              <div className="text-center">
                <img
                  src={url}
                  alt="Preview image"
                  style={{ height: 300, width: "auto" }}
                ></img>
              </div>
            </>
          ) : (
            <>
              <ReactPlayer
                url={url}
                controls={true}
                height={300}
                width={"100%"}
              ></ReactPlayer>
            </>
          )}
        </>
      )}
    </>
  );
};

const FileSection = ({ asset, shareable }) => {
  const [sharing, setSharing] = useState(false);
  const [url, setURL] = useState(null);

  useEffect(() => {
    getAssetFromURL(asset?.file?.url).then((u) => {
      if (u) {
        setURL(u);

        return;
      }

      setURL(null);
    });
  }, [asset]);

  let getAssetFromURL = async (url) => {
    return new Promise((resolve) => {
      APIV2.getPrivateFileURL(url).then(
        (data) => {
          resolve(data.data.url);
        },
        (e) => {
          console.error(e);

          resolve(null);
        }
      );
    });
  };

  let base64FromBlob = async (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject("method did not return a string");
        }
      };

      reader.readAsDataURL(blob);
    });
  };

  let shareFile = async () => {
    if (!url) {
      return;
    }

    setSharing(true);

    if (!Capacitor.isNativePlatform()) {
      LinkUtils.openInNewTab(url);

      setSharing(false);

      return;
    }

    let result = null;

    try {
      result = await APIV2.fetchFile(url);
    } catch (e) {
      console.error(e);
    }

    const base64 = await base64FromBlob(result?.data);

    try {
      console.log("Requesting...");

      await Filesystem.requestPermissions();

      console.log("Requested");

      let fileResult = await Filesystem.writeFile({
        path: asset?.file?.fileName,
        data: base64,
        directory: Directory.Documents,
        recursive: true,
      });

      setTimeout(async () => {
        console.log("FILE URI", fileResult?.uri); //debug

        let result2 = await SocialSharing.shareWithOptions({
          text: asset?.title,
          url: fileResult?.uri,
          //files: [fileResult?.uri],
          //files: [fileResult?.uri],
        });

        console.log("SHARE RESULT", result2);
      }, 1);
    } catch (e) {
      console.error(e);
    }

    setSharing(false);
  };

  return (
    <>
      <div className="pb-3 mb-3">
        <Row className="align-items-center mb-3">
          <Col xs="auto" className="pr-0">
            {shareable && (
              <h2 className="mb-2">
                <Badge
                  color="primary"
                  className="py-1 border border-primary-lighter "
                >
                  Step{" "}
                  {asset?.editorsNotesPlainText ? 3 : asset?.caption ? 2 : 1}
                </Badge>
              </h2>
            )}
            <h3 className="text-dark m-0">
              {shareable ? "Share" : "View"} The Asset:
            </h3>
          </Col>
          <Col xs="" className="pl-2"></Col>
        </Row>

        <div style={{ position: "relative" }}>
          <div
            style={{
              fontFamily: "Karla, sans-serif",
              width: "100%",
              fontSize: 16,
            }}
            className="text-dark border rounded bg-white px-2 pt-2 m-0"
          >
            <div className="pb-2 mb-2 border-bottom">
              <h4
                style={{ lineHeight: 1.2 }}
                className="text-dark m-0 text-truncate"
              >
                {asset?.file?.fileName}
              </h4>
            </div>
            {/**
             * Load previews here for video / image
             */}
            <div className="border-bottom mb-2 pb-2">
              <FilePreview file={asset?.file} url={url}></FilePreview>
            </div>
            {shareable ? (
              <Button
                outline
                color="dark"
                block
                size="sm"
                className="px-2 mb-2 text-center"
                disabled={sharing}
                onClick={() => {
                  shareFile(asset);
                }}
              >
                <Row className="align-items-center justify-content-center">
                  <Col xs="auto" className="pr-2">
                    {sharing ? (
                      <>
                        <Spinner color="dark" size="sm"></Spinner>
                      </>
                    ) : (
                      <>
                        <i
                          className="mdi mdi-export-variant"
                          style={{ fontSize: 16, lineHeight: 1 }}
                        ></i>
                      </>
                    )}
                  </Col>
                  <Col xs="auto" className="pl-0">
                    {sharing ? "Preparing.." : "Share"}
                  </Col>
                </Row>
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

class ContentCalendarDetailDrawer extends React.Component {
  state = {
    dueDate: moment().day(6).toDate(),
    subject: "",
    description: "",
    relatedToType: "",
    type: "",
    priority: "Normal",
    status: "Not Started",
    assignedTo: null,
    timeToContact: "",
    customers: [],
    recentFirst: "recentFirst",
    activeAsset: 0,
  };

  dropdownRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (this.props.content != prevProps?.content) {
      if (this.props.content?.assets?.length) {
        this.setState({
          activeAsset: 0,
        });
      }
    }
  }

  componentDidMount() {
    if (this.props.content?.assets?.length) {
      this.setState({
        activeAsset: 0,
      });
    }
  }

  toggleModal() {
    this.setState({
      assetPreviews: {},
      activeAsset: null,
      sharing: null,
    });

    let stateKeys = _.keys(this.state);

    let filtered = _.filter(stateKeys, (key) => {
      if (!key) {
        return false;
      }

      return key?.includes("preview");
    });

    let out = {};

    for (let i = 0; i < out?.length; i++) {
      out[filtered] = null;
    }

    this.setState({ out });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  userIsGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  /**
   * Fetches the active asset by index.
   *
   * @param {*} idx
   * @returns
   */
  getActiveAsset(idx) {
    if (idx < 0 || idx > this.props.content?.assets?.length) {
      return null;
    }

    return this.props.content.assets[idx];
  }

  renderPlatforms(content) {
    let out = [];

    if (!content) {
      return;
    }

    if (content?.type == "Email") {
      return (
        <>
          <div className="d-flex">
            <svg
              style={{
                height: 20,
                width: 20,
                marginRight: "0.1rem",
                display: "inline-block",
              }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>email</title>
              <path
                fill="var(--dark)"
                d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
              />
            </svg>
          </div>
        </>
      );
    }

    if (content.type == "Broadcast") {
      return (
        <>
          <div className="d-flex">
            <svg
              style={{
                height: 20,
                width: 20,
                marginRight: "0.1rem",
                display: "inline-block",
              }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>message-fast</title>
              <path
                fill="var(--dark)"
                d="M3 7C2.4 7 2 7.4 2 8S2.4 9 3 9H5V7H3M2 11C1.4 11 1 11.4 1 12S1.4 13 2 13H5V11H2M1 15C.4 15 0 15.4 0 16C0 16.6 .4 17 1 17H5V15H1M20 5H9C7.9 5 7 5.9 7 7V21L11 17H20C21.1 17 22 16.1 22 15V7C22 5.9 21.1 5 20 5Z"
              />
            </svg>
          </div>
        </>
      );
    }

    if (content?.platforms?.includes("Instagram")) {
      out.push(
        <div className="d-inline-flex">
          <svg
            style={{
              height: 20,
              width: 20,
              marginRight: "0.1rem",
              display: "inline-block",
            }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <title>instagram</title>
            <path
              fill="var(--dark)"
              d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
            />
          </svg>
        </div>
      );
    }

    if (content?.platforms?.includes("Facebook")) {
      out.push(
        <div className="d-inline-flex">
          <svg
            style={{
              height: 20,
              width: 20,
              marginRight: "0.1rem",
              display: "inline-block",
            }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <title>facebook</title>
            <path
              fill="var(--dark)"
              d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"
            />
          </svg>
        </div>
      );
    }

    return (
      <>
        {out.map((item, i) => (
          <>{item}</>
        ))}
      </>
    );
  }

  copyToClipboard(id, content) {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(content);
    }

    this.setState({
      copied: id,
    });

    setTimeout(() => {
      this.setState({
        copied: null,
      });
    }, 2000);
  }

  getAsset(id) {
    const asset = this.getActiveAsset(id)?._id;

    return this.state[`preview${asset}`] ? (
      this.state[`preview${asset}`]
    ) : (
      <p className="m-0">No preview available</p>
    );
  }

  deleteContent() {
    this.setState({
      deleting: true,
    });

    APIV2.deleteContentCalendarItem(this.props.content?._id)
      .then(
        (data) => {
          PubSub.publish(Event.CONTENT.DELETED, this.props.content);

          this.toggleModal();
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to delete content - unknown error occurred. Try again."
          );
        }
      )
      .finally(
        this.setState({
          deleting: false,
        })
      );
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Content Details
            </h3>
            {this.userIsGlobal() && (
              <>
                <Drawer.Actions>
                  <Whisper
                    ref={this.dropdownRef}
                    trigger="click"
                    placement={"autoVerticalEnd"}
                    speaker={
                      <Popover full>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            panel
                            className="py-2 font-weight-800 text-uppercase"
                            style={{ paddingLeft: 12, paddingRight: 12 }}
                          >
                            <h5 className="m-0">Actions</h5>
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => {
                              this.setState({
                                modifyOpen: true,
                                modifyContent: this.props.content,
                              });

                              this.dropdownRef.current.close();
                            }}
                          >
                            Modify Content
                          </Dropdown.Item>
                          <Dropdown.Item divider></Dropdown.Item>
                          <Dropdown.Item
                            className="text-danger"
                            onClick={() => {
                              swal({
                                title: "Delete Content",
                                text: `Are you sure you want to delete this content?`,
                                icon: "warning",
                                buttons: ["Nevermind", "Delete"],
                                dangerMode: true,
                              }).then((conf) => {
                                if (!conf) {
                                  return;
                                }

                                this.deleteContent();

                                this.dropdownRef.current.close();
                              });
                            }}
                          >
                            Delete Content
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Popover>
                    }
                  >
                    <Button size="sm" outline color="secondary">
                      Actions <i className="mdi mdi-chevron-down pl-2"></i>
                    </Button>
                  </Whisper>
                </Drawer.Actions>
              </>
            )}
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}

            <Row className="align-items-center mb-2">
              <Col xs="">
                <h4 className="mb-1 text-dark">
                  {moment(this.props.content?.scheduledFor).format(
                    "MM/DD/YY hh:mm A"
                  )}
                </h4>
                <Row className="align-items-center">
                  <Col
                    style={{ lineHeight: 1, paddingTop: 2 }}
                    xs="auto"
                    className="pr-0"
                  >
                    {this.renderPlatforms(this.props.content)}
                  </Col>
                  <Col xs="" className="pl-1">
                    <h3 className="m-0 text-dark font-weight-500">
                      {this.props.content?.type == "Social Media" ? (
                        <>{this.props.content?.postTypes?.join(", ")}</>
                      ) : (
                        <>{this.props.content?.audiences?.join(", ")}</>
                      )}
                    </h3>
                  </Col>
                </Row>
              </Col>
              {this.props.content?.shareable ? (
                <Col xs="auto">
                  <h3 className="m-0">
                    <Badge
                      color="medium"
                      style={{ position: "relative", top: -2 }}
                    >
                      <i className="mdi mdi-export-variant"></i>
                      &nbsp;Shareable
                    </Badge>
                  </h3>
                </Col>
              ) : null}
            </Row>
            <h2 className="mb-1">{this.props.content?.name}</h2>
            {this.props.content?.description ? (
              <>
                <p
                  className="mb-2 text-muted"
                  style={{ lineHeight: 1.3 }}
                  dangerouslySetInnerHTML={{
                    __html: this.props.content?.description
                      ?.split("\n")
                      .join("<br/>"),
                  }}
                ></p>
              </>
            ) : null}
            {this.props.content?.tags?.length ? (
              <>
                <p className="mb-1" style={{ lineHeight: 1.3 }}>
                  {this.props.content?.tags.map((tag, i) => (
                    <Badge
                      color="medium"
                      className="mt-2 mr-2 text-lowercase border border-lighter"
                      style={{ fontSize: 13 }}
                      key={i}
                    >
                      #{tag}
                    </Badge>
                  ))}
                </p>
              </>
            ) : null}
            <div className="pt-3"></div>
            {this.props.content?.assets?.length ? (
              <>
                <div className="pb-3">
                  <div
                    className="pb-3 pt-3 px-4 mx--4 border-top border-bottom bg-superlight"
                    style={{ position: "sticky", zIndex: 1, top: -25 }}
                  >
                    {this.props.content?.assets?.length > 1 ? (
                      <div
                        style={{ background: "#FFF3BC" }}
                        className="rounded px-3 py-2 border border-dark mb-3 text-dark font-weight-500"
                      >
                        <Row className="align-items-center">
                          <Col xs="auto" className="pr-0">
                            <h1 className="m-0">
                              <i className="mdi mdi-information-outline pr-2"></i>
                            </h1>
                          </Col>
                          <Col xs="" className="pl-2 pr-2">
                            <div>
                              This{" "}
                              {this.props.content?.type == "Social Media" ? (
                                <>{this.props.content?.postTypes?.join(", ")}</>
                              ) : (
                                <>{this.props.content?.type}</>
                              )}
                              &nbsp;contains multiple assets. Click the Arrow
                              buttons to view & share all of them.
                            </div>
                          </Col>
                          <Col className="pl-0" xs="auto">
                            <h1 className="m-0">
                              <i className="mdi mdi-arrow-down"></i>
                            </h1>
                          </Col>
                        </Row>
                      </div>
                    ) : null}
                    <Row className="align-items-center">
                      <Col xs="">
                        <div style={{ fontSize: 14 }}>
                          {this.props.content?.assets?.length} Asset
                          {this.props.content?.assets?.length != 1 && "s"} Total
                        </div>
                        <div className="text-dark font-weight-500">
                          Viewing {this.state.activeAsset + 1} of{" "}
                          {this.props.content?.assets?.length}
                        </div>
                      </Col>
                      {this.props.content?.assets?.length > 1 ? (
                        <Col xs="auto">
                          <Button
                            size="sm"
                            outline
                            color={
                              this.state.activeAsset == 0 ? "light" : "dark"
                            }
                            disabled={this.state.activeAsset == 0}
                            className="btn-icon-only"
                            onClick={() => {
                              this.setState({
                                activeAsset:
                                  this.state.activeAsset - 1 <= 0
                                    ? 0
                                    : this.state.activeAsset - 1,
                              });
                            }}
                          >
                            <i className="mdi mdi-chevron-left"></i>
                          </Button>
                          <Button
                            size="sm"
                            outline
                            color={
                              this.state.activeAsset ==
                              this.props.content.assets?.length - 1
                                ? "light"
                                : "dark"
                            }
                            disabled={
                              this.state.activeAsset ==
                              this.props.content?.assets?.length - 1
                            }
                            className="btn-icon-only"
                            onClick={() => {
                              this.setState({
                                activeAsset:
                                  this.state.activeAsset + 1 >=
                                  this.props.content?.assets?.length - 1
                                    ? this.props.content?.assets?.length - 1
                                    : this.state.activeAsset + 1,
                              });
                            }}
                          >
                            <i className="mdi mdi-chevron-right"></i>
                          </Button>
                        </Col>
                      ) : null}
                    </Row>
                  </div>

                  <Row className="align-items-center mt-3">
                    <Col xs="">
                      <h3 className="m-0">
                        {this.getActiveAsset(this.state.activeAsset)?.title}
                      </h3>
                      {this.getActiveAsset(this.state.activeAsset)
                        ?.description ? (
                        <p
                          className="mb-0 text-muted small"
                          style={{ lineHeight: 1.2 }}
                        >
                          {
                            this.getActiveAsset(this.state.activeAsset)
                              ?.description
                          }
                        </p>
                      ) : null}
                    </Col>
                    <Col xs="auto" style={{ lineHeight: 1 }}>
                      {this.renderPlatforms(this.props.content)}
                    </Col>
                  </Row>
                  <hr className="my-3"></hr>
                  {this.getActiveAsset(this.state.activeAsset)
                    ?.editorsNotesPlainText ? (
                    <div className="border-bottom pb-3 mb-3">
                      <Row className="align-items-center mb-3">
                        <Col xs="" className="pr-0">
                          {this.props.content?.shareable && (
                            <h2 className="m-0">
                              <Badge
                                color="primary"
                                className="py-1 border border-primary-lighter mb-2"
                              >
                                Step 1
                              </Badge>
                            </h2>
                          )}
                          <h3 className="text-dark m-0">
                            {this.props.content?.shareable && "Read "}Editor's
                            Notes:
                          </h3>
                        </Col>
                      </Row>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.getActiveAsset(this.state.activeAsset)
                            ?.editorsNotes,
                        }}
                      ></div>
                    </div>
                  ) : null}
                  {this.getActiveAsset(this.state.activeAsset)?.caption ? (
                    <div className="border-bottom pb-3 mb-3">
                      <Row className="align-items-center mb-3">
                        <Col xs="auto" className="pr-0">
                          {this.props.content?.shareable && (
                            <h2 className="mb-2">
                              <Badge
                                color="primary"
                                className="py-1 border border-primary-lighter "
                              >
                                Step{" "}
                                {this.getActiveAsset(this.state.activeAsset)
                                  ?.editorsNotesPlainText
                                  ? 2
                                  : 1}
                              </Badge>
                            </h2>
                          )}
                          <h3 className="text-dark m-0">
                            {this.props.content?.shareable
                              ? "Copy Caption To Clipboard:"
                              : "Caption:"}
                          </h3>
                        </Col>
                        <Col xs="" className="pl-2"></Col>
                      </Row>

                      <div style={{ position: "relative" }}>
                        <pre
                          style={{
                            fontFamily: "Karla, sans-serif",
                            width: "100%",
                            fontSize: 16,
                          }}
                          className="text-dark border rounded bg-white px-2 pt-3 pb-2 m-0"
                        >
                          <div
                            className="mb-4"
                            style={{ whiteSpace: "prewrap" }}
                          >
                            {
                              this.getActiveAsset(this.state.activeAsset)
                                ?.caption
                            }
                          </div>
                          <Button
                            block
                            size="sm"
                            outline
                            color="dark"
                            className="px-2"
                            disabled={
                              this.state.copied ===
                              this.getActiveAsset(this.state.activeAsset)?._id
                            }
                            onClick={() => {
                              this.copyToClipboard(
                                this.getActiveAsset(this.state.activeAsset)
                                  ?._id,
                                this.getActiveAsset(this.state.activeAsset)
                                  ?.caption
                              );
                            }}
                          >
                            <Row className="align-items-center justify-content-center">
                              <Col xs="auto" className="pr-2">
                                <i
                                  className="mdi mdi-content-copy"
                                  style={{ fontSize: 16, lineHeight: 1 }}
                                ></i>
                              </Col>
                              <Col xs="auto" className="pl-0">
                                {this.state.copied ===
                                this.getActiveAsset(this.state.activeAsset)?._id
                                  ? "Copied"
                                  : "Copy"}
                              </Col>
                            </Row>
                          </Button>
                        </pre>
                      </div>
                    </div>
                  ) : null}
                  <FileSection
                    asset={this.getActiveAsset(this.state.activeAsset)}
                    shareable={this.props.content?.shareable}
                  ></FileSection>
                </div>
              </>
            ) : (
              <>
                <div className="text-center py-3">
                  <p className="m-0">
                    This content does not have any shareable assets.
                  </p>
                </div>
              </>
            )}
          </Drawer.Body>
        </Drawer>
        <NewContentDrawer
          store={this.props.store}
          open={this.state.modifyOpen}
          content={this.state.modifyContent}
          mode={this.state.modifyMode}
          onClose={() => {
            this.setState({
              modifyOpen: false,
              modifyContent: null,
              modifyMode: "",
            });
          }}
        ></NewContentDrawer>
      </>
    );
  }
}

export default withRouter(ContentCalendarDetailDrawer);
