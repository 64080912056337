import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import { Capacitor } from "@capacitor/core";
import FileUploadDrawer from "../FileUploadDrawer";
import uniqueCodeImg from "../../assets/img/unique_code.jpeg";
import { Filesystem } from "@capacitor/filesystem";

class NewQualityClaimDrawer extends React.Component {
  state = {
    reason: "",
    notes: "",
    mealOptions: [],
  };

  async _loadClaimState() {
    this.setState({
      loading: true,
    });

    let claimedMeals = [];

    //console.log("UPDATE");

    for (let i = 0; i < this.props.claim?.claims?.length; i++) {
      const claim = this.props.claim?.claims[i];

      let claimedMeal = {
        _id: StringUtils.uuid(),
        meal: {
          sku: claim?.meal?.sku,
          name: claim?.meal?.name,
          planType: claim?.meal?.planType,
          planTypeID: claim?.meal?.planType?._id,
          _id: claim?.meal?._id,
        },
        code1: claim?.code1,
        code2: claim?.code2,
        image: "",
        imageFile: {},
        manualCodes: {
          value:
            claim?.code1 && claim?.code2
              ? claim?.code1 + "-" + claim?.code2
              : "",
        },
      };

      if (claim?.imageFile) {
        claimedMeal.imageFile = {
          url: claim?.imageFile?.url,
          mimetype: claim?.imageFile?.mimetype,
          cdn: claim?.imageFile?.cdn,
          fileName: claim?.imageFile?.fileName,
        };

        claimedMeal.imageCDNURL = await this.getImageURL(
          claimedMeal?.imageFile?.url
        );
      }

      claimedMeals.push(claimedMeal);
    }

    claimedMeals = JSON.parse(JSON.stringify(claimedMeals));

    this.setState({
      claimedMeals,
      name: this.props.claim?.submittedByName
        ? this.props.claim?.submittedByName
        : this.props.claim?.submittedBy?.personName
        ? this.props.claim?.submittedBy?.personName
        : "",
      notes: this.props.claim?.notes,
      loading: false,
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.open != prevProps.open && this.props.open) {
      this.loadMeals();

      if (this.props.claim) {
        this._loadClaimState();
      }
    }

    if (
      this.props.claim?._id != prevProps.claim?._id &&
      this.props.claim?._id
    ) {
      this._loadClaimState();
    }
  }

  loadMeals() {
    this.setState({
      mealsLoading: true,
    });

    APIV2.getMeals()
      .then((data) => {
        let mealOptions = [];

        for (let i = 0; i < data.data.meals.length; i++) {
          let meal = data.data.meals[i];

          if (_.findWhere(mealOptions, { value: meal?.sku })) {
            continue;
          }

          let category = _.findWhere(meal?.categories, { name: "Lean Cheats" })
            ? "Lean Cheats"
            : meal?.planType?.name;

          if (
            meal?.planType?.name == "Athlete" &&
            _.findWhere(meal?.categories, { name: "Lean Cheats" })
          ) {
            continue;
          }

          mealOptions.push({
            value: meal?.sku,
            mealID: meal?._id,
            label: meal?.name,
            category,
            planType: meal?.planType?.name,
            planTypeID: meal?.planType?._id,
          });
        }

        this.setState({
          meals: data.data.meals,
          mealOptions: mealOptions,
        });
      })
      .finally(() => {
        this.setState({
          mealsLoading: false,
        });
      });
  }

  async loadScannedMeals(data) {
    this.setState({
      loadingScanned: true,
    });

    let claimedMeals = this.state.claimedMeals?.length
      ? this.state.claimedMeals
      : [];

    this.setState({
      totalCount: data?.length,
    });

    for (let i = 0; i < data.length; i++) {
      let claim = data[i];

      console.log(claim);

      this.setState({
        uploadCount: i + 1,
      });

      let planTypeName = claim?.meal?.planType?.name;

      if (_.findWhere(claim?.meal?.categories, { name: "Lean Cheats" })) {
        planTypeName = "Lifestyle";
      }

      const meal = _.find(this.state.meals, (m) => {
        return (
          m?.sku == claim?.meal?.sku &&
          m?.planType?.name?.trim() == planTypeName
        );
      });

      let claimedMeal = {
        _id: StringUtils.uuid(),
        meal: {
          sku: meal?.sku,
          name: meal?.name,
          planType: meal?.planType,
          planTypeID: meal?.planType?._id,
          _id: meal?._id,
        },
        code1: claim?.code1,
        code2: claim?.code2,
        image: "",
        imageFile: {},
        manualCodes: {
          value: claim?.code1 + "-" + claim?.code2,
        },
      };

      if (claim?.image) {
        try {
          let file = new FormData();

          //console.log("BEFORE BLOB", claim?.image);

          let b = await StringUtils.fetchFile(claim?.image);

          console.log("IMAGE", claim?.image);

          if (b) {
            b = await StringUtils.base64ImageToBlob(b?.data, "image/jpeg");
          }

          //console.log("BLOB", b);

          file.append("file", b, StringUtils.uuid() + ".jpg");

          let res = null;

          try {
            res = await APIV2.uploadPrivateFile(file);
          } catch (e) {
            console.error(e);

            res = null;
          }

          if (!res) {
            continue;
          }

          //console.log("RESULT", res?.data?.data);

          claimedMeal.imageFile = {
            url: res.data?.data?.url,
            mimetype: "image/jpeg",
            cdn: "private",
            fileName: `${claim?.meal?.name?.replace(" ", "_")}+${
              claim?.code1
            }-${claim?.code2}.jpeg`,
          };

          claimedMeal.imageCDNURL = await this.getImageURL(
            claimedMeal?.imageFile?.url
          );
        } catch (e) {
          console.error(e);
        }
      }

      console.log("CDN URL", claimedMeal?.imageCDNURL);

      claimedMeals.push(claimedMeal);
    }

    for (let i = 0; i < claimedMeals.length; i++) {
      let claim = data[i];

      if (claim?.originalFileURL) {
        try {
          Filesystem.deleteFile({
            path: claim?.originalFileURL,
          });
        } catch (e) {
          console.error(e);
        }
      }
    }

    this.setState({
      claimedMeals,
      loadingScanned: false,
    });
  }

  componentDidMount() {
    if (this.props.open) {
      this.loadMeals();
    }

    PubSub.subscribe(Event.LOOSE_SEAL_SCANNER.COMPLETE, (data) => {
      if (data?.length) {
        this.loadScannedMeals(data);
      }
    });

    PubSub.subscribe(Event.LOOSE_SEAL_SCANNER.LOADED, () => {
      this.setState({
        loadingScanner: false,
      });
    });
  }

  toggleModal() {
    this.setState({
      reason: "",
      notes: "",
      name: "",
      pendingMeals: [],
      claimedMeals: [],
    });

    this.props.onClose();
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  updateReason() {
    this.setState({ locationSubmitting: true });
  }

  async getImageURL(privateURL) {
    return new Promise((resolve) => {
      APIV2.getPrivateFileURL(privateURL).then(
        (data) => {
          resolve(data.data.url);
        },
        (e) => {
          console.error(e);

          resolve("");
        }
      );
    });
  }

  validClaimForm() {
    if (!this.state.name) {
      return false;
    }

    if (!this.state.claimedMeals?.length) {
      return false;
    }

    for (let i = 0; i < this.state.claimedMeals?.length; i++) {
      const meal = this.state.claimedMeals[i];

      if (!meal?.meal?.sku) {
        return false;
      }

      if (!meal?.imageFile?.url) {
        return false;
      }

      if (
        _.filter(
          this.state.claimedMeals,
          (m) =>
            m?.code1 == meal?.code1 &&
            m.code2 == meal?.code2 &&
            m?.code1 &&
            m?.code2
        ).length > 1
      ) {
        return false;
      }
    }

    if (this.state.claimErrors?.length) {
      for (let i = 0; i < this.state.claimedMeals?.length; i++) {
        const claim = this.state.claimedMeals[i];

        if (
          _.findWhere(this.state.claimErrors, {
            code1: claim?.code1,
            code2: claim?.code2,
          })
        ) {
          return false;
        }
      }
    }

    return true;
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  async submit() {
    this.setState({
      submitting: true,
      error: "",
      claimErrors: [],
    });

    let claims = this.state.claimedMeals.map((c) => {
      return {
        meal: c?.meal,
        code1: c.code1,
        code2: c.code2,
        imageFile: c?.imageFile,
      };
    });

    let claimErrors = [];

    for (let i = 0; i < claims.length; i++) {
      const claim = claims[i];

      try {
        if (!claim?.code1 || !claim?.code2) {
          continue;
        }

        let res = await APIV2.getProductionQualityClaims(
          1,
          100,
          {
            "claims.code1": claim?.code1,
            "claims.code2": claim?.code2,
          },
          null
        );

        if (res?.data?.claims?.length) {
          claimErrors.push({
            sku: claim?.meal?.sku,
            code1: claim?.code1,
            code2: claim?.code2,
            error:
              "A claim has already been placed for this meal's unique code. Please remove to continue.",
          });
        }
      } catch (e) {
        console.error(e);
      }
    }

    if (claimErrors?.length) {
      this.setState({
        claimErrors,
        submitting: false,
      });

      return;
    }

    if (this.props.claim) {
      let payload = {
        submittedByName: this.state.name,
        notes: this.state.notes ? this.state.notes : null,
        claims,
        storeID: this.props.store?._id,
      };

      APIV2.modifyProductionQualityClaim(this.props?.claim?._id, payload)
        .then(
          (data) => {
            this.toggleModal();

            swal({
              title: "Claim Updated",
              text: `Your claim has been successfully updated. When you're ready to submit your claim, click on the claim & click submit.`,
              icon: "success",
            });

            PubSub.publish(Event.QUALITY_CLAIM.MODIFIED, data.data);
          },
          (e) => {
            this.setError(
              "error",
              `Unable to modify claim: ${
                e?.response?.message ?? "unknown error occurred. Try again."
              }`
            );
          }
        )
        .finally(() => {
          this.setState({
            submitting: false,
          });
        });
    } else {
      let payload = {
        submittedByName: this.state.name,
        notes: this.state.notes ? this.state.notes : null,
        claims,
        storeID: this.props.store?._id,
      };

      APIV2.createProductionQualityClaim(payload)
        .then(
          (data) => {
            this.toggleModal();

            swal({
              title: "Claim Created",
              text: `Your claim has been successfully created. When you're ready to submit your claim, click on the claim & click submit.`,
              icon: "success",
            });

            PubSub.publish(Event.QUALITY_CLAIM.CREATED, data.data);
          },
          (e) => {
            this.setError(
              "error",
              `Unable to create claim: ${
                e?.response?.message ?? "unknown error occurred. Try again."
              }`
            );
          }
        )
        .finally(() => {
          this.setState({
            submitting: false,
          });
        });
    }
  }

  claimError(code1, code2) {
    return _.findWhere(this.state.claimErrors, { code1, code2 });
  }

  claimedIsLeanCheat(claimed) {
    return _.findWhere(claimed?.meal?.categories, { name: "Lean Cheats" })
      ? true
      : false;
  }

  render() {
    return (
      <>
        <Drawer
          backdrop="static"
          className="pln-drawer-scannerhide"
          size="md"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.modify ? "Modify" : "New"}&nbsp;Quality Claim
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={this.state.submitting || !this.validClaimForm()}
                color="primary"
                onClick={this.submit.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>{this.props.claim ? "Save" : "Create"}</>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            <FormGroup>
              <h4 className="mb-0">
                Submitted By <span className="text-danger">*</span>
              </h4>
              <p className="mb-3 mt-0 small" style={{ lineHeight: 1.2 }}>
                Enter your name below
              </p>
              <Input
                type="text"
                name="name"
                onChange={this.handleInputChange.bind(this)}
                placeholder="Name"
                value={this.state.name}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h4 className="mb-0">Claim Information & Notes</h4>
              <p className="mb-3 mt-0 small" style={{ lineHeight: 1.2 }}>
                Enter any extra information you think would be helpful for
                processing your claim.
              </p>
              <Input
                type="textarea"
                name="notes"
                onChange={this.handleInputChange.bind(this)}
                placeholder="Enter notes"
                value={this.state.notes}
              ></Input>
            </FormGroup>
            <FormGroup>
              <Whisper
                placement="auto"
                preventOverflow={true}
                enterable={true}
                trigger="click"
                speaker={
                  <Popover
                    className="p-0"
                    style={{ width: "calc(100% - 20px)", maxWidth: 400 }}
                  >
                    <div className="p-3 border-bottom">
                      <h3 className="m-0">Identify Meal Unique Codes</h3>
                    </div>
                    <div className="p-3">
                      <img
                        src={uniqueCodeImg}
                        alt="img"
                        style={{ width: "100%" }}
                      ></img>
                    </div>
                  </Popover>
                }
              >
                <div className="cursor-pointer p-3 rounded border border-lighter bg-superlight text-dark">
                  <i className="mdi mdi-information-outline mr-2"></i>Click here
                  to learn how to identify and document a meal's unique code
                </div>
              </Whisper>
            </FormGroup>
            <FormGroup>
              <h4 className="mb-0">Meals</h4>
              <p className="mt-0 mb-3 small" style={{ lineHeight: 1.2 }}>
                Add all meals you would like to submit a claim for.
              </p>
              {Capacitor.isNativePlatform() ? (
                <>
                  {this.state.loadingScanned ? (
                    <div className="p-3 border rounded border-dark text-dark mb-4">
                      <Spinner size="sm"></Spinner>&nbsp;Uploading{" "}
                      {this.state.uploadCount} of {this.state.totalCount}{" "}
                      Captured Meals
                    </div>
                  ) : (
                    <Button
                      className="mb-4 p-3 mt-4"
                      outline
                      color="dark"
                      block
                      size="lg"
                      style={{ fontSize: 16 }}
                      disabled={this.state.loadingScanner}
                      onClick={() => {
                        PubSub.publish(Event.LOOSE_SEAL_SCANNER.OPEN);

                        this.setState({
                          loadingScanner: true,
                        });
                      }}
                    >
                      {this.state.loadingScanner ? (
                        <>
                          <Spinner size="sm" className="mr-2"></Spinner>Loading
                          Camera...
                        </>
                      ) : (
                        "Capture With Camera"
                      )}
                    </Button>
                  )}
                </>
              ) : (
                <div className="pb-4">
                  <Button
                    disabled={this.state.loadingScanner}
                    size="lg"
                    className="mb-4 p-3"
                    style={{ fontSize: 16 }}
                    block={true}
                    outline
                    color="primary"
                    onClick={() => {
                      let claimedMeals = this.state.claimedMeals?.length
                        ? this.state.claimedMeals
                        : [];

                      claimedMeals.push({
                        _id: StringUtils.uuid(),
                        meal: null,
                        code1: "",
                        code2: "",
                        image: "",
                        imageFile: "",
                        manualCodes: {
                          value: null,
                        },
                      });

                      this.setState({
                        claimedMeals,
                      });
                    }}
                  >
                    Manually Add Meal
                  </Button>
                </div>
              )}
              {this.state.loading ? (
                <div className="text-center">
                  <Spinner size="sm"></Spinner>
                </div>
              ) : null}
              {this.state.claimedMeals?.length ? (
                <>
                  {this.state.claimedMeals.map((claimed, i) => (
                    <div className="mb-2 pb-4 border-bottom" key={i}>
                      <h4>Meal #{i + 1}</h4>
                      {this.claimError(claimed?.code1, claimed?.code2) ? (
                        <>
                          <p
                            className="small mt--2 mb-3 text-danger"
                            style={{ lineHeight: 1.2 }}
                          >
                            {
                              this.claimError(claimed?.code1, claimed?.code2)
                                ?.error
                            }
                          </p>
                        </>
                      ) : null}
                      <Row className="align-items-center">
                        <Col xs="auto" className="pr-0">
                          {claimed?.imageFile ? (
                            <>
                              <div
                                style={{
                                  height: 70,
                                  width: 70,
                                  background: `url(${claimed?.imageCDNURL}) center center / contain no-repeat`,
                                }}
                                className="rounded border cursor-pointer"
                                onClick={() => {
                                  this.setState({
                                    showUpload: true,

                                    claimUpload: claimed,
                                  });
                                }}
                              ></div>
                            </>
                          ) : (
                            <Button
                              style={{ height: 70, width: 70 }}
                              outline
                              color="dark"
                              className="btn-icon-only m-0"
                              onClick={() => {
                                this.setState({
                                  showUpload: true,
                                  claimUpload: claimed,
                                });
                              }}
                            >
                              <i className="mdi mdi-image-plus"></i>
                            </Button>
                          )}
                        </Col>
                        <Col xs="" style={{ maxWidth: "calc(100% - 170px)" }}>
                          <SelectPicker
                            placeholder="Select Meal"
                            loading={this.state.mealsLoading}
                            data={this.state.mealOptions}
                            value={claimed?.meal?.sku}
                            onChange={(v) => {
                              console.log(v);

                              let pendingClaims = this.state.claimedMeals?.map(
                                (claim) => {
                                  return _.clone(claim);
                                }
                              );

                              console.log(pendingClaims);

                              if (!pendingClaims[i].meal) {
                                pendingClaims[i].meal = {};
                              }

                              let opt = _.findWhere(this.state.mealOptions, {
                                value: v,
                              });

                              let meal = _.find(this.state.meals, (m) => {
                                return (
                                  m?.sku == v &&
                                  m?.planType?.name == opt?.planType
                                );
                              });

                              console.log(meal, opt);

                              pendingClaims[i].meal = {
                                sku: v,
                                name: meal?.name,
                                planType: {
                                  _id: meal?.planType?._id,
                                  name: meal?.planType?.name,
                                },
                                categories: meal?.categories,
                                planTypeID: meal?.planType?._id,
                                _id: meal?._id,
                              };

                              this.setState({
                                claimedMeals: pendingClaims,
                              });
                            }}
                            groupBy="category"
                            block
                            placement="autoVertical"
                            preventOverflow={true}
                          ></SelectPicker>
                          {claimed?.code1 && claimed?.code2 ? (
                            <>
                              <p className="small text-dark mb-0 mt-2">
                                Unique Code: {claimed?.code1}-{claimed?.code2}
                              </p>
                            </>
                          ) : null}
                        </Col>
                        <Col xs="auto" className="pl-0">
                          <Button
                            size="sm"
                            outline
                            color="danger"
                            className="btn-icon-only"
                            style={{ width: 70, height: 70 }}
                            onClick={() => {
                              let claimedMeals = this.state.claimedMeals;

                              claimedMeals.splice(i, 1);

                              this.setState({
                                claimedMeals,
                              });
                            }}
                          >
                            <i className="mdi mdi-delete"></i>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              ) : null}
            </FormGroup>
          </Drawer.Body>
        </Drawer>
        <FileUploadDrawer
          cdn="private"
          compressUploads={true}
          size="sm"
          onComplete={async (files) => {
            console.log(files);

            let claimedMeals = this.state.claimedMeals;

            if (files.length) {
              const idx = _.findIndex(claimedMeals, {
                _id: this.state.claimUpload?._id,
              });

              if (idx != -1) {
                claimedMeals[idx].imageFile = files[0];

                claimedMeals[idx].imageCDNURL = await this.getImageURL(
                  files[0].url
                );
              }
            }

            this.setState({
              showUpload: false,
              claimedMeals,
            });
          }}
          open={this.state.showUpload}
          onClose={() => {
            this.setState({
              showUpload: false,
            });
          }}
        ></FileUploadDrawer>
      </>
    );
  }
}

export default withRouter(NewQualityClaimDrawer);
