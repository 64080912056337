import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Constant from "lib/Constant";

class CustomerHoldDrawer extends React.Component {
  state = {
    reason: "",
    notes: "",
    reasonOptions: Constant.CUSTOMER_HOLD_REASONS,
  };

  componentDidUpdate(prevProps) {}

  componentDidMount() {}

  toggleModal() {
    this.setState({
      reason: "",
      notes: "",
    });

    this.props.onClose();
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  hold() {
    this.setState({ submitting: true });

    APIV2.updateCustomerStatus(
      this.props.customer.storeID,
      this.props.customer._id,
      "HOLD"
    ).then(
      (data) => {
        APIV2.updateCustomerHoldReason(
          this.props?.customer?.storeID,
          this.props?.customer?._id,
          this.state.reason
        ).then(
          (data) => {
            APIV2.updateCustomerHoldNotes(
              this.props.customer?.storeID,
              this.props?.customer?._id,
              this.state.notes
            )
              .then(
                (data) => {
                  PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

                  this.toggleModal();
                },
                (e) => {
                  this.setError(
                    "error",
                    "Could not update member hold notes, please try again."
                  );
                }
              )
              .finally(() => {
                this.setState({
                  submitting: false,
                });
              });
          },
          (e) => {
            this.setError(
              "error",
              "Could not update member hold reason, please try again."
            );

            this.setState({
              submitting: false,
            });
          }
        );
      },
      (err) => {
        this.setError("error", "Unable to put member on hold. Try again.");

        this.setState({
          submitting: false,
        });
      }
    );
  }

  updateReason() {
    this.setState({ locationSubmitting: true });
  }

  updateNotes() {}

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Place Member On Hold
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  !this.state.reason ||
                  (this.state.reason == "Other" && !this.state.notes)
                }
                color="danger"
                onClick={this.hold.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  "Hold"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            <FormGroup>
              <h5>
                Reason
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <SelectPicker
                placement="auto"
                cleanable={true}
                size="md"
                placeholder="Select A Reason"
                data={this.state.reasonOptions}
                style={{}}
                value={this.state.reason}
                block
                onChange={(val) => {
                  this.setState({ reason: val });
                }}
              />
            </FormGroup>
            <FormGroup>
              <h5>
                Additional Notes
                {this.state.reason == "Other" ? (
                  <i
                    className="mdi mdi-octagram text-danger pl-1"
                    style={{ fontSize: "80%", position: "relative", top: -1 }}
                  ></i>
                ) : null}
              </h5>
              <Input
                type="textarea"
                name="notes"
                onChange={this.handleInputChange.bind(this)}
                placeholder="Enter notes"
                value={this.state.notes}
              ></Input>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(CustomerHoldDrawer);
