import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeaderV2 from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker, Tooltip, Whisper } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import CustomerCreateDrawer from "components/Drawers/CustomerCreateDrawer";
import TaskCreateDrawer from "components/Drawers/TaskCreateDrawer";
import TaskModifyDrawer from "components/Drawers/TaskModifyDrawer";
import TaskDetailDrawer from "components/Drawers/TaskDetailDrawer";
import TechSupportTicketDrawer from "components/Drawers/TechSupportTicketDrawer";
import SupportTicketDetailDrawer from "components/Drawers/SupportTicketDetailDrawer";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";

class FranDevSupportTicketListView extends React.Component {
  state = {
    active: {
      id: "Open Tickets Assigned To Me",
      name: "Open Tickets Assigned To Me",
    },
    tabs: [
      {
        name: "Open Tickets Assigned To Me",
        id: "Open Tickets Assigned To Me",
      },

      {
        name: "Unit Operations",
        id: "Unit Operations",
      },

      {
        name: "Fran-Dev",
        id: "Fran-Dev",
      },
      {
        name: "Fran-Support",
        id: "Fran-Support",
      },
      {
        name: "Technology",
        id: "Technology",
      },
      {
        name: "Production",
        id: "Production",
      },
      {
        name: "Marketing",
        id: "Marketing",
      },
      {
        name: "Open Tickets Created By Me",
        id: "Open Tickets Created By Me",
      },
      {
        name: "All Tickets Created By Me",
        id: "All Tickets Created By Me",
      },
      {
        name: "All Open Tickets",
        id: "All Open Tickets",
      },
      {
        name: "All Resolved Tickets",
        id: "All Resolved Tickets",
      },
    ],
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 15,
    filters: null,
    orderBy: {
      dueDate: 1,
    },
    sortByOption: "dueDate",
    sortByDirection: "ascending",
    searchTasks: [],
  };

  componentDidUpdate(prevProps) {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }

    if (prevProps?.match?.params.storeID != this.props.match?.params?.storeID) {
      this.handleTabChange(this.state.active, true);
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }

    this.loadStores();

    PubSub.subscribe(Event.TASK.CREATED, (m) => {
      this.loadTasks(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });

    PubSub.subscribe(Event.TASK.MODIFIED, (a) => {
      this.loadTasks(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );

      if (this.state.detailTask?._id == a?._id) {
        this.setState({
          detailTask: a,
        });
      }
    });

    PubSub.subscribe(Event.TASK.DELETED, (a) => {
      this.loadTasks(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });
  }

  onFileChange(e) {
    this.setState({ selectedFile: e.target.files[0] });
  }

  testImageUpload(e) {
    e.preventDefault();

    // Create an object of formData
    let formData = new FormData();

    formData.append("file", this.state.selectedFile);

    console.log(formData);

    API.setMealImage(1, formData).then(
      (data) => {
        console.log(data);
      },
      (e) => {
        console.error(e);
      }
    );
  }

  loadTasks(page = 1, count = 15, filters = null, orderBy) {
    this.setState({
      loadingTasks: true,
    });

    APIV2.getTechSupportTickets(page, count, filters, orderBy)
      .then(
        (data) => {
          if (data && data.data) {
            let tasks = [];

            if (data.data.tickets?.length) {
              tasks = data.data.tickets;
            }

            this.setState({
              tasks,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the tasks. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingTasks: false,
        });
      });
  }

  performSearch(page) {
    console.log(this.state.search);

    this.setState({
      loadingTasks: true,
      searchTasks: [],
    });

    let orderBy = {
      dueDate: 1,
    };

    let filters = {
      $or: [
        {
          subject: { $regex: this.state.search, $options: "i" },
        },
        {
          desiredSolution: { $regex: this.state.search, $options: "i" },
        },
      ],
      supportTicket: true,
      type: "Fran-Support",
      supportType: { $ne: "Production" },
    };

    APIV2.getTechSupportTickets(page, 25, filters, orderBy)
      .then(
        (data) => {
          if (data && data.data) {
            let tasks = [];

            if (data.data.tickets?.length) {
              tasks = data.data.tickets;
            }

            this.setState({
              searchTasks: tasks,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the tasks. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingTasks: false,
        });
      });

    APIV2.simpleSearchTasksForStore(
      this.props?.match?.params?.storeID,
      this.state.search
    )
      .then(
        (data) => {
          this.setState({
            searchResultTasks: data.data.tasks,
            searchResults: true,
          });
        },
        (e) => {
          console.error(e);

          this.setState({ searchResults: true, searchResultTasks: [] });
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
      searchTasks: [],
    });
  }

  async loadTabCounts(force = false) {
    if (this.state.tabsLoading) {
      return;
    }

    this.setState(
      {
        tabsLoading: true,
      },
      () => {
        let out = this.state.tabs?.map((tab) => {
          if (force || tab?.countLoading === undefined) {
            tab.count = 0;
            tab.countLoading = true;
          }

          return _.clone(tab);
        });

        this.setState({ tabs: out }, async () => {
          let tabs = this.state.tabs?.map((t) => {
            const { filters } = this.getFilters(t);

            return {
              id: t.id,
              collection: "Tasks",
              query: filters,
            };
          });

          try {
            let data = await APIV2.getTabCounts(tabs);

            let out = this.state.tabs?.map((tab) => {
              const res = _.findWhere(data.data?.tabs, { id: tab.id });

              tab.count = res?.count ? res.count : 0;
              tab.countLoading = false;

              return tab;
            });

            this.setState({
              tabs: out,
            });
          } catch (e) {
            console.error(e);
          }

          this.setState({
            tabsLoading: false,
          });
        });
      }
    );
  }

  getFilters(option) {
    let filters = null;
    let orderBy = this.state.orderBy ? this.state.orderBy : { dueDate: 1 };

    let user = LocalStorage.get(Constant.CACHE.USER);

    if (option.id == "Open Tickets Assigned To Me") {
      filters = {
        status: { $nin: ["Resolved"] },
        supportTicket: true,
        assignedTo: user?._id,
        deleted: { $ne: true },
      };
    } else if (option.id == "Open Tickets Created By Me") {
      filters = {
        status: { $nin: ["Resolved"] },
        supportTicket: true,
        "createdBy._id": user?._id,
        deleted: { $ne: true },
      };
    } else if (option.id == "All Tickets Created By Me") {
      filters = {
        supportTicket: true,
        "createdBy._id": user?._id,
        deleted: { $ne: true },
      };
    } else if (option.id == "All Assigned To Me") {
      filters = {
        supportTicket: true,
        supportType: { $ne: "Production" },
        assignedTo: user?._id,
        deleted: { $ne: true },
      };
    } else if (option.id == "Unit Operations") {
      filters = {
        status: { $nin: ["Resolved"] },
        supportTicket: true,
        supportType: "Unit Operations",
        deleted: { $ne: true },
      };
    } else if (option.id == "Fran-Dev") {
      filters = {
        status: { $nin: ["Resolved"] },
        supportTicket: true,
        deleted: { $ne: true },
        supportType: "Fran-Dev",
      };
    } else if (option.id == "Fran-Support") {
      filters = {
        status: { $nin: ["Resolved"] },
        supportTicket: true,
        supportType: "Fran-Support",
        deleted: { $ne: true },
      };
    } else if (option.id == "Technology") {
      filters = {
        status: { $nin: ["Resolved"] },
        supportTicket: true,
        supportType: "Technology",
        deleted: { $ne: true },
      };
    } else if (option.id == "Production") {
      filters = {
        status: { $nin: ["Resolved"] },
        supportTicket: true,
        supportType: "Production",
        deleted: { $ne: true },
      };
    } else if (option.id == "Marketing") {
      filters = {
        status: { $nin: ["Resolved"] },
        supportTicket: true,
        supportType: "Marketing",
        deleted: { $ne: true },
      };
    } else if (option.id == "All Open Tickets") {
      filters = {
        status: { $nin: ["Resolved"] },
        supportTicket: true,
        supportType: { $ne: "Production" },
      };
    } else if (option.id == "Response Needed") {
      filters = {
        supportTicket: true,
        requireResponse: true,
        status: { $nin: ["Resolved"] },
        supportType: { $ne: "Production" },
        deleted: { $ne: true },
      };
    } else if (option.id == "All Resolved Tickets") {
      filters = {
        status: { $in: ["Resolved"] },
        supportTicket: true,
        supportType: { $ne: "Production" },
        deleted: { $ne: true },
      };
    }

    return { filters, orderBy };
  }

  handleTabChange(option, force = false) {
    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    let user = LocalStorage.get(Constant.CACHE.USER);

    const { filters, orderBy } = this.getFilters(option);

    this.setState({
      filters,
      orderBy,
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadTasks(1, this.state.count, filters, orderBy);
    this.loadTabCounts();
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    } else {
      this.setState({
        searchLoading: false,
        searchResults: false,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  loadStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStores()
      .then(
        (data) => {
          if (data && data.data) {
            let store = _.findWhere(data.data.stores, {
              _id: this.props?.match?.params?.storeID,
            });

            this.setState({ stores: data.data.stores, store });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the stores. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadTasks(
      page,
      this.state.count,
      this.state.filters,
      this.state.orderBy
    );
  }

  formatDueDate(dateString, status) {
    if (!dateString) {
      return "--";
    }

    console.log(dateString);

    let now = moment();
    let due = moment(dateString);

    return due.format("MMM DD, YYYY");
  }

  formatStatus(status) {
    if (!status) {
      return "--";
    }

    if (status == "On Hold") {
      return <Badge color="danger">{status}</Badge>;
    }

    if (status == "Resolved") {
      return <Badge color="medium">{status}</Badge>;
    }

    return <Badge color="success">{status}</Badge>;
  }

  row(c) {
    return (
      <Row className="align-items-center">
        <Col xs="6" sm="6" md="6">
          <Whisper
            speaker={
              <Tooltip>
                <div className="text-left">
                  <p className="mb-1" style={{ fontSize: "13px" }}>
                    {c.store?.name}
                  </p>
                  <h4 className="mb-1 text-white">{c.subject}</h4>
                  <p className="mb-0" style={{ fontSize: "13px" }}>
                    {c.supportType}
                    {c?.customer ? ` for Member: ${c?.customer?.name}` : ""}
                    {c?.lead ? ` for Lead: ${c?.lead?.name}` : ""}
                  </p>
                </div>
              </Tooltip>
            }
            placement="auto"
            trigger="hover"
            delayOpen={400}
          >
            <div>
              <p className="mb-0" style={{ fontSize: "13px" }}>
                {c.store?.name}
              </p>
              <h4 className="mb-0 text-truncate text-dark">{c.subject}</h4>
              <p className="mb-0 text-truncate" style={{ fontSize: "13px" }}>
                {c.supportType}
                {c?.customer ? ` for Member: ${c?.customer?.name}` : ""}
                {c?.lead ? ` for Lead: ${c?.lead?.name}` : ""}
              </p>
            </div>
          </Whisper>
        </Col>

        <Col xs="6" sm="6" md="2" className="text-truncate">
          {this.formatStatus(c.status)}
        </Col>
        <Col xs="6" sm="6" md="2" className="text-truncate text-dark">
          <Whisper
            speaker={
              <Tooltip>
                <p className="m-0 text-white">
                  {c?.assignedTo ? c?.assignedTo?.name : "(unassigned)"}
                </p>
              </Tooltip>
            }
            placement="auto"
            trigger={`${c?.assignedTo ? "hover" : "none"}`}
            delayOpen={400}
          >
            <span>{c?.assignedTo ? c?.assignedTo?.name : "--"}</span>
          </Whisper>
        </Col>
        <Col xs="6" sm="6" md="2" className="text-dark">
          {moment(c?.dueDate).format("MM/DD/YY")}
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeaderV2
            sticky={true}
            title="Franchise Support Tickets"
            showBackButton={false}
            primaryAction={{
              name: <>Create</>,
              onClick: () => {
                this.setState({
                  createOpen: true,
                });
              },
            }}
            secondaryActions={
              [
                /*{
                name: <>View As Table</>,
                onClick: () => {
                  this.setState({
                    viewMode: "table",
                  });
                },
                disabled: this.state.viewMode == "table",
              },
              {
                name: <>View As Board</>,
                onClick: () => {
                  this.setState({
                    viewMode: "board",
                  });
                },
                disabled: this.state.viewMode == "board",
              },*/
              ]
            }
            tabs={
              <CardSelectorHeader
                compact={true}
                value={this.state.active}
                options={this.state.tabs}
                showCount={true}
                onChange={this.handleTabChange.bind(this)}
              ></CardSelectorHeader>
            }
          />

          <div
            className="pb-3 bg-white border-bottom"
            style={{
              marginLeft: -15,
              marginRight: -15,
              marginTop: "-1.5rem",
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: "1rem",
            }}
          >
            <Row className="align-items-center">
              <Col xs="12" sm="" className="">
                <Input
                  type="text"
                  placeholder="Search tasks..."
                  size="sm"
                  name="search"
                  value={this.state.search}
                  onChange={this.handleSearchChange.bind(this)}
                ></Input>
              </Col>
            </Row>
            <Row className="align-items-center mt-2">
              {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
              <Col xs="" sm="" className="">
                {this.state.search ? (
                  <>
                    Found{" "}
                    {this.state.searchTasks?.length
                      ? StringUtils.numberFormat(this.state.searchTasks?.length)
                      : "0"}{" "}
                    tickets
                    {this.state.searchTasks?.length == 1 ? "" : "s"} matching
                    your search.
                  </>
                ) : (
                  <div>
                    {this.state.result?.start && this.state.result?.end ? (
                      <>
                        Showing{" "}
                        {StringUtils.numberFormat(this.state.result?.start)}-
                        {StringUtils.numberFormat(this.state.result?.end)}
                        {this.state.result?.total ? (
                          <>
                            &nbsp;of{" "}
                            {StringUtils.numberFormat(this.state.result?.total)}{" "}
                            tickets
                          </>
                        ) : null}
                      </>
                    ) : (
                      <>---</>
                    )}
                  </div>
                )}
              </Col>
              <Col xs="auto" sm="auto" className="pl-2 pl-sm-3 text-right">
                <Button
                  style={{ height: "28px" }}
                  size="sm"
                  color="secondary"
                  className="btn-icon-only"
                  outline
                  disabled={
                    this.state.page == 1 ||
                    !this.state.pagination?.hasPrevious ||
                    this.state.searchResults
                  }
                  onClick={() => {
                    this.incrementPage(-1);
                  }}
                >
                  <i
                    className="mdi mdi-chevron-left"
                    style={{ fontSize: "18px" }}
                  ></i>
                </Button>
                <Button
                  style={{ height: "28px" }}
                  size="sm"
                  color="secondary"
                  disabled={
                    !this.state.pagination?.hasNext || this.state.searchResults
                  }
                  className="btn-icon-only"
                  outline
                  onClick={() => {
                    this.incrementPage(1);
                  }}
                >
                  <i
                    className="mdi mdi-chevron-right"
                    style={{ fontSize: "18px" }}
                  ></i>
                </Button>
              </Col>
            </Row>
          </div>

          <div
            style={{ marginLeft: -15, marginRight: -15 }}
            className="bg-white"
          >
            <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
              <Row className="align-items-center">
                <Col xs="6" sm="6" md="6">
                  <h4 className="mb-0">Ticket</h4>
                </Col>

                <Col xs="6" sm="6" md="2">
                  <h4 className="mb-0">Status</h4>
                </Col>
                <Col xs="6" sm="6" md="2">
                  <h4 className="mb-0">Assigned To</h4>
                </Col>
                <Col xs="6" sm="6" md="2">
                  <h4
                    onClick={() => {
                      this.setState(
                        {
                          orderBy: {
                            dueDate: this.state.orderBy?.dueDate == 1 ? -1 : 1,
                          },
                        },
                        () => {
                          console.log("ORDER BY");

                          this.loadTasks(
                            1,
                            this.state.count,
                            this.state.filters,
                            this.state.orderBy
                          );
                        }
                      );
                    }}
                    className={`mb-0 cursor-pointer`}
                  >
                    Due Date{" "}
                    <i
                      className={`mdi mdi-sort-${
                        this.state.orderBy?.dueDate == 1
                          ? "ascending"
                          : "descending"
                      } pl-1`}
                    ></i>
                  </h4>
                </Col>
              </Row>
            </div>
            <div>
              {this.state.loadingTasks || this.state.searchLoading ? (
                <>
                  <div
                    className="text-center p-3"
                    style={{
                      zIndex: 1,
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      background: "rgba(255, 255, 255, 0.6)",
                    }}
                  >
                    <Spinner
                      style={{ position: "relative", top: "15vh" }}
                      color="medium"
                      size=""
                    ></Spinner>
                  </div>
                </>
              ) : null}
              {this.state.search ? (
                <>
                  <div>
                    {!this.state.searchTasks?.length &&
                    !this.state.loadingTasks ? (
                      <div className="px-3 py-5 border-bottom">
                        <Row>
                          <Col>No tickets found.</Col>
                        </Row>
                      </div>
                    ) : null}

                    {this.state.searchTasks?.map((c, i) => (
                      <div
                        className={`px-3 py-2 bg-superlight--hover cursor-pointer border-bottom`}
                        key={i}
                        onClick={() => {
                          this.setState({
                            detailTask: c,
                            detailOpen: true,
                          });
                        }}
                      >
                        {this.row(c)}
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div>
                    {!this.state.tasks?.length && !this.state.loadingTasks ? (
                      <div className="px-3 py-5 border-bottom">
                        <Row>
                          <Col>No tickets found.</Col>
                        </Row>
                      </div>
                    ) : null}

                    {this.state.tasks?.map((c, i) => (
                      <div
                        className={`px-3 py-2 bg-superlight--hover cursor-pointer border-bottom`}
                        key={i}
                        onClick={() => {
                          this.setState({
                            detailTask: c,
                            detailOpen: true,
                          });
                        }}
                      >
                        {this.row(c)}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </Container>
        <TechSupportTicketDrawer
          store={this.state.store}
          open={this.state.createOpen}
          storeMode={false}
          onClose={() => {
            this.setState({
              createOpen: false,
            });
          }}
          onCreate={(c) => {
            PubSub.publish(Event.TASK.CREATED, c);
          }}
        ></TechSupportTicketDrawer>
        <SupportTicketDetailDrawer
          store={this.state.store}
          open={this.state.detailOpen}
          task={this.state.detailTask}
          storeMode={false}
          onClose={() => {
            this.setState({
              detailOpen: false,
              detailTask: null,
            });
          }}
        ></SupportTicketDetailDrawer>
      </>
    );
  }
  f;
}

export default FranDevSupportTicketListView;
