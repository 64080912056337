import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
  Badge,
  FormGroup,
  Collapse,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import { DatePicker, SelectPicker } from "rsuite";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";
import Constant from "../../lib/Constant";
import _ from "underscore";
import moment from "moment";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import LinkUtils from "lib/LinkUtils";
import { de } from "date-fns/locale";
import swal from "sweetalert";

class FranDevBusinessesWidget extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    name: "",
    email: "",
  };

  modifyFranDevLeadBusiness(id, data) {
    this.setState({
      valSubmitting: true,
    });

    let payload = {
      ...data,
    };

    if (payload?.phone?.value) {
      payload.phone = "+1" + payload.phone.value;
    } else {
      payload.phone = null;
    }

    if (payload.units) {
      payload.units = parseInt(payload.units);
    }

    if (payload?.phoneInformation) {
      delete payload.phoneInformation;
    }

    if (payload.smsConversationSupported) {
      delete payload.smsConversationSupported;
    }

    APIV2.modifyFranDevLeadBusiness(this.props.lead?._id, id, payload)
      .then(
        (data) => {
          PubSub.publish(Event.LEAD.MODIFIED, data.data.lead);

          this.setState({
            [`editing${id}`]: false,
            [`values${id}`]: {},
          });
        },
        (e) => {
          this.setError(
            id,
            `Error modifying business: ${
              e?.response?.data?.error?.message ?? `Unknown error`
            }`
          );
        }
      )
      .finally(() => {
        this.setState({
          valSubmitting: false,
        });
      });
  }

  createFranDevLeadBusiness(data) {
    this.setState({
      valSubmitting: true,
    });

    let payload = {
      ...data,
    };

    if (payload?.phone?.value) {
      payload.phone = "+1" + payload.phone.value;
    } else {
      payload.phone = null;
    }

    if (payload.units) {
      payload.units = parseInt(payload.units);
    }

    if (payload?.phoneInformation) {
      delete payload.phoneInformation;
    }

    if (payload.smsConversationSupported) {
      delete payload.smsConversationSupported;
    }

    APIV2.createFranDevLeadBusiness(this.props.lead?._id, payload)
      .then(
        (data) => {
          PubSub.publish(Event.LEAD.MODIFIED, data.data.lead);

          this.setState({
            showCreate: false,
            [`valuesCreate`]: {},
          });
        },
        (e) => {
          this.setError(
            "create",
            `Error creating business: ${
              e?.response?.data?.error?.message ?? `Unknown error`
            }`
          );
        }
      )
      .finally(() => {
        this.setState({
          valSubmitting: false,
        });
      });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  deleteFranDevLeadBusiness(id) {
    swal({
      title: "Delete Business",
      text: `Are you sure you want to delete this business`,
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deletingBusiness: id,
      });

      APIV2.deleteFranDevLeadBusiness(this.props.lead?._id, id)
        .then(
          (data) => {
            PubSub.publish(Event.LEAD.MODIFIED, data.data.lead);

            this.setState({
              [`editing${id}`]: false,
              [`values${id}`]: {},
            });
          },
          (e) => {
            this.setError(
              id,
              `Error deleting business: ${
                e?.response?.data?.error?.message ?? `Unknown error`
              }`
            );
          }
        )
        .finally(() => {
          this.setState({
            deletingBusiness: false,
          });
        });
    });
  }

  render() {
    return (
      <>
        <Card className="mb-4 shadow border">
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Businesss</h3>
              </div>
              <Col xs="auto">
                {this.state.showCreate ? (
                  <>
                    <Button
                      size="sm"
                      outline
                      color="dark"
                      onClick={() => {
                        this.setState({
                          showCreate: false,
                          [`valuesCreate`]: {},
                        });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={
                        this.state.valSubmitting ||
                        !this.state.valuesCreate?.name
                      }
                      size="sm"
                      color="primary"
                      onClick={() => {
                        this.createFranDevLeadBusiness(this.state.valuesCreate);
                      }}
                    >
                      {this.state.valSubmitting ? (
                        <Spinner size="sm"></Spinner>
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    size="sm"
                    outline
                    color="primary"
                    className="btn-icon-only"
                    onClick={() => {
                      this.setState({
                        showCreate: true,
                        [`valuesCreate`]: {},
                      });
                    }}
                  >
                    <i className="mdi mdi-plus"></i>
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>
          {this.props.loading ? (
            <CardBody>
              <Spinner size="sm" color="dark" className="mr-2" /> Loading...
            </CardBody>
          ) : (
            <>
              {this.state.error ? (
                <>
                  <CardBody>
                    <Alert color="danger" className="mb-0 mt-0">
                      {this.state.error}
                    </Alert>
                  </CardBody>
                </>
              ) : null}
              {this.state.showCreate ? (
                <CardBody className="border-bottom py-3">
                  <div className="mt-3">
                    <Row className="align-items-center mb-3">
                      <Col xs="">
                        <h4 className="m-0">Create Business</h4>
                      </Col>
                    </Row>
                    <FormGroup className="mb-2">
                      <h5>
                        Name <span className="text-danger">*</span>
                      </h5>
                      <Input
                        bsSize="sm"
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={this.state[`valuesCreate`]?.name}
                        onChange={(e) => {
                          let c = this.state[`valuesCreate`];

                          console.log(c);

                          c.name = e.target.value;

                          this.setState({
                            [`valuesCreate`]: c,
                          });
                        }}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="mb-2">
                      <h5>Type</h5>
                      <SelectPicker
                        placeholder="Select a Type"
                        value={this.state[`valuesCreate`]?.type}
                        data={[
                          { label: "Franchise Unit" },
                          { label: "Franchising Company" },
                          {
                            label: "Private Company",
                          },
                          {
                            label: "Public Company",
                          },
                          {
                            label: "Other",
                          },
                        ]}
                        block
                        size="sm"
                        placement="auto"
                        preventOverflow={true}
                        searchable={false}
                        valueKey="label"
                        onChange={(value) => {
                          let business = this.state[`valuesCreate`];

                          business.type = value;

                          this.setState({
                            [`valuesCreate`]: business,
                          });
                        }}
                      ></SelectPicker>
                    </FormGroup>
                    <FormGroup className="mb-2">
                      <h5># Units</h5>
                      <Input
                        bsSize="sm"
                        type="number"
                        name="units"
                        placeholder="Units"
                        value={this.state[`valuesCreate`]?.units}
                        onChange={(e) => {
                          let c = this.state[`valuesCreate`];

                          c.units = e.target.value;

                          this.setState({
                            [`valuesCreate`]: c,
                          });
                        }}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="mb-2">
                      <h5>Email</h5>
                      <Input
                        bsSize="sm"
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={this.state[`valuesCreate`]?.email}
                        onChange={(e) => {
                          let c = this.state[`valuesCreate`];

                          c.email = e.target.value;

                          this.setState({
                            [`valuesCreate`]: c,
                          });
                        }}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="mb-2">
                      <h5>Phone</h5>
                      <NumberFormat
                        className="form-control form-control-sm"
                        format="(###) ###-####"
                        mask="_"
                        type="text"
                        name="phone"
                        placeholder="Phone"
                        value={this.state[`valuesCreate`]?.phone?.value}
                        onValueChange={(e, v) => {
                          let c = this.state[`valuesCreate`];

                          c.phone = e;

                          console.log(c);

                          this.setState({
                            [`valuesCreate`]: c,
                          });
                        }}
                      ></NumberFormat>
                    </FormGroup>
                    <FormGroup className="mb-2">
                      <h5>Address</h5>
                      <ReactGoogleAutocomplete
                        options={{ types: ["address"] }}
                        required
                        placeholder="Address"
                        apiKey={"AIzaSyA5-fbnedb5uXDBwbjnz0J_2eDe_9Gi2Cs"}
                        onPlaceSelected={(place) => {
                          console.log(place);

                          let c = this.state[`valuesCreate`];

                          c.address = place.formatted_address;
                          c.addressComponents = place.address_components;
                          c.geometry = place.geometry;
                          c.placeID = place.place_id;

                          this.setState({
                            [`valuesCreate`]: c,
                          });
                        }}
                        onChange={(e) => {
                          let c = this.state[`valuesCreate`];

                          c.address = e.target.value;

                          this.setState({
                            [`valuesCreate`]: c,
                          });
                        }}
                        name="marketName"
                        value={this.state[`valuesCreate`]?.address}
                        className="form-control form-control-sm"
                        //value={this.state.address1}
                      ></ReactGoogleAutocomplete>
                    </FormGroup>
                    <FormGroup className="mb-2">
                      <h5>Website URL</h5>
                      <Input
                        bsSize="sm"
                        type="text"
                        name="websiteURL"
                        placeholder="Website URL"
                        value={this.state[`valuesCreate`]?.websiteURL}
                        onChange={(e) => {
                          let c = this.state[`valuesCreate`];

                          c.websiteURL = e.target.value;

                          this.setState({
                            [`valuesCreate`]: c,
                          });
                        }}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="mb-2">
                      <h5>LinkedIn URL</h5>
                      <Input
                        bsSize="sm"
                        type="text"
                        name="linkedinURL"
                        placeholder="LinkedIn URL"
                        value={this.state[`valuesCreate`]?.linkedinURL}
                        onChange={(e) => {
                          let c = this.state[`valuesCreate`];

                          c.linkedinURL = e.target.value;

                          this.setState({
                            [`valuesCreate`]: c,
                          });
                        }}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="mb-2">
                      <h5>Instagram URL</h5>
                      <Input
                        bsSize="sm"
                        type="text"
                        name="instagramURL"
                        placeholder="Instagram URL"
                        value={this.state[`valuesCreate`]?.instagramURL}
                        onChange={(e) => {
                          let c = this.state[`valuesCreate`];

                          c.instagramURL = e.target.value;

                          this.setState({
                            [`valuesCreate`]: c,
                          });
                        }}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="mb-2">
                      <h5>Facebook URL</h5>
                      <Input
                        bsSize="sm"
                        type="text"
                        name="facebookURL"
                        placeholder="Facebook URL"
                        value={this.state[`valuesCreate`]?.facebookURL}
                        onChange={(e) => {
                          let c = this.state[`valuesCreate`];

                          c.facebookURL = e.target.value;

                          this.setState({
                            [`valuesCreate`]: c,
                          });
                        }}
                      ></Input>
                    </FormGroup>
                  </div>
                </CardBody>
              ) : null}
              {this.props.lead?.businesses?.map((business, i) => (
                <CardBody
                  key={i}
                  className={`py-3 ${
                    i != this.props?.lead?.businesses?.length - 1
                      ? "border-bottom"
                      : ""
                  }`}
                >
                  <Row className="align-items-center">
                    <Col
                      className="cursor-pointer"
                      onClick={() => {
                        if (this.state[`editing${business._id}`]) {
                          return;
                        }

                        this.setState({
                          [`open${business._id}`]:
                            !this.state[`open${business._id}`],
                        });
                      }}
                    >
                      <h3 className="mb-0">
                        {business.name}
                        {business?.units
                          ? `: ${business?.units} Unit${
                              business?.units > 1 ? "s" : ""
                            }`
                          : ""}
                      </h3>
                      {business.type ? (
                        <>
                          <p className="text-muted mb-0">{business.type}</p>
                        </>
                      ) : null}
                    </Col>
                    <Col xs="auto">
                      {this.state[`editing${business._id}`] ? (
                        <>
                          <Button
                            size="sm"
                            color="dark"
                            className="btn-icon-only"
                            onClick={() => {
                              this.setState({
                                [`editing${business._id}`]:
                                  !this.state[`editing${business._id}`],
                                [`open${business._id}`]: true,
                              });
                            }}
                          >
                            <i className="mdi mdi-close"></i>
                          </Button>
                          <Button
                            size="sm"
                            color="primary"
                            className="btn-icon-only"
                            disabled={this.state.valSubmitting}
                            onClick={() => {
                              this.modifyFranDevLeadBusiness(
                                business._id,
                                this.state[`values${business._id}`]
                              );
                            }}
                          >
                            {this.state.valSubmitting ? (
                              <Spinner size="sm" />
                            ) : (
                              <i className="mdi mdi-check"></i>
                            )}
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            size="sm"
                            outline
                            color="dark"
                            className="btn-icon-only"
                            onClick={() => {
                              let c = _.clone(business);

                              c.phone = {
                                value: c.phone
                                  ? c.phone?.replace("+1", "")
                                  : "",
                              };

                              console.log(c);

                              this.setState({
                                [`editing${business._id}`]:
                                  !this.state[`editing${business._id}`],
                                [`open${business._id}`]: true,
                                [`values${business._id}`]: c,
                              });
                            }}
                          >
                            <i className="mdi mdi-pencil"></i>
                          </Button>
                          <Button
                            size="sm"
                            outline
                            color="dark"
                            className="btn-icon-only"
                            onClick={() => {
                              this.setState({
                                [`open${business._id}`]:
                                  !this.state[`open${business._id}`],
                              });
                            }}
                          >
                            <i
                              className={`mdi mdi-chevron-${
                                this.state[`open${business._id}`]
                                  ? "down"
                                  : "right"
                              }`}
                            ></i>
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Collapse isOpen={this.state[`open${business._id}`]}>
                    {this.state[`editing${business._id}`] ? (
                      <>
                        <div className="mt-3">
                          <Row className="align-items-center mb-3">
                            <Col xs="">
                              <h4 className="m-0">Modify Business</h4>
                            </Col>
                            <Col xs="auto">
                              <Button
                                size="sm"
                                outline
                                color="danger"
                                className="btn-icon-only"
                                disabled={
                                  this.state.deletingBusiness == business._id
                                }
                                onClick={() => {
                                  this.deleteFranDevLeadBusiness(business._id);
                                }}
                              >
                                {this.state.deletingBusiness == business._id ? (
                                  <Spinner size="sm" />
                                ) : (
                                  <i className="mdi mdi-trash-can"></i>
                                )}
                              </Button>
                            </Col>
                          </Row>
                          <FormGroup className="mb-2">
                            <h5>
                              Name <span className="text-danger">*</span>
                            </h5>
                            <Input
                              bsSize="sm"
                              type="text"
                              name="name"
                              placeholder="Name"
                              value={this.state[`values${business?._id}`]?.name}
                              onChange={(e) => {
                                let c = this.state[`values${business._id}`];

                                console.log(c);

                                c.name = e.target.value;

                                this.setState({
                                  [`values${business._id}`]: c,
                                });
                              }}
                            ></Input>
                          </FormGroup>
                          <FormGroup className="mb-2">
                            <h5>Type</h5>
                            <SelectPicker
                              placeholder="Select a Type"
                              value={this.state[`values${business?._id}`]?.type}
                              data={[
                                { label: "Franchise Unit" },
                                { label: "Franchising Company" },
                                {
                                  label: "Private Company",
                                },
                                {
                                  label: "Public Company",
                                },
                                {
                                  label: "Other",
                                },
                              ]}
                              block
                              size="sm"
                              placement="auto"
                              preventOverflow={true}
                              searchable={false}
                              valueKey="label"
                              onChange={(value) => {
                                let b = this.state[`values${business._id}`];

                                b.type = value;

                                this.setState({
                                  [`values${business._id}`]: b,
                                });
                              }}
                            ></SelectPicker>
                          </FormGroup>
                          <FormGroup className="mb-2">
                            <h5># Units</h5>
                            <Input
                              bsSize="sm"
                              type="number"
                              name="units"
                              placeholder="Units"
                              value={this.state[`values${business._id}`]?.units}
                              onChange={(e) => {
                                let c = this.state[`values${business._id}`];

                                c.units = e.target.value;

                                this.setState({
                                  [`values${business._id}`]: c,
                                });
                              }}
                            ></Input>
                          </FormGroup>
                          <FormGroup className="mb-2">
                            <h5>Email</h5>
                            <Input
                              bsSize="sm"
                              type="text"
                              name="email"
                              placeholder="Email"
                              value={this.state[`values${business._id}`]?.email}
                              onChange={(e) => {
                                let c = this.state[`values${business._id}`];

                                c.email = e.target.value;

                                this.setState({
                                  [`values${business._id}`]: c,
                                });
                              }}
                            ></Input>
                          </FormGroup>
                          <FormGroup className="mb-2">
                            <h5>Phone</h5>
                            <NumberFormat
                              className="form-control form-control-sm"
                              format="(###) ###-####"
                              mask="_"
                              type="text"
                              name="phone"
                              placeholder="Phone"
                              value={
                                this.state[`values${business._id}`]?.phone
                                  ?.value
                              }
                              onValueChange={(e, v) => {
                                let c = this.state[`values${business._id}`];

                                c.phone = e;

                                console.log(c);

                                this.setState({
                                  [`values${business._id}`]: c,
                                });
                              }}
                            ></NumberFormat>
                          </FormGroup>
                          <FormGroup className="mb-2">
                            <h5>Address</h5>
                            <ReactGoogleAutocomplete
                              options={{ types: ["address"] }}
                              required
                              placeholder="Address"
                              apiKey={"AIzaSyA5-fbnedb5uXDBwbjnz0J_2eDe_9Gi2Cs"}
                              onPlaceSelected={(place) => {
                                console.log(place);

                                let c = this.state[`values${business._id}`];

                                c.address = place.formatted_address;
                                c.addressComponents = place.address_components;
                                c.geometry = place.geometry;
                                c.placeID = place.place_id;

                                this.setState({
                                  [`values${business._id}`]: c,
                                });
                              }}
                              onChange={(e) => {
                                let c = this.state[`values${business._id}`];

                                c.address = e.target.value;

                                this.setState({
                                  [`values${business._id}`]: c,
                                });
                              }}
                              name="marketName"
                              value={
                                this.state[`values${business._id}`]?.address
                              }
                              className="form-control form-control-sm"
                              //value={this.state.address1}
                            ></ReactGoogleAutocomplete>
                            {business.placeID ? (
                              <>
                                <Button
                                  className="mt-2"
                                  onClick={() => {
                                    LinkUtils.openInNewTab(
                                      `https://www.google.com/maps/search/?api=1&query=${business.address}`
                                    );
                                  }}
                                  size="sm"
                                  color="dark"
                                  outline
                                >
                                  Open In Google Maps
                                </Button>
                              </>
                            ) : null}
                          </FormGroup>
                          <FormGroup className="mb-2">
                            <h5>Website URL</h5>
                            <Input
                              bsSize="sm"
                              type="text"
                              name="websiteURL"
                              placeholder="Website URL"
                              value={
                                this.state[`values${business?._id}`]?.websiteURL
                              }
                              onChange={(e) => {
                                let c = this.state[`values${business._id}}`];

                                c.websiteURL = e.target.value;

                                this.setState({
                                  [`values${business?._id}`]: c,
                                });
                              }}
                            ></Input>
                          </FormGroup>
                          <FormGroup className="mb-2">
                            <h5>LinkedIn URL</h5>
                            <Input
                              bsSize="sm"
                              type="text"
                              name="linkedinURL"
                              placeholder="LinkedIn URL"
                              value={
                                this.state[`values${business._id}`]?.linkedinURL
                              }
                              onChange={(e) => {
                                let c = this.state[`values${business._id}`];

                                c.linkedinURL = e.target.value;

                                this.setState({
                                  [`values${business._id}`]: c,
                                });
                              }}
                            ></Input>
                          </FormGroup>
                          <FormGroup className="mb-2">
                            <h5>Instagram URL</h5>
                            <Input
                              bsSize="sm"
                              type="text"
                              name="instagramURL"
                              placeholder="Instagram URL"
                              value={
                                this.state[`values${business._id}`]
                                  ?.instagramURL
                              }
                              onChange={(e) => {
                                let c = this.state[`values${business._id}`];

                                c.instagramURL = e.target.value;

                                this.setState({
                                  [`values${business._id}`]: c,
                                });
                              }}
                            ></Input>
                          </FormGroup>
                          <FormGroup className="mb-2">
                            <h5>Facebook URL</h5>
                            <Input
                              bsSize="sm"
                              type="text"
                              name="facebookURL"
                              placeholder="Facebook URL"
                              value={
                                this.state[`values${business._id}`]?.facebookURL
                              }
                              onChange={(e) => {
                                let c = this.state[`values${business._id}`];

                                c.facebookURL = e.target.value;

                                this.setState({
                                  [`values${business._id}`]: c,
                                });
                              }}
                            ></Input>
                          </FormGroup>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="mt-3 border rounded p-2">
                          <Row className="align-items-center">
                            <Col>
                              <h5 className="mb-0">Email</h5>
                              <p className="text-muted text-lowercase mb-0">
                                {business.email ? <>{business.email}</> : "--"}
                              </p>
                            </Col>
                            {business.email ? (
                              <Col xs="auto">
                                <Button
                                  className="btn-icon-only my-0"
                                  size="sm"
                                  outline
                                  color="dark"
                                >
                                  <i className="mdi mdi-content-copy"></i>
                                </Button>
                                <Button
                                  className="btn-icon-only my-0"
                                  size="sm"
                                  outline
                                  color="dark"
                                >
                                  <i className="mdi mdi-send"></i>
                                </Button>
                              </Col>
                            ) : null}
                          </Row>
                        </div>
                        <div className="mt-3 border rounded p-2">
                          <Row className="align-items-center">
                            <Col>
                              <h5 className="mb-0">Phone</h5>
                              <p className="text-muted mb-0">
                                {business.phone ? (
                                  <>
                                    {StringUtils.formatPhoneNumber(
                                      business.phone
                                    )}
                                  </>
                                ) : (
                                  "--"
                                )}
                              </p>
                              {business.phoneInformation?.carrier?.type ? (
                                <p
                                  className="small text-muted text-capitalize mt-0 mb-0"
                                  style={{ lineHeight: 1.2 }}
                                >
                                  {business.phoneInformation?.carrier?.type}
                                  &nbsp;Number -{" "}
                                  {business.phoneInformation?.carrier?.name}
                                </p>
                              ) : null}
                            </Col>
                            {business.phone ? (
                              <Col xs="auto">
                                <Button
                                  className="btn-icon-only my-0"
                                  size="sm"
                                  outline
                                  color="dark"
                                >
                                  <i className="mdi mdi-content-copy"></i>
                                </Button>
                                <Button
                                  className="btn-icon-only my-0"
                                  size="sm"
                                  outline
                                  color="dark"
                                >
                                  <i className="mdi mdi-phone"></i>
                                </Button>
                                <Button
                                  disabled={!business.smsConversationSupported}
                                  className="btn-icon-only my-0"
                                  size="sm"
                                  outline
                                  color="dark"
                                >
                                  <i className="mdi mdi-message-text"></i>
                                </Button>
                              </Col>
                            ) : null}
                          </Row>
                        </div>

                        <div className="mt-3 border rounded p-2">
                          <Row className="align-items-center">
                            <Col>
                              <h5 className="mb-0">Address</h5>
                              <p className="text-muted mb-0">
                                {business.address ? (
                                  <>{business.address}</>
                                ) : (
                                  "--"
                                )}
                              </p>
                            </Col>
                            {business.address ? (
                              <Col xs="auto">
                                <Button
                                  className="btn-icon-only my-0"
                                  size="sm"
                                  outline
                                  color="dark"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      business.address
                                    );
                                  }}
                                >
                                  <i className="mdi mdi-content-copy"></i>
                                </Button>
                                <Button
                                  className="btn-icon-only my-0"
                                  size="sm"
                                  outline
                                  color="dark"
                                  onClick={() => {
                                    LinkUtils.openInNewTab(
                                      `https://www.google.com/maps/search/?api=1&query=${business.address}`
                                    );
                                  }}
                                >
                                  <i className="mdi mdi-open-in-new"></i>
                                </Button>
                              </Col>
                            ) : null}
                          </Row>
                        </div>
                        <div className="mt-3 border rounded p-2">
                          <Row className="align-items-center">
                            <Col>
                              <h5 className="mb-0">Website URL</h5>
                              <p className="text-muted text-lowercase mb-0">
                                {business.websiteURL ? (
                                  <>{business.websiteURL}</>
                                ) : (
                                  "--"
                                )}
                              </p>
                            </Col>
                            {business.websiteURL ? (
                              <Col xs="auto">
                                <Button
                                  className="btn-icon-only my-0"
                                  size="sm"
                                  outline
                                  color="dark"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      business?.websiteURL
                                    );
                                  }}
                                >
                                  <i className="mdi mdi-content-copy"></i>
                                </Button>
                                <Button
                                  className="btn-icon-only my-0"
                                  size="sm"
                                  outline
                                  color="dark"
                                  onClick={() => {
                                    LinkUtils.openInNewTab(
                                      business?.websiteURL
                                    );
                                  }}
                                >
                                  <i className="mdi mdi-open-in-new"></i>
                                </Button>
                              </Col>
                            ) : null}
                          </Row>
                        </div>
                        <div className="mt-3 border rounded p-2">
                          <Row className="align-items-center">
                            <Col>
                              <h5 className="mb-0">LinkedIn Profile URL</h5>
                              <p className="text-muted text-lowercase mb-0">
                                {business.linkedinURL ? (
                                  <>{business.linkedinURL}</>
                                ) : (
                                  "--"
                                )}
                              </p>
                            </Col>
                            {business.linkedinURL ? (
                              <Col xs="auto">
                                <Button
                                  className="btn-icon-only my-0"
                                  size="sm"
                                  outline
                                  color="dark"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      business?.linkedinURL
                                    );
                                  }}
                                >
                                  <i className="mdi mdi-content-copy"></i>
                                </Button>
                                <Button
                                  className="btn-icon-only my-0"
                                  size="sm"
                                  outline
                                  color="dark"
                                  onClick={() => {
                                    LinkUtils.openInNewTab(
                                      business?.linkedinURL
                                    );
                                  }}
                                >
                                  <i className="mdi mdi-open-in-new"></i>
                                </Button>
                              </Col>
                            ) : null}
                          </Row>
                        </div>
                        <div className="mt-3 border rounded p-2">
                          <Row className="align-items-center">
                            <Col>
                              <h5 className="mb-0">Instagram Profile URL</h5>
                              <p className="text-muted text-lowercase mb-0">
                                {business.instagramURL ? (
                                  <>{business.instagramURL}</>
                                ) : (
                                  "--"
                                )}
                              </p>
                            </Col>
                            {business.instagramURL ? (
                              <Col xs="auto">
                                <Button
                                  className="btn-icon-only my-0"
                                  size="sm"
                                  outline
                                  color="dark"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      business?.instagramURL
                                    );
                                  }}
                                >
                                  <i className="mdi mdi-content-copy"></i>
                                </Button>
                                <Button
                                  className="btn-icon-only my-0"
                                  size="sm"
                                  outline
                                  color="dark"
                                  onClick={() => {
                                    LinkUtils.openInNewTab(
                                      business?.instagramURL
                                    );
                                  }}
                                >
                                  <i className="mdi mdi-open-in-new"></i>
                                </Button>
                              </Col>
                            ) : null}
                          </Row>
                        </div>
                        <div className="mt-3 border rounded p-2">
                          <Row className="align-items-center">
                            <Col>
                              <h5 className="mb-0">Facebook Profile URL</h5>
                              <p className="text-muted text-lowercase mb-0">
                                {business.facebookURL ? (
                                  <>{business.facebookURL}</>
                                ) : (
                                  "--"
                                )}
                              </p>
                            </Col>
                            {business.facebookURL ? (
                              <Col xs="auto">
                                <Button
                                  className="btn-icon-only my-0"
                                  size="sm"
                                  outline
                                  color="dark"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      business?.facebookURL
                                    );
                                  }}
                                >
                                  <i className="mdi mdi-content-copy"></i>
                                </Button>
                                <Button
                                  className="btn-icon-only my-0"
                                  size="sm"
                                  outline
                                  color="dark"
                                  onClick={() => {
                                    LinkUtils.openInNewTab(
                                      business?.facebookURL
                                    );
                                  }}
                                >
                                  <i className="mdi mdi-open-in-new"></i>
                                </Button>
                              </Col>
                            ) : null}
                          </Row>
                        </div>
                      </>
                    )}
                  </Collapse>
                </CardBody>
              ))}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default withRouter(FranDevBusinessesWidget);
