import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  Checkbox,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";

class LibraryTopicEditDrawer extends React.Component {
  state = {
    name: "",
    description: "",
    internal: false,
  };

  getTopics() {
    this.setState({
      loadingTopics: true,
    });

    APIV2.getLibraryTopics()
      .then(
        (data) => {
          let topics = data.data.topics?.map((mod) => {
            return {
              label: `${mod?.internal ? "(Internal) " : ""}${mod?.name}`,
              value: mod?._id,
            };
          });

          topics = _.sortBy(
            _.filter(topics, (mod) => {
              return !_.findWhere(this.props.section?.topics, {
                topicID: mod?.value,
              });
            }),
            "name"
          );

          this.setState({
            topics: topics,
          });
        },
        () => {
          window.alert("Failed to get topics. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          loadingTopics: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.topic != prevProps.topic ||
      this.props.open != prevProps.open
    ) {
      if (this.props.topic) {
        this.setState({
          name: this.props.topic?.name,
          description: this.props.topic?.description,
          topic: this.props.topic,
          internal: this.props.topic?.internal ? true : false,
        });
      } else {
        this.setState({
          name: "",
          description: "",
          topic: null,
          internal: false,
        });
      }
    }

    if (this.props.open != prevProps.open && this.props.open) {
      this.getTopics();
    }
  }

  componentDidMount() {
    if (this.props.topic) {
      this.setState({
        name: this.props.topic?.name,
        description: this.props.topic?.description,
        topic: this.props.topic,
        internal: this.props.topic?.internal ? true : false,
      });
    } else {
      this.setState({
        name: "",
        description: "",
        topic: null,
        internal: false,
      });
    }

    this.getTopics();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  create() {
    this.setState({ submitting: true });

    if (!this.state.existingTopic) {
      APIV2.createLibraryTopic(
        this.state.name,
        this.state.description,
        this.state.internal
      ).then(
        (data) => {
          const topic = data.data.topic;

          APIV2.addLibraryTopicToModuleSection(
            this.props.module?._id,
            this.props.section?._id,
            topic?._id
          )
            .then(
              (data2) => {
                this.setState({
                  name: "",
                  description: "",
                  existingTopic: null,
                  internal: false,
                });

                const cat = data2.data.module;

                if (typeof this.props.onCreate == "function") {
                  this.props.onCreate(cat);
                }
              },
              (e) => {
                this.setError(
                  "error",
                  e?.response?.body?.message ??
                    "Unable to create topic - unknown error occurred. Try again."
                );
              }
            )
            .finally(() => {
              this.setState({ submitting: false });
            });
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create topic - unknown error occurred. Try again."
          );

          this.setState({
            submitting: false,
          });
        }
      );
    } else {
      console.log(this.state.existingTopic);

      APIV2.addLibraryTopicToModuleSection(
        this.props.module?._id,
        this.props.section?._id,
        this.state.existingTopic
      )
        .then(
          (data) => {
            this.setState({
              name: "",
              description: "",
              existingTopic: null,
              internal: false,
            });

            if (typeof this.props.onCreate == "function") {
              this.props.onCreate(data.data.module);
            }
          },
          (e) => {
            this.setError(
              "error",
              e?.response?.body?.message ??
                "Unable to add topic - unknown error occurred. Try again."
            );
          }
        )
        .finally(() => {
          this.setState({ submitting: false });
        });
    }
  }

  modify() {
    this.setState({ submitting: true });

    APIV2.modifyLibraryTopic(
      this.state.topic?._id,
      this.state.name,
      this.state.description,
      this.state.internal
    )
      .then(
        (data) => {
          const topic = data.data.topic;

          this.setState({
            name: "",
            description: "",
            internal: false,
          });

          if (typeof this.props.onModify == "function") {
            this.props.onModify(topic);
          }
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify topic - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => {
            this.setState({
              existingTopic: null,
              name: "",
              description: "",
            });

            if (typeof this.props.onClose == "function") {
              this.props.onClose();
            }
          }}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.topic ? "Modify" : "Add"} Topic
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  (!this.state.name && !this.state.existingTopic)
                }
                color="primary"
                onClick={() => {
                  if (this.props.topic) {
                    this.modify();
                  } else {
                    this.create();
                  }
                }}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>{this.props.topic ? "Save" : "Add"}</>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            <h3 className="mb-3">
              {this.props.topic ? "Topic Information" : "Create A New Topic"}
            </h3>
            <FormGroup>
              <h5>Name</h5>
              <Input
                disabled={this.state.existingTopic}
                bsSize="sm"
                type="text"
                name="name"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Description</h5>
              <Input
                bsSize="sm"
                type="textarea"
                name="description"
                placeholder="Description"
                disabled={this.state.existingTopic}
                value={this.state.description}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Internal Topic</h5>
              <p className="small" style={{ lineHeight: 1.2 }}>
                This topic will only be visible to the Franchise Development &
                Production teams
              </p>
              <Checkbox
                checked={this.state.internal}
                onChange={(e, v) => {
                  this.setState({ internal: v });
                }}
              >
                Internal
              </Checkbox>
            </FormGroup>
            {!this.props.topic && (
              <>
                <hr className="my-2"></hr>
                <h3 className="mb-3 mt-3">Or Link An Existing Topic</h3>
                <FormGroup>
                  <h5>Topic</h5>
                  <SelectPicker
                    renderMenu={(menu) => {
                      if (this.state.loadingTopics) {
                        return (
                          <div className="p-3 text-center">
                            <Spinner size="sm" color="dark" />
                          </div>
                        );
                      }
                      return menu;
                    }}
                    data={this.state.topics}
                    value={this.state.existingTopic}
                    block
                    cleanable={true}
                    placeholder="Select A Topic..."
                    onChange={(v) => {
                      this.setState({ existingTopic: v });
                    }}
                    placement="autoVerticalStart"
                  ></SelectPicker>
                </FormGroup>
              </>
            )}
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(LibraryTopicEditDrawer);
