import ArrowText from "components/ArrowText";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import moment from "moment";
import CustomerOrderDrawer from "components/Drawers/CustomerOrderDrawer";
import CustomerCreateOrderDrawer from "components/Drawers/CustomerCreateOrderDrawer";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import CustomerAllOrdersDrawer from "components/Drawers/CustomerAllOrdersDrawer";
import _ from "underscore";
import {
  Drawer,
  Dropdown,
  Popover,
  SelectPicker,
  Tooltip,
  Uploader,
  Whisper,
} from "rsuite";
import LinkUtils from "lib/LinkUtils";
import swal from "sweetalert";
import FileUploadDrawer from "./FileUploadDrawer";
import FileViewer from "react-file-viewer";

class NoteBody extends React.Component {
  state = {
    title: "",
    body: "",
    editMode: false,
    submitting: false,
    attachments: [],
    files: [],
  };

  onFileChange(e) {
    let files = [];

    files = e;

    console.log(files);

    this.setState({ files: files });
  }

  removeAttachment(attachment) {
    swal({
      title: "Remove Attachment",
      text: `Are you sure you want to remove the "${attachment?.fileName}" attachment?`,
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      let attachments = this.state?.attachments?.length
        ? this.state?.attachments
        : [];

      attachments = _.filter(attachments, (at) => {
        return (
          at?.fileName != attachment?.fileName && at?.url != attachment?.url
        );
      });

      this.setState({
        attachments,
      });
    });
  }

  async uploadAttachments(files) {
    let attachments = this.state?.attachments?.length
      ? this.state.attachments
      : [];

    attachments = attachments.concat(files);

    this.setState({
      attachments,
    });

    return attachments;
  }

  componentDidMount() {
    if (this.props.note) {
      this.setState({
        title: this.props.note?.title,
        body: this?.props?.note?.body,
        attachments: this?.props?.note?.attachments ?? [],
      });
    }

    if (this.props.defaultTitle) {
      this.setState({
        title: this.props.defaultTitle,
        showTitle: true,
      });
    }

    if (this.props.forceEdit) {
      this.setState({
        editMode: true,
        showTitle: true,
      });
    }
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (typeof this.props.onChange == "function") {
          this.props.onChange({
            title: this.state.showTitle ? this.state.title : "",
            body: this.state.body,
            attachments: this.state.attachments,
          });
        }
      }
    );
  }

  async _saveNote() {
    let payload = {
      title: this.state.showTitle ? this.state.title : "",
      body: this.state.body,
    };

    console.log(payload);

    let promise = null;

    if (this.props.note) {
      promise = APIV2.modifyStoreNote(
        this.props?.note?.storeID ? this.props.note?.storeID : "none",
        this.props.note?._id,
        this.props.customer ? this.props?.customer?._id : null,
        this.props.task ? this.props?.task?._id : null,
        this.props.lead ? this.props?.lead?._id : null,
        this.props.affiliate ? this.props?.affiliate?._id : null,
        this.props.frandevLead ? this.props?.frandevLead?._id : null,
        this.props.appointment ? this.props.appointment?._id : null,
        this.props.productOrder ? this.props?.productOrder?._id : null,
        this.props.store ? true : false,
        payload.title,
        payload.body,
        this.state.attachments?.length > 0 ? this.state.attachments : [],
        this.props.claim ? this.props.claim?._id : false
      );
    } else {
      let storeID = "";

      if (this.props.task) {
        storeID = this.props.task?.storeID;
      }

      if (this.props.productOrder) {
        storeID = this.props.productOrder?.storeID;
      }

      if (this.props.customer) {
        storeID = this.props.customer?.storeID;
      }

      if (this.props.lead) {
        storeID = this.props?.lead?.storeID;
      }

      if (this.props.affiliate) {
        storeID = this.props?.affiliate?.storeID;
      }

      if (this.props.appointment) {
        storeID = this.props?.appointment?.storeID;
      }

      if (this.props.claim) {
        storeID = this.props?.claim?.storeID;
      }

      if (this.props.store) {
        storeID = this.props?.store?._id;
      }

      if (!storeID) {
        storeID = "none";
      }

      promise = APIV2.createStoreNote(
        storeID,
        this.props.customer ? this.props?.customer?._id : null,
        this.props.task ? this.props?.task?._id : null,
        this.props.lead ? this.props?.lead?._id : null,
        this.props.affiliate ? this.props?.affiliate?._id : null,
        this.props.frandevLead ? this.props?.frandevLead?._id : null,
        this.props.appointment ? this.props.appointment?._id : null,
        this.props.productOrder ? this.props?.productOrder?._id : null,
        this.props.store ? true : false,
        payload.title,
        payload.body,
        this.state.attachments?.length > 0 ? this.state.attachments : [],
        this.props.claim ? this.props.claim?._id : false
      );
    }

    this.setState({
      submitting: true,
    });

    if (typeof this.props?.onSubmit == "function") {
      this.props.onSubmit();
    }

    promise
      .then(
        (data) => {
          this.setState({
            editMode: this.props.forceEdit ? true : false,
            title: "",
            body: "",
            showTitle: this.props?.forceEdit ? true : false,
          });

          if (typeof this.props.onSave == "function") {
            this.props.onSave(data.data.note);
          }
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  async save() {
    if (this.props.productOrders?.length) {
      this.setState({
        submitting: true,
      });

      let payload = {
        title: this.state.showTitle ? this.state.title : "",
        body: this.state.body,
      };

      let promises = [];

      for (let i = 0; i < this.props.productOrders?.length; i++) {
        let productOrder = this.props.productOrders[i];
        let storeID = productOrder?.storeID;

        let promise = APIV2.createStoreNote(
          storeID,
          null,
          null,
          null,
          null,
          null,
          null,
          productOrder?._id,
          false,
          payload.title,
          payload.body,
          this.state.attachments?.length > 0 ? this.state.attachments : []
        );

        promises.push(promise);
      }

      Promise.all(promises)
        .then(() => {
          this.setState({
            editMode: this.props.forceEdit ? true : false,
            title: "",
            body: "",
            showTitle: this.props?.forceEdit ? true : false,
          });

          if (typeof this.props.onSave == "function") {
            this.props.onSave();
          }
        })
        .finally(() => {
          this.setState({
            submitting: false,
          });
        });

      return;
    }

    this._saveNote();
  }

  cancel() {
    if (typeof this.props.onCancel == "function") {
      this.props.onCancel();
    }

    this.setState({
      editMode: this.props.forceEdit ? true : false,
      title: "",
      body: "",
      showTitle: this.props?.forceEdit ? true : false,
      attachments: [],
      files: [],
    });
  }

  async openAttachment(attachment) {
    LinkUtils.openPrivateURL(attachment?.url);

    return;
  }

  componentDidUpdate(prevProps) {
    if (this.props.forceEdit != prevProps.forceEdit) {
      this.setState({
        editMode: this.props.forceEdit,
        showTitle: this.props.forceEdit,
      });
    }
  }

  render() {
    return (
      <>
        {this.state.editMode ? (
          <>
            <div
              style={{
                position: "relative",
                border: "1px solid #cad1d7",
                borderRadius: "0.375rem",
              }}
            >
              {this.state.showTitle ? (
                <div className="mx-1 mt-1">
                  <Input
                    type="text"
                    name="title"
                    placeholder="Title"
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.title}
                    bsSize="sm"
                  ></Input>
                </div>
              ) : null}
              <Input
                type="textarea"
                placeholder="Add a note"
                onChange={this.handleInputChange.bind(this)}
                name="body"
                value={this.state.body}
                autoFocus
                className="border-0"
                style={{ minHeight: "100px" }}
              ></Input>
              {this.state?.attachments?.length > 0 ? (
                <>
                  <h5 className="px-2">Attachments: </h5>
                  {this.state.attachments?.map((at, i) => (
                    <div className={`p-2 border rounded mx-2 mb-2`} key={i}>
                      <Row className="align-items-center">
                        <Col xs="12" sm="">
                          <p className="small m-0 text-dark text-truncate">
                            {at?.fileName}
                          </p>
                        </Col>
                        <Col
                          xs="12"
                          sm="auto"
                          className="mt-3 mt-sm-0 text-right"
                        >
                          <Button
                            className="btn-icon-only"
                            size="sm"
                            outline
                            color="secondary"
                            onClick={() => {
                              this.openAttachment(at);
                            }}
                          >
                            <i className="mdi mdi-eye"></i>
                          </Button>
                          <Button
                            className="btn-icon-only"
                            size="sm"
                            outline
                            color="danger"
                            onClick={() => {
                              this.removeAttachment(at);
                            }}
                          >
                            <i className="mdi mdi-close"></i>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              ) : null}

              <hr className="my-0"></hr>
              <div className="py-1 pr-1">
                <Row className="align-items-center">
                  <Col xs="">
                    <Button
                      color=""
                      size="sm"
                      className="btn-link text-muted mr-0"
                      onClick={() => {
                        this.setState({
                          showTitle: !this.state.showTitle,
                        });
                      }}
                    >
                      {this.state.showTitle ? "Remove" : "Add"} Title
                    </Button>
                    <Button
                      color=""
                      size="sm"
                      className="btn-link text-muted ml-0"
                      onClick={() => {
                        this.setState({
                          showAttachment: true,
                        });
                      }}
                    >
                      Add Attachments
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Button
                      color="secondary"
                      size="sm"
                      outline
                      onClick={() => {
                        this.cancel();
                      }}
                      className="mr-0"
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {
                        this.save();
                      }}
                      disabled={!this.state.body || this.state.submitting}
                    >
                      {this.state.submitting ? (
                        <Spinner size="sm" color="white"></Spinner>
                      ) : (
                        <>{this.props.note ? "Save" : "Add"}</>
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <div
                onClick={() => {
                  this.setState(
                    {
                      editMode: true,
                    },
                    () => {}
                  );
                }}
                className="cursor-pointer form-control"
              >
                Add a note
              </div>
            </div>
          </>
        )}
        <FileUploadDrawer
          compressUploads={true}
          size="sm"
          onComplete={(files) => {
            console.log(files);

            this.setState({
              showAttachment: false,
            });

            this.uploadAttachments(files);
          }}
          open={this.state.showAttachment}
          onClose={() => {
            this.setState({
              showAttachment: false,
            });
          }}
        ></FileUploadDrawer>
        <Drawer
          open={this.state.openPreview}
          onClose={() => {
            this.setState({
              openPreview: false,
            });
          }}
          size="lg"
        >
          <Drawer.Header className="pr-4 border-bottom-0">
            <h3 className="mb-2" style={{ position: "relative", top: "7px" }}>
              Upload Files
            </h3>
          </Drawer.Header>
          <Drawer.Body className="p-0" style={{ height: "calc(100% - 70px)" }}>
            <FileViewer
              fileType={this.state.fileType}
              filePath={this.state.fileURL}
              errorComponent={<div>Error</div>}
              onError={(e) => {
                console.log(e);
              }}
            />
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default NoteBody;
