import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import CustomerInvoiceDrawer from "components/Drawers/CustomerInvoiceDrawer";
import ProductionOrderDrawer from "../../components/Drawers/ProductionOrderDrawer";
import StorePurchaseListWidget from "components/Widgets/StorePurchaseListWidget";

class StorefrontNSOChecklistView extends React.Component {
  state = {
    active: {
      id: "All Pending Orders",
      name: "All Pending Orders",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingOrders: true,
    orders: [],
    page: 1,
    count: 25,
    filters: null,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props?.match?.params?.storeID != prevProps?.match?.params?.storeID &&
      this.props.match?.params?.storeID
    ) {
      this.loadStore();
    }
  }

  componentDidMount() {
    if (this.props.match?.params?.storeID) {
      this.loadStore();
    }
  }

  loadStore() {
    APIV2.getStoreByID(this.props.match.params.storeID).then(
      (data) => {
        this.setState({
          store: data.data.store,
        });
      },
      (e) => {
        alert("Unable to load store. Reload and try again.");
      }
    );
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader title="NSO / Equipment List" showBackButton={false} />
          <StorePurchaseListWidget
            store={this.state.store}
            sections={["items_nso"]}
            onUpdate={(nso) => {
              let store = this.state.store;

              store.nso = nso;

              this.setState({
                store,
              });
            }}
          />
        </Container>
      </>
    );
  }
}

export default StorefrontNSOChecklistView;
