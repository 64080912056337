import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Collapse,
} from "reactstrap";
import QRCodeSVG from "qrcode.react";
// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment from "moment";
import { CheckboxGroup, DatePicker, SelectPicker, Checkbox } from "rsuite";
import StringUtils from "lib/StringUtils";
import { Table, Cell, Column, HeaderCell } from "rsuite-table";
import _ from "underscore";
import MoneyCell from "components/Table/MoneyCell";
import NumericCell from "components/Table/NumericCell";
import StoreCell from "components/Table/StoreCell";
import BootstrapTable from "react-bootstrap-table-next";
import MealsOrderedWidget from "components/Widgets/Fulfillment/MealsOrderedWidget";
import PlansFulfilledWidget from "components/Widgets/Fulfillment/PlansFulfilledWidget";
import AverageBoxSizeWidget from "components/Widgets/Fulfillment/AverageBoxSizeWidget";
import PlanFrequencyChartWidget from "components/Widgets/Fulfillment/PlanFrequencyChartWidget";
import PlanProcurementChartWidget from "components/Widgets/Fulfillment/PlanProcurementChartWidget";
import PlanTypeChartWidget from "components/Widgets/Fulfillment/PlanTypeChartWidget";
import { Link } from "react-router-dom";
import Input from "reactstrap/lib/Input";
import swal from "sweetalert";
import CustomerOrderRow from "components/CustomerOrderRow";

class StoreOrderLabelView extends React.Component {
  state = {
    stores: [],
    loading: true,
    fulfillmentDate: new Date(),
    availableDates: [],
    storeOpen: true,
    mealOpen: true,
    planOpen: true,
    storePerformance: [],
    dates: [],
    orders: [],
    compareToOptions: [],
    count: 25,
    page: 1,
    selectedOrders: [],
    searchResultOrders: [],
    active: {
      id: "All",
    },
  };

  getStore() {
    APIV2.getStoreByID(this.props.match?.params?.storeID).then(
      (data) => {
        this.setState({
          store: data.data.store,
        });
      },
      (e) => {
        console.error(e);
      }
    );
  }

  getAvailableDates() {
    return new Promise((resolve) => {
      APIV2.getStoreSubscriptionFulfillmentDates(
        this.props.match?.params?.storeID
      )
        .then(
          (data) => {
            let dates = data.data.dates;

            dates.push(this.state.fulfillmentMoment.format("YYYY-MM-DD"));

            this.setState({
              availableDates: dates,
            });
          },
          (e) => {
            this.setState({
              availableDates: [],
            });

            console.error(e);
          }
        )
        .finally(() => {
          resolve();
        });
    });
  }

  _rerunStatus(reportID, jobID, callback) {
    APIV2.checkRerunSubscriptionFulfillmentReportStatus(reportID, jobID).then(
      (data) => {
        //console.log(data);

        callback(data);
      },
      (e) => {
        //console.log(e);

        callback(null, e);
      }
    );
  }

  sortOrders(orders) {
    let outArr = [];
    let firstWeekArr = [];

    let typegroups = _.groupBy(orders, (o) => {
      return o?.subscription?.planTypeID;
    });

    let outObj = {};

    let typeKeys = _.keys(typegroups);

    typeKeys = _.sortBy(typeKeys, (k) => k?.toLowerCase());

    //console.log("TYPE", typeKeys);

    for (let i = 0; i < typeKeys?.length; i++) {
      let procurementGroups = _.groupBy(typegroups[typeKeys[i]], (o) => {
        return o?.subscription?.plan?.procurement;
      });

      if (!outObj.hasOwnProperty(typeKeys[i])) {
        outObj[typeKeys[i]] = {};
      }

      let procKeys = _.keys(procurementGroups);

      procKeys = _.sortBy(procKeys, (k) => k?.toLowerCase());

      console.log("Proc", procKeys);

      let allProcArr = [];

      for (let j = 0; j < procKeys?.length; j++) {
        let firstWeekOrders = _.filter(procurementGroups[procKeys[j]], (o) => {
          return (
            o?.subscription?.currentFulfillmentCount == 1 ||
            o?.subscription?.currentFulfillmentCount == 0
          );
        });

        firstWeekOrders.sort((a, b) => {
          let name1 = a?.customer?.name?.toLowerCase().trim();
          let name2 = b?.customer?.name?.toLowerCase().trim();

          return name1 > name2 ? 1 : -1;
        });

        let otherOrders = _.filter(procurementGroups[procKeys[j]], (o) => {
          return (
            o?.subscription?.currentFulfillmentCount != 1 &&
            o.subscription?.currentFulfillmentCount !== 0
          );
        });

        otherOrders.sort((a, b) => {
          let name1 = a?.customer?.name?.toLowerCase().trim();
          let name2 = b?.customer?.name?.toLowerCase().trim();

          return name1 > name2 ? 1 : -1;
        });

        outObj[typeKeys[i]][procKeys[j]] = firstWeekOrders.concat(otherOrders);

        allProcArr = allProcArr.concat(outObj[typeKeys[i]][procKeys[j]]);
      }

      outArr = outArr.concat(allProcArr);
    }

    return outArr;
  }

  checkRerunStatus(reportID, jobID) {
    let self = this;

    let load = (dateString) => {
      this.loadSummary(dateString);
    };

    return new Promise((resolve, reject) => {
      this._rerunStatus(reportID, jobID, function callback(data, error) {
        if (error) {
          resolve();

          return; // Don't continue after error
        }

        if (data?.data?.job?.progress == 100) {
          resolve();

          load(self.state.fulfillmentDateString);

          return;
        }

        setTimeout(() => {
          self._rerunStatus(reportID, jobID, callback);
        }, 5000);
      });
    });
  }

  rerunReport(reportID) {
    APIV2.rerunSubscriptionFulfillmentReport(reportID).then(
      (data) => {
        this.setState({
          rerunning: true,
          rerunJob: data.data.job,
        });

        this.checkRerunStatus(reportID, data.data.job.id).then(() => {
          console.log("DONE");
        });
      },
      (e) => {
        window.alert("Unable to rerun report. Try again.");
      }
    );
  }

  sortBom(bom, side = 1) {
    let allMeals = _.sortBy(
      _.filter(bom, (meal) => {
        return !_.findWhere(meal?.categories, { name: "Lean Cheats" });
      }),
      "name"
    );

    let allLeanCheats = _.sortBy(
      _.filter(bom, (meal) => {
        return _.findWhere(meal?.categories, { name: "Lean Cheats" });
      }),
      "name"
    );

    allMeals = allMeals.concat(allLeanCheats);

    let splitIDX = allMeals?.length / 2;

    // 25
    // split 1: 0-12
    // split 2: 13-25

    if (parseInt(splitIDX) != splitIDX) {
      splitIDX = Math.ceil(splitIDX) + 1;
      splitIDX = splitIDX - 1;
    }

    if (side == 1) {
      return allMeals.slice(0, splitIDX);
    }

    if (side == 2) {
      return allMeals.slice(splitIDX, allMeals?.length);
    }
  }

  loadOrders() {
    this.setState({
      loadingOrders: true,
    });

    APIV2.getOrdersForStore(
      this.props?.match?.params?.storeID,
      1,
      2000000,
      {
        fulfillmentDateString: {
          $regex: this.state.fulfillmentDateString,
          $options: "i",
        },
        status: { $ne: "CANCELLED" },
      },
      {
        "customer.name": -1,
        "subscription.plan.planTypeID": -1,
        "subscription.plan.procurement": 1,
      },
      true
    )
      .then(
        (data) => {
          if (data && data.data) {
            let orders = [];

            if (data.data.orders.length) {
              orders = data.data.orders;
            }

            if (this.state.ordersArr?.length) {
              orders = _.filter(orders, (o) => {
                return this.state.ordersArr.indexOf(o._id) >= 0;
              });
            }

            orders = this.sortOrders(orders);

            //console.log(orders);

            let ordersOut = [];
            let counter = 0;

            let temp = [];

            for (let i = 0; i < orders.length; i++) {
              if (counter < 4) {
                temp.push(orders[i]);

                counter++;
              }

              if (counter == 4 || i == orders?.length - 1) {
                let t = [];

                ordersOut.push(t.concat(temp));

                temp = [];
                counter = 0;
              }
            }

            this.setState({
              orders: ordersOut,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the orders. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingOrders: false,
          loading: false,
        });
      });
  }

  formatWeekStatus(count) {
    if (count >= 0) {
      return "Week " + count;
    }

    return;
  }

  loadSummary(dateString) {
    if (!dateString) {
      return;
    }

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("date", dateString);
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.setState(
      {
        loading: true,
        rerunning: false,
        noReport: false,
        dateString,
      },
      () => {
        this.loadOrders();
      }
    );
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const date = urlParams.get("date");

    const orders = urlParams.get("orders");

    let ordersArr = [];

    let fulfillmentDate = moment();

    if (date) {
      fulfillmentDate = moment(date);
    } else {
      fulfillmentDate.day(6);
    }

    if (orders) {
      ordersArr = orders.split(",");
    }

    fulfillmentDate.set("hours", 0).set("minutes", 0).set("seconds", 0);

    this.getStore();

    this.setState(
      {
        fulfillmentDate: fulfillmentDate.toDate(),
        fulfillmentMoment: fulfillmentDate,
        fulfillmentDateString: fulfillmentDate.format("YYYY-MM-DD"),
        ordersArr,
      },
      () => {
        this.loadSummary(this.state.fulfillmentDateString);
      }
    );
  }

  render() {
    return (
      <>
        <div>
          {this.state.loading || this.state.loadingOrders ? (
            <div className="p-3">
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <Spinner color="dark" size="sm"></Spinner>
                    <span className="pl-3">Generating Labels</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  Hang tight while we fetch your orders for{" "}
                  {this.state.fulfillmentMoment?.format("dddd, MMM Do YYYY")}.
                </CardBody>
              </Card>
            </div>
          ) : null}

          {this.state.noReport ||
          (!this.state.orders.length &&
            !this.state.loadingOrders &&
            !this.state.loading) ? (
            <div className="p-3">
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <span className="pl-3">No Labels Available</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  There are no orders available for fulfillment on{" "}
                  {this.state.fulfillmentMoment?.format("dddd, MMM Do YYYY")}.
                </CardBody>
              </Card>
            </div>
          ) : null}
          {!this.state.loading &&
          !this.state.loadingOrders &&
          !this.state.noReport ? (
            <div className="label-page-container" style={{ maxWidth: "8.5in" }}>
              {this.state.orders.map((page, i) => (
                <div key={i}>
                  {page?.map((order, k) => (
                    <div
                      key={k}
                      className="order-label"
                      style={{ position: "relative" }}
                    >
                      <Row>
                        <Col xs="7">
                          <h2
                            style={{ fontSize: "1.1rem" }}
                            className="mb-0 text-uppercase font-weight-800 text-dark"
                          >
                            {order.customer?.name}
                          </h2>
                          <p
                            style={{
                              lineHeight: 1.1,
                              whiteSpace: "nowrap",
                              overflowX: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            className="m-0 text-dark text-uppercase"
                          >
                            <small>
                              {order.customer?.shippingAddress?.address1}
                              {order.customer?.shippingAddress?.address2
                                ? ` ${order.customer?.shippingAddress?.address2}`
                                : ""}
                            </small>
                          </p>
                          <p
                            style={{
                              lineHeight: 1.1,
                              whiteSpace: "nowrap",
                              overflowX: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            className="m-0 text-dark text-uppercase"
                          >
                            <small>
                              {order.customer?.shippingAddress?.city},{" "}
                              {order.customer?.shippingAddress?.state}{" "}
                              {order.customer?.shippingAddress?.zip}
                            </small>
                          </p>
                          <p
                            style={{
                              lineHeight: 1.1,
                              whiteSpace: "nowrap",
                              overflowX: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            className="m-0 text-dark"
                          >
                            <small>
                              {StringUtils.formatPhoneNumber(
                                order.customer?.phone
                              )}
                            </small>
                          </p>
                        </Col>
                        <Col xs="5" className="text-right">
                          <h4
                            style={{
                              fontSize: "1rem",
                              whiteSpace: "nowrap",
                              overflowX: "hidden",
                              textOverflow: "ellipsis",
                              marginTop: "0.1rem",
                              marginBottom: "0.1rem",
                              color: this.state.store?.labelColorCode
                                ? this.state.store?.labelColorCode
                                : "#000000",
                            }}
                            className="text-uppercase font-weight-800 text-truncate"
                          >
                            {order?.store?.name
                              ?.replace("Project LeanNation", "")
                              ?.replace("PLN", "")}
                          </h4>
                          <h3
                            style={{ fontSize: "0.9rem" }}
                            className="m-0 text-uppercase font-weight-800 text-dark"
                          >
                            {order.subscription?.plan?.code}
                          </h3>
                          <h3
                            style={{ fontSize: "0.9rem" }}
                            className="m-0 text-uppercase font-weight-800 text-dark"
                          >
                            {order.subscription?.plan?.planType?.name
                              ?.replace("Lifestyle", "LS")
                              ?.replace("Athlete", "ATH")}
                            &nbsp;{order?.quantity}
                            &nbsp;{order.subscription?.plan?.procurement}
                          </h3>

                          <h3
                            style={{ fontSize: "0.9rem" }}
                            className="m-0 text-uppercase font-weight-800 text-dark"
                          >
                            {moment(order?.fulfillmentDateString).format(
                              "MM/DD/YY"
                            )}
                          </h3>
                        </Col>
                      </Row>

                      <hr className="my-1"></hr>
                      <Row className="align-items-center">
                        <Col xs="">
                          <h5 className={`text-uppercase text-dark mb-2`}>
                            Items
                          </h5>
                        </Col>
                        <Col className="text-right" xs="auto">
                          <h5 className={`text-uppercase text-dark mb-2`}>
                            QTY: {order.quantity}
                          </h5>
                        </Col>
                      </Row>
                      <Row className="align-items-top">
                        <Col xs="6" className="pr-1">
                          {this.sortBom(order?.bom, 1)?.map((meal, i) => (
                            <Row
                              className={`align-items-center ${
                                i != 0 && "mt-1 pt-1 border-top"
                              }`}
                            >
                              <Col xs="auto" className="pr-1">
                                <h4
                                  className="m-0 text-dark"
                                  style={{
                                    fontSize: 14,
                                    minWidth: 27,
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {meal.quantity} -
                                </h4>
                              </Col>
                              <Col
                                xs=""
                                className="pl-0 pr-2"
                                style={{ maxWidth: "calc(100% - 47px)" }}
                              >
                                <h5
                                  className="m-0 text-uppercase font-weight-normal text-dark"
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    fontSize: 14,
                                  }}
                                >
                                  <div
                                    className="mr-1 d-inline-block"
                                    style={{ minWidth: 21, fontWeight: "bold" }}
                                  >
                                    {meal?.logisticsCode}
                                  </div>
                                  {meal.name}
                                </h5>
                              </Col>
                            </Row>
                          ))}
                        </Col>
                        <Col xs="6" className="pl-1">
                          {this.sortBom(order?.bom, 2)?.map((meal, i) => (
                            <Row
                              className={`align-items-center ${
                                i != 0 && "mt-1 pt-1 border-top"
                              }`}
                            >
                              <Col xs="auto" className="pr-1">
                                <h4
                                  className="m-0 text-dark"
                                  style={{
                                    fontSize: 14,
                                    minWidth: 27,
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {meal.quantity} -
                                </h4>
                              </Col>
                              <Col
                                xs=""
                                className="pl-0"
                                style={{ maxWidth: "calc(100% - 47px)" }}
                              >
                                <h5
                                  className="m-0 text-uppercase font-weight-normal text-dark"
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    fontSize: 14,
                                  }}
                                >
                                  <div
                                    className="mr-1 d-inline-block"
                                    style={{ minWidth: 21, fontWeight: "bold" }}
                                  >
                                    {meal?.logisticsCode}
                                  </div>
                                  {meal.name}
                                </h5>
                              </Col>
                            </Row>
                          ))}
                        </Col>
                      </Row>

                      <div
                        style={{
                          position: "absolute",
                          bottom: 0,
                          width: "100%",
                          fontSize: 14,
                        }}
                        className="pr-2"
                      >
                        <Row className="align-items-end">
                          <Col xs="8">
                            <div
                              style={{
                                fontSize: 14,
                              }}
                              className={`${
                                order?.subscription?.currentFulfillmentCount ==
                                1
                                  ? "font-weight-800 text-primary"
                                  : "font-weight-700 text-dark"
                              } text-left text-uppercase pb-1`}
                            >
                              {this.formatWeekStatus(
                                order?.subscription?.currentFulfillmentCount
                              )}

                              <div
                                className="text-dark"
                                style={{ lineHeight: 1.1 }}
                              >
                                <small>
                                  {order?.subscription?.totalFulfillmentCount}{" "}
                                  All-Time
                                </small>
                              </div>
                            </div>
                            {order?.customer?.delivery?.notes ? (
                              <>
                                <div
                                  className="text-uppercase pr-2 text-dark pb-1 small"
                                  style={{ lineHeight: 1.1 }}
                                >
                                  Notes: {order?.customer?.delivery?.notes}
                                </div>
                              </>
                            ) : (
                              <>
                                {_.filter(order?.customer?.goals, (g) => {
                                  return g != "None Of These Apply";
                                })?.length ? (
                                  <div
                                    className="text-uppercase pr-2 text-dark pb-1 small"
                                    style={{ lineHeight: 1.1 }}
                                  >
                                    Goals: {order?.customer?.goals?.join(", ")}
                                  </div>
                                ) : (
                                  <div
                                    className="text-uppercase pb-1 text-dark"
                                    style={{ lineHeight: 1 }}
                                  >
                                    <small>
                                      ALL CHOICES SUBJECT TO AVAILABILITY
                                    </small>
                                  </div>
                                )}
                              </>
                            )}
                          </Col>
                          <Col xs="4">
                            <div className="px-1 text-right">
                              <QRCodeSVG
                                level="L"
                                size={70}
                                value={"CustomerFulfillment:" + order?._id}
                              ></QRCodeSVG>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  ))}
                  <div className="page-break"></div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default StoreOrderLabelView;
