import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Input,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeaderV2 from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import CreateStoreDrawer from "components/Drawers/CreateStoreDrawer";
import _ from "underscore";
import moment from "moment";
import StringUtils from "lib/StringUtils";
import { Checkbox, Dropdown, Popover, SelectPicker, Whisper } from "rsuite";
import CreateFranDevLeadDrawer from "../../components/Drawers/CreateFranDevLeadDrawer";
import LinkUtils from "lib/LinkUtils";
import swal from "sweetalert";
import FranDevLeadStageDrawer from "../../components/Drawers/FranDevLeadStageDrawer";
import TaskCreateDrawer from "components/Drawers/TaskCreateDrawer";
import FranLeadMessageDrawer from "../../components/Drawers/FranLeadMessageDrawer";

class FranDevLeadList extends React.Component {
  state = {
    leads: [],
    options: [
      { name: "All", id: "All", _id: "All" },
      { name: "Not Contacted", id: "NotContacted", _id: "NotContacted" },
      {
        name: "Not Contacted (Call/Text List)",
        id: "NotContactedCallList",
        _id: "NotContactedCallList",
      },
      { name: "Contacted", id: "Contacted", _id: "Contacted" },
      {
        name: "Contacted (Last Touch 30+ Days)",
        id: "ContactedLast30",
        _id: "ContactedLast30",
      },
      {
        name: "Pre-Focus Call Nurturing",
        id: "PreFocusNurturing",
        _id: "PreFocusNurturing",
      },
      { name: "Focus Call", id: "FocusCall", _id: "FocusCall" },

      { name: "Discovery Call", id: "DiscoveryCall", _id: "DiscoveryCall" },
      {
        name: "Pre-Discovery Day Nurturing",
        id: "PreDiscoveryNurturing",
        _id: "PreDiscoveryNurturing",
      },
      { name: "Discovery Day", id: "DiscoveryDay", _id: "DiscoveryDay" },
      { name: "Funding", id: "Funding", _id: "Funding" },
      { name: "Converted", id: "Converted", _id: "Converted" },

      { name: "Inactive", id: "Inactive", _id: "Inactive" },
    ],
    active: {
      name: "All",
      id: "All",
      _id: "All",
    },
    loading: true,
    page: 1,
    count: 25,
    filters: {},
    orderBy: { createdAt: -1 },
    orderByName: "createdAt_asc",
    selectedRecords: [],
  };

  dropdownRef = React.createRef();
  contactRef = React.createRef();

  getFilters(option) {
    let filters = null;

    if (option.id == "All") {
      filters = null;
    } else if (option.id == "NotContactedCallList") {
      filters = {
        stage: "Not Contacted",
        "contacts.smsConversationSupported": true,
        "contacts.phoneInformation.carrier.type": { $ne: "voip" },
      };
    } else if (option.id == "ContactedLast30") {
      filters = {
        stage: "Contacted",
        "contacts.smsConversationSupported": true,
        lastContactDate: {
          $lte: { $date: moment().subtract(30, "days").toISOString() },
        },
      };
    } else {
      filters = {
        stage: option.name,
      };
    }

    return filters;
  }

  async loadTabCounts(force = false) {
    if (this.state.tabsLoading) {
      return;
    }

    this.setState(
      {
        tabsLoading: true,
      },
      () => {
        let out = this.state.options?.map((tab) => {
          if (force || tab?.countLoading === undefined) {
            tab.count = 0;
            tab.countLoading = true;
          }

          return _.clone(tab);
        });

        this.setState({ options: out }, async () => {
          let tabs = this.state.options?.map((t) => {
            const filters = this.getFilters(t);

            return {
              id: t.id,
              collection: "FranDevLeads",
              query: filters,
            };
          });

          try {
            let data = await APIV2.getTabCounts(tabs);

            let out = this.state.options?.map((tab) => {
              const res = _.findWhere(data.data?.tabs, { id: tab.id });

              tab.count = res?.count ? res.count : 0;
              tab.countLoading = false;

              return tab;
            });

            this.setState({
              options: out,
            });
          } catch (e) {
            console.error(e);
          }

          this.setState({
            tabsLoading: false,
          });
        });
      }
    );
  }

  handleTabChange(option, force = false, resetPage = true) {
    let orderBy = this.state.orderBy
      ? this.state.orderBy
      : {
          createdAt: -1,
        };

    //console.log("ORDER", orderBy);

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    this.setState({
      leads: [],
      loading: true,
    });

    let filters = this.getFilters(option);

    this.setState(
      {
        filters,
        active: option,
        orderBy,
        page: resetPage ? 1 : this.state.page,
      },
      () => {
        //console.log("TAB CHANGE", filters);

        this.loadLeads(
          this.state.page ? this.state.page : 1,
          this.state.count,
          filters,
          orderBy
        );
      }
    );

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    queryParams.set("p", encodeURIComponent(resetPage ? 1 : this.state.page));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );
  }

  loadLeads(page = 1, count = 25, filters = {}, orderBy = { createdAt: -1 }) {
    let allFilters = _.extend(
      filters ? filters : {},
      _.clone(this.state.searchFilters ? this.state.searchFilters : {})
    );

    //console.log(allFilters, this.state.searchFilters);

    this.setState({
      selectedRecords: [],
    });

    APIV2.getFranDevLeads(page, count, allFilters, orderBy)
      .then(
        (data) => {
          //console.log(data);

          if (data?.data?.leads) {
            this.setState({
              leads: data?.data?.leads,
              pagination: data?.data?.pagination,
              resultData: data?.data?.result,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  _formatSort(v) {
    let direction = 1;

    if (v?.includes("_asc")) {
      direction = -1;
    }

    let orderBy = v?.replace("_asc", "")?.replace("_desc", "");

    return {
      [orderBy]: direction,
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");
    const sort = urlParams.get("sort")
      ? urlParams.get("sort")
      : "createdAt_asc";
    let search = urlParams.get("q") ? urlParams.get("q") : "";
    let page =
      urlParams.get("p") && !isNaN(parseInt(urlParams.get("p")))
        ? parseInt(urlParams.get("p"))
        : 1;

    let q = {};

    if (search) {
      search = decodeURIComponent(search);

      q = this._loadSearchQuery(search, false);

      //console.log(q);
    }

    this.setState(
      {
        orderByName: sort,
        orderBy: this._formatSort(sort),
        search,
        searchFilters: q,
        page,
      },
      () => {
        let opt = _.findWhere(this.state.options, { id: tab });

        if (tab) {
          this.setState(
            {
              active: opt,
            },
            () => {
              this.loadTabCounts();

              this.handleTabChange(opt, true, false);
            }
          );
        } else {
          this.handleTabChange(this.state.active, true, false);
          this.loadTabCounts();
        }
      }
    );
  }

  _loadSearchQuery(search, allFilters = true) {
    if (search) {
      let filters = {};

      filters["$or"] = [];

      filters["$or"].push({
        "contacts.name": { $regex: search, $options: "i" },
      });

      filters["$or"].push({
        "contacts.email": { $regex: search, $options: "i" },
      });

      filters["$or"].push({
        "contacts.phone": { $regex: search, $options: "i" },
      });

      filters["$or"].push({
        "markets.name": { $regex: search, $options: "i" },
      });

      filters["$or"].push({
        "businesses.name": { $regex: search, $options: "i" },
      });

      filters["$or"].push({
        name: { $regex: search, $options: "i" },
      });

      filters["$or"].push({
        "markets.addressComponents.long_name": {
          $regex: search,
          $options: "i",
        },
      });

      filters["$or"].push({
        "markets.addressComponents.short_name": {
          $regex: search,
          $options: "i",
        },
      });

      filters["$or"].push({
        source: {
          $regex: search,
          $options: "i",
        },
      });

      filters["$or"].push({
        sourceDetails: {
          $regex: search,
          $options: "i",
        },
      });

      return filters;
    }

    return {};
  }

  getBusinessType(type) {
    switch (type) {
      case "Franchise Unit":
        return "Franchisee";
      case "Franchise Company":
        return "Franchisor";
      case "Public Company":
        return "Public";
      case "Private Company":
        return "Private";
      default:
        return type;
    }
  }

  performSearch() {
    if (this.state.search) {
      let q = this._loadSearchQuery(this.state.search);

      this.setState(
        {
          searchFilters: this._loadSearchQuery(this.state.search, false),
        },
        () => {
          this.loadTabCounts();
          this.loadLeads(
            1,
            this.state.count,
            this.state.active?.name != "All"
              ? { stage: this.state.active.name }
              : null,
            this.state.orderBy
          );
        }
      );
    } else {
      this.setState(
        {
          search: "",
          searchFilters: null,
        },
        () => {
          this.loadTabCounts();
          this.handleTabChange(this.state.active, true);
        }
      );
    }

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set(
      "q",
      encodeURIComponent(this.state.search ? this.state.search : "")
    );
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    this.setState({
      loading: true,
    });

    let timeout = setTimeout(() => {
      this.performSearch();
    }, 1200);

    this.setState({
      searchTimeout: timeout,
    });

    this.setState({
      [name]: value,
    });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page, selectedRecords: [] }, () => {
      // Construct URLSearchParams object instance from current URL querystring.
      var queryParams = new URLSearchParams(window.location.search);

      // Set new or modify existing parameter value.
      queryParams.set(
        "p",
        encodeURIComponent(this.state.page ? this.state.page : "")
      );
      window.history.pushState(
        null,
        null,
        window.location.pathname + "?" + queryParams.toString()
      );
    });

    this.setState({
      loading: true,
      leads: [],
    });

    this.loadLeads(
      page,
      this.state.count,
      this.state.filters,
      this.state.orderBy
    );
  }

  deleteSelected() {
    swal({
      title: `Delete ${this.state.selectedRecords?.length} leads?`,
      text: "Are you sure you want to delete these leads. This cannot be undone.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (!willDelete) {
        return;
      }

      this.setState({
        loading: true,
      });

      APIV2.deleteFranDevLeads(this.state.selectedRecords)
        .then(
          (data) => {
            this.setState({
              selectedRecords: [],
            });

            this.loadLeads(
              this.state.page,
              this.state.count,
              this.state.filters,
              this.state.orderBy
            );

            this.loadTabCounts();
          },
          (error) => {
            console.error(error);
          }
        )
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    });
  }

  filterList(contacts) {
    if (!contacts) {
      return [];
    }

    if (contacts?.length < 4) {
      return contacts;
    }

    return contacts.slice(0, 3);
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeaderV2
            sticky={true}
            title="Leads"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  createOpen: true,
                });
              },
            }}
            tabs={
              <CardSelectorHeader
                sticky={false}
                value={this.state.active}
                options={this.state.options}
                showCount={true}
                compact={true}
                onChange={(v) => {
                  this.handleTabChange(v);
                }}
              ></CardSelectorHeader>
            }
          />
          {/* Table */}
          <div
            style={{ marginLeft: -15, marginRight: -15 }}
            className="bg-white mt--4 pt-2 border-bottom"
          >
            <CardHeader className="p-3">
              <Row className="align-items-center">
                <Col xs="" className="pr-0">
                  <Input
                    type="text"
                    placeholder="Search leads by contact, source, market, and business..."
                    size="sm"
                    name="search"
                    value={this.state.search}
                    onChange={this.handleSearchChange.bind(this)}
                  ></Input>
                </Col>
                <Col xs="auto" className="">
                  <SelectPicker
                    searchable={false}
                    size="sm"
                    placeholder="Sort"
                    data={[
                      {
                        label: (
                          <>
                            <i
                              className="mdi mdi-arrow-down-thick"
                              style={{ position: "relative", top: 1 }}
                            ></i>
                            &nbsp;Name
                          </>
                        ),
                        value: "name_desc",
                      },
                      {
                        label: (
                          <>
                            <i
                              className="mdi mdi-arrow-up-thick"
                              style={{ position: "relative", top: 1 }}
                            ></i>
                            &nbsp;Name
                          </>
                        ),
                        value: "name_asc",
                      },

                      {
                        label: (
                          <>
                            <i
                              className="mdi mdi-arrow-down-thick"
                              style={{ position: "relative", top: 1 }}
                            ></i>
                            &nbsp;Stage
                          </>
                        ),
                        value: "stage_desc",
                      },
                      {
                        label: (
                          <>
                            <i
                              className="mdi mdi-arrow-up-thick"
                              style={{ position: "relative", top: 1 }}
                            ></i>
                            &nbsp;Stage
                          </>
                        ),
                        value: "stage_asc",
                      },

                      {
                        label: (
                          <>
                            <i
                              className="mdi mdi-arrow-down-thick"
                              style={{ position: "relative", top: 1 }}
                            ></i>
                            &nbsp;Source
                          </>
                        ),
                        value: "source_desc",
                      },
                      {
                        label: (
                          <>
                            <i
                              className="mdi mdi-arrow-up-thick"
                              style={{ position: "relative", top: 1 }}
                            ></i>
                            &nbsp;Source
                          </>
                        ),
                        value: "source_asc",
                      },

                      {
                        label: (
                          <>
                            <i
                              className="mdi mdi-arrow-up-thick"
                              style={{ position: "relative", top: 1 }}
                            ></i>
                            &nbsp;# Units
                          </>
                        ),
                        value: "totalUnits_asc",
                      },
                      {
                        label: (
                          <>
                            <i
                              className="mdi mdi-arrow-down-thick"
                              style={{ position: "relative", top: 1 }}
                            ></i>
                            &nbsp;# Units
                          </>
                        ),
                        value: "totalUnits_desc",
                      },
                      {
                        label: (
                          <>
                            <i
                              className="mdi mdi-arrow-down-thick"
                              style={{ position: "relative", top: 1 }}
                            ></i>
                            &nbsp;Last Contacted
                          </>
                        ),
                        value: "lastContactDate_asc",
                      },
                      {
                        label: (
                          <>
                            <i
                              className="mdi mdi-arrow-up-thick"
                              style={{ position: "relative", top: 1 }}
                            ></i>
                            &nbsp;Last Contacted
                          </>
                        ),
                        value: "lastContactDate_desc",
                      },
                      {
                        label: (
                          <>
                            <i
                              className="mdi mdi-arrow-down-thick"
                              style={{ position: "relative", top: 1 }}
                            ></i>
                            &nbsp;Last Modified
                          </>
                        ),
                        value: "modifiedAt_asc",
                      },
                      {
                        label: (
                          <>
                            <i
                              className="mdi mdi-arrow-up-thick"
                              style={{ position: "relative", top: 1 }}
                            ></i>
                            &nbsp;Last Modified
                          </>
                        ),
                        value: "modifiedAt_desc",
                      },
                      {
                        label: (
                          <>
                            <i
                              className="mdi mdi-arrow-down-thick"
                              style={{ position: "relative", top: 1 }}
                            ></i>
                            &nbsp;Created At
                          </>
                        ),
                        value: "createdAt_asc",
                      },
                      {
                        label: (
                          <>
                            <i
                              className="mdi mdi-arrow-up-thick"
                              style={{ position: "relative", top: 1 }}
                            ></i>
                            &nbsp;Created At
                          </>
                        ),
                        value: "createdAt_desc",
                      },
                    ]}
                    onChange={(v) => {
                      //console.log(v);

                      let direction = 1;

                      if (v?.includes("_asc")) {
                        direction = -1;
                      }

                      let orderBy = v
                        ?.replace("_asc", "")
                        ?.replace("_desc", "");

                      if (v == this.state.orderByName) {
                        return;
                      }

                      this.setState(
                        {
                          orderByName: v,
                          orderBy: { [orderBy]: direction },
                          loading: true,
                          leads: [],
                        },
                        () => {
                          // Construct URLSearchParams object instance from current URL querystring.
                          var queryParams = new URLSearchParams(
                            window.location.search
                          );

                          // Set new or modify existing parameter value.
                          queryParams.set("sort", encodeURIComponent(v));
                          window.history.pushState(
                            null,
                            null,
                            window.location.pathname +
                              "?" +
                              queryParams.toString()
                          );

                          this.loadLeads(
                            this.state.page,
                            this.state.count,
                            this.state.filters,
                            this.state.orderBy
                          );
                        }
                      );
                    }}
                    label="Sort"
                    value={this.state.orderByName}
                    block
                    placement="autoVerticalEnd"
                  />
                </Col>
              </Row>
              <Row className="align-items-center mt-2">
                <Col xs="" sm="" className="">
                  {this.state.selectedRecords?.length ? (
                    <div>
                      {this.state.selectedRecords?.length} leads selected
                    </div>
                  ) : (
                    <div>
                      {this.state.resultData?.start &&
                      this.state.resultData?.end ? (
                        <>
                          Showing{" "}
                          {StringUtils.numberFormat(
                            this.state.resultData?.start
                          )}
                          -
                          {StringUtils.numberFormat(this.state.resultData?.end)}
                          {this.state.resultData?.total ? (
                            <>
                              &nbsp;of{" "}
                              {StringUtils.numberFormat(
                                this.state.resultData?.total
                              )}{" "}
                              leads
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>---</>
                      )}
                    </div>
                  )}
                </Col>
                <Col xs="auto" sm="auto" className="pl-2 pl-sm-3 text-right">
                  {this.state.selectedRecords?.length ? (
                    <>
                      <Whisper
                        trigger="click"
                        placement="bottomEnd"
                        preventOverflow
                        ref={this.dropdownRef}
                        speaker={
                          <Popover className="dropdown-popover" full>
                            <Dropdown.Menu
                              style={{
                                minWidth: 150,
                                paddingBottom: 0,
                              }}
                            >
                              <Dropdown.Item
                                panel
                                className="font-weight-800 text-uppercase"
                                style={{
                                  padding: 12,
                                }}
                              >
                                <h5 className="m-0 text-dark">Actions</h5>
                              </Dropdown.Item>
                              <li
                                role="separator"
                                class="rs-dropdown-item-divider my-0"
                              ></li>

                              {/*<Dropdown.Item
                                disabled={false}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  this.dropdownRef.current.close();
                                }}
                                className="text-dark bg-superlight--hover"
                              >
                                <Row className="align-items-center">
                                  <Col xs="auto" className="pr-0">
                                    <i
                                      className={`mdi mdi-note-plus`}
                                      style={{ fontSize: 16, lineHeight: 1.3 }}
                                    ></i>
                                  </Col>
                                  <Col xs="" className="pl-2">
                                    <p className="m-0" style={{ fontSize: 14 }}>
                                      New Note
                                    </p>
                                  </Col>
                                </Row>
                            </Dropdown.Item>*/}
                              <Dropdown.Item
                                disabled={false}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  this.dropdownRef.current.close();

                                  this.setState({
                                    openTask: true,
                                  });
                                }}
                                className="text-dark bg-superlight--hover"
                              >
                                <Row className="align-items-center">
                                  <Col xs="auto" className="pr-0">
                                    <i
                                      className={`mdi mdi-checkbox-marked-circle-plus-outline`}
                                      style={{ fontSize: 16, lineHeight: 1.3 }}
                                    ></i>
                                  </Col>
                                  <Col xs="" className="pl-2">
                                    <p className="m-0" style={{ fontSize: 14 }}>
                                      New Task
                                    </p>
                                  </Col>
                                </Row>
                              </Dropdown.Item>
                              <Dropdown.Item
                                disabled={false}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  this.dropdownRef.current.close();

                                  this.setState({
                                    stageOpen: true,
                                  });
                                }}
                                className="text-dark bg-superlight--hover"
                              >
                                <Row className="align-items-center">
                                  <Col xs="auto" className="pr-0">
                                    <i
                                      className={`mdi mdi-pencil`}
                                      style={{ fontSize: 16, lineHeight: 1.3 }}
                                    ></i>
                                  </Col>
                                  <Col xs="" className="pl-2">
                                    <p className="m-0" style={{ fontSize: 14 }}>
                                      Change Stage
                                    </p>
                                  </Col>
                                </Row>
                              </Dropdown.Item>
                              {/*<Dropdown.Item
                                disabled={false}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  this.dropdownRef.current.close();
                                }}
                                className="text-dark bg-superlight--hover"
                              >
                                <Row className="align-items-center">
                                  <Col xs="auto" className="pr-0">
                                    <i
                                      className={`mdi mdi-merge`}
                                      style={{ fontSize: 16, lineHeight: 1.3 }}
                                    ></i>
                                  </Col>
                                  <Col xs="" className="pl-2">
                                    <p className="m-0" style={{ fontSize: 14 }}>
                                      Merge
                                    </p>
                                  </Col>
                                </Row>
                            </Dropdown.Item>*/}
                              {/**<Dropdown.Item
                                disabled={false}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  this.dropdownRef.current.close();
                                }}
                                className="text-dark bg-superlight--hover"
                              >
                                <Row className="align-items-center">
                                  <Col xs="auto" className="pr-0">
                                    <i
                                      className={`mdi mdi-download`}
                                      style={{ fontSize: 16, lineHeight: 1.3 }}
                                    ></i>
                                  </Col>
                                  <Col xs="" className="pl-2">
                                    <p className="m-0" style={{ fontSize: 14 }}>
                                      Download
                                    </p>
                                  </Col>
                                </Row>
                            </Dropdown.Item>**/}
                              {/**
                               * <li
                                role="separator"
                                class="rs-dropdown-item-divider my-0"
                              ></li>
                              <Dropdown.Item
                                disabled={false}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  this.dropdownRef.current.close();

                                  this.deleteSelected();
                                }}
                                className="text-danger bg-superlight--hover"
                              >
                                <Row className="align-items-center">
                                  <Col xs="auto" className="pr-0">
                                    <i
                                      className={`mdi mdi-trash-can`}
                                      style={{ fontSize: 16, lineHeight: 1.3 }}
                                    ></i>
                                  </Col>
                                  <Col xs="" className="pl-2">
                                    <p className="m-0" style={{ fontSize: 14 }}>
                                      Delete
                                    </p>
                                  </Col>
                                </Row>
                              </Dropdown.Item>
                               */}
                            </Dropdown.Menu>
                          </Popover>
                        }
                      >
                        <Button
                          style={{ height: "28px" }}
                          className="m-0 pr-2"
                          color="primary"
                          size="sm"
                        >
                          <span style={{ position: "relative", top: -2 }}>
                            <span style={{ position: "relative", top: -4 }}>
                              {StringUtils.numberFormat(
                                this.state.selectedRecords?.length
                              )}{" "}
                              Selected
                            </span>
                            <i
                              style={{ fontSize: 20, lineHeight: 1 }}
                              className="mdi mdi-chevron-down"
                            ></i>
                          </span>
                        </Button>
                      </Whisper>
                    </>
                  ) : (
                    <>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        className="btn-icon-only mb-0  mr-1"
                        outline
                        disabled={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious ||
                          this.state.searchResults
                        }
                        onClick={() => {
                          this.incrementPage(-1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-left"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        disabled={
                          !this.state.pagination?.hasNext ||
                          this.state.searchResults
                        }
                        className="btn-icon-only mb-0"
                        outline
                        onClick={() => {
                          this.incrementPage(1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-right"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <Table
              className="align-items-center table-bordered table-flush mb-0"
              responsive
              style={{ tableLayout: "fixed" }}
            >
              <thead className="thead-light">
                <tr>
                  <th scope="col" className="py-0" style={{ width: 60 }}>
                    <Checkbox
                      checked={
                        this.state.selectedRecords?.length ==
                        this.state.resultData?.count
                      }
                      indeterminate={
                        this.state.selectedRecords?.length > 0 &&
                        this.state.selectedRecords?.length !=
                          this.state.resultData?.count
                      }
                      onChange={(v, c) => {
                        if (c) {
                          let records = this.state.leads?.map((l) => l._id);

                          this.setState({
                            selectedRecords: records,
                          });
                        } else {
                          this.setState({
                            selectedRecords: [],
                          });
                        }
                      }}
                    ></Checkbox>
                  </th>
                  <th scope="col" className="text-dark" style={{ width: 220 }}>
                    Name
                  </th>
                  <th
                    style={{
                      width: 400,
                    }}
                    className="text-dark"
                    scope="col"
                  >
                    Markets
                  </th>
                  <th scope="col" className="text-dark" style={{ width: 160 }}>
                    Stage
                  </th>
                  <th scope="col" className="text-dark" style={{ width: 160 }}>
                    Source
                  </th>
                  <th scope="col" className="text-dark" style={{ width: 350 }}>
                    Contacts
                  </th>
                  <th scope="col" className="text-dark" style={{ width: 150 }}>
                    Lead Created
                  </th>
                  <th scope="col" className="text-dark" style={{ width: 150 }}>
                    Last Contacted
                  </th>
                  <th scope="col" className="text-dark" style={{ width: 150 }}>
                    Communication
                  </th>
                  <th scope="col" className="text-dark" style={{ width: 350 }}>
                    Businesses
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.loading ? (
                  <tr>
                    <td colSpan="6">Loading...</td>
                  </tr>
                ) : null}
                {!this.state.loading && !this.state.leads.length ? (
                  <tr>
                    <td colSpan="6">No leads.</td>
                  </tr>
                ) : null}
                {!this.state.loading &&
                  this.state.leads.map((lead, i) => (
                    <>
                      <tr className="">
                        <td className="py-2" scope="col" style={{ width: 50 }}>
                          <Checkbox
                            checked={this.state.selectedRecords?.includes(
                              lead?._id
                            )}
                            onChange={(v, c) => {
                              let records = this.state.selectedRecords;

                              if (c) {
                                records.push(lead?._id);
                              } else {
                                records = records.filter((r) => r != lead?._id);
                              }

                              this.setState({
                                selectedRecords: records,
                              });
                            }}
                          ></Checkbox>
                        </td>
                        <td
                          style={{
                            wordBreak: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                          className="py-2 cursor-pointer"
                          onClick={() => {
                            this.props.history.push(
                              `/frandev/leads/${lead?._id}/summary`
                            );
                          }}
                        >
                          <p className="m-0 text-dark text-primary--hover">
                            {moment().diff(moment(lead?.createdAt), "days") <
                              14 && lead?.stage == "Not Contacted" ? (
                              <Badge
                                color="primary"
                                className="mr-2 border border-primary-lighter py-1"
                                style={{
                                  position: "relative",
                                  top: -2,
                                }}
                              >
                                HOT
                              </Badge>
                            ) : (
                              ""
                            )}
                            {lead?.name ? lead?.name : "(no name)"}
                          </p>
                        </td>
                        <td
                          style={{
                            wordBreak: "break-all",
                            whiteSpace: "pre-wrap",
                          }}
                          className="py-2"
                        >
                          {lead?.markets?.length ? (
                            <>
                              {this.filterList(
                                _.sortBy(lead?.markets, "priority")
                              )?.map((market, i) => (
                                <div
                                  className={`px-2 py-1 border text-dark bg-ultralight bg-superlight--hover rounded d-inline-block bg-white shadow-sm--hover cursor-pointer ${
                                    i != lead?.markets?.length - 1 &&
                                    "mb-1 mt-1 mr-2"
                                  }`}
                                  key={i}
                                  onClick={() => {
                                    LinkUtils.openInNewTab(
                                      `https://www.google.com/maps/search/?api=1&query=${market?.name
                                        ?.split(" ")
                                        .join("+")}`
                                    );
                                  }}
                                >
                                  <i className="mdi mdi-map-marker pr-1 text-medium"></i>
                                  {market?.priority ? (
                                    <>[{market?.priority}]&nbsp;</>
                                  ) : null}
                                  {market?.name}
                                  <i className="mdi mdi-open-in-new pl-1"></i>
                                </div>
                              ))}
                              {lead?.markets?.length >= 4 && (
                                <div
                                  className={`px-2 py-1 text-dark rounded d-inline-block`}
                                >
                                  +{lead?.markets?.length - 3} more
                                </div>
                              )}
                            </>
                          ) : (
                            "--"
                          )}
                        </td>
                        <td className="py-2">
                          <p className="m-0 text-dark text-truncate">
                            {lead?.stage ? lead?.stage : "--"}
                          </p>
                        </td>
                        <td className="py-2">
                          <p className="m-0 text-dark">
                            {lead?.source ? lead?.source : "--"}
                          </p>
                        </td>
                        <td
                          className="py-2"
                          style={{
                            wordBreak: "break-all",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {lead?.contacts?.length ? (
                            <>
                              {this.filterList(lead?.contacts)?.map(
                                (contact, i) => (
                                  <div
                                    className={`d-inline-block  ${
                                      i != lead?.contacts?.length - 1 &&
                                      "mb-1 mt-1 mr-2"
                                    }`}
                                    key={i}
                                  >
                                    <Whisper
                                      placement="auto"
                                      preventOverflow={true}
                                      trigger="click"
                                      enterable={true}
                                      ref={this.contactRef}
                                      speaker={
                                        <Popover
                                          full
                                          className="dropdown-popover overflow-hidden"
                                        >
                                          <div
                                            style={{
                                              minWidth: 200,
                                              maxWidth: 300,
                                            }}
                                            className="p-2 border-bottom bg-ultralight"
                                          >
                                            <Row className="align-items-center">
                                              <Col xs="">
                                                <h4 className="text-dark font-weight-500 m-0">
                                                  {contact?.name}
                                                </h4>
                                              </Col>
                                              <Col xs="auto">
                                                <Button
                                                  size="sm"
                                                  outline
                                                  color="dark"
                                                  className="btn-icon-only border-0 m-0"
                                                  onClick={() => {
                                                    this.contactRef.current.close();
                                                  }}
                                                >
                                                  <i className="mdi mdi-close"></i>
                                                </Button>
                                              </Col>
                                            </Row>
                                          </div>
                                          {contact?.email ? (
                                            <div
                                              style={{
                                                minWidth: 200,
                                                maxWidth: 300,
                                              }}
                                              className="p-2 border-bottom"
                                            >
                                              <Row className="align-items-center">
                                                <Col xs="">
                                                  <p
                                                    style={{
                                                      fontSize: 12,
                                                      lineHeight: 1,
                                                    }}
                                                    className="text-medium text-uppercase font-weight-bold mt-0 mb-0"
                                                  >
                                                    EMAIL
                                                  </p>
                                                  <p
                                                    style={{ fontSize: 14 }}
                                                    className="text-dark text-lowercase font-weight-500 m-0"
                                                  >
                                                    {contact?.email}
                                                  </p>
                                                </Col>
                                                <Col xs="auto">
                                                  <Button
                                                    size="sm"
                                                    outline
                                                    color="dark"
                                                    className="btn-icon-only"
                                                    onClick={() => {
                                                      window.open(
                                                        `mailto:${contact?.email}`,
                                                        "_self"
                                                      );
                                                    }}
                                                  >
                                                    <i className="mdi mdi-send"></i>
                                                  </Button>
                                                </Col>
                                              </Row>
                                            </div>
                                          ) : null}
                                          {contact?.phone ? (
                                            <div
                                              style={{
                                                minWidth: 200,
                                                maxWidth: 300,
                                              }}
                                              className="p-2 border-bottom"
                                            >
                                              <Row className="align-items-center">
                                                <Col xs="">
                                                  <p
                                                    style={{
                                                      fontSize: 12,
                                                      lineHeight: 1,
                                                    }}
                                                    className="text-medium text-uppercase font-weight-bold mt-0 mb-0"
                                                  >
                                                    PHONE
                                                  </p>
                                                  <p
                                                    style={{ fontSize: 14 }}
                                                    className="text-dark text-lowercase font-weight-500 m-0"
                                                  >
                                                    {StringUtils.formatPhoneNumber(
                                                      contact?.phone
                                                    )}
                                                  </p>
                                                </Col>
                                                <Col xs="auto">
                                                  <Button
                                                    size="sm"
                                                    outline
                                                    color="dark"
                                                    className="btn-icon-only mr-1"
                                                    onClick={() => {
                                                      window.open(
                                                        `tel:${contact?.phone}`,
                                                        "_self"
                                                      );
                                                    }}
                                                  >
                                                    <i className="mdi mdi-phone"></i>
                                                  </Button>
                                                  <Button
                                                    size="sm"
                                                    outline
                                                    color="dark"
                                                    className="btn-icon-only"
                                                    onClick={() => {
                                                      this.setState({
                                                        messageOpen: true,
                                                        messageContact: contact,
                                                        messageLead: lead,
                                                      });

                                                      this.contactRef.current.close();

                                                      PubSub.publish(
                                                        "editor:contentUpdated",
                                                        {
                                                          event:
                                                            "contentUpdated",
                                                          forceHydrate: true,
                                                          id: "franleadMessageEditor",
                                                          data: {},
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <i className="mdi mdi-message-text"></i>
                                                  </Button>
                                                </Col>
                                              </Row>
                                            </div>
                                          ) : null}
                                          {contact?.address ? (
                                            <div
                                              style={{
                                                minWidth: 200,
                                                maxWidth: 300,
                                              }}
                                              className="p-2 border-bottom"
                                            >
                                              <Row className="align-items-center">
                                                <Col xs="">
                                                  <p
                                                    style={{
                                                      fontSize: 12,
                                                      lineHeight: 1,
                                                    }}
                                                    className="text-medium text-uppercase font-weight-bold mt-0 mb-0"
                                                  >
                                                    ADDRESS
                                                  </p>
                                                  <p
                                                    style={{
                                                      fontSize: 14,
                                                      lineHeight: 1.3,
                                                    }}
                                                    className="text-dark font-weight-500 m-0"
                                                  >
                                                    {contact?.address}
                                                  </p>
                                                </Col>
                                                <Col xs="auto">
                                                  <Button
                                                    size="sm"
                                                    outline
                                                    color="dark"
                                                    className="btn-icon-only"
                                                    onClick={() => {
                                                      LinkUtils.openInNewTab(
                                                        `https://www.google.com/maps/search/?api=1&query=${contact?.address}`
                                                      );
                                                    }}
                                                  >
                                                    <i className="mdi mdi-open-in-new"></i>
                                                  </Button>
                                                </Col>
                                              </Row>
                                            </div>
                                          ) : null}

                                          {contact?.linkedinURL ? (
                                            <div
                                              style={{
                                                minWidth: 200,
                                                maxWidth: 300,
                                              }}
                                              className="p-2 border-bottom"
                                            >
                                              <Row className="align-items-center">
                                                <Col xs="">
                                                  <p
                                                    style={{
                                                      fontSize: 12,
                                                      lineHeight: 1,
                                                    }}
                                                    className="text-medium text-uppercase font-weight-bold mt-0 mb-0"
                                                  >
                                                    LINKEDIN
                                                  </p>
                                                  <p
                                                    style={{ fontSize: 14 }}
                                                    className="text-dark text-truncate text-lowercase font-weight-500 m-0"
                                                  >
                                                    {contact?.linkedinURL}
                                                  </p>
                                                </Col>
                                                <Col xs="auto">
                                                  <Button
                                                    size="sm"
                                                    outline
                                                    color="dark"
                                                    className="btn-icon-only"
                                                    onClick={() => {
                                                      LinkUtils.openInNewTab(
                                                        contact?.linkedinURL
                                                      );
                                                    }}
                                                  >
                                                    <i className="mdi mdi-open-in-new"></i>
                                                  </Button>
                                                </Col>
                                              </Row>
                                            </div>
                                          ) : null}
                                          {contact?.instagramURL ? (
                                            <div
                                              style={{
                                                minWidth: 200,
                                                maxWidth: 300,
                                              }}
                                              className="p-2 border-bottom"
                                            >
                                              <Row className="align-items-center">
                                                <Col xs="">
                                                  <p
                                                    style={{
                                                      fontSize: 12,
                                                      lineHeight: 1,
                                                    }}
                                                    className="text-medium text-uppercase font-weight-bold mt-0 mb-0"
                                                  >
                                                    INSTAGRAM
                                                  </p>
                                                  <p
                                                    style={{ fontSize: 14 }}
                                                    className="text-dark text-truncate text-lowercase font-weight-500 m-0"
                                                  >
                                                    {contact?.instagramURL}
                                                  </p>
                                                </Col>
                                                <Col xs="auto">
                                                  <Button
                                                    size="sm"
                                                    outline
                                                    color="dark"
                                                    className="btn-icon-only"
                                                    onClick={() => {
                                                      LinkUtils.openInNewTab(
                                                        contact?.instagramURL
                                                      );
                                                    }}
                                                  >
                                                    <i className="mdi mdi-open-in-new"></i>
                                                  </Button>
                                                </Col>
                                              </Row>
                                            </div>
                                          ) : null}
                                          {contact?.facebookURL ? (
                                            <div
                                              style={{
                                                minWidth: 200,
                                                maxWidth: 300,
                                              }}
                                              className="p-2 border-bottom"
                                            >
                                              <Row className="align-items-center">
                                                <Col xs="">
                                                  <p
                                                    style={{
                                                      fontSize: 12,
                                                      lineHeight: 1,
                                                    }}
                                                    className="text-medium text-uppercase font-weight-bold mt-0 mb-0"
                                                  >
                                                    FACEBOOK
                                                  </p>
                                                  <p
                                                    style={{ fontSize: 14 }}
                                                    className="text-dark text-truncate text-lowercase font-weight-500 m-0"
                                                  >
                                                    {contact?.facebookURL}
                                                  </p>
                                                </Col>
                                                <Col xs="auto">
                                                  <Button
                                                    size="sm"
                                                    outline
                                                    color="dark"
                                                    className="btn-icon-only"
                                                    onClick={() => {
                                                      LinkUtils.openInNewTab(
                                                        contact?.facebookURL
                                                      );
                                                    }}
                                                  >
                                                    <i className="mdi mdi-open-in-new"></i>
                                                  </Button>
                                                </Col>
                                              </Row>
                                            </div>
                                          ) : null}
                                        </Popover>
                                      }
                                    >
                                      <div
                                        className={`px-2 py-1 border text-dark rounded bg-ultralight bg-superlight--hover  bg-white shadow-sm--hover cursor-pointer`}
                                      >
                                        {contact?.name}
                                        <i className="mdi mdi-open-in-new pl-1"></i>
                                      </div>
                                    </Whisper>
                                  </div>
                                )
                              )}
                              {lead?.contacts?.length >= 4 && (
                                <div
                                  className={`px-2 py-1 text-dark rounded d-inline-block`}
                                >
                                  +{lead?.contacts?.length - 3} more
                                </div>
                              )}
                            </>
                          ) : (
                            "--"
                          )}
                        </td>
                        <td className="py-2 text-dark">
                          {lead?.createdAt
                            ? moment(lead?.createdAt).format("MM/DD/YY")
                            : "--"}
                        </td>
                        <td className="py-2 text-dark">
                          {lead?.lastContactDate
                            ? moment(lead?.lastContactDate).format("MM/DD/YY")
                            : "--"}
                        </td>
                        <td
                          style={{
                            wordBreak: "break-all",
                            whiteSpace: "pre-wrap",
                          }}
                          className="py-2"
                        >
                          {lead?.contacts?.length ? (
                            <>
                              {_.findWhere(lead?.contacts, {
                                smsConversationSupported: true,
                              }) ? (
                                <i
                                  style={{ fontSize: 18 }}
                                  className="mdi mdi-message-text text-dark mr-2"
                                ></i>
                              ) : null}
                              {_.find(lead?.contacts, (item) => {
                                return item?.phone;
                              }) ? (
                                <i
                                  style={{ fontSize: 18 }}
                                  className="mdi mdi-phone text-dark mr-2"
                                ></i>
                              ) : null}
                              {_.find(lead?.contacts, (item) => {
                                return item?.email;
                              }) ? (
                                <i
                                  style={{ fontSize: 18 }}
                                  className="mdi mdi-send text-dark"
                                ></i>
                              ) : null}
                            </>
                          ) : (
                            "--"
                          )}
                        </td>
                        <td
                          style={{
                            wordBreak: "break-all",
                            whiteSpace: "pre-wrap",
                          }}
                          className="py-2"
                        >
                          {lead?.businesses?.length ? (
                            <>
                              {_.sortBy(lead?.businesses, "name")?.map(
                                (business, i) => (
                                  <div
                                    className={`px-2 py-1 border text-dark bg-ultralight bg-superlight--hover rounded d-inline-block bg-white shadow-sm--hover ${
                                      i != lead?.businesses?.length - 1 &&
                                      "mb-1 mt-1 mr-2"
                                    } ${
                                      business?.websiteURL && "cursor-pointer"
                                    }`}
                                    key={i}
                                    onClick={() => {
                                      if (!business?.websiteURL) {
                                        return;
                                      }

                                      LinkUtils.openInNewTab(
                                        business?.websiteURL
                                      );
                                    }}
                                  >
                                    <i className="mdi mdi-domain pr-1 text-medium"></i>

                                    {business?.name}
                                    {business?.type
                                      ? ` (${this.getBusinessType(
                                          business?.type
                                        )}${
                                          business?.type === "Franchise Unit" &&
                                          business?.units
                                            ? `: ${business?.units} Unit${
                                                business?.units > 1 ? "s" : ""
                                              }`
                                            : ""
                                        })`
                                      : ""}

                                    {business?.websiteURL && (
                                      <i className="mdi mdi-open-in-new pl-1"></i>
                                    )}
                                  </div>
                                )
                              )}
                            </>
                          ) : (
                            "--"
                          )}
                        </td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </Table>
          </div>
        </Container>

        <CreateFranDevLeadDrawer
          open={this.state.createOpen}
          onClose={() => {
            this.setState({
              createOpen: false,
            });
          }}
          onCreate={() => {
            this.handleTabChange(this.state.active, true);
          }}
        ></CreateFranDevLeadDrawer>
        <FranDevLeadStageDrawer
          open={this.state.stageOpen}
          onClose={() => {
            this.setState({
              stageOpen: false,
            });
          }}
          leads={this.state.selectedRecords}
          onSave={() => {
            //this.handleTabChange(this.state.active, true);

            this.loadLeads(
              this.state.page,
              this.state.count,
              this.state.filters,
              this.state.orderBy
            );

            this.loadTabCounts();
          }}
        ></FranDevLeadStageDrawer>
        <TaskCreateDrawer
          allowAssignStore={false}
          storeTask={true}
          open={this.state.openTask}
          onClose={() => {
            this.setState({ openTask: false });
          }}
          frandevLeads={this.state.selectedRecords}
        ></TaskCreateDrawer>
        <FranLeadMessageDrawer
          open={this.state.messageOpen}
          lead={this.state.messageLead}
          contact={this.state.messageContact}
          onClose={() => {
            this.setState({
              messageOpen: false,
              messageLead: null,
              messageContact: null,
            });
          }}
        ></FranLeadMessageDrawer>
      </>
    );
  }
}

export default FranDevLeadList;
