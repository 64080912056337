import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  InputPicker,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import { Capacitor } from "@capacitor/core";
import FileUploadDrawer from "../FileUploadDrawer";
import LinkUtils from "lib/LinkUtils";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import NoteBody from "components/NoteBody";
import Linkify from "components/Linkify";
import NewQualityClaimDrawer from "./NewQualityClaimDrawer";

class QualityClaimDetailDrawer extends React.Component {
  state = {
    reason: "",
    notes: [],
    mealOptions: [],
    tab: {
      id: "meals",
      name: "Submitted Meals",
    },
    viewMode: "list",
    page: 1,
    disapprovalOptions: [
      {
        value: "Seal Not Loose",
        label: "Seal Not Loose",
      },
      {
        value: "Missing Label Information",
        label: "Missing Label Information",
      },
      {
        value: "Incorrect Meal Identified",
        label: "Incorrect Meal Identified",
      },
      {
        value: "Duplicate Submitted",
        label: "Duplicate Submitted",
      },
      {
        value: "Submitted After Cutoff",
        label: "Submitted After Cutoff",
      },
    ],
  };

  dropdownRef = React.createRef();
  popoverRef = React.createRef();

  getTotalValue(c) {
    let claims = _.filter(c?.claims, (cl) => {
      return cl?.approved;
    });

    let total = 0;

    for (let i = 0; i < claims?.length; i++) {
      total += claims[i]?.value;
    }

    return total;
  }

  getTotalCount(c, type) {
    let claims = _.filter(c?.claims, (cl) => {
      if (!cl?.approved) {
        return false;
      }

      if (type == "Lifestyle") {
        return (
          cl?.meal?.planType?.name == "Lifestyle" &&
          !_.findWhere(cl?.meal?.categories, { name: "Lean Cheats" })
        );
      }

      if (type == "Athlete") {
        return (
          cl?.meal?.planType?.name == "Athlete" &&
          !_.findWhere(cl?.meal?.categories, { name: "Lean Cheats" })
        );
      }

      if (type == "Lean Cheats") {
        return _.findWhere(cl?.meal?.categories, { name: "Lean Cheats" });
      }
    });

    let total = 0;

    for (let i = 0; i < claims?.length; i++) {
      total += 1;
    }

    return total;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.open != prevProps.open &&
      this.props.open &&
      this.props.claim
    ) {
      this._loadImages();
      this.fetchNotes(true);
    }

    if (this.props.claim != prevProps.claim && this.props.claim) {
      this._loadImages();
      this.fetchNotes(true);
    }
  }

  componentDidMount() {
    if (this.props.open && this.props.claim) {
      this._loadImages();

      this.fetchNotes(true);
    }
  }

  async _loadImages() {
    let meals = this.props.claim?.claims;

    if (!meals?.length) {
      return;
    }

    let images = {};

    for (let i = 0; i < meals.length; i++) {
      if (!meals[i]?.imageFile?.url) {
        continue;
      }

      let url = await this.getImageURL(meals[i]?.imageFile?.url);

      images[meals[i]?._id] = url;
    }

    this.setState({
      images,
    });
  }

  async getImageURL(privateURL) {
    return new Promise((resolve) => {
      APIV2.getPrivateFileURL(privateURL).then(
        (data) => {
          resolve(data.data.url);
        },
        (e) => {
          console.error(e);

          resolve("");
        }
      );
    });
  }

  toggleModal() {
    console.log("MODAL CLOSED");

    this.setState({
      images: {},
      tab: {
        id: "meals",
        name: "Submitted Meals",
      },
      pendingClaims: [],
      reviewMode: false,
      viewMode: "list",
    });

    this.props.onClose();
  }

  /**
   * Fetch notes for the order
   */
  fetchNotes(forceLoad = false) {
    if (!this.props.claim) {
      return;
    }

    if (forceLoad) {
      this.setState({
        loading: true,
      });
    }

    let query = {
      claimID: this?.props?.claim?._id,
    };

    let sortBy = {
      createdAt: this.state.recentFirst == "recentFirst" ? -1 : 1,
    };

    APIV2.getNotesForStore("none", this.state.page, 20, query, sortBy)
      .then(
        (data) => {
          let notes = forceLoad ? [] : this.state.notes;

          notes = data?.data?.notes?.length
            ? notes.concat(data?.data?.notes)
            : notes;

          let notesOut = [];

          for (let i = 0; i < notes?.length; i++) {
            if (!_.findWhere(notesOut, { _id: notes[i]?._id })) {
              notesOut.push(notes[i]);
            }
          }

          this.setState({
            notes: notesOut,
            pagination: data?.data?.pagination,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  userIsGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ||
      user?.productionPermissions?.length
      ? true
      : false;
  }

  getClaimImage(claimed) {
    if (!claimed?._id) {
      return "";
    }

    return this.state.images?.[claimed?._id] ?? "";
  }

  setStatus(status) {
    this.setState({
      statusSubmitting: true,
    });

    APIV2.modifyProductionQualityClaimStatus(this.props.claim?._id, status)
      .then(
        (data) => {
          PubSub.publish(Event.QUALITY_CLAIM.MODIFIED, data?.data?.claim);
        },
        (e) => {
          console.error(e);

          this.setError(
            "error",
            `Unable to update claim status: ${
              e?.response?.data?.error ?? "An unknown error occurred."
            }`
          );
        }
      )
      .finally(() => {
        this.setState({
          statusSubmitting: false,
        });
      });
  }

  delete() {
    swal({
      title: "Delete Claim",
      text: "Are you sure you want delete this claim?",
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({ deleting: true });

      APIV2.deleteProductionQualityClaim(this?.props?.claim?._id)
        .then(
          (data) => {
            PubSub.publish(Event.QUALITY_CLAIM.DELETED);

            this.toggleModal();
          },
          (e) => {
            this.setError(
              "error",
              `Unable to delete claim: ${
                e?.response?.body?.message ??
                "Unknown error occurred. Try again."
              }`
            );
          }
        )
        .finally(() => {
          this.setState({ deleting: false });
        });
    });
  }

  getClaimStatus(claimed) {
    const claim = _.findWhere(this.state.pendingClaims, { _id: claimed?._id });

    return claim?.approved;
  }

  allClaimsReviewed() {
    let claims = this.props?.claim?.claims;

    if (!claims?.length) {
      return false;
    }

    console.log(claims);

    for (let i = 0; i < claims.length; i++) {
      if (claims[i]?.approved === null) {
        return false;
      }
    }

    return true;
  }

  modifyClaims(claims) {
    this.setState({
      submitting: true,
    });

    APIV2.modifyProductionQualityClaim(this.props.claim?._id, { claims })
      .then(
        (data) => {
          PubSub.publish(Event.QUALITY_CLAIM.MODIFIED, data?.data?.claim);

          this.setState({
            reviewMode: false,
            pendingClaims: [],
            tab: {
              id: "meals",
            },
          });
        },
        (e) => {
          this.setError(
            "error",
            `Unable to update claim: ${
              e?.response?.data?.error ?? "An unknown error occurred."
            }`
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  modifyInvoiceNumber(invoiceNumber) {
    this.setState({
      submitting: true,
    });

    return new Promise((resolve, reject) => {
      APIV2.modifyProductionQualityClaim(this.props.claim?._id, {
        invoiceNumber,
      })
        .then(
          (data) => {
            PubSub.publish(Event.QUALITY_CLAIM.MODIFIED, data?.data?.claim);

            this.setState({
              reviewMode: false,
              pendingClaims: [],
              invoiceNumber: "",
            });

            resolve(true);
          },
          (e) => {
            this.setError(
              "error",
              `Unable to update claim: ${
                e?.response?.data?.error ?? "An unknown error occurred."
              }`
            );

            reject(e);
          }
        )
        .finally(() => {
          this.setState({
            submitting: false,
          });
        });
    });
  }

  formatStatus(claim) {
    let color = "light";

    let icon = "mdi-circle-outline";

    if (claim?.status == "SUBMITTED") {
      color = "medium";
    }

    if (claim?.status == "PROCESSING") {
      color = "medium";
      icon = "mdi-circle";
    }

    if (claim?.status == "CREDIT PENDING") {
      color = "success";
    }

    if (claim?.status == "COMPLETE") {
      color = "success";

      icon = "mdi-circle";
    }

    return (
      <Badge color={color}>
        <i className={`mr-1 mdi ${icon}`}></i>
        {claim?.status}
      </Badge>
    );

    return <>--</>;
  }

  render() {
    return (
      <>
        <Drawer
          backdrop="static"
          className="pln-drawer-scannerhide"
          size={"lg"}
          style={{
            maxWidth: "100%",
            width: this.state.reviewMode ? "100vw" : "",
          }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Quality Claim
            </h3>
            <Drawer.Actions>
              <Whisper
                ref={this.dropdownRef}
                trigger="click"
                placement={"auto"}
                preventOverflow={true}
                speaker={
                  <Popover full>
                    <Dropdown.Menu>
                      {this.userIsGlobal() ? (
                        <>
                          <Dropdown.Item
                            panel
                            className="py-2 font-weight-800 text-uppercase"
                            style={{ paddingLeft: 12, paddingRight: 12 }}
                          >
                            <h5 className="m-0">Claim Status</h5>
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={
                              this.props.claim?.status == "DRAFT" ||
                              this.state.statusSubmitting
                            }
                            onClick={() => {
                              this.setStatus("DRAFT");

                              this.dropdownRef.current.close();
                            }}
                          >
                            Mark as Draft
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={
                              this.props.claim?.status == "SUBMITTED" ||
                              this.state.statusSubmitting
                            }
                            onClick={() => {
                              this.setStatus("SUBMITTED");

                              this.dropdownRef.current.close();
                            }}
                          >
                            Mark as Submitted
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={
                              this.props.claim?.status == "PROCESSING" ||
                              this.state.statusSubmitting
                            }
                            onClick={() => {
                              this.setStatus("PROCESSING");

                              this.dropdownRef.current.close();
                            }}
                          >
                            Mark as Processing
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={
                              this.props.claim?.status == "CREDIT PENDING" ||
                              this.state.statusSubmitting
                            }
                            onClick={() => {
                              this.setStatus("CREDIT PENDING");

                              this.dropdownRef.current.close();
                            }}
                          >
                            Mark as Credit Pending
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={
                              this.props.claim?.status == "COMPLETE" ||
                              this.state.statusSubmitting
                            }
                            onClick={() => {
                              this.setStatus("COMPLETE");

                              this.dropdownRef.current.close();
                            }}
                          >
                            Mark as Completed
                          </Dropdown.Item>

                          <Dropdown.Item divider></Dropdown.Item>
                        </>
                      ) : null}

                      <Dropdown.Item
                        panel
                        className="py-2 font-weight-800 text-uppercase"
                        style={{ paddingLeft: 12, paddingRight: 12 }}
                      >
                        <h5 className="m-0">Manage Claim</h5>
                      </Dropdown.Item>
                      {/**
                       * <Dropdown.Item
                        disabled={
                          (this.props.claim?.status != "SUBMITTED" &&
                            !this.userIsGlobal()) ||
                          this.props.claim?.status == "COMPLETED" ||
                          this.state.statusSubmitting
                        }
                        onClick={() => {
                          this.setState({
                            editMode: true,
                          });

                          this.dropdownRef.current.close();
                        }}
                      >
                        Modify
                      </Dropdown.Item>
                       */}
                      <Dropdown.Item
                        disabled={
                          this.props.claim?.status == "COMPLETE" ||
                          (this.props.claim?.status != "SUBMITTED" &&
                            !this.userIsGlobal()) ||
                          this.state.statusSubmitting ||
                          this.state.deleting
                        }
                        onClick={() => {
                          this.delete();

                          this.dropdownRef.current.close();
                        }}
                        className={`${
                          this.props.claim?.status == "COMPLETE" ||
                          (this.props.claim?.status != "SUBMITTED" &&
                            !this.userIsGlobal()) ||
                          this.state.statusSubmitting ||
                          this.state.deleting
                            ? "text-light"
                            : "text-danger"
                        }`}
                      >
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Popover>
                }
              >
                <Button size="sm" outline color="secondary">
                  Actions <i className="mdi mdi-chevron-down pl-2"></i>
                </Button>
              </Whisper>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            {this.state.reviewMode ? (
              <>
                <Row className="align-items-center mb-3 mt-0">
                  <Col xs="">
                    <p className="m-0 small text-dark">
                      #{this.props?.claim?._id}
                    </p>
                    <h4 className="mb-0 text-dark">
                      (
                      {this.props?.claim?.store?.name
                        ?.replace("Project LeanNation", "PLN")
                        ?.trim()}
                      ) Product Quality Claim -{" "}
                      {moment(
                        this.props.claim?.submittedOn
                          ? this.props.claim?.submittedOn
                          : this.props.claim?.claimDate
                      ).format("MM/DD/YYYY")}
                    </h4>
                  </Col>
                  <Col xs="auto">
                    <h3 className="m-0">
                      {this.formatStatus(this.props.claim)}
                    </h3>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className="align-items-center mb-2 mt-0">
                  <Col xs="">
                    <p className="m-0 text-dark font-weight-500">
                      {this.props?.claim?.store?.name
                        ?.replace("Project LeanNation", "PLN")
                        ?.trim()}
                    </p>
                  </Col>
                  <Col xs="auto">
                    <h3 className="m-0">
                      {this.formatStatus(this.props.claim)}
                    </h3>
                  </Col>
                </Row>
                <p className="m-0 small">#{this.props?.claim?._id}</p>

                <h2 className="mb-4 text-dark">
                  Product Quality Claim -{" "}
                  {moment(this.props.claim?.claimDate).format("MM/DD/YYYY")}
                </h2>
              </>
            )}
            {!this.state.reviewMode && (
              <Row>
                {this.props.claim?.notes ? (
                  <>
                    <Col xs="12">
                      <FormGroup>
                        <h5>Notes</h5>
                        <p className="m-0">{this.props.claim?.notes}</p>
                      </FormGroup>
                    </Col>
                  </>
                ) : null}
                <Col xs="12" md="6">
                  <FormGroup>
                    <h5>Meals Submitted</h5>
                    <p className="m-0">{this.props.claim?.claims?.length}</p>
                  </FormGroup>
                </Col>
                {this.props.claim?.submittedOn ? (
                  <Col xs="12" md="6">
                    <FormGroup>
                      <h5>Submitted On</h5>
                      <p className="m-0">
                        {moment(this.props.claim?.submittedOn).format(
                          "MM/DD/YYYY"
                        )}
                      </p>
                    </FormGroup>
                  </Col>
                ) : null}
                <Col xs="12" md="6">
                  <FormGroup>
                    <h5>Submitted By</h5>
                    <p className="m-0">
                      {this.props.claim?.submittedBy?.personName
                        ? this.props.claim?.submittedBy?.personName
                        : ""}
                      {this.props.claim?.submittedByName
                        ? this.props.claim?.submittedByName
                        : ""}
                      {!this.props.claim?.submittedBy?.personName &&
                      !this.props.claim?.submittedByName
                        ? "--"
                        : ""}
                    </p>
                  </FormGroup>
                </Col>
                {this.props.claim?.status == "COMPLETE" ? (
                  <Col xs="12" md="6">
                    <FormGroup>
                      <h5>Applied To Invoice</h5>
                      <p className="m-0">
                        {this.props.claim?.invoiceNumber ?? "--"}
                      </p>
                    </FormGroup>
                  </Col>
                ) : null}
                {this.props.claim?.status == "COMPLETE" ? (
                  <Col xs="12" md="6">
                    <FormGroup>
                      <h5>Credit Value</h5>
                      <p className="mb-1 text-dark">
                        {StringUtils.centsToCurrency(
                          this.getTotalValue(this.props.claim)
                        )}
                      </p>
                      <p className="small m-0 text-dark">
                        ({this.getTotalCount(this.props.claim, "Lifestyle")}{" "}
                        Lifestyle,{" "}
                        {this.getTotalCount(this.props.claim, "Athlete")}{" "}
                        Athlete,{" "}
                        {this.getTotalCount(this.props.claim, "Lean Cheats")}{" "}
                        Cheats)
                      </p>
                    </FormGroup>
                  </Col>
                ) : null}
              </Row>
            )}
            {this.props.claim?.status == "DRAFT" ? (
              <>
                <Card>
                  <CardBody>
                    <h3 className="m-0">Modify or Submit Your Claim</h3>
                    <p className="mt-0">
                      Your claim is currently in Draft Mode. You can add
                      additional meals to your claim or submit it now for
                      review.
                    </p>

                    <Row className="align-items-center">
                      <Col xs="12" sm="auto" className="mb-3 mb-sm-0 pr-sm-0">
                        <Button
                          color="secondary"
                          outline
                          block
                          onClick={() => {
                            this.setState({
                              createDrawer: true,
                              modifyClaim: this.props.claim,
                            });
                          }}
                        >
                          Modify & Add Meals
                        </Button>
                      </Col>
                      <Col xs="12" sm="auto">
                        <Button
                          block
                          color="primary"
                          onClick={() => {
                            this.setStatus("SUBMITTED");
                          }}
                        >
                          Submit Claim
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </>
            ) : null}
            {this.userIsGlobal() && this.props.claim?.status == "SUBMITTED" ? (
              <>
                <Card>
                  <CardBody>
                    <h3 className="m-0">Begin Processing Claim</h3>
                    <p className="mt-0">
                      Click the button below to mark this claim as processing
                      and begin the claim approval process.
                    </p>
                    <Button
                      color="primary"
                      onClick={() => {
                        this.setStatus("PROCESSING");
                      }}
                    >
                      Start Processing
                    </Button>
                  </CardBody>
                </Card>
              </>
            ) : null}
            {this.userIsGlobal() &&
            this.props.claim?.status == "PROCESSING" &&
            !this.state.reviewMode ? (
              <>
                <Card>
                  <CardBody>
                    <h3 className="m-0">Review & Complete Processing</h3>
                    <p className="mt-0">
                      Review all meals submitted in the claim and
                      approve/disapprove all of the submitted meals. Once
                      complete, click the Complete Processing button below to
                      mark the claim as "Credit Pending" and complete
                      processing.
                    </p>
                    <Button
                      onClick={() => {
                        let claims = this.props.claim?.claims.map((c) => {
                          return _.clone(c);
                        });

                        this.setState({
                          reviewMode: true,
                          pendingClaims: claims,
                          viewMode: "list",
                          tab: {
                            id: "reviewMode",
                          },
                          reviewIndex: 0,
                        });
                      }}
                      color={this.allClaimsReviewed() ? "dark" : "primary"}
                      outline={this.allClaimsReviewed()}
                      className="m-0"
                    >
                      Start Review
                    </Button>
                    {this.allClaimsReviewed() ? (
                      <Button
                        className="ml-2"
                        color="primary"
                        onClick={() => {
                          this.setStatus("CREDIT PENDING");
                        }}
                        disabled={this.state.reviewMode}
                      >
                        Complete Processing
                      </Button>
                    ) : null}
                  </CardBody>
                </Card>
              </>
            ) : null}
            {this.userIsGlobal() &&
            this.props.claim?.status == "CREDIT PENDING" ? (
              <>
                <Card>
                  <CardBody>
                    <h3 className="m-0">Apply Credit & Complete Claim</h3>
                    <p className="mt-0">
                      Enter the invoice number that the credit was applied to
                      below and mark the claim as completed.
                    </p>
                    <h4>Invoice Number</h4>
                    <Input
                      type="text"
                      className="form-control mb-3"
                      placeholder="Invoice Number"
                      value={this.state.invoiceNumber}
                      disabled={this.state.submitting}
                      onChange={(e) => {
                        this.setState({
                          invoiceNumber: e.target.value,
                        });
                      }}
                    />
                    <Button
                      color="primary"
                      disabled={!this.state.invoiceNumber}
                      onClick={() => {
                        this.modifyInvoiceNumber(this.state.invoiceNumber).then(
                          () => {
                            this.setStatus("COMPLETE");
                          },
                          () => {}
                        );
                      }}
                    >
                      Complete Claim
                    </Button>
                  </CardBody>
                </Card>
              </>
            ) : null}
            {!this.userIsGlobal() &&
            this.props.claim?.status == "PROCESSING" ? (
              <>
                <Alert color="superlight" className="p-3 border border-lighter">
                  <h3 className="m-0 text-dark">Processing Claim</h3>
                  <p className="m-0 text-dark">
                    Your claim is currently being processed by the production
                    team. At this time you're unable to make further adjustments
                    to your claim. We'll reach out with any questions or
                    updates.
                  </p>
                </Alert>
              </>
            ) : null}
            {!this.userIsGlobal() &&
            this.props.claim?.status == "CREDIT PENDING" ? (
              <>
                <Alert color="superlight" className="p-3 border border-lighter">
                  <h3 className="m-0 text-dark">Credit Pending</h3>
                  <p className="m-0 text-dark">
                    Your claim has been successfully processed and a credit will
                    be applied to your next production order.
                  </p>
                </Alert>
              </>
            ) : null}
            {!this.state.reviewMode && (
              <div className="mx--2">
                <CardSelectorHeader
                  compact={true}
                  value={this.state.tab}
                  options={[
                    {
                      name: "Submitted Meals",
                      id: "meals",
                    },
                    {
                      name: "Notes",
                      id: "notes",
                    },
                  ]}
                  onChange={(v) => {
                    console.log(v);

                    this.setState({ tab: v });
                  }}
                ></CardSelectorHeader>
              </div>
            )}
            {!this.state.reviewMode && this.state.tab?.id == "meals" && (
              <CardHeader className="px-0 py-2">
                <Button
                  onClick={() => {
                    this.setState({
                      viewMode: "list",
                    });
                  }}
                  className="btn-icon-only"
                  color="dark"
                  outline={this.state.viewMode != "list"}
                >
                  <i className="mdi mdi-view-list"></i>
                </Button>
                <Button
                  onClick={() => {
                    this.setState({
                      viewMode: "grid",
                    });
                  }}
                  className="btn-icon-only"
                  color="dark"
                  outline={this.state.viewMode != "grid"}
                >
                  <i className="mdi mdi-view-grid"></i>
                </Button>
              </CardHeader>
            )}

            {this.state.tab?.id == "reviewMode" ? (
              <>
                <div className="border-top border-bottom p-3">
                  <Row className="align-items-center">
                    <Col xs="12" sm="">
                      <h3 className="m-0 text-dark">
                        Reviewing Meal {this.state.reviewIndex + 1} of{" "}
                        {this.state.pendingClaims.length}
                      </h3>
                    </Col>
                    <Col xs="12" sm="auto" className="pr-sm-0">
                      <Row className="align-items-center">
                        <Col xs="6" sm="auto" className="pr-2">
                          <Button
                            block
                            color="dark"
                            outline
                            className="btn-icon-only m-0"
                            disabled={this.state.reviewIndex == 0}
                            onClick={() => {
                              this.setState({
                                reviewIndex: this.state.reviewIndex - 1,
                              });
                            }}
                          >
                            <i className="mdi mdi-chevron-left"></i>
                          </Button>
                        </Col>
                        <Col xs="6" sm="auto" className="pl-2">
                          <Button
                            block
                            color="dark"
                            outline
                            className="btn-icon-only m-0"
                            disabled={
                              this.state.reviewIndex + 1 ==
                              this.state.pendingClaims.length
                            }
                            onClick={() => {
                              this.setState({
                                reviewIndex: this.state.reviewIndex + 1,
                              });
                            }}
                          >
                            <i className="mdi mdi-chevron-right"></i>
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="12" sm="auto">
                      <Button
                        className="m-0"
                        color="primary"
                        disabled={this.state.submitting}
                        onClick={() => {
                          this.modifyClaims(this.state.pendingClaims);
                        }}
                      >
                        {this.state.submitting ? (
                          <Spinner size="sm"></Spinner>
                        ) : (
                          "Finish Review"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div className="p-3">
                  <Row className="align-items-center">
                    <Col xs="12" sm="6" md="5">
                      <div
                        onMouseOver={() => {
                          this.setState({
                            imageHover: true,
                          });
                        }}
                        onMouseLeave={() => {
                          this.setState({
                            imageHover: false,
                          });
                        }}
                        className="rounded border bg-dark border-midlighter cursor-pointer"
                        style={{
                          width: "100%",
                          height: "calc(100vh - 300px)",
                          background: `url(${this.getClaimImage(
                            this.state.pendingClaims[this.state.reviewIndex]
                          )}) center center / ${
                            this.state.imageHover ? "75%" : "contain"
                          } no-repeat`,
                          position: "relative",
                        }}
                      >
                        <Button
                          onClick={() => {
                            LinkUtils.openInNewTab(
                              this.getClaimImage(
                                this.state.pendingClaims[this.state.reviewIndex]
                              )
                            );
                          }}
                          size="sm"
                          color="white"
                          style={{
                            position: "absolute",
                            bottom: 10,
                            right: 10,
                            zIndex: 2,
                          }}
                        >
                          Open In New Tab
                        </Button>
                      </div>
                    </Col>
                    <Col xs="12" sm="6" md="7" className="mt-4 mt-sm-0">
                      <div className="pb-4 border-bottom mb-4">
                        <h3 className="text-dark mb-0">
                          {_.findWhere(
                            this.state.pendingClaims[this.state.reviewIndex]
                              ?.meal?.categories,
                            {
                              name: "Lean Cheats",
                            }
                          )
                            ? "Lean Cheats"
                            : this.state.pendingClaims[this.state.reviewIndex]
                                ?.meal?.planType?.name}
                        </h3>
                        <h1 className="m-0 text-dark">
                          {
                            this.state.pendingClaims[this.state.reviewIndex]
                              ?.meal?.name
                          }
                        </h1>
                        <p
                          className="text-dark mt-2 mb-0"
                          style={{ lineHeight: 1.2 }}
                        >
                          Code:{" "}
                          {this.state.pendingClaims[this.state.reviewIndex]
                            ?.code1 ?? "(empty)"}
                          -
                          {this.state.pendingClaims[this.state.reviewIndex]
                            ?.code2 ?? "(empty)"}
                        </p>
                      </div>
                      <div>
                        <Button
                          className="btn-icon-only"
                          size="lg"
                          color="danger"
                          outline={
                            this.state.pendingClaims[this.state.reviewIndex]
                              ?.approved === null ||
                            this.state.pendingClaims[this.state.reviewIndex]
                              ?.approved === true
                          }
                          onClick={() => {
                            let claims = this.state.pendingClaims?.map((c) => {
                              return _.clone(c);
                            });

                            claims[this.state.reviewIndex].approved = false;

                            this.setState({
                              pendingClaims: claims,
                            });
                          }}
                        >
                          <i className="mdi mdi-close"></i>
                        </Button>
                        <Button
                          color="success"
                          size="lg"
                          className="btn-icon-only"
                          outline={
                            this.state.pendingClaims[this.state.reviewIndex]
                              ?.approved === null ||
                            this.state.pendingClaims[this.state.reviewIndex]
                              ?.approved === false
                          }
                          onClick={() => {
                            let claims = this.state.pendingClaims?.map((c) => {
                              return _.clone(c);
                            });

                            claims[this.state.reviewIndex].approved = true;
                            claims[this.state.reviewIndex].reason = "";

                            this.setState({
                              pendingClaims: claims,
                            });
                          }}
                        >
                          <i className="mdi mdi-check"></i>
                        </Button>
                        {this.state.pendingClaims[this.state.reviewIndex]
                          ?.approved === false ? (
                          <div className="mt-3">
                            <h3 className="text-dark">Disapproval Reason</h3>
                            <InputPicker
                              style={{ width: "100%", maxWidth: 400 }}
                              position="autoVertical"
                              preventOverflow={true}
                              creatable={true}
                              size="lg"
                              data={this.state.disapprovalOptions}
                              value={
                                this.state.pendingClaims[this.state.reviewIndex]
                                  .reason
                              }
                              cleanable={true}
                              onChange={(v) => {
                                let claims = this.state.pendingClaims?.map(
                                  (c) => {
                                    return _.clone(c);
                                  }
                                );

                                const idx = this.state.reviewIndex;

                                claims[idx].reason = v;

                                this.setState({
                                  pendingClaims: claims,
                                });
                              }}
                            ></InputPicker>
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            ) : null}

            {this.state.tab?.id == "meals" ? (
              <>
                {this.state.viewMode == "list" ? (
                  <>
                    <Table
                      className="mb-0"
                      responsive
                      style={{ minWidth: "100%" }}
                    >
                      <thead className="thead-light">
                        <th
                          className="text-center align-middle text-dark"
                          style={{ width: 60 }}
                        >
                          Image
                        </th>
                        <th className="text-dark align-middle">Meal</th>
                        <th className="text-dark align-middle">
                          {this.props.claim?.status == "PROCESSING" &&
                          this.userIsGlobal() ? (
                            <>
                              {!this.state.reviewMode ? (
                                <></>
                              ) : (
                                <Button
                                  className="m-0"
                                  size="sm"
                                  color="primary"
                                  disabled={this.state.submitting}
                                  onClick={() => {
                                    this.modifyClaims(this.state.pendingClaims);
                                  }}
                                >
                                  {this.state.submitting ? (
                                    <Spinner size="sm"></Spinner>
                                  ) : (
                                    "Save"
                                  )}
                                </Button>
                              )}
                            </>
                          ) : (
                            <>Status</>
                          )}
                        </th>
                      </thead>
                      {this.props.claim?.claims?.map((claimed, i) => (
                        <tr key={i}>
                          <td className="align-middle" style={{ width: 60 }}>
                            <Whisper
                              trigger={"click"}
                              placement="auto"
                              preventOverflow={true}
                              ref={this.popoverRef}
                              enterable
                              speaker={
                                <Popover full>
                                  <Card
                                    style={{
                                      width: "auto",

                                      maxWidth: 700,
                                      overflow: "auto",
                                      maxHeight: "75vh",
                                    }}
                                    className=" shadow-none rounded"
                                  >
                                    <CardHeader
                                      className="p-2 border-bottom"
                                      style={{ position: "sticky", top: 0 }}
                                    >
                                      <div>
                                        <Row className="align-items-center">
                                          <Col xs="">
                                            <h4 className="m-0">Meal Image</h4>
                                          </Col>
                                          <Col xs="auto">
                                            <Button
                                              onClick={() => {
                                                this.popoverRef.current.close();
                                              }}
                                              size="sm"
                                              outline
                                              color="dark"
                                              className="btn-icon-only"
                                            >
                                              <i className="mdi mdi-close"></i>
                                            </Button>
                                            <Button
                                              onClick={() => {
                                                LinkUtils.openInNewTab(
                                                  this.getClaimImage(claimed)
                                                );
                                              }}
                                              size="sm"
                                              outline
                                              color="dark"
                                              className="btn-icon-only"
                                            >
                                              <i className="mdi mdi-open-in-new"></i>
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </CardHeader>
                                    <CardBody className="p-0">
                                      <img
                                        src={this.getClaimImage(claimed)}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                        alt="Meal"
                                      ></img>
                                    </CardBody>
                                  </Card>
                                </Popover>
                              }
                            >
                              <div
                                style={{
                                  width: 60,
                                  height: 60,
                                  background: `url(${this.getClaimImage(
                                    claimed
                                  )}) center center / contain no-repeat`,
                                }}
                                className="border rounded cursor-pointer"
                              ></div>
                            </Whisper>
                          </td>
                          <td className="align-middle">
                            <h3 className="m-0 text-dark">
                              {claimed?.meal?.name} (
                              {_.findWhere(claimed?.meal?.categories, {
                                name: "Lean Cheats",
                              })
                                ? "Lean Cheats"
                                : claimed?.meal?.planType?.name}
                              )
                            </h3>
                            {claimed?.code1 && claimed?.code2 ? (
                              <p
                                className="text-dark m-0"
                                style={{ lineHeight: 1.2 }}
                              >
                                Code: {claimed?.code1 ?? "(empty)"}-
                                {claimed?.code2 ?? "(empty)"}
                              </p>
                            ) : null}
                            {claimed?.approved === false ||
                            this.getClaimStatus(claimed) === false ? (
                              <>
                                {this.state.reviewMode ? (
                                  <>
                                    <Input
                                      type="textarea"
                                      placeholder="Disapproval Reason"
                                      value={this.state.pendingClaims[i].reason}
                                      onChange={(e) => {
                                        let claims =
                                          this.state.pendingClaims?.map((c) => {
                                            return _.clone(c);
                                          });

                                        const idx = _.findIndex(claims, {
                                          _id: claimed?._id,
                                        });

                                        claims[idx].reason = e.target.value;

                                        this.setState({
                                          pendingClaims: claims,
                                        });
                                      }}
                                      className="form-control-sm mt-2"
                                    ></Input>
                                  </>
                                ) : (
                                  <>
                                    <p
                                      className="mb-0 mt-2 small text-dark"
                                      style={{ lineHeight: 1.2, fontSize: 14 }}
                                    >
                                      Reason:{" "}
                                      {claimed?.reason ? claimed?.reason : "--"}
                                    </p>
                                  </>
                                )}
                              </>
                            ) : null}
                          </td>
                          <td className="align-middle">
                            {this.state.reviewMode ? (
                              <>
                                <Button
                                  size="sm"
                                  className="btn-icon-only"
                                  outline={
                                    this.getClaimStatus(claimed) === null ||
                                    this.getClaimStatus(claimed) === true
                                  }
                                  color="danger"
                                  onClick={() => {
                                    let claims = this.state.pendingClaims?.map(
                                      (c) => {
                                        return _.clone(c);
                                      }
                                    );

                                    const idx = _.findIndex(claims, {
                                      _id: claimed?._id,
                                    });

                                    claims[idx].approved = false;

                                    console.log(claims[idx]);

                                    this.setState({
                                      pendingClaims: claims,
                                    });
                                  }}
                                >
                                  <i className="mdi mdi-close"></i>
                                </Button>
                                <Button
                                  size="sm"
                                  className="btn-icon-only"
                                  outline={
                                    this.getClaimStatus(claimed) === null ||
                                    this.getClaimStatus(claimed) === false
                                  }
                                  color="success"
                                  onClick={() => {
                                    let claims = this.state.pendingClaims?.map(
                                      (c) => {
                                        return _.clone(c);
                                      }
                                    );

                                    const idx = _.findIndex(claims, {
                                      _id: claimed?._id,
                                    });

                                    claims[idx].approved = true;
                                    claims[idx].reason = "";

                                    this.setState({
                                      pendingClaims: claims,
                                    });
                                  }}
                                >
                                  <i className="mdi mdi-check"></i>
                                </Button>
                              </>
                            ) : (
                              <h3 className="m-0">
                                {claimed?.approved === null ||
                                  (claimed?.approved === undefined && (
                                    <>
                                      <Badge color="medium">
                                        {this.props.claim?.status == "DRAFT"
                                          ? "Pending Submission"
                                          : "Pending Review"}
                                      </Badge>
                                    </>
                                  ))}
                                {claimed?.approved && (
                                  <>
                                    <Badge color="success">Approved</Badge>
                                  </>
                                )}
                                {claimed?.approved === false && (
                                  <>
                                    <Badge color="danger">Unapproved</Badge>
                                  </>
                                )}
                              </h3>
                            )}
                          </td>
                        </tr>
                      ))}
                    </Table>
                  </>
                ) : (
                  <>
                    <Row className="align-items-top mt-4">
                      {this.props.claim?.claims?.map((claimed, i) => (
                        <Col
                          className="text-center mb-4"
                          xs="12"
                          sm="6"
                          md="6"
                          lg="6"
                          key={i}
                        >
                          <Card>
                            <Whisper
                              trigger={"click"}
                              placement="auto"
                              preventOverflow={true}
                              ref={this.popoverRef}
                              enterable
                              speaker={
                                <Popover full>
                                  <Card
                                    style={{
                                      width: "auto",

                                      maxWidth: 700,
                                      overflow: "auto",
                                      maxHeight: "75vh",
                                    }}
                                    className=" shadow-none rounded"
                                  >
                                    <CardHeader
                                      className="p-2 border-bottom"
                                      style={{ position: "sticky", top: 0 }}
                                    >
                                      <div>
                                        <Row className="align-items-center">
                                          <Col xs="">
                                            <h4 className="m-0">Meal Image</h4>
                                          </Col>
                                          <Col xs="auto">
                                            <Button
                                              onClick={() => {
                                                this.popoverRef.current.close();
                                              }}
                                              size="sm"
                                              outline
                                              color="dark"
                                              className="btn-icon-only"
                                            >
                                              <i className="mdi mdi-close"></i>
                                            </Button>
                                            <Button
                                              onClick={() => {
                                                LinkUtils.openInNewTab(
                                                  this.getClaimImage(claimed)
                                                );
                                              }}
                                              size="sm"
                                              outline
                                              color="dark"
                                              className="btn-icon-only"
                                            >
                                              <i className="mdi mdi-open-in-new"></i>
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </CardHeader>
                                    <CardBody className="p-0">
                                      <img
                                        src={this.getClaimImage(claimed)}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                        alt="Meal"
                                      ></img>
                                    </CardBody>
                                  </Card>
                                </Popover>
                              }
                            >
                              <div
                                style={{
                                  width: "100%",
                                  height: 400,
                                  background: `url(${this.getClaimImage(
                                    claimed
                                  )}) center center / cover no-repeat`,
                                  position: "relative",
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                }}
                                className="cursor-pointer"
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0.5rem",
                                    right: "0.5rem",
                                  }}
                                >
                                  {claimed?.approved === null ||
                                    (claimed?.approved === undefined && (
                                      <>
                                        <Badge
                                          style={{ fontSize: 16 }}
                                          color=""
                                          className="bg-midlighter text-dark shadow"
                                        >
                                          {this.props.claim?.status == "DRAFT"
                                            ? "Pending"
                                            : "Pending"}
                                        </Badge>
                                      </>
                                    ))}
                                  {claimed?.approved && (
                                    <>
                                      <Badge
                                        style={{ fontSize: 16 }}
                                        className="bg-success text-white shadow"
                                        color=""
                                      >
                                        Approved
                                      </Badge>
                                    </>
                                  )}
                                  {claimed?.approved === false && (
                                    <>
                                      <Badge
                                        style={{ fontSize: 16 }}
                                        className="bg-danger text-white shadow"
                                        color=""
                                      >
                                        Unapproved
                                      </Badge>
                                    </>
                                  )}
                                </div>
                                {(claimed?.approved === false ||
                                  this.getClaimStatus(claimed) === false) && (
                                  <div
                                    className="bg-white p-1 rounded shadow border"
                                    style={{
                                      position: "absolute",
                                      bottom: "0.5rem",
                                      left: "0.5rem",
                                      maxWidth: "calc(100% - 1rem)",
                                    }}
                                  >
                                    <p
                                      className="m-0 small text-dark"
                                      style={{ lineHeight: 1.2, fontSize: 14 }}
                                    >
                                      Reason:{" "}
                                      {claimed?.reason ? claimed?.reason : "--"}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </Whisper>
                            <CardBody>
                              <div>
                                <h3 className="m-0 text-dark">
                                  {claimed?.meal?.name} (
                                  {_.findWhere(claimed?.meal?.categories, {
                                    name: "Lean Cheats",
                                  })
                                    ? "Lean Cheats"
                                    : claimed?.meal?.planType?.name}
                                  )
                                </h3>
                                {claimed?.code1 && claimed?.code2 ? (
                                  <p
                                    className="text-dark mb-2"
                                    style={{ lineHeight: 1.2 }}
                                  >
                                    Code: {claimed?.code1 ?? "(empty)"}-
                                    {claimed?.code2 ?? "(empty)"}
                                  </p>
                                ) : null}
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </>
                )}
              </>
            ) : null}
            {this.state.tab?.id == "notes" ? (
              <>
                <div className="my-3">
                  <div>
                    <NoteBody
                      claim={this.props.claim}
                      onSave={(note) => {
                        this.setState(
                          {
                            page: 1,
                          },
                          () => {
                            this.fetchNotes(true);
                          }
                        );
                      }}
                    ></NoteBody>
                  </div>
                  <div>
                    {this.state?.loading && (
                      <div className="px-3 py-5 text-center">
                        <Spinner size="sm" color="dark"></Spinner>
                      </div>
                    )}
                    {!this.state?.notes?.length && !this.state.loading && (
                      <div className="px-3 py-5">
                        <Row>
                          <Col xs="12" className="text-center">
                            <p className="m-0">No notes found.</p>
                          </Col>
                        </Row>
                      </div>
                    )}

                    {this.state?.notes?.map((note, i) => (
                      <div key={note?._id}>
                        {this.state.editNote == note?._id ? (
                          <>
                            <div className="p-4">
                              <NoteBody
                                note={note}
                                claim={this.props.claim}
                                forceEdit={true}
                                onSave={(n) => {
                                  let notes = this.state.notes;

                                  let idx = _.findIndex(notes, {
                                    _id: n._id,
                                  });

                                  if (idx >= 0) {
                                    notes[idx] = n;
                                  }

                                  this.setState({
                                    editNote: "",
                                    notes,
                                  });
                                }}
                                onCancel={() => {
                                  this.setState({ editNote: "" });
                                }}
                              ></NoteBody>
                            </div>
                          </>
                        ) : (
                          <div className="px-4 cursor-pointer py-3 bg-superlight--hover">
                            <Row>
                              <Col xs="">
                                {note?.title ? (
                                  <>
                                    <h3 className="text-dark mb-1">
                                      {note?.title}
                                    </h3>
                                    <Linkify
                                      body={note?.body}
                                      wrap={true}
                                    ></Linkify>
                                  </>
                                ) : (
                                  <>
                                    <Linkify
                                      body={note?.body}
                                      wrap={true}
                                    ></Linkify>
                                  </>
                                )}
                              </Col>
                              <Col xs="auto">
                                <Button
                                  size="sm"
                                  color="secondary"
                                  outline
                                  className="p-1 mr-0"
                                  style={{ width: 22, height: 22 }}
                                  onClick={() => {
                                    this.setState({
                                      editNote: note?._id,
                                    });
                                  }}
                                >
                                  <i
                                    className="mdi mdi-pencil"
                                    style={{
                                      fontSize: 16,
                                      lineHeight: 1,
                                      position: "relative",
                                      left: -2,
                                      top: -2,
                                    }}
                                  ></i>
                                </Button>
                                <Button
                                  size="sm"
                                  color="danger"
                                  outline
                                  className="p-1"
                                  style={{ width: 22, height: 22 }}
                                  disabled={
                                    this.state.deletingNote == note?._id
                                  }
                                  onClick={() => {
                                    swal({
                                      title: "Delete Note?",
                                      text: "Are you sure you want to delete this note?",
                                      icon: "warning",
                                      buttons: ["Nevermind", "Delete"],
                                      dangerMode: true,
                                    }).then((conf) => {
                                      if (!conf) {
                                        return;
                                      }

                                      APIV2.deleteStoreNote(
                                        note?.storeID,
                                        note?._id
                                      ).then(() => {
                                        let notes = this.state.notes;

                                        let idx = _.findIndex(notes, {
                                          _id: note._id,
                                        });

                                        notes.splice(idx, 1);

                                        this.setState({
                                          editNote: "",
                                          notes,
                                        });

                                        this.setState({
                                          deletingNote: "",
                                        });
                                      });
                                    });
                                  }}
                                >
                                  <i
                                    className="mdi mdi-close"
                                    style={{
                                      fontSize: 18,
                                      lineHeight: 1,
                                      position: "relative",
                                      left: -3,
                                      top: -3,
                                    }}
                                  ></i>
                                </Button>
                              </Col>
                              {note?.attachments?.length > 0 && (
                                <>
                                  <Col className="mt-2" xs="12">
                                    <h5>Attachments: </h5>
                                    {note?.attachments?.map((at, i) => (
                                      <div
                                        className={`p-2 border rounded ${
                                          i !=
                                            this.state?.attachments?.length -
                                              1 && "mb-2"
                                        }`}
                                        key={i}
                                      >
                                        <Row className="align-items-center">
                                          <Col xs="">
                                            <p className="small m-0 text-dark">
                                              {at?.fileName}
                                            </p>
                                          </Col>
                                          <Col xs="auto">
                                            <Button
                                              className="btn-icon-only"
                                              size="sm"
                                              outline
                                              color="secondary"
                                              onClick={() => {
                                                LinkUtils.openPrivateURL(
                                                  at?.url
                                                );
                                              }}
                                            >
                                              <i className="mdi mdi-eye"></i>
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    ))}
                                  </Col>
                                </>
                              )}
                            </Row>
                            <div className="mt-2">
                              <small>
                                {moment(note?.createdAt).format(
                                  "MM/DD/YY h:mmA"
                                )}{" "}
                                by {note?.createdBy?.name}
                              </small>
                            </div>
                          </div>
                        )}
                        {i != this.state?.notes?.length - 1 && (
                          <hr className="m-0"></hr>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="text-center p-2">
                    <Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        console.log(this.state.pagination);

                        this.setState(
                          {
                            page: this.state.pagination?.next,
                          },
                          () => {
                            this.fetchNotes();
                          }
                        );
                      }}
                      disabled={!this.state.pagination?.hasNext}
                    >
                      {this.state.pagination?.hasNext
                        ? "View More Notes"
                        : "Viewing All Notes"}
                    </Button>
                  </div>
                </div>
              </>
            ) : null}
          </Drawer.Body>
        </Drawer>
        <NewQualityClaimDrawer
          store={this.props.store}
          open={this.state.createDrawer}
          claim={this.state.modifyClaim}
          onClose={() => {
            this.setState({
              createDrawer: false,
            });
          }}
        ></NewQualityClaimDrawer>
      </>
    );
  }
}

export default withRouter(QualityClaimDetailDrawer);
