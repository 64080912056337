import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import API from "lib/API";
import BillingAccountRow from "components/BillingAccountRow";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex, isNumber } from "underscore";
import { withRouter } from "react-router-dom";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import FormGroup from "reactstrap/lib/FormGroup";
import _ from "underscore";
import swal from "sweetalert";
import Spinner from "reactstrap/lib/Spinner";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";

import draftToHtml from "draftjs-to-html";
import { TagPicker, Uploader } from "rsuite";
import moment from "moment";
import StringUtils from "lib/StringUtils";
import ArrowText from "components/ArrowText";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import LeadInformationWidget from "components/Widgets/LeadInformationWidget";
import { Capacitor } from "@capacitor/core";
import LeadSummaryWidget from "components/Widgets/LeadSummaryWidget";
import LeadAllTasksWidget from "components/Widgets/LeadAllTasksWidget";
import CustomerCreateDrawer from "components/Drawers/CustomerCreateDrawer";
import LeadCommunicationTabView from "components/TabView/LeadCommunicationTabView";
import LeadTimelineWidget from "components/Widgets/LeadTimelineWidget";
import TechSupportTicketDrawer from "components/Drawers/TechSupportTicketDrawer";
import FranDevLeadSummaryWidget from "../../components/Widgets/FranDevLeadSummaryWidget";
import FranDevLeadNotesWidget from "../../components/Widgets/FranDevLeadNotesWidget";
import FranDevLeadTasksWidget from "../../components/Widgets/FranDevLeadTasksWidget";

const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};

const delimiters = [...KeyCodes.enter, KeyCodes.comma];

class FranDevLeadView extends React.Component {
  state = {
    store: null,
    loading: true,
    activeTab: "about",
    descriptionState: EditorState.createEmpty(),
    loadingCategories: false,
    categories: [],
    categoryTags: [],
    allergens: [],
    tab: { name: "Information", id: "information" },
    tabs: [
      {
        name: "Information",
        id: "information",
      },
      {
        name: "Notes",
        id: "notes",
      },
      {
        name: "Tasks",
        id: "tasks",
      },
    ],
  };

  constructor(props) {
    super(props);

    this.headerRef = React.createRef();
  }

  onDescriptionStateChange(editorState) {
    this.setState({
      descriptionState: editorState,
      modified: true,
    });
  }

  _loadLeadState(lead) {
    if (!lead) {
      return;
    }

    if (!lead.details) {
      lead.details = "";
    }

    let leadDetails = lead.details;

    this.setState({
      lead: lead,
      leadDetails: leadDetails,
    });
  }

  async loadLead() {
    const {
      match: { params },
    } = this.props;

    APIV2.getFranDevLeadByID(params.leadID)
      .then(
        (data) => {
          console.log(data.data);

          if (data && data.data && data.data.lead) {
            this._loadLeadState(data.data.lead);
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  delete() {
    swal({
      title: "Delete Lead?",
      text: "Are you sure you want to delete this lead? This cannot be undone.",
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (!willDelete) {
        return;
      }

      this.setState({ deleting: true });

      APIV2.deleteFranDevLead(this.state.lead._id)
        .then(
          (data) => {
            PubSub.publish(Event.LEAD.DELETED, {
              id: this.state.lead._id,
            });

            swal("Lead successfully deleted", {
              icon: "success",
            });

            this.props.history.push("/frandev/leads");
          },
          (e) => {
            console.error(e);
            window.alert(
              "We're having trouble removing this lead. Please try again."
            );
          }
        )
        .finally(() => {
          this.setState({ deleting: false });
        });
    });
  }

  checkStickyHeader(e) {
    if (this.headerRef.current) {
      const stickyElm = this.headerRef.current;

      var rect = stickyElm.getBoundingClientRect();

      if (rect.top <= (Capacitor.getPlatform() == "ios" ? 110 : 80)) {
        this.setState({ sticky: true });
      } else {
        this.setState({ sticky: false });
      }

      if (rect.top == 0) {
        this.setState({ fixed: true });
      } else {
        this.setState({ fixed: false });
      }
    }
  }

  componentDidMount() {
    this.loadLead();

    PubSub.subscribe(Event.LEAD.MODIFIED, (c) => {
      this.setState({ lead: c, leadDetails: c?.details });
    });

    if (this.props.match?.params?.tabID) {
      this.setTab(this.props.match?.params?.tabID);
    } else {
      this.setTab("summary");
    }

    document.addEventListener("scroll", this.checkStickyHeader.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.checkStickyHeader.bind(this));
  }

  setTab(tab) {
    if (!tab) {
      return;
    }

    let t = _.findWhere(this.state.allTabs, { id: tab });

    if (t) {
      this.setState({ tab: t });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match?.params?.leadID != prevProps.match?.params?.leadID) {
      this.loadLead();
    }

    if (this.props.match?.params?.tabID !== prevProps.match?.params?.tabID) {
      if (this.props.match?.params?.tabID) {
        this.setTab(this.props.match?.params?.tabID);
      } else {
        this.setTab("information");
      }
    }
  }

  /**
   * Sets the lead's details
   */
  setLeadDetails() {
    this.setState({
      submittingLeadDetails: true,
    });

    APIV2.setLeadVariable(
      this.props.match.params.storeID,
      this.state.lead._id,
      "details",
      this.state.leadDetails
    )
      .then(
        (data) => {
          this._loadLeadState(data.data.lead);
        },
        (err) => {}
      )
      .finally(() => {
        this.setState({ submittingLeadDetails: false });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({ modified: true });

    this.setState({
      [name]: value,
    });
  }

  handleInputNoSpaceChange(e) {
    const { name, value } = e.target;

    this.setState({ modified: true });

    this.setState({
      [name]: value.replace(/\s/g, ""),
    });
  }

  handleNumberInputChange(e) {
    const { name, value } = e.target;

    this.setState({ modified: true });

    this.setState({
      [name]: value.replace(/[^\d.]/g, ""),
    });
  }

  categoryChange(v) {
    this.setState({ categories: v, modified: true });
  }

  allergenChange(v) {
    this.setState({ allergens: v, modified: true });
  }

  sinceDateFormat(d) {
    let date = moment(d);

    return date.format("MMMM YYYY");
  }

  renderNextBillDate(d) {
    let mom = moment(d);

    let now = moment();

    if (mom.format("MMMM Do YYYY") == now.format("MMMM Do YYYY")) {
      return "Today";
    }

    return mom.format("M/D/YY");
  }

  formatStatus(c) {
    if (c?.status == "Not Contacted") {
      return (
        <Badge color="" className="bg-yellow text-dark">
          {c?.status}
        </Badge>
      );
    }

    if (c?.status == "Attempted") {
      return <Badge color="info">{c?.status}</Badge>;
    }

    if (c?.status == "closedwon") {
      return <Badge color="success">Converted</Badge>;
    }

    if (c?.status == "Inactive") {
      return <Badge color="light">{c?.status}</Badge>;
    }

    return "--";
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader
            backOverride={`/frandev/leads`}
            title={
              this.state.lead ? (
                <>
                  {this.state.lead?.name
                    ? this.state.lead?.name
                    : "(add a name)"}

                  <br />
                  <small className="text-dark">{this.state.lead?.stage}</small>
                </>
              ) : (
                "Loading..."
              )
            }
            showBackButton={true}
            forceSecondaryDropdown={true}
            secondaryActions={
              [
                /*{
                name: <>Duplicate</>,
                onClick: () => {
                  PubSub.publish(Event.MEAL.CREATE_OPEN);
                },
              },*/
              ]
            }
            secondaryAction={
              this.state.modified
                ? {
                    name: <>Cancel</>,
                    onClick: () => {
                      this.loadMeal();
                      this.setState({ modified: false, modifyImage: false });
                    },
                    disabled: this.state.submitting,
                  }
                : null
            }
            tabs={
              <CardSelectorHeader
                id="stickyHeaderFranDevLeads"
                loading={this.state.loading}
                value={this.state.tab}
                options={this.state.tabs}
                compact={true}
                onChange={(option) => {
                  this.props.history.push(
                    `/frandev/leads/${this.props.match.params.leadID}/${option.id}`,
                    { noScroll: true }
                  );
                }}
              ></CardSelectorHeader>
            }
            sticky={true}
            primaryAction={
              this.state.modified
                ? {
                    name: <>Save</>,
                    onClick: () => {
                      this.modify();
                    },
                    submitting: this.state.submitting,
                    disabled: this.state.submitting,
                  }
                : null
            }
          />

          {/* Table */}
          <Row>
            <Col xs="12" md="12">
              {this.props?.match?.params?.activeTab == "information" ? (
                <>
                  <FranDevLeadSummaryWidget
                    loading={this.state.loading}
                    lead={this.state.lead}
                  />
                </>
              ) : null}
              {/**Primary  Widgets */}
              {this.props?.match?.params?.activeTab == "notes" ? (
                <>
                  <FranDevLeadNotesWidget
                    lead={this.state.lead}
                    loading={this.state.loading}
                  ></FranDevLeadNotesWidget>
                </>
              ) : null}
              {this.props?.match?.params?.activeTab == "tasks" ? (
                <>
                  <FranDevLeadTasksWidget
                    lead={this.state.lead}
                    loading={this.state.loading}
                  ></FranDevLeadTasksWidget>
                </>
              ) : null}
            </Col>
          </Row>
          <hr className="mt-2 mb-4" />
          <Row>
            <Col xs="auto">
              <Button
                color="danger"
                onClick={() => this.delete()}
                outline
                disabled={this.state.deleting}
              >
                {this.state.deleting ? (
                  <>
                    <Spinner size="sm" color="danger" />
                  </>
                ) : (
                  <>Delete Lead</>
                )}
              </Button>
            </Col>
            <Col xs=""></Col>
          </Row>
        </Container>
        <CustomerCreateDrawer
          store={this.state.lead?.store}
          open={this.state.createCustomerOpen}
          lead={this.state?.lead}
          onClose={() => {
            this.setState({
              createCustomerOpen: false,
            });
          }}
          onCreate={(c) => {
            PubSub.publish(Event.CUSTOMER.CREATED, c);
          }}
        ></CustomerCreateDrawer>
      </>
    );
  }
}

export default withRouter(FranDevLeadView);
