import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  CheckPicker,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Constant from "lib/Constant";
import Editor from "components/NovelEditor/ui/editor";

class NoteTemplateDrawer extends React.Component {
  state = {
    message: "",
    name: "",
  };

  componentDidUpdate(prevProps) {
    if (this.props.template != prevProps?.template && this.props.template) {
      this.setState({
        name: this.props.template?.name + (this.props.duplicate ? " Copy" : ""),
        description: this.props.template?.description,
        type: this.props.template?.type,
        template: this.props.template?.template,
        templateLoad: true,
      });

      if (this.props.template?.template && this.props.open) {
        setTimeout(() => {
          console.log("PUBLISH");

          PubSub.publish("editor:contentUpdated", {
            event: "contentUpdated",
            data: this.props.template?.template,
            id: "noteTemplateEditor",
            forceHydrate: true,
          });
        }, 0);
      }
    }
  }

  componentDidMount() {
    if (this.props.template) {
      this.setState({
        name: this.props.template?.name + (this.props.duplicate ? " Copy" : ""),
        description: this.props.template?.description,
        type: this.props.template?.type,
        template: this.props.template?.template,
        templateLoad: true,
      });
    }
  }

  toggleModal() {
    this.setState({
      name: "",
      description: "",
      type: [],
      template: {},
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  submit() {
    if (this.props.template && !this.props.duplicate) {
      this.modify();
    } else {
      this.create();
    }
  }

  create() {
    this.setState({ submitting: true });

    APIV2.createGlobalNoteTemplate(
      this.state.name,
      this.state.description,
      this.state.type,
      this.state.template
    )
      .then(
        (data) => {
          const template = data.data.template;

          this.toggleModal();

          PubSub.publish(Event.NOTE_TEMPLATE.CREATED, template);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create template - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  modify() {
    this.setState({ submitting: true });

    APIV2.modifyGlobalNoteTemplate(
      this.props.template?._id,
      this.state.name,
      this.state.description,
      this.state.type,
      this.state.template
    )
      .then(
        (data) => {
          const template = data.data.template;

          this.toggleModal();

          PubSub.publish(Event.NOTE_TEMPLATE.MODIFIED, template);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify template - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="md"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.template && !this.props.duplicate
                ? "Modify Template"
                : null}
              {this.props.template && this.props.duplicate
                ? "Duplicate Template"
                : null}
              {!this.props.template ? "New Template" : null}
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  !this.state.name ||
                  !this.state.type?.length ||
                  !this.state.template?.text
                }
                color="primary"
                onClick={this.submit.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>
                    {this.props.template && !this.props.duplicate
                      ? "Save"
                      : "Add"}
                  </>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}

            <FormGroup>
              <h5>
                Name{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="text"
                name="name"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Description</h5>
              <Input
                bsSize="sm"
                type="textarea"
                rows={2}
                name="description"
                placeholder="Description"
                value={this.state.description}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>
                Template Type{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <CheckPicker
                block
                placement="auto"
                preventOverflow={true}
                cleanable
                searchable={false}
                data={[
                  {
                    label: "Store Note",
                    value: "Store",
                  },
                  {
                    label: "FranDev Lead Note",
                    value: "FranDev Lead",
                  },
                  {
                    label: "FranDev Lead Text Message",
                    value: "FranDev Lead Text Message",
                  },
                  {
                    label: "Resource Center Topic",
                    value: "Resource Center",
                  },
                  {
                    label: "System Update Announcement",
                    value: "System Update",
                  },
                ]}
                value={this.state.type}
                onChange={(v) => {
                  this.setState({
                    type: v,
                  });
                }}
              ></CheckPicker>
            </FormGroup>
            {this.state.type == "FranDev Lead Text Message" && (
              <>
                <FormGroup>
                  <h5>Variable Options</h5>
                  <div className="p-2 border rounded mb-2">
                    <code className="text-dark bg-superlight">{`{{contact.first_name}}`}</code>{" "}
                    - Contact's first name
                  </div>
                  <div className="p-2 border rounded mb-2">
                    <code className="text-dark bg-superlight">{`{{contact.last_name}}`}</code>{" "}
                    - Contact's last name
                  </div>
                  <div className="p-2 border rounded mb-2">
                    <code className="text-dark bg-superlight">{`{{contact.market_name}}`}</code>{" "}
                    - Contact's top market name
                  </div>
                </FormGroup>
              </>
            )}
            <FormGroup>
              <h5>
                Template{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <div className="rounded border p-3">
                <Editor
                  editable={true}
                  allowForceHydration={
                    this.props.template && this.state.templateLoad
                  }
                  id={"noteTemplateEditor"}
                  padding="p-0"
                  autoSave={false}
                  debounceRate={750}
                  value={this.state?.template}
                  onChange={(value) => {
                    if (value?.clear) {
                      this.setState({
                        templateLoad: false,
                      });

                      return;
                    }

                    this.setState({
                      template: value,
                    });
                  }}
                  allowTaskLists={true}
                ></Editor>
              </div>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(NoteTemplateDrawer);
