import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
} from "date-fns";
import { Button, Col, Row } from "reactstrap";
import moment from "moment-timezone";
import _, { map } from "underscore";
import { Calendar, Whisper, Popover, Badge, Drawer } from "rsuite";
import AppointmentCard from "./AppointmentCard";
import OutreachCard from "./OutreachCard";
import ContentCalendarCard from "components/ContentCalendarCard";
import ConsultationDetailDrawer from "./Drawers/ConsultationDetailDrawer";
import ContentCalendarDetailDrawer from "./Drawers/ContentCalendarDetailDrawer";
import OutreachDetailDrawer from "./Drawers/OutreachDetailDrawer";

const CalendarMonthView = ({
  timeslotComponent,
  onChange,
  loading,
  data,
  compareKey,
  emptyString,
  onRefresh,
  columnHeight,
  groups = null,
  grouped = false,
  store = null,
  dateRange = null,
}) => {
  const [viewDay, setViewDay] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dayEvents, setDayEvents] = useState([]);
  const [detailOpen, setDetailOpen] = useState(false);
  const [detailContent, setDetailContent] = useState(null);

  const renderCell = (date) => {
    const list = getDataForDay(date);

    //console.log(list);

    const displayList = list.filter((item, index) => index < 3);

    if (list.length) {
      const moreCount = list.length - displayList.length;
      const moreItem = (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            textAlign: "center",
            width: "100%",
          }}
        >
          <div
            className="text-primary cursor-pointer"
            onClick={() => {
              setSelectedDate(date);
              setDayEvents(list);
              setViewDay(true);
            }}
            style={{ fontSize: 14 }}
          >
            View<span className="d-none d-sm-inline">&nbsp;All</span>
          </div>
        </div>
      );

      return (
        <>
          {loading ? (
            <div style={{ position: "relative", height: `calc(100% - 23px)` }}>
              <div
                style={{
                  height: 11,
                  borderRadius: 2,
                  width: "100%",
                  marginBottom: 3,
                }}
                className="skeleton"
              ></div>
              <div
                style={{
                  height: 11,
                  borderRadius: 2,
                  width: "100%",
                  marginBottom: 3,
                }}
                className="skeleton"
              ></div>
            </div>
          ) : (
            <div style={{ position: "relative", height: `calc(100% - 23px)` }}>
              {displayList.map((item, index) => (
                <div
                  style={{
                    fontSize: 11,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  key={index}
                  className="cursor-pointer"
                  onClick={() => {
                    setDetailContent(item);
                    if (item?.group == "Appointments") {
                      setDetailOpen("consult");
                    } else if (item?.group == "Community Events") {
                      setDetailOpen("outreach");
                    } else if (item?.group == "Marketing & Social") {
                      setDetailOpen("content");
                    }
                  }}
                >
                  <div
                    style={{
                      height: 7,
                      width: 7,
                      borderRadius: "100%",
                      marginRight: 3,
                    }}
                    className={`d-inline-block text-dark ${
                      item?.group == "Appointments"
                        ? item?.appointmentType == "ec-consult"
                          ? "bg-success"
                          : "bg-primary"
                        : null
                    } ${item?.type == "Social Media" ? "bg-purple" : null} ${
                      item?.group == "Community Events" ? "bg-info" : null
                    }`}
                  />{" "}
                  <b>{moment(item?.startsAt).format("h:mma")}</b>:{" "}
                  <span
                    style={{
                      textDecoration:
                        (item?.group == "Appointments" ||
                          item?.group == "Community Events") &&
                        (item?.status == "COMPLETED" ||
                          item?.status == "NO SHOW")
                          ? "line-through"
                          : "",
                    }}
                  >
                    {item.name}
                  </span>
                </div>
              ))}
              {moreItem}
            </div>
          )}
        </>
      );
    }

    return null;
  };

  const [currentMonth, setCurrentMonth] = useState(new Date());

  const getDataForDay = useCallback(
    (day, group = null, countOnly = false) => {
      if (loading) {
        return [
          {
            loading: true,
          },
          {
            loading: true,
          },
        ];
      }

      if (!data?.length) {
        return [];
      }

      const dateString = moment(day?.toISOString());

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      let filtered = _.filter(data, (val) => {
        return (
          moment.utc(val[compareKey]).tz(timezone)?.format("YYYY-MM-DD") ==
            dateString?.format("YYYY-MM-DD") && groups?.includes(val?.group)
        );
      });

      filtered = _.sortBy(filtered, (v) => {
        return moment(v?.startsAt)?.toDate();
      });

      if (countOnly) {
        return filtered.length;
      }

      return filtered;
    },
    [data, loading, groups]
  );

  return (
    <div>
      <Calendar
        className="pln-calendar-month mb-4"
        bordered
        renderCell={renderCell}
        onChange={(date) => {
          let startDate = moment(date)
            .startOf("month")
            .startOf("day")

            .toDate();
          let endDate = moment(date).endOf("month").endOf("day").toDate();

          //console.log("changed", startDate, endDate);

          if (dateRange?.startDate && dateRange?.endDate) {
            if (date < dateRange.startDate || date > dateRange.endDate) {
              onChange({ startDate, endDate });
            }
          }
        }}
      />

      <Drawer
        size="sm"
        style={{ maxWidth: "100%" }}
        open={viewDay}
        onClose={() => setViewDay(false)}
      >
        <Drawer.Header className="pr-4">
          <h3 className="mb-2" style={{ position: "relative", top: "7px" }}>
            {moment(selectedDate).format("MMM D, YYYY")}
          </h3>
        </Drawer.Header>
        <Drawer.Body className="p-3">
          {dayEvents.map((item, index) => (
            <div className="mb-4" key={index}>
              {item?.group == "Appointments" ? (
                <AppointmentCard
                  onClick={() => {
                    //console.log(item);

                    setDetailContent(item);
                    setDetailOpen("consult");
                  }}
                  appointment={item}
                  nameField={"SHORT_NAME"}
                ></AppointmentCard>
              ) : null}
              {item?.group == "Community Events" ? (
                <OutreachCard
                  onClick={() => {
                    setDetailContent(item);
                    setDetailOpen("outreach");
                  }}
                  appointment={item}
                  nameField={"SHORT_NAME"}
                ></OutreachCard>
              ) : null}
              {item?.group == "Marketing & Social" ? (
                <ContentCalendarCard
                  onClick={() => {
                    setDetailContent(item);
                    setDetailOpen("content");
                  }}
                  content={item}
                ></ContentCalendarCard>
              ) : null}
            </div>
          ))}
        </Drawer.Body>
      </Drawer>
      <ConsultationDetailDrawer
        consultation={detailContent}
        open={detailOpen == "consult"}
        onClose={() => {
          setDetailContent(null);
          setDetailOpen(false);
        }}
        store={store}
      ></ConsultationDetailDrawer>
      <ContentCalendarDetailDrawer
        content={detailContent}
        open={detailOpen == "content"}
        onClose={() => {
          setDetailContent(null);
          setDetailOpen(false);
        }}
      ></ContentCalendarDetailDrawer>
      <OutreachDetailDrawer
        event={detailContent}
        open={detailOpen == "outreach"}
        onClose={() => {
          setDetailContent(null);
          setDetailOpen(false);
        }}
      ></OutreachDetailDrawer>
    </div>
  );
};

export default CalendarMonthView;
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */
