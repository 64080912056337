import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { DatePicker, SelectPicker, Modal, Drawer } from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";

class EditBoxDrawer extends React.Component {
  state = {
    selectedMeals: [],
    loadingMeals: true,
    modifySelectedMeals: [],
    modifySelectedQty: 0,
    allMeals: [],
    filteredAllMeals: [],
  };

  handleSearchChange(e) {
    const { name, value } = e.target;

    let filteredAll = [];

    if (!value) {
      filteredAll = this.state.allMeals;
    } else {
      filteredAll = _.filter(this.state.allMeals, (m) => {
        return m.name.toLowerCase().indexOf(value.toLowerCase()) >= 0;
      });
    }

    this.setState({
      [name]: value,
      filteredAllMeals: filteredAll,
    });
  }

  toggleModal() {
    this.props.onClose();
  }

  incrementMeal(meal, quantity) {
    let i = _.findIndex(this.state.modifySelectedMeals, { _id: meal._id });

    if (i < 0) {
      let m = _.clone(meal);
      let cats = [];

      for (let i = 0; i < m.categories.length; i++) {
        let c = _.clone(m.categories[i]);

        c.meals = [];

        cats.push(c);
      }

      m.quantity = 1;
      m.categories = cats;

      let meals = this.state.modifySelectedMeals;

      meals.push(m);

      meals = _.sortBy(meals, "name");

      this.setState({ modifySelectedMeals: meals }, () => {
        this.updateModifyQty();
      });

      return;
    }

    let meals = this.state.modifySelectedMeals;

    meals[i].quantity = meals[i].quantity + quantity;

    if (meals[i].quantity <= 0) {
      meals = _.filter(meals, (m) => {
        return m._id != meal._id;
      });
    }

    meals = _.sortBy(meals, "name");

    this.setState({ modifySelectedMeals: meals }, () => {
      this.updateModifyQty();
    });
  }

  updateModifyQty() {
    let qty = 0;

    for (let i = 0; i < this.state.modifySelectedMeals.length; i++) {
      qty += this.state.modifySelectedMeals[i].quantity;
    }

    this.setState({ modifySelectedQty: qty });
  }

  openModal() {
    let selected = this.state.selectedMeals.map((m) => {
      return _.clone(m);
    });

    selected = _.filter(selected, (m) => {
      return _.findWhere(this.state.allMeals, { _id: m._id });
    });

    selected = _.sortBy(selected, "name");

    this.setState(
      {
        modifySelectedMeals: selected,
      },
      () => {
        this.updateModifyQty();
      }
    );
  }

  clearAll() {
    this.setState({
      modifySelectedMeals: [],
      modifySelectedQty: 0,
    });
  }

  getSelectedQuantity(meal) {
    if (!_.findWhere(this.state.modifySelectedMeals, { _id: meal?._id })) {
      return 0;
    }

    let m = _.findWhere(this.state.modifySelectedMeals, {
      _id: meal?._id,
    });

    return m.quantity ? m.quantity : 0;
  }

  loadMeals(customer, bom) {
    if (!customer) {
      this.setState({ loadingMeals: false });

      return;
    }

    this.setState({ loadingMeals: true });

    APIV2.getStoreByID(this.props.match?.params?.storeID).then(
      (data) => {
        let selectedMeals = [];
        let selectedQuantity = 0;

        let meals =
          data?.data?.store?.menus?.meals[
            customer?.subscription?.plan?.planTypeID
          ]?.active?.meals;

        for (let i = 0; i < bom.length; i++) {
          let meal = _.findWhere(meals, {
            _id: bom[i].mealID,
          });

          if (meal) {
            meal.quantity = bom[i].quantity;

            if (
              meal?.retired &&
              meal?.remainingQuantity &&
              meal?.remainingQuantity < meal.quantity
            ) {
              meal.quantity = meal.remainingQuantity;
            }

            selectedQuantity = selectedQuantity + meal.quantity;

            selectedMeals.push(meal);
          }
        }

        this.setState({ loadingMeals: false, selectedMeals, selectedQuantity });
      },
      (e) => {
        this.setState({ loadingMeals: false });
      }
    );

    APIV2.getStoreByID(customer.storeID).then(
      (data) => {
        let store = data.data.store;

        if (store?.menus?.meals?.hasOwnProperty(this.props.planTypeID)) {
          let menu = store?.menus?.meals[this.props.planTypeID]?.active;

          let meals = _.sortBy(menu?.meals, "name");

          if (meals) {
            this.setState({ allMeals: meals, filteredAllMeals: meals });
          }
        }
      },
      (e) => {
        console.error(e);
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.customer !== this.props.customer ||
      prevProps.bom != this.props.bom
    ) {
      this.loadMeals(this.props.customer, this.props.bom);
    }

    if (prevProps.open != this.props.open && this.props.open) {
      this.openModal();
    }
  }

  componentDidMount() {
    if (this.props.customer && this.props.bom) {
      this.loadMeals(this.props.customer, this.props.bom);
    }

    if (this.props.open) {
      this.openModal();
    }
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  submit() {
    if (this.state.modifySelectedQty != this.props.quantity) {
      return;
    }

    let bom = this.state.modifySelectedMeals.map((m) => {
      return {
        mealID: m._id,
        quantity: m.quantity,
      };
    });

    this.setState({
      submitting: true,
    });

    this.props.onSubmit(bom).then(() => {
      this.setState({ submitting: false });
    });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Edit Box
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                color="primary"
                disabled={
                  this.state.submitting ||
                  this.state.modifySelectedQty != this.props.quantity
                }
                onClick={() => this.submit()}
              >
                {this.state.submitting ? (
                  <Spinner color="white" size="sm" />
                ) : (
                  "Save"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>

          <Drawer.Body className="p-4">
            <div
              className={`px-3 py-2 border mb-3 ${
                this.state.expandCollapse ? "shadow" : ""
              }`}
            >
              <div>
                <Row className="align-items-center">
                  <Col
                    onClick={() => {
                      this.setState({
                        expandCollapse: !this.state.expandCollapse,
                      });
                    }}
                  >
                    <h3 className="m-0 text-uppercase cursor-pointer">
                      <i
                        className={`${
                          this.state.expandCollapse
                            ? "mdi-chevron-down"
                            : "mdi-chevron-right"
                        } mdi pr-3`}
                      />
                      In Box
                      {this.state.modifySelectedQty ? (
                        <Badge
                          color={
                            this.state.modifySelectedQty == this.props.quantity
                              ? "success"
                              : ""
                          }
                          className={`ml-2 ${
                            this.state.modifySelectedQty != this.props.quantity
                              ? "bg-yellow text-dark"
                              : ""
                          }`}
                          style={{ position: "relative", top: "-1px" }}
                        >
                          {this.state.modifySelectedQty !=
                          this.props.quantity ? (
                            <>
                              <i
                                className="mdi mdi-checkbox-blank-circle mr-1"
                                style={{ position: "top", top: "1px" }}
                              />
                            </>
                          ) : null}
                          {this.state.modifySelectedQty} Selected
                        </Badge>
                      ) : (
                        <Badge
                          color=""
                          className={`ml-2 bg-yellow text-dark`}
                          style={{ position: "relative", top: "-1px" }}
                        >
                          <i
                            className="mdi mdi-checkbox-blank-circle mr-1"
                            style={{ position: "top", top: "1px" }}
                          />
                          Box Empty
                        </Badge>
                      )}
                    </h3>
                  </Col>
                  <Col xs="auto">
                    <Button
                      size="sm"
                      color=""
                      className="btn-link text-uppercase pr-0"
                      onClick={() => {
                        this.clearAll();
                      }}
                    >
                      Clear All
                    </Button>
                  </Col>
                </Row>
              </div>
              <Collapse isOpen={this.state.expandCollapse}>
                <Row className="align-items-center">
                  {this.state.modifySelectedMeals.map((meal, i) => (
                    <Col key={i} xs="12" md="12">
                      <SelectedMealCheckoutRow
                        meal={meal}
                        selected={meal.quantity}
                        modifiable={true}
                        disableAdd={
                          this.state.modifySelectedQty >= this.props.quantity
                        }
                        incrementMeal={this.incrementMeal.bind(this)}
                      ></SelectedMealCheckoutRow>
                      {i != this.state.modifySelectedMeals.length - 1 ? (
                        <hr className={`my-1`}></hr>
                      ) : null}
                    </Col>
                  ))}
                </Row>
              </Collapse>
            </div>
            <div>
              <Row className="align-items-center">
                <Col xs="12" sm="" className="mb-1 mb-sm-0">
                  <h3 className="m-0 text-uppercase cursor-pointer">Menu</h3>
                </Col>
                <Col xs="12" sm="">
                  <Input
                    bsSize="sm"
                    type="text"
                    name="search"
                    onChange={this.handleSearchChange.bind(this)}
                    value={this.state.search}
                    placeholder="Search Meals"
                  ></Input>
                  {this.state.search ? (
                    <div
                      style={{
                        position: "absolute",
                        right: "22px",
                        top: "3px",
                      }}
                      className="cursor-pointer"
                      onClick={() => {
                        this.setState({
                          search: "",
                          filteredAllMeals: this.state.allMeals,
                        });
                      }}
                    >
                      <i className="mdi mdi-close-circle" />
                    </div>
                  ) : null}
                </Col>
              </Row>
              <hr className="my-2" />
              <Row className="align-items-center">
                {this.state.filteredAllMeals.map((meal, i) => (
                  <Col key={i} xs="12" md="12">
                    <SelectedMealCheckoutRow
                      meal={meal}
                      selected={this.getSelectedQuantity(meal)}
                      modifiable={true}
                      disableAdd={
                        this.state.modifySelectedQty >= this.props.quantity
                      }
                      incrementMeal={this.incrementMeal.bind(this)}
                    ></SelectedMealCheckoutRow>
                    {i != this.state.filteredAllMeals.length - 1 ? (
                      <hr className={`my-1`}></hr>
                    ) : null}
                  </Col>
                ))}
              </Row>
              {!this.state.filteredAllMeals.length && !this.state.search ? (
                <>
                  <Card>
                    <CardBody>
                      <h3 className="mb-1">Hang tight...</h3>
                      <p className="m-0">We're loading the menu.</p>
                    </CardBody>
                  </Card>
                </>
              ) : null}
              {!this.state.filteredAllMeals.length && this.state.search ? (
                <>
                  <Card>
                    <CardBody>
                      <h3 className="mb-1">No Results</h3>
                      <p className="m-0">
                        We weren't able to find any meals matching your search.
                      </p>
                    </CardBody>
                  </Card>
                </>
              ) : null}
            </div>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(EditBoxDrawer);
