import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeaderV2 from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import CreateStoreDrawer from "components/Drawers/CreateStoreDrawer";
import _ from "underscore";
import moment from "moment";

class StoreList extends React.Component {
  state = {
    stores: [],
    allStores: [],
    options: [
      { name: "All Open", id: "All Open", _id: "All Open" },

      { name: "NSO: Live", id: "Live", _id: "Live" },
      { name: "NSO: Prelaunch", id: "Prelaunch", _id: "Prelaunch" },
      { name: "NSO: Buildout", id: "Buildout", _id: "Buildout" },
      {
        name: "NSO: Site Selection",
        id: "Site Selection",
        _id: "Site Selection",
      },
      {
        name: "Performance: Impact",
        id: "Performance: Impact",
        _id: "Performance: Impact",
      },
      {
        name: "Performance: Growth",
        id: "Performance: Growth",
        _id: "Performance: Growth",
      },

      {
        name: "Performance: Critical",
        id: "Performance: Critical",
        _id: "Performance: Critical",
      },
      {
        name: "Performance: Post-Open",
        id: "Performance: Post-Open",
        _id: "Performance: Post-Open",
      },
      {
        name: "Performance: Pre-Open",
        id: "Performance: Pre-Open",
        _id: "Performance: Pre-Open",
      },
      {
        name: "Pricing: Tier 1",
        id: "Pricing: Tier 1",
        _id: "Pricing: Tier 1",
      },
      {
        name: "Pricing: Tier 2",
        id: "Pricing: Tier 2",
        _id: "Pricing: Tier 2",
      },
      {
        name: "Pricing: Tier 3",
        id: "Pricing: Tier 3",
        _id: "Pricing: Tier 3",
      },

      {
        name: "Logistics: Weekly Product Orders",
        id: "Logistics: Weekly Product Orders",
        _id: "Logistics: Weekly Product Orders",
      },
      {
        name: "Logistics: Bi-Weekly Product Orders",
        id: "Logistics: Bi-Weekly Product Orders",
        _id: "Logistics: Bi-Weekly Product Orders",
      },
      { name: "All Closed", id: "All Closed", _id: "All Closed" },
    ],
    active: {
      name: "All Open",
      id: "All Open",
    },
    loading: true,
  };

  handleTabChange(option, force = false) {
    if (!option || (option?.id == this.state.active?.id && !force)) {
      return;
    }

    this.setState({
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    if (
      option?.id != "All Open" &&
      option?.id != "All Closed" &&
      !option?.id.includes("Performance:") &&
      !option?.id.includes("Pricing:") &&
      !option.id.includes("Logistics:")
    ) {
      this.setState({
        stores: _.filter(this.state.allStores, (st) => {
          return st.nsoStage == option.id && st?.closed != true;
        }),
      });
    } else if (option?.id.includes("Performance:")) {
      let level = option?.id?.replace("Performance: ", "")?.trim();

      this.setState({
        stores: _.filter(this.state.allStores, (st) => {
          return st?.supportLevel?.includes(level) && st?.closed != true;
        }),
      });
    } else if (option?.id.includes("Pricing")) {
      let level = option?.id?.replace("Pricing: ", "")?.trim();

      this.setState({
        stores: _.filter(this.state.allStores, (st) => {
          console.log(st?.pricingTier, level);

          return st?.pricingTier == level && st?.closed != true;
        }),
      });
    } else if (option?.id?.includes("Logistics:")) {
      this.setState({
        stores: _.filter(this.state.allStores, (st) => {
          if (option.id?.includes("Bi-Weekly")) {
            return (
              st?.productOrderStrategy?.includes("Bi-Weekly") &&
              st?.closed != true
            );
          } else {
            return (
              (!st?.productOrderStrategy ||
                !st?.productOrderStrategy?.includes("Bi-Weekly")) &&
              st?.closed != true
            );
          }
        }),
      });
    } else if (option?.id == "All Closed") {
      this.setState({
        stores: this.state.closedStores,
      });
    } else {
      this.setState({
        stores: this.state.allStores?.map((st) => st),
      });
    }
  }

  loadStores() {
    APIV2.getNSOTemplate().then((data) => {
      this.setState({
        nsoTemplate: data?.data?.template,
      });
    });

    let projection = {
      name: 1,
      _id: 1,
      supportPerformance: 1,
      pricingPerformance: 1,
      operations: 1,
      nsoStage: 1,
      location: 1,
      successVisitScore: 1,
      lastVisitDate: 1,
      franchisePartners: 1,
      nsoID: 1,
      onSiteTrainingDate: 1,
    };

    APIV2.getStores(null, projection, true)
      .then(
        (data) => {
          if (data && data.data && data.data.stores) {
            let allStores = data.data.stores;

            let closedStores = _.filter(allStores, (st) => {
              return st?.closed == true;
            });

            allStores = _.filter(allStores, (st) => {
              return st?.closed != true;
            });

            allStores.sort((a, b) => {
              const aDate = a?.operations?.storeOpening?.prelaunchStartDate
                ? moment(
                    a?.operations?.storeOpening?.prelaunchStartDate
                  )?.toDate()
                : null;
              const bDate = b?.operations?.storeOpening?.prelaunchStartDate
                ? moment(b?.operations?.storeOpening?.prelaunchStartDate)
                : null;

              if (aDate === null) {
                return 1;
              }

              if (bDate === null) {
                return -1;
              }

              if (aDate > bDate) {
                return 1;
              }

              if (bDate > aDate) {
                return -1;
              }

              if (aDate == bDate) {
                return 0;
              }
            });

            console.log(allStores);

            this.setState(
              {
                stores: allStores,
                allStores: allStores,
                closedStores,
              },
              () => {
                this.handleTabChange(this.state.active, true);
              }
            );

            let options = this.state.options;

            const allIdx = _.findIndex(options, { id: "All Open" });

            options[allIdx].count = allStores?.length;

            const closedIdx = _.findIndex(options, { id: "All Closed" });

            options[closedIdx].count = closedStores?.length;

            for (let i = 0; i < allStores?.length; i++) {
              const st = allStores[i];

              if (st?.nsoStage) {
                const stageIdx = _.findIndex(options, { id: st.nsoStage });

                if (stageIdx >= 0) {
                  options[stageIdx].count = options[stageIdx].count
                    ? options[stageIdx].count + 1
                    : 1;
                }
              }

              if (st?.supportLevel) {
                const levelIdx = _.findIndex(options, (opt) => {
                  return opt.id.includes(
                    st.supportLevel?.replace("Level", "")?.trim()
                  );
                });

                if (levelIdx >= 0) {
                  options[levelIdx].count = options[levelIdx].count
                    ? options[levelIdx].count + 1
                    : 1;
                }
              }

              if (st?.pricingTier) {
                const levelIdx = _.findIndex(options, (opt) => {
                  return opt.id.includes(st.pricingTier);
                });

                if (levelIdx >= 0) {
                  options[levelIdx].count = options[levelIdx].count
                    ? options[levelIdx].count + 1
                    : 1;
                }
              }

              if (st?.productOrderStrategy) {
                const levelIdx = _.findIndex(options, (opt) => {
                  if (st?.productOrderStrategy?.includes("Bi-Weekly")) {
                    return (
                      opt?.id?.includes("Logistics:") &&
                      opt?.id?.includes("Bi-Weekly")
                    );
                  } else {
                    return (
                      opt?.id?.includes("Logistics:") &&
                      !opt?.id?.includes("Bi-Weekly")
                    );
                  }
                });

                if (levelIdx >= 0) {
                  options[levelIdx].count = options[levelIdx].count
                    ? options[levelIdx].count + 1
                    : 1;
                }
              } else {
                const levelIdx = _.findIndex(options, (opt) => {
                  return (
                    opt?.id?.includes("Logistics:") &&
                    !opt?.id?.includes("Bi-Weekly")
                  );
                });

                if (levelIdx >= 0) {
                  options[levelIdx].count = options[levelIdx].count
                    ? options[levelIdx].count + 1
                    : 1;
                }
              }
            }

            this.setState({
              options,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.setState(
        {
          active: {
            id: decodeURIComponent(tab),
            name: decodeURIComponent(tab),
          },
        },
        () => {
          this.loadStores();
        }
      );
    } else {
      this.loadStores();
    }

    PubSub.subscribe(Event.STORE.DELETED, (data) => {
      this.loadStores();
    });

    PubSub.subscribe(Event.STORE.CREATED, (s) => {
      this.loadStores();
    });

    PubSub.subscribe(Event.STORE.MODIFIED, (store) => {
      this.loadStores();
    });
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeaderV2
            sticky={true}
            title="Stores"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  createOpen: true,
                });
              },
            }}
            tabs={
              <CardSelectorHeader
                sticky={false}
                value={this.state.active}
                options={this.state.options}
                showCount={true}
                compact={true}
                onChange={(v) => {
                  this.handleTabChange(v);
                }}
              ></CardSelectorHeader>
            }
          />
          {/* Table */}
          <div
            style={{ marginLeft: -15, marginRight: -15 }}
            className="bg-white mt--4 border-bottom"
          >
            <Table
              className="align-items-center table-flush table-hover"
              responsive
            >
              <thead className="thead-light">
                <tr>
                  <th scope="col" style={{ minWidth: "180px" }}>
                    Name
                  </th>
                  <th scope="col">NSO Stage</th>
                  <th scope="col">Location</th>

                  <th scope="col">Prelaunch</th>
                  <th scope="col">Onsite Training</th>

                  <th scope="col">First Bill Date</th>
                  <th scope="col">Pricing Tier</th>
                  <th scope="col">Success Visit Score</th>
                  <th scope="col">Last Visit Date</th>
                  <th
                    style={{ minWidth: 250, textAlign: "center" }}
                    scope="col"
                  >
                    NSO Timeline
                  </th>

                  <th scope="col">Franchise Partners</th>
                </tr>
              </thead>
              <tbody>
                {this.state.loading ? (
                  <tr>
                    <td colSpan="2">Loading...</td>
                  </tr>
                ) : null}
                {!this.state.loading && !this.state.stores.length ? (
                  <tr>
                    <td colSpan="2">No stores.</td>
                  </tr>
                ) : null}
                {this.state.stores.map((s, i) => (
                  <StoreRow
                    nsoTemplate={this.state.nsoTemplate}
                    key={i}
                    store={s}
                  ></StoreRow>
                ))}
              </tbody>
            </Table>
          </div>
        </Container>
        <CreateStoreDrawer
          open={this.state.createOpen}
          onClose={() => {
            this.setState({
              createOpen: false,
            });
          }}
        ></CreateStoreDrawer>
      </>
    );
  }
}

export default StoreList;
