import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  Card,
  DeltaBar,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
  Col as TCol,
  ColGrid,
  DonutChart,
  Bold,
  List,
  ListItem,
  BarList,
  BarChart,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../lib/DataAPI";
import StringUtils from "../../lib/StringUtils";
import _ from "underscore";
import ChartMetricHeader from "./Scorecard/ChartMetricHeader";
import ScorecardNewMemberDetailDrawer from "./Scorecard/ScorecardNewMemberDetailDrawer";
import { fi } from "date-fns/locale";

class FranLeadActivityCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    active: "A",
    typeList: [],
    nameList: [],
    utmSourceList: [],
    utmCampaignList: [],
    activities: [
      "Call",
      "Email",
      "Text / Direct Message",
      "Focus Call",
      "Discovery Call",
      "Meeting",
    ],
    activityColors: ["orange", "green", "blue", "yellow", "red", "purple"],
  };

  /**
   * Fetches a summary of all the member data
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchData(cubejsApi, dateRange, activities) {
    let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let granularity = secondDifference > 60 * 60 * 24 * 90 ? "week" : "day";

    if (secondDifference >= 60 * 60 * 24 * 364) {
      granularity = "month";
    }

    let start = moment(dateRange[0]).startOf("day").toDate();
    let end = moment(dateRange[1]).endOf("day").toDate();

    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["Tasks.activitycount"],
          order: {
            "Tasks.activitycount": "desc",
          },
          dimensions: ["Tasks.activity"],
          timeDimensions: [
            {
              dimension: "Tasks.completedat",
              granularity,
              dateRange: [start, end],
            },
          ],
          filters: [
            {
              member: "Tasks.frandevleadid",
              operator: "set",
            },
            {
              member: "Tasks.activity",
              operator: "equals",
              values: activities,
            },
            {
              member: "Tasks.deleted",
              operator: "notEquals",
              values: ["true"],
            },
          ],
          limit: 5000,
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          let total = 0;

          if (!data?.length) {
            return resolve(null);
          }

          data = data.map((item) => {
            total += item["Tasks.activitycount"];

            let mom = moment(item[`Tasks.completedat.${granularity}`]);

            let granularityString = mom.format("MM/DD/YYYY");

            if (granularity == "week") {
              mom.day(0);
              granularityString = "Week " + mom.format("MM/DD/YYYY");
            }

            if (granularity == "month") {
              mom.startOf("month");

              granularityString = mom.format("MMMM YY");
            }

            return {
              count: item["Tasks.activitycount"],
              dateObj: mom.toDate(),
              date: granularityString,
              activity: item["Tasks.activity"],
            };
          });

          let out = [];

          for (let i = 0; i < data.length; i++) {
            const idx = _.findIndex(out, (item) => {
              return item.date == data[i].date;
            });

            if (idx == -1) {
              let item = {
                date: data[i].date,
                dateObj: data[i].dateObj,
                [data[i].activity]: data[i].count,
              };

              out.push(item);
            } else {
              out[idx][data[i].activity] = data[i].count;
            }
          }

          return resolve({
            total,
            history: out,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async _fetchReport(dateRange) {
    this.setState({
      loading: true,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchData(
        cubejsApi,
        dateRange,
        this.state.activities
      );
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load new member quantity.",
        loading: false,
      });

      return;
    }

    console.log(currentCount);

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: true,
      });

      currentCount = {
        count: 0,
        total: 0,
      };
    }

    if (this.props.comparePrevious) {
      let previousCount = null;

      let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

      secondDifference = secondDifference / 1000;

      let granularity = secondDifference > 60 * 60 * 24 * 90 ? "week" : "day";

      if (secondDifference >= 60 * 60 * 24 * 364) {
        granularity = "month";
      }

      let startMoment = moment(dateRange[0].toISOString());
      let endMoment = moment(dateRange[1].toISOString());

      startMoment.subtract(secondDifference, "seconds");
      endMoment.subtract(secondDifference + 1, "seconds");

      try {
        previousCount = await this._fetchData(
          cubejsApi,
          [startMoment.toDate(), endMoment.toDate()],
          this.state.activities
        );
      } catch (e) {}

      if (!previousCount) {
        previousCount = {
          total: 0,
        };
      }

      let dataHistory = currentCount?.history?.length
        ? currentCount?.history
        : [];

      startMoment = moment(dateRange[0]);
      endMoment = moment(dateRange[1]);

      let tempDate = moment(dateRange[0])
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      if (granularity == "week") {
        tempDate.day(0);
      }

      if (granularity == "month") {
        tempDate.startOf("month");
      }

      for (let i = 0; i < dataHistory?.length; i++) {
        for (let j = 0; j < this.state.activities.length; j++) {
          if (!dataHistory[i][this.state.activities[j]]) {
            dataHistory[i][this.state.activities[j]] = 0;
          }
        }
      }

      while (tempDate.toDate() <= endMoment.toDate()) {
        let granularityString = tempDate.format("MM/DD/YYYY");

        if (granularity == "week") {
          tempDate.day(0);
          granularityString = "Week " + granularityString;
        }

        if (granularity == "month") {
          tempDate.startOf("month");
          granularityString = tempDate.format("MMMM YY");
        }

        if (!_.findWhere(dataHistory, { date: granularityString })) {
          let filler = {
            dateObj: tempDate.toDate(),
            date: granularityString,
          };

          for (let i = 0; i < this.state.activities.length; i++) {
            filler[this.state.activities[i]] = 0;
          }

          dataHistory.push(filler);
        }

        if (granularity == "day") {
          tempDate.add(1, "day");
        } else if (granularity == "week") {
          tempDate.add(1, "week");
        } else if (granularity == "month") {
          tempDate.add(1, "month");
        }
      }

      if (previousCount !== null) {
        let percentChange =
          previousCount?.total > 0
            ? (currentCount?.total - previousCount?.total) /
              previousCount?.total
            : null;

        let declineMode = "";
        let isNegative = false;

        if (percentChange > 0) {
          if (Math.abs(percentChange) < 0.015) {
            declineMode = "unchanged";
          } else if (Math.abs(percentChange) < 0.1) {
            declineMode = "moderateIncrease";
          } else if (Math.abs(percentChange) >= 0.1) {
            declineMode = "increase";
          }
        } else {
          isNegative = true;

          if (Math.abs(percentChange) < 0.015) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) < 0.1) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) >= 0.1) {
            declineMode = "decrease";
          }
        }

        dataHistory = _.sortBy(dataHistory, "dateObj");

        this.setState({
          previousCount,
          changeIsNegative: isNegative,
          deltaType: declineMode,
          percentChange,
          percentChangeString: Math.abs(percentChange * 100).toFixed(1) + "%",

          dataHistory,
        });
      }
    }

    this.setState({
      currentCount,
      loading: false,
    });
  }

  /**
   * Load all report information
   *
   * @param {*} stores
   * @param {*} dateRange
   * @param {*} compare
   */
  async loadReport(dateRange, compare) {
    this.setState({
      loading: true,
    });

    this._fetchReport(dateRange);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.dateRange != prevProps?.dateRange ||
      this.props.comparePrevious != prevProps.comparePrevious
    ) {
      this.loadReport(this.props.dateRange, this.props.comparePrevious);
    }
  }

  componentDidMount() {
    if (this.props.dateRange) {
      this.loadReport(this.props.dateRange, this.props.comparePrevious);
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-4">
          <ChartMetricHeader
            forceWrapComparison={true}
            title="Outreach Activities"
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={`${StringUtils.numberFormat(
              this.state.currentCount?.total
            )} Activities`}
            comparisonMetric={
              this.state.previousCount !== null
                ? `${StringUtils.numberFormat(
                    this.state.previousCount?.total
                  )} Activities`
                : null
            }
            dateRange={this.props.dateRange}
            deltaType={this.state.deltaType}
            percentChange={this.state.percentChangeString}
            showPercentChange={true}
          ></ChartMetricHeader>

          {this.state.loading ? (
            <>
              <div
                className="skeleton rounded"
                style={{ width: "100%", height: 320, marginTop: "1.5rem" }}
              >
                &nbsp;
              </div>
            </>
          ) : (
            <BarChart
              data={this.state.dataHistory}
              dataKey="date"
              categories={this.state.activities}
              colors={this.state.activityColors}
              stack={true}
              valueFormatter={(number) => {
                return StringUtils.numberFormat(number);
              }}
              marginTop="mt-6"
              yAxisWidth="w-14"
            />
          )}
        </Card>
        <ScorecardNewMemberDetailDrawer
          open={this.state.open}
          onClose={() => {
            this.setState({
              open: false,
            });
          }}
          stores={this.props.stores}
          dateRange={this.props.dateRange}
        />
      </>
    );
  }
}

export default FranLeadActivityCard;
