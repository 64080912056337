import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _, { times } from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Table from "reactstrap/lib/Table";

class CustomerCreateOrderDrawer extends React.Component {
  state = {
    dueOn: moment().toDate(),
    lineItems: [
      {
        id: StringUtils.uuid(),
        type: "line",
        name: "",
        quantity: null,
        unitPrice: null,
      },
    ],
  };

  dropdownRef = React.createRef();

  componentDidUpdate(prevProps) {}

  componentDidMount() {
    this.loadCoupons();
  }

  toggleModal() {
    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  create() {
    this.setState({ submitting: true });

    let lineItems = this.state.lineItems?.map((item) => {
      return {
        name: item?.name,
        unitPrice: item?.unitPrice?.floatValue * 100,
        quantity: item?.quantity?.floatValue,
        totalPrice:
          item?.unitPrice?.floatValue * 100 * item?.quantity?.floatValue,
      };
    });

    if (this.state.discountLine) {
      lineItems.push({
        name: "Discount Applied - " + this.state.discountObject?.code,
        unitPrice: this.state.discountLine?.unitPrice?.floatValue * 100,
        quantity: this.state.discountLine?.quantity?.floatValue,
        totalPrice:
          this.state.discountLine?.unitPrice?.floatValue *
          100 *
          this.state.discountLine?.quantity?.floatValue,
      });
    }

    let tax = 0;

    if (!isNaN(this.state.tax?.floatValue)) {
      tax = this.state.tax?.floatValue * 100;
    }

    APIV2.createCustomerInvoice(
      this.props?.customer?.storeID,
      this.props?.customer?._id,
      {
        customer: this.props.customer,
        lineItems: lineItems,
        discount: this.state.discountObject ? this.state.discountObject : null,
        subtotalPrice: this.state.subTotalCents,
        totalPrice: this.state.totalCents,
        tax: tax,
        dueOnString: moment(this.state.dueOn?.toISOString()).format(
          "YYYY-MM-DD"
        ),
        name: this.state.invoiceName,
        dryRun: false,
        currency: "USD",
      }
    )
      .then(
        (data) => {
          const invoice = data.data.invoice;

          this.toggleModal();

          PubSub.publish(Event.INVOICE.CREATED, invoice);

          this.setState({
            dueOn: moment().toDate(),
            lineItems: [
              {
                id: StringUtils.uuid(),
                type: "line",
                name: "",
                quantity: null,
                unitPrice: null,
              },
            ],
            invoiceName: "",
          });
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create invoice - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  getDiscountValue(c) {
    let discountValue = "";

    if (c.discountType == "Flat") {
      discountValue = StringUtils.centsToCurrency(c.flatDiscount);
    }

    if (c.discountType == "Percent") {
      discountValue = c.percentDiscount * 100 + "%";
    }

    return discountValue;
  }

  loadCoupons() {
    APIV2.getCoupons().then(
      (data) => {
        let coupData = data.data?.coupons?.map((c) => {
          let discountValue = this.getDiscountValue(c);

          let label = (
            <>
              <p className="m-0 font-weight-bold">{c.name}</p>
              <p className="m-0" style={{ lineHeight: 1.3 }}>
                <small>
                  {discountValue} OFF (
                  {c.removeFromSubscriptionAfterUses == 1
                    ? "One-Time"
                    : "Recurring"}
                  )
                </small>
              </p>
            </>
          );

          return {
            label: label,
            value: c._id,
            coupon: c,
            usage:
              c.removeFromSubscriptionAfterUses == 1
                ? "One-Time Use"
                : "Recurring",
          };
        });

        this.setState({ coupons: coupData });
      },
      (e) => {}
    );
  }

  calcItemPrice(item) {
    if (
      isNaN(item?.unitPrice?.floatValue) ||
      isNaN(item?.quantity?.floatValue) ||
      item?.quantity?.floatValue < 0
    ) {
      return "$0.00";
    }

    let cents =
      item?.quantity?.floatValue * (item?.unitPrice?.floatValue * 100);

    return StringUtils.centsToCurrency(cents);
  }

  handleUnitPriceChange(item, v) {
    let items = this.state.lineItems;

    let idx = _.findIndex(items, { id: item?.id });

    if (idx >= 0) {
      items[idx].unitPrice = v;
    }

    this.setState(
      {
        lineItems: items,
      },
      () => {
        if (this.state?.discountObject) {
          this.handleDiscountChange(this?.state?.discountObject?._id);
        }

        this.calculateTotals();
      }
    );
  }

  handleItemNameChange(item, e) {
    let items = this.state.lineItems;

    let idx = _.findIndex(items, { id: item?.id });

    if (idx >= 0) {
      items[idx].name = e?.target?.value;
    }

    this.setState({
      lineItems: items,
    });
  }

  handleItemQuantityChange(item, e) {
    let items = this.state.lineItems;

    let idx = _.findIndex(items, { id: item?.id });

    if (idx >= 0) {
      items[idx].quantity = e;
    }

    this.setState(
      {
        lineItems: items,
      },
      () => {
        if (this.state?.discountObject) {
          this.handleDiscountChange(this?.state?.discountObject?._id);
        }

        this.calculateTotals();
      }
    );
  }

  isValidInvoice() {
    if (!this.state.lineItems?.length) {
      return false;
    }

    for (let i = 0; i < this.state.lineItems?.length; i++) {
      const item = this.state.lineItems[i];

      if (
        isNaN(item?.unitPrice?.floatValue) ||
        isNaN(item?.quantity?.floatValue) ||
        item?.quantity?.floatValue < 1
      ) {
        return false;
      }

      if (!item?.name) {
        return false;
      }
    }

    if (this.state.discountLine) {
      if (
        isNaN(this.state.discountLine?.unitPrice?.floatValue) ||
        isNaN(this.state.discountLine?.quantity?.floatValue) ||
        this.state.discountLine?.quantity?.floatValue < 1
      ) {
        return false;
      }
    }

    if (!isNaN(this.state.tax?.floatValue) && this.state.tax?.floatValue < 0) {
      return false;
    }

    if (
      this.state.totalCents < 0 ||
      !this.state.dueOn ||
      !this.state.invoiceName
    ) {
      return false;
    }

    return true;
  }

  calculateTotals() {
    let totalCents = 0;
    let subTotalCents = 0;

    if (!this.state.lineItems.length) {
      this.setState({
        totalCents: 0,
        subTotalCents: 0,
      });

      return;
    }

    for (let i = 0; i < this.state.lineItems.length; i++) {
      const item = this.state.lineItems[i];

      if (
        isNaN(item?.unitPrice?.floatValue) ||
        isNaN(item?.quantity?.floatValue) ||
        item?.quantity?.floatValue < 0
      ) {
        continue;
      }

      subTotalCents +=
        item?.quantity?.floatValue * (item?.unitPrice?.floatValue * 100);
    }

    if (
      this.state.discountLine &&
      !isNaN(this.state.discountLine?.unitPrice?.floatValue) &&
      !isNaN(this.state.discountLine?.quantity?.floatValue)
    ) {
      subTotalCents +=
        this.state.discountLine?.unitPrice?.floatValue *
        100 *
        this.state.discountLine?.quantity?.floatValue;
    }

    totalCents = subTotalCents;

    if (!isNaN(this.state.tax?.floatValue)) {
      totalCents += this.state.tax?.floatValue * 100;
    }

    this.setState({
      totalCents: totalCents,
      subTotalCents: subTotalCents,
    });
  }

  containsCoupon() {
    return this.state.discountLine ? true : false;
  }

  handleDiscountChange(value) {
    let coupon = _.findWhere(this.state.coupons, { value });
    let discountLine = this.state.discountLine;

    if (!value || !coupon) {
      discountLine.unitPrice = null;
      discountLine.quantity = {
        floatValue: 1,
      };

      return this.setState(
        {
          discountLine,
        },
        () => {
          this.calculateTotals();
        }
      );
    }

    let subTotalCents = 0;

    for (let i = 0; i < this.state.lineItems.length; i++) {
      const item = this.state.lineItems[i];

      if (
        isNaN(item?.unitPrice?.floatValue) ||
        isNaN(item?.quantity?.floatValue) ||
        item?.quantity?.floatValue < 0
      ) {
        continue;
      }

      subTotalCents +=
        item?.quantity?.floatValue * (item?.unitPrice?.floatValue * 100);
    }

    coupon = coupon?.coupon;

    let valueCents = 0;

    if (coupon?.discountType == "Percent") {
      valueCents = Math.floor(subTotalCents * coupon?.percentDiscount);
    } else if (coupon?.discountType == "Flat") {
      if (coupon?.flatDiscount >= subTotalCents) {
        valueCents = subTotalCents;
      } else {
        valueCents = coupon?.flatDiscount;
      }
    }

    discountLine.unitPrice = {
      floatValue: -1 * (valueCents / 100),
    };
    discountLine.quantity = {
      floatValue: 1,
    };

    return this.setState(
      {
        discountLine,
      },
      () => {
        this.calculateTotals();
      }
    );
  }

  render() {
    return (
      <>
        <Drawer
          size="md"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              New Invoice
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={this.state.submitting || !this.isValidInvoice()}
                color="primary"
                onClick={this.create.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  "Create"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            <FormGroup>
              <h4>Invoice Name</h4>
              <Input
                type="text"
                value={this.state.invoiceName}
                name="invoiceName"
                placeholder="Name"
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>

            <Table className="table-sm table-bordered">
              <tbody>
                <tr>
                  <th className="p-2 align-middle">
                    <h4 className="m-0">Due On:</h4>
                  </th>
                  <td className="p-2">
                    <DatePicker
                      oneTap
                      required
                      block
                      placeholder="Select A Date"
                      defaultValue={moment().toDate()}
                      value={this.state.dueOn}
                      onChange={(e) => {
                        this.setState({ dueOn: e });
                      }}
                      disabledDate={(date) =>
                        date > new Date() &&
                        date.getDay() != 6 &&
                        moment(date).format("YYYY-MM-DD") !=
                          moment().format("YYYY-MM-DD")
                      }
                      ranges={[
                        {
                          label: "Today",
                          value: moment().toDate(),
                          closeOverlay: true,
                        },
                        {
                          label: "This Saturday",
                          value:
                            moment().day() == 6
                              ? moment().toDate()
                              : moment().day(6).toDate(),
                          closeOverlay: true,
                        },
                      ]}
                    ></DatePicker>
                  </td>
                </tr>
                <tr>
                  <th className="p-2">
                    <h4 className="m-0">Bill To:</h4>
                  </th>
                  <td className="p-2">
                    <h4 className="m-0 font-weight-normal">
                      {this.props?.customer?.billingAddress?.name}
                    </h4>
                    <p className="m-0" style={{ lineHeight: 1.3 }}>
                      <small>
                        {this.props?.customer?.billingAddress?.address1}
                      </small>
                    </p>
                    {this.props?.customer?.billingAddress?.address2 ? (
                      <p className="m-0" style={{ lineHeight: 1.3 }}>
                        <small>
                          {this.props?.customer?.billingAddress?.address2}
                        </small>
                      </p>
                    ) : null}
                    <p className="m-0" style={{ lineHeight: 1.3 }}>
                      <small>
                        {this.props?.customer?.billingAddress?.city},{" "}
                        {this.props?.customer?.billingAddress?.state}{" "}
                        {this.props?.customer?.billingAddress?.zip}
                      </small>
                    </p>
                    <p className="m-0" style={{ lineHeight: 1.3 }}>
                      <small>
                        {this.props?.customer?.phone
                          ? StringUtils.formatPhoneNumber(
                              this.props?.customer?.phone
                            )
                          : null}
                      </small>
                    </p>
                  </td>
                </tr>
                <tr>
                  <th className="p-2">
                    <h4 className="m-0">Ship To:</h4>
                  </th>
                  <td className="p-2">
                    <h4 className="m-0 font-weight-normal">
                      {this.props?.customer?.shippingAddress?.name}
                    </h4>
                    <p className="m-0" style={{ lineHeight: 1.3 }}>
                      <small>
                        {this.props?.customer?.shippingAddress?.address1}
                      </small>
                    </p>
                    {this.props?.customer?.shippingAddress?.address2 ? (
                      <p className="m-0" style={{ lineHeight: 1.3 }}>
                        <small>
                          {this.props?.customer?.shippingAddress?.address2}
                        </small>
                      </p>
                    ) : null}
                    <p className="m-0" style={{ lineHeight: 1.3 }}>
                      <small>
                        {this.props?.customer?.shippingAddress?.city},{" "}
                        {this.props?.customer?.shippingAddress?.state}{" "}
                        {this.props?.customer?.shippingAddress?.zip}
                      </small>
                    </p>
                    <p className="m-0" style={{ lineHeight: 1.3 }}>
                      <small>
                        {this.props?.customer?.phone
                          ? StringUtils.formatPhoneNumber(
                              this.props?.customer?.phone
                            )
                          : null}
                      </small>
                    </p>
                  </td>
                </tr>
              </tbody>
            </Table>

            <h4>Items:</h4>
            <Table className="table-sm table-bordered">
              <thead>
                <th className="p-2 bg-superlight text-dark">Item</th>
                <th
                  className="p-2 bg-superlight text-dark"
                  style={{ width: "120px" }}
                >
                  Qty
                </th>
                <th
                  className="p-2 bg-superlight text-dark"
                  style={{ width: "120px" }}
                >
                  Price
                </th>
                <th
                  className="text-right p-2 bg-superlight text-dark"
                  style={{ width: "120px" }}
                >
                  Subtotal
                </th>
                <th
                  className="text-right p-2 bg-superlight text-dark"
                  style={{ width: "50px" }}
                ></th>
              </thead>
              <tbody>
                {this.state.lineItems?.map((item) => (
                  <tr key={item?.id}>
                    <>
                      <td
                        className="p-2"
                        style={{
                          whiteSpace: "pre-wrap",
                          verticalAlign: "middle",
                        }}
                      >
                        <Input
                          bsSize="sm"
                          type="select"
                          placeholder="Item Name"
                          value={item?.name}
                          onChange={(e) => {
                            this.handleItemNameChange(item, e);
                          }}
                        >
                          <option value="" disabled>
                            Select An Option
                          </option>
                          <option value="Paid In Full Subscription">
                            Paid In Full Subscription
                          </option>
                          <option value="Subscription Adjustment">
                            Subscription Adjustment
                          </option>
                          <option value="Restocking Fee">Restocking Fee</option>
                          <option value="Delivery Fee">Delivery Fee</option>
                        </Input>
                      </td>
                      <td className="p-2" style={{ verticalAlign: "middle" }}>
                        <NumberFormat
                          className="form-control form-control-sm"
                          fixedDecimalScale={true}
                          decimalScale={0}
                          required={true}
                          value={item?.quantity?.value}
                          placeholder="QTY"
                          onValueChange={(v) => {
                            this.handleItemQuantityChange(item, v);
                          }}
                          thousandSeparator={true}
                        />
                      </td>
                      <td className="p-2" style={{ verticalAlign: "middle" }}>
                        <NumberFormat
                          className="form-control form-control-sm"
                          fixedDecimalScale={true}
                          decimalScale={2}
                          required={true}
                          value={item?.unitPrice?.value}
                          placeholder="Price"
                          onValueChange={(v) => {
                            this.handleUnitPriceChange(item, v);
                          }}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                      <td
                        className="p-2 text-right"
                        style={{ verticalAlign: "middle" }}
                      >
                        {this.calcItemPrice(item)}
                      </td>
                      <td>
                        <Button
                          size="sm"
                          outline
                          color="danger"
                          className="btn-icon-only"
                          onClick={() => {
                            let items = this.state.lineItems;

                            items = _.filter(items, (i) => {
                              return i.id != item?.id;
                            });

                            this.setState(
                              {
                                lineItems: items,
                              },
                              () => {
                                this.calculateTotals();
                              }
                            );
                          }}
                        >
                          <i className="mdi mdi-close" />
                        </Button>
                      </td>
                    </>
                  </tr>
                ))}
                {this.state.discountLine ? (
                  <>
                    <>
                      <td
                        className="p-2"
                        style={{
                          whiteSpace: "pre-wrap",
                          verticalAlign: "middle",
                        }}
                      >
                        <SelectPicker
                          placement="auto"
                          placeholder="Select Coupon"
                          data={this.state.coupons}
                          value={this.state.discount}
                          groupBy="usage"
                          block
                          onChange={(value, i) => {
                            let coupon = _.findWhere(this.state.coupons, {
                              value,
                            });

                            let out = null;

                            if (coupon?.coupon) {
                              out = coupon?.coupon;
                            }

                            this.setState(
                              { discount: value, discountObject: out },
                              () => {
                                this.handleDiscountChange(value, i);
                              }
                            );
                          }}
                        ></SelectPicker>
                      </td>
                      <td className="p-2" style={{ verticalAlign: "middle" }}>
                        1
                      </td>
                      <td className="p-2" style={{ verticalAlign: "middle" }}>
                        {this.calcItemPrice(this.state.discountLine)}
                      </td>
                      <td
                        className="p-2 text-right"
                        style={{ verticalAlign: "middle" }}
                      >
                        {this.calcItemPrice(this.state.discountLine)}
                      </td>
                      <td className="align-middle">
                        <Button
                          size="sm"
                          outline
                          color="danger"
                          className="btn-icon-only"
                          onClick={() => {
                            this.setState(
                              {
                                discountLine: null,
                              },
                              () => {
                                this.calculateTotals();
                              }
                            );
                          }}
                        >
                          <i className="mdi mdi-close" />
                        </Button>
                      </td>
                    </>
                  </>
                ) : null}
                <tr>
                  <td
                    colSpan={4}
                    className="p-2 text-right"
                    style={{ whiteSpace: "pre-wrap", verticalAlign: "middle" }}
                  ></td>
                  <td
                    className="p-2 text-center"
                    style={{ whiteSpace: "pre-wrap", verticalAlign: "middle" }}
                  >
                    <Whisper
                      ref={this.dropdownRef}
                      trigger="click"
                      placement={"autoVerticalEnd"}
                      speaker={
                        <Popover full>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                let items = this.state.lineItems;

                                items.push({
                                  id: StringUtils.uuid(),
                                  name: "",
                                  type: "line",
                                  quantity: null,
                                  unitPrice: null,
                                });

                                this.setState({
                                  lineItems: items,
                                });

                                this.dropdownRef.current.close();
                              }}
                            >
                              Line Item
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={this.containsCoupon()}
                              onClick={() => {
                                this.setState({
                                  discountLine: {
                                    id: StringUtils.uuid(),
                                    type: "coupon",
                                    name: "",
                                    quantity: null,
                                    unitPrice: null,
                                  },
                                });

                                this.dropdownRef.current.close();
                              }}
                            >
                              Coupon
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Popover>
                      }
                    >
                      <Button
                        size="sm"
                        outline
                        color="secondary"
                        className="btn-icon-only"
                      >
                        <i className="mdi mdi-plus" />
                      </Button>
                    </Whisper>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    className="p-2"
                    style={{ whiteSpace: "pre-wrap", verticalAlign: "middle" }}
                  ></td>

                  <th
                    className="p-2 font-weight-bold text-dark text-uppercase text-right"
                    style={{ verticalAlign: "middle", fontSize: "0.65rem" }}
                  >
                    Subtotal:
                  </th>
                  <td
                    className="p-2 text-right"
                    style={{ verticalAlign: "middle" }}
                  >
                    {isNaN(this.state.subTotalCents)
                      ? "--"
                      : StringUtils.centsToCurrency(this.state.subTotalCents)}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    className="p-2"
                    style={{ whiteSpace: "pre-wrap", verticalAlign: "middle" }}
                  ></td>

                  <th
                    className="p-2 font-weight-bold text-dark text-uppercase text-right"
                    style={{ verticalAlign: "middle", fontSize: "0.65rem" }}
                  >
                    Tax:
                  </th>
                  <td
                    className="p-2 text-right"
                    style={{ verticalAlign: "middle" }}
                  >
                    <NumberFormat
                      className="form-control form-control-sm text-right"
                      fixedDecimalScale={true}
                      decimalScale={2}
                      required={true}
                      value={this.state?.tax?.value}
                      placeholder="$0.00"
                      onValueChange={(v) => {
                        this.setState({ tax: v }, () => {
                          this.calculateTotals();
                        });
                      }}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    className="p-2"
                    style={{ whiteSpace: "pre-wrap", verticalAlign: "middle" }}
                  ></td>

                  <th
                    className="p-2 font-weight-bold text-dark text-uppercase text-right"
                    style={{ verticalAlign: "middle", fontSize: "0.65rem" }}
                  >
                    Total:
                  </th>
                  <td
                    className="p-2 text-right"
                    style={{ verticalAlign: "middle" }}
                  >
                    {isNaN(this.state.totalCents)
                      ? "--"
                      : StringUtils.centsToCurrency(this.state.totalCents)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(CustomerCreateOrderDrawer);
