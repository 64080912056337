import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { Col, Row } from "reactstrap";
import { Dropdown, Popover, Whisper } from "rsuite";
import APIV2 from "../lib/APIV2";
import LinkUtils from "../lib/LinkUtils";

const LinkPreview = ({ url }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [preview, setPreview] = useState(null);
  const [loadedURL, setLoadedURL] = useState("");

  useEffect(() => {
    if (url && url != loadedURL) {
      setLoading(true);
      setError(false);
      setLoadedURL(url);

      APIV2.getURLPreview(url)
        .then(
          (data) => {
            if (data.data.preview?.error) {
              setError(true);
              setPreview(null);

              return;
            }

            setPreview(data.data.preview);
          },
          (e) => {
            setError(true);
            setPreview(null);
          }
        )
        .finally(() => {
          setLoading(false);
        });
    }
  }, [url]);

  return (
    <>
      {loading ? (
        <>
          <div style={{ maxWidth: 280 }} className="bg-white rounded border">
            <Row className="align-items-center">
              <Col xs="auto" style={{ maxWidth: 70 }}>
                <div
                  className="bg-lighter"
                  style={{ height: 70, width: 70 }}
                ></div>
              </Col>

              <Col xs="" style={{ maxWidth: "calc(100% - 70px)" }}>
                <div style={{ height: 70 }} className="p-2">
                  <div
                    className="skeleton"
                    style={{
                      width: "80%",
                      maxWidth: 300,
                      height: 15,
                      marginBottom: "0.5rem",
                    }}
                  ></div>
                  <div
                    className="skeleton"
                    style={{
                      width: "100%",
                      maxWidth: 450,
                      height: 12,
                      marginBottom: "0.5rem",
                    }}
                  ></div>
                  <div
                    className="skeleton"
                    style={{ width: "55%", maxWidth: 200, height: 12 }}
                  ></div>
                </div>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <>
          <div
            className={`bg-white rounded border border-lighter cursor-pointer shadow--hover bg-ultralight--hover ${
              !preview?.url && "d-none"
            }`}
            style={{ maxWidth: 280 }}
            onClick={() => {
              LinkUtils.openInNewTab(url);
            }}
          >
            {error ? (
              <>
                <Row className="align-items-center">
                  <Col xs="auto" style={{ maxWidth: 70 }}>
                    <div
                      className="bg-lighter"
                      style={{ height: 70, width: 70 }}
                    ></div>
                  </Col>

                  <Col xs="" style={{ maxWidth: "calc(100% - 70px)" }}>
                    <div style={{ height: 70 }} className="p-2">
                      <h4 className="text-truncate text-dark m-0">
                        No Preview Available
                      </h4>
                      <p
                        className="m-0 small text-dark text-truncate"
                        style={{ lineHeight: 1.4 }}
                      >
                        &nbsp;
                      </p>
                      <p className="text-muted text-truncate small m-0">
                        {preview?.url}
                      </p>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className="align-items-center">
                  {preview?.images?.length ? (
                    <Col xs="auto" style={{ maxWidth: 70 }}>
                      <div
                        className="bg-white"
                        style={{ height: 70, width: 70 }}
                      >
                        <div
                          style={{
                            height: 70,
                            width: 70,
                            borderBottomLeftRadius: 4,
                            borderTopLeftRadius: 4,
                            background: `url(${preview?.images[0]}) center center / cover`,
                          }}
                          className="border-right"
                        ></div>
                      </div>
                    </Col>
                  ) : null}
                  {!preview?.images?.length && preview?.favicons?.length ? (
                    <Col xs="auto" style={{ maxWidth: 70 }}>
                      <div
                        className="bg-white"
                        style={{ height: 70, width: 70 }}
                      >
                        <div
                          style={{
                            height: 50,
                            width: 50,
                            borderBottomLeftRadius: 4,
                            borderTopLeftRadius: 4,
                            background: `url(${preview?.favicons[0]}) center center / cover`,
                            margin: 10,
                          }}
                          className="border-right"
                        ></div>
                      </div>
                    </Col>
                  ) : null}
                  <Col xs="" style={{ maxWidth: "calc(100% - 70px)" }}>
                    <div style={{ height: 70 }} className="p-2">
                      <h4 className="text-truncate text-dark m-0">
                        {preview?.title}
                      </h4>
                      <p
                        className="m-0 small text-dark text-truncate"
                        style={{ lineHeight: 1.4 }}
                      >
                        {preview?.description ? (
                          preview?.description
                        ) : (
                          <>&nbsp;</>
                        )}
                      </p>
                      <p className="text-muted text-truncate small m-0">
                        {preview?.url}
                      </p>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

const Linkify = ({ body, showPreviews = true, wrap = false }) => {
  const dropdownRef = useRef();
  const [copied, setCopied] = useState(false);

  const output = useMemo(() => {
    let inputText = body;

    let replacedText, replacePattern1, replacePattern2, replacePattern3;

    let bodyArr = body?.split("\n");

    let bodyComponents = [];
    let linkPreviews = [];

    for (let i = 0; i < bodyArr?.length; i++) {
      let html = false;

      let line = bodyArr[i];

      let bodyStringArr = line?.split(" ");

      for (let j = 0; j < bodyStringArr?.length; j++) {
        let bodyString = bodyStringArr[j];

        //URLs starting with http://, https://, or ftp://
        replacePattern1 =
          /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        const urlMatch1 = bodyString.match(replacePattern1);

        //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
        replacePattern2 =
          /^(((?!\-))(xn\-\-)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn\-\-)?([a-z0-9\-]{1,61}|[a-z0-9\-]{1,30})\.[a-z]{2,}$/gim;
        const urlMatch2 = bodyString.match(replacePattern2);

        replacePattern3 =
          /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
        const emailMatch = bodyString.match(replacePattern3);

        //console.log(bodyString, "Match", replacedText);

        if (urlMatch1?.length) {
          bodyComponents.push(
            <span>
              <a
                href={urlMatch1[0]}
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();

                  LinkUtils.openInNewTab(urlMatch1[0]);
                }}
              >
                {urlMatch1[0]}
              </a>
            </span>
          );
          linkPreviews.push(<LinkPreview url={urlMatch1[0]}></LinkPreview>);
        } else if (urlMatch2?.length) {
          bodyComponents.push(
            <span>
              <a
                href={"https://" + urlMatch2[0]}
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();

                  LinkUtils.openInNewTab("https://" + urlMatch2[0]);
                }}
              >
                {urlMatch2[0]}
              </a>
            </span>
          );
          linkPreviews.push(
            <LinkPreview url={"https://" + urlMatch2[0]}></LinkPreview>
          );
        } else if (emailMatch?.length) {
          bodyComponents.push(
            <span>
              <Whisper
                placement="auto"
                trigger={["click", "hover"]}
                enterable
                ref={dropdownRef}
                delayOpen={400}
                speaker={
                  <Popover full className="dropdown-popover">
                    <Dropdown.Menu
                      style={{
                        minWidth: 150,
                        paddingBottom: 0,
                      }}
                    >
                      <Dropdown.Item
                        panel
                        className="font-weight-800 text-uppercase"
                        style={{
                          padding: 12,
                        }}
                      >
                        <h5 className="m-0 text-dark">Actions</h5>
                      </Dropdown.Item>
                      <Dropdown.Item className="my-0" divider></Dropdown.Item>
                      <Dropdown.Item
                        disabled={copied}
                        onClick={() => {
                          //this.settingsDropdownRef.current.close();

                          if (
                            typeof navigator?.clipboard?.writeText == "function"
                          ) {
                            navigator.clipboard.writeText(emailMatch[0]);

                            setCopied(true);

                            setTimeout(() => {
                              setCopied(false);
                            }, 1000);
                          }

                          dropdownRef.current.close();
                        }}
                        className="text-dark bg-superlight--hover"
                      >
                        <Row className="align-items-center">
                          <Col xs="auto" className="pr-0">
                            <i
                              className={`mdi mdi-content-copy`}
                              style={{
                                fontSize: 16,
                                lineHeight: 1.3,
                              }}
                            ></i>
                          </Col>
                          <Col xs="" className="pl-2">
                            <p className="m-0" style={{ fontSize: 14 }}>
                              {copied ? "Copied!" : "Copy Email"}
                            </p>
                          </Col>
                        </Row>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          //this.settingsDropdownRef.current.close();

                          LinkUtils.openInNewTab("mailto:" + emailMatch[0]);

                          dropdownRef.current.close();
                        }}
                        className="text-dark bg-superlight--hover"
                      >
                        <Row className="align-items-center">
                          <Col xs="auto" className="pr-0">
                            <i
                              className={`mdi mdi-send-circle-outline`}
                              style={{
                                fontSize: 16,
                                lineHeight: 1.3,
                              }}
                            ></i>
                          </Col>
                          <Col xs="" className="pl-2">
                            <p className="m-0" style={{ fontSize: 14 }}>
                              Send Email
                            </p>
                          </Col>
                        </Row>
                      </Dropdown.Item>
                      {/*<Dropdown.Item
                        onClick={() => {
                          //this.settingsDropdownRef.current.close();

                          console.log("SET AS EMAIL");

                          dropdownRef.current.close();
                        }}
                        className="text-dark bg-superlight--hover"
                      >
                        <Row className="align-items-center">
                          <Col xs="auto" className="pr-0">
                            <i
                              className={`mdi mdi-account-edit`}
                              style={{
                                fontSize: 16,
                                lineHeight: 1.3,
                              }}
                            ></i>
                          </Col>
                          <Col xs="" className="pl-2">
                            <p className="m-0" style={{ fontSize: 14 }}>
                              Set As Contact's Email
                            </p>
                          </Col>
                        </Row>
                            </Dropdown.Item>*/}
                    </Dropdown.Menu>
                  </Popover>
                }
              >
                <a
                  href={"mailto:" + emailMatch[0]}
                  target="_blank"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {emailMatch[0]}
                </a>
              </Whisper>
            </span>
          );
        } else {
          bodyComponents.push(bodyString);
        }

        if (j != bodyStringArr?.length - 1) {
          bodyComponents.push(" ");
        }
      }

      if (i != bodyArr?.length - 1) {
        bodyComponents.push(
          <>
            <div style={{ width: "100%", marginBottom: 10 }}></div>
            {`\n`}
          </>
        );
      }
    }

    return (
      <div>
        <div
          style={{
            lineHeight: wrap ? 1.5 : 1,
            display: wrap ? "flex" : "",
            flexWrap: wrap ? "wrap" : "",
          }}
          className="text-dark"
        >
          {bodyComponents?.map((comp) => comp)}
        </div>
        <div>
          {linkPreviews?.length && showPreviews ? (
            <>
              {linkPreviews?.map((preview, i) => (
                <>
                  <div className={`mt-3`}>{preview}</div>
                </>
              ))}
            </>
          ) : null}
        </div>
      </div>
    );
  }, [body]);

  return <>{output}</>;
};

export default Linkify;
