import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import API from "lib/API";
import BillingAccountRow from "components/BillingAccountRow";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex, isNumber } from "underscore";
import CreateBillingAccountModal from "components/CreateBillingAccountModal";
import StoreRow from "components/StoreRow";
import ModifyStoreModal from "components/ModifyStoreModal";
import { withRouter } from "react-router-dom";
import DeleteStoreModal from "components/DeleteStoreModal";
import StoreViewAboutTab from "components/StoreViewAboutTab";
import StoreViewLogisticsTab from "components/StoreViewLogisticsTab";
import StoreViewManagementTab from "components/StoreViewManagementTab";
import StoreViewTermsTab from "components/StoreViewTermsTab";
import StoreViewProductsTab from "components/StoreViewProductsTab";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import FormGroup from "reactstrap/lib/FormGroup";
import _ from "underscore";
import swal from "sweetalert";
import Spinner from "reactstrap/lib/Spinner";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";

import draftToHtml from "draftjs-to-html";
import { Drawer, TagPicker, Uploader } from "rsuite";
import moment from "moment";
import StringUtils from "lib/StringUtils";
import ArrowText from "components/ArrowText";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import CustomerInformationWidget from "components/Widgets/CustomerInformationWidget";
import CustomerSubscriptionWidget from "components/Widgets/CustomerSubscriptionWidget";
import CustomerFulfillmentWidget from "components/Widgets/CustomerFulfillmentWidget";
import CustomerUnclaimedBoxWidget from "components/Widgets/CustomerUnclaimedBoxWidget";
import CustomerOpenActivitiesWidget from "components/Widgets/CustomerOpenActivitiesWidget";
import CustomerBillingWidget from "components/Widgets/CustomerBillingWidget";
import { Capacitor } from "@capacitor/core";
import CustomerSummaryWidget from "components/Widgets/CustomerSummaryWidget";
import CustomerTimelineWidget from "components/Widgets/CustomerTimelineWidget";
import CustomerAllTasksWidget from "components/Widgets/CustomerAllTasksWidget";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import CustomerCommunicationTabView from "components/TabView/CustomerCommunicationTabView";
import TechSupportTicketDrawer from "components/Drawers/TechSupportTicketDrawer";

const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};

const delimiters = [...KeyCodes.enter, KeyCodes.comma];

class CustomerView extends React.Component {
  state = {
    store: null,
    loading: true,
    activeTab: "about",
    descriptionState: EditorState.createEmpty(),
    loadingCategories: false,
    categories: [],
    categoryTags: [],
    allergens: [],
    tab: { name: "Summary", id: "summary" },
    allTabs: [
      {
        name: "Summary",
        id: "summary",
      },
      {
        name: "Information",
        id: "information",
      },
      {
        name: "Subscription",
        id: "subscription",
      },

      {
        name: "Billing",
        id: "billing",
      },
      {
        name: "Fulfillment",
        id: "fulfillment",
      },
      {
        name: "Tasks",
        id: "tasks",
      },
      {
        name: "Communications",
        id: "communications",
      },
      {
        name: "Timeline",
        id: "timeline",
      },
    ],
    accountActions: LocalStorage.get(Constant.CACHE.USER)?.globalPermissions
      ?.length
      ? [
          {
            name: <>Open Support Ticket</>,
            onClick: () => {
              this.setState({
                supportTicket: true,
              });
            },
          },
          {
            name: <>Clear Cache</>,
            onClick: () => {
              APIV2.clearCustomerCache(
                this.state?.customer?.storeID,
                this?.state?.customer?._id
              ).then(
                () => {
                  swal("Cache successfully created", {
                    icon: "success",
                  }).then(() => {
                    window.location.reload();
                  });
                },
                () => {}
              );
            },
          },
          {
            name: <>View JSON Object</>,
            onClick: () => {
              this.setState({
                viewObjectDrawer: true,
              });
            },
          },
        ]
      : [
          {
            name: <>Open Support Ticket</>,
            onClick: () => {
              this.setState({
                supportTicket: true,
              });
            },
          },
        ],
  };

  constructor(props) {
    super(props);

    this.headerRef = React.createRef();
  }

  onDescriptionStateChange(editorState) {
    this.setState({
      descriptionState: editorState,
      modified: true,
    });
  }

  async modify() {
    let content = draftToHtml(
      convertToRaw(this.state.descriptionState.getCurrentContent())
    );

    if (
      !this.state.name ||
      (!this.state.selectedFile?.length && this.state.modifyImage) ||
      !content
    ) {
      swal({
        title: "Add Meal Information",
        text: "Please include a name, description, and image to continue.",
        icon: "warning",
      });

      return;
    }

    let calories = parseInt(this.state.calories);
    let carbs = parseInt(this.state.carbs);
    let fat = parseInt(this.state.fat);
    let protein = parseInt(this.state.protein);
    let sugar = parseInt(this.state.sugar);
    let fiber = parseInt(this.state.fiber);
    let sodium = parseInt(this.state.sodium);
    let foodWeight = parseFloat(this.state.foodWeight);
    let finishedWeight = parseFloat(this.state.finishedWeight);

    if (
      !isNumber(calories) ||
      !isNumber(carbs) ||
      !isNumber(fat) ||
      !isNumber(protein) ||
      !isNumber(sugar) ||
      !isNumber(fiber) ||
      !isNumber(sodium) ||
      !isNumber(foodWeight) ||
      !isNumber(finishedWeight) ||
      calories < 0 ||
      carbs < 0 ||
      fat < 0 ||
      protein < 0 ||
      sugar < 0 ||
      fiber < 0 ||
      foodWeight < 0 ||
      finishedWeight < 0
    ) {
      swal({
        title: "Add Macros & Info",
        text: "Please include valid macros and production information to continue.",
        icon: "warning",
      });

      return;
    }

    let hasCategory = false;

    for (let i = 0; i < this.state.categories.length; i++) {
      if (this.state.categories[i]) {
        hasCategory = true;
      }
    }

    if (!hasCategory) {
      swal({
        title: "Add A Category",
        text: "Please select at least one category for this meal to continue.",
        icon: "warning",
      });

      return;
    }

    this.setState({
      submitting: true,
    });

    let url = "";

    if (this.state.modifyImage) {
      // Create an object of formData
      let formData = new FormData();

      formData.append("file", this.state.selectedFile[0].blobFile);

      let data = await APIV2.uploadCDNImage(formData);

      url = data.data.data.url;
    }

    let allergens = [];
    let categories = [];

    for (let i = 0; i < this.state.allergens.length; i++) {
      allergens.push(this.state.allergens[i]);
    }

    for (let i = 0; i < this.state.categories.length; i++) {
      categories.push(this.state.categories[i]);
    }

    APIV2.modifyMeal(
      this.state.customer._id,
      this.state.name,
      this.state.zohoAPIName,
      content,
      this.state.modifyImage ? url : this.state.customer.imageURL,
      this.state.customer.planTypeID,
      calories,
      carbs,
      protein,
      fat,
      sugar,
      fiber,
      sodium,
      foodWeight,
      finishedWeight,
      categories,
      allergens
    )
      .then(
        (data) => {
          let m = data.data.meal;

          this._loadCustomerState(m);

          this.setState({
            modified: false,
            modifyImage: false,
          });

          swal("Meal successfully updated", {
            icon: "success",
          });

          PubSub.publish(Event.MEAL.MODIFIED, m);
          //this.closeModifyModal();
        },
        (e) => {
          console.error(e);
          swal({
            title: "Error Updating",
            text: "We were unable to update the meal. Please try again.",
            icon: "warning",
          });
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  _loadCustomerState(customer) {
    if (!customer) {
      return;
    }

    if (!customer.details) {
      customer.details = "";
    }

    let customerDetails = customer.details;

    if (customer?.onlineAccountDeleted) {
      let accountActions = this.state.accountActions;

      accountActions.push({
        name: "Restore Online Account",
        onClick: () => {
          APIV2.setCustomerVariable(
            this.state.store?._id,
            this.state.customer?._id,
            "onlineAccountDeleted",
            false
          ).then(
            () => {
              this.loadCustomer();
            },
            (e) => {}
          );
        },
      });
    } else {
      let accountActions = _.filter(this.state.accountActions, (a) => {
        return a.name != "Restore Online Account";
      });

      this.setState({
        accountActions,
      });
    }

    this.setState({
      customer,
      customerDetails,
    });
  }

  async loadCustomer() {
    const {
      match: { params },
    } = this.props;

    APIV2.getCustomerByID(params.storeID, params.customerID)
      .then(
        (data) => {
          if (data && data.data && data.data.customer) {
            this._loadCustomerState(data.data.customer);
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  delete() {
    swal({
      title: "Delete Member?",
      text: "Are you sure you want to delete this member? This cannot be undone.",
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (!willDelete) {
        return;
      }

      this.setState({ deleting: true });

      APIV2.deleteCustomer(
        this.state.customer?.storeID,
        this.state.customer._id
      )
        .then(
          (data) => {
            PubSub.publish(Event.CUSTOMER.DELETED, {
              id: this.state.customer._id,
            });

            swal("Customer successfully deleted", {
              icon: "success",
            });

            this.props.history.push(
              "/storefront/" + this?.props?.match?.params?.storeID + "/members"
            );
          },
          (e) => {
            console.error(e);
            window.alert(
              "We're having trouble removing this member. Please try again."
            );
          }
        )
        .finally(() => {
          this.setState({ deleting: false });
        });
    });
  }

  onFileChange(e) {
    let files = [];

    files = e.length ? [e[e.length - 1]] : [];

    this.setState({ selectedFile: files });
  }

  checkStickyHeader(e) {
    if (this.headerRef.current) {
      const stickyElm = this.headerRef.current;

      var rect = stickyElm.getBoundingClientRect();

      if (rect.top <= (Capacitor.getPlatform() == "ios" ? 110 : 80)) {
        this.setState({ sticky: true });
      } else {
        this.setState({ sticky: false });
      }

      if (rect.top == 0) {
        this.setState({ fixed: true });
      } else {
        this.setState({ fixed: false });
      }
    }
  }

  loadStore() {
    const {
      match: { params },
    } = this.props;

    if (!params?.storeID) {
      return;
    }

    APIV2.getStoreByID(params.storeID)
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            this.setState({
              store: data.data.store,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    this.loadCustomer();

    PubSub.subscribe(Event.CUSTOMER.MODIFIED, (c) => {
      this.setState({ customer: c, customerDetails: c?.details });
    });

    if (this.props.match?.params?.tabID) {
      this.setTab(this.props.match?.params?.tabID);
    } else {
      this.setTab("summary");
    }

    this.loadStore();

    document.addEventListener("scroll", this.checkStickyHeader.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.checkStickyHeader.bind(this));
  }

  componentDid;

  setTab(tab) {
    if (!tab) {
      return;
    }

    let t = _.findWhere(this.state.allTabs, { id: tab });

    if (t) {
      this.setState({ tab: t });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match?.params?.customerID !=
        prevProps.match?.params?.customerID ||
      this.props.match?.params?.storeID != prevProps.match?.params?.storeID
    ) {
      this.loadCustomer();
    }

    if (
      this.props.match?.params?.storeID != prevProps?.match?.params?.storeID
    ) {
      this.loadStore();
    }

    if (this.props.match?.params?.tabID !== prevProps.match?.params?.tabID) {
      if (this.props.match?.params?.tabID) {
        this.setTab(this.props.match?.params?.tabID);
      } else {
        this.setTab("summary");
      }
    }
  }

  /**
   * Sets the customer's details
   */
  setCustomerDetails() {
    this.setState({
      submittingCustomerDetails: true,
    });

    APIV2.setCustomerVariable(
      this.props.match.params.storeID,
      this.state.customer._id,
      "details",
      this.state.customerDetails
    )
      .then(
        (data) => {
          this._loadCustomerState(data.data.customer);
        },
        (err) => {}
      )
      .finally(() => {
        this.setState({ submittingCustomerDetails: false });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({ modified: true });

    this.setState({
      [name]: value,
    });
  }

  handleInputNoSpaceChange(e) {
    const { name, value } = e.target;

    this.setState({ modified: true });

    this.setState({
      [name]: value.replace(/\s/g, ""),
    });
  }

  handleNumberInputChange(e) {
    const { name, value } = e.target;

    this.setState({ modified: true });

    this.setState({
      [name]: value.replace(/[^\d.]/g, ""),
    });
  }

  categoryChange(v) {
    this.setState({ categories: v, modified: true });
  }

  allergenChange(v) {
    this.setState({ allergens: v, modified: true });
  }

  sinceDateFormat(d) {
    let date = moment(d);

    return date.format("MMMM YYYY");
  }

  renderNextBillDate(d) {
    let mom = moment(d);

    let now = moment();

    if (mom.format("MMMM Do YYYY") == now.format("MMMM Do YYYY")) {
      return "Today";
    }

    return mom.format("M/D/YY");
  }

  renderProcurement(plan) {
    if (!plan) {
      return "";
    }

    let procurement = plan.procurement;

    if (procurement == "pickup") {
      procurement = "Pickup";
    }

    if (procurement == "delivery") {
      procurement = "Delivery";
    }

    if (procurement == "shipping") {
      procurement = "Shipping";
    }

    return procurement;
  }

  countCallTasks(customer) {
    let count = 0;

    if (customer?.cohort_customerCallTaskWeek1Complete) {
      count++;
    }

    if (customer?.cohort_customerCallTaskWeek3Complete) {
      count++;
    }

    return count;
  }

  countOrders(customer) {
    let count = 0;

    if (customer?.cohort_customerOnePlusOrderFirst30Days) {
      count++;
    }

    if (customer?.cohort_customerOrdersFirst30Days) {
      count++;
    }

    return count;
  }

  getSubscriptionWeek(customer) {
    if (customer?.hasOwnProperty("subscriptionWeeks")) {
      if (customer?.subscriptionWeeks == 0) {
        return "Pre-First Fulfillment";
      }

      return `Subscription Week ${customer?.subscriptionWeeks}`;
    } else {
      if (customer?.subscription?.currentFulfillmentCount > 0) {
        return `Subscription Week ${
          customer?.subscription?.currentFulfillmentCount + 1
        }`;
      }

      return "Pre First-Fulfillment";
    }
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader
            backOverride={`/storefront/${this?.props?.match?.params?.storeID}/members`}
            title={
              this.state.customer ? (
                <>
                  <div>{this.state.customer.name}</div>

                  <p className="small mt-1 mb-0">
                    <Badge
                      color={
                        this.state.customer.accountStatus == "ACTIVE"
                          ? "success"
                          : "medium"
                      }
                      className="mr-2"
                      style={{ position: "relative", top: "-2px" }}
                    >
                      {this.state.customer.accountStatus}
                    </Badge>
                    {this.state.customer?.accountStatus == "ACTIVE" ? (
                      <span className="text-dark">
                        {this.renderProcurement(
                          this.state.customer?.subscription?.plan
                        )}
                        :{" "}
                        {this.renderNextBillDate(
                          this.state.customer?.subscription?.nextBillDate
                        )}
                      </span>
                    ) : null}
                  </p>
                </>
              ) : (
                "Loading..."
              )
            }
            showBackButton={true}
            forceSecondaryDropdown={true}
            secondaryActions={this.state.accountActions}
          />
          {/* Table */}
          <Row>
            <Col xs="12" md="12">
              <Card className="mb-4 mt--4 border-0">
                <CardHeader className="bg-transparent px-0 pt-2">
                  <Row className="align-items-center">
                    <Col xs="12" sm="6" className="text-left">
                      {this.state.customer?.subscription?.status == "ACTIVE" ? (
                        <div>
                          <p
                            className="m-0 text-dark font-weight-500"
                            style={{ lineHeight: 1.4 }}
                          >
                            {this.getSubscriptionWeek(this.state.customer)}
                          </p>
                          {this.state.customer?.signupDate &&
                          !this.state?.customer?.subscriptionStartDate ? (
                            <p
                              className="m-0 text-dark font-weight-500"
                              style={{ lineHeight: 1.2 }}
                            >
                              <small>
                                Signed up on{" "}
                                {moment(this.state.customer?.signupDate).format(
                                  "MMM DD, YYYY"
                                )}
                              </small>
                            </p>
                          ) : null}
                          {this.state?.customer?.subscriptionStartDate ? (
                            <p
                              className="m-0 text-dark font-weight-500"
                              style={{ lineHeight: 1.2 }}
                            >
                              <small>
                                Subscription Started on{" "}
                                {moment(
                                  this.state.customer?.subscriptionStartDate
                                ).format("MMM DD, YYYY")}
                              </small>
                            </p>
                          ) : null}
                          {this.state.customer?.subscription?.status ==
                            "ACTIVE" &&
                          this.state.customer?.subscription?.pauseCounter &&
                          this.state.customer?.subscription?.pauseCounter >=
                            4 ? (
                            <>
                              <div
                                style={{ fontSize: 13 }}
                                className="badge badge-warning p-1 border border-warning text-capitalize mt-2 mb-1 mb-md-0"
                              >
                                <i
                                  className="mdi mdi-alert-box mr-1"
                                  style={{ position: "relative", top: 1 }}
                                ></i>
                                HFN Risk:{" "}
                                {
                                  this.state.customer?.subscription
                                    ?.pauseCounter
                                }{" "}
                                orders skipped in a row
                              </div>
                            </>
                          ) : null}
                        </div>
                      ) : null}

                      {this.state.customer?.subscription?.status == "HOLD" &&
                        this.state.customer?.subscription && (
                          <>
                            <p
                              className="m-0 text-dark font-weight-500"
                              style={{ lineHeight: 1.4 }}
                            >
                              <small>
                                Fulfilled{" "}
                                {
                                  this.state.customer?.subscription
                                    ?.totalFulfillmentCount
                                }{" "}
                                Orders To Date
                              </small>
                            </p>
                          </>
                        )}
                    </Col>
                    <Col xs="12" sm="6" className="text-sm-right">
                      {this.state.customer?.subscription
                        ?.totalFulfillmentCount > 0 ? (
                        <>
                          <p
                            className="m-0 text-dark font-weight-500"
                            style={{ lineHeight: 1.2 }}
                          >
                            <small>
                              Fulfilled{" "}
                              {
                                this.state.customer?.subscription
                                  ?.totalFulfillmentCount
                              }{" "}
                              Orders To Date
                            </small>
                          </p>
                        </>
                      ) : null}
                      {this.state.customer?.subscription?.lastBillDate ? (
                        <>
                          <div className="mb-0 small text-dark">
                            Last billed on{" "}
                            {moment(
                              this.state.customer?.subscription?.lastBillDate
                            ).format("MMM DD, YYYY")}
                          </div>
                        </>
                      ) : (
                        <div>
                          <small
                            className="mb-0 text-dark"
                            style={{ lineHeight: 1.4 }}
                          >
                            {this.state.customer?.accountStatus == "ACTIVE"
                              ? "Active"
                              : "Inactive"}{" "}
                            member
                            {this.state.customer?.billingAddress?.city
                              ? ` from ${
                                  this.state.customer?.billingAddress?.city
                                }, ${
                                  this.state.customer?.billingAddress?.state
                                    ? this.state.customer?.billingAddress?.state.toUpperCase()
                                    : ""
                                }`
                              : ""}
                          </small>
                        </div>
                      )}
                    </Col>

                    <Col xs="12" className="mt-3">
                      <Row>
                        <Col xs="12" sm="12">
                          <Card className="border">
                            <CardHeader className="p-2">
                              <Row className="align-items-center">
                                <Col xs="">
                                  <h4 className="text-dark m-0">
                                    Member Experience Score
                                  </h4>
                                  <p className="small m-0 text-dark">
                                    Based on activity and order history during
                                    the first 30 days after signup
                                  </p>
                                </Col>
                                <Col xs="auto">
                                  <div
                                    className={`${
                                      this.state.customer?.memberHealthScore <=
                                      50
                                        ? "bg-danger text-white"
                                        : ""
                                    } ${
                                      this.state.customer?.memberHealthScore >
                                        50 &&
                                      this.state.customer?.memberHealthScore <=
                                        70
                                        ? "bg-yellow text-dark"
                                        : ""
                                    } ${
                                      this.state.customer?.memberHealthScore >
                                      70
                                        ? "bg-success text-white"
                                        : ""
                                    } rounded d-flex align-items-center  justify-content-center`}
                                    style={{ height: 30, width: 30 }}
                                  >
                                    <div className="text-center">
                                      <div
                                        className="font-weight-bold"
                                        style={{
                                          fontSize: 16,
                                          lineHeight: 1.1,
                                        }}
                                      >
                                        {this.state.customer?.memberHealthScore}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody className="p-2">
                              <Row className="align-items-center">
                                <Col xs="12" sm="6">
                                  <div>
                                    <Row className="align-items-center">
                                      <Col xs="auto" className="pr-2">
                                        <h3
                                          style={{
                                            fontSize: 22,
                                            lineHeight: 1,
                                          }}
                                          className="m-0"
                                        >
                                          {this.state.customer
                                            ?.cohort_received_consult ? (
                                            <i className="mdi mdi-check-circle text-success"></i>
                                          ) : (
                                            <i className="mdi mdi-close-circle text-danger"></i>
                                          )}
                                        </h3>
                                      </Col>
                                      <Col xs="" className="pl-0">
                                        <p
                                          style={{
                                            fontSize: 14,
                                            lineHeight: 1.2,
                                          }}
                                          className="m-0 text-dark"
                                        >
                                          Completed LEAN Consultation
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                                <Col xs="12" sm="6" className="mt-1 mt-sm-0">
                                  <div>
                                    <Row className="align-items-center">
                                      <Col xs="auto" className="pr-2">
                                        <h3
                                          style={{
                                            fontSize: 22,
                                            lineHeight: 1,
                                          }}
                                          className="m-0"
                                        >
                                          {this.state.customer
                                            ?.cohort_received_checkin ? (
                                            <i className="mdi mdi-check-circle text-success"></i>
                                          ) : (
                                            <i className="mdi mdi-close-circle text-danger"></i>
                                          )}
                                        </h3>
                                      </Col>
                                      <Col xs="" className="pl-0">
                                        <p
                                          style={{
                                            fontSize: 14,
                                            lineHeight: 1.2,
                                          }}
                                          className="m-0 text-dark"
                                        >
                                          Completed A Member Check-In
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                                <Col xs="12" sm="6" className="mt-1">
                                  <div>
                                    <Row className="align-items-center">
                                      <Col xs="auto" className="pr-2">
                                        <h3
                                          style={{
                                            fontSize: 22,
                                            lineHeight: 1,
                                          }}
                                          className="m-0"
                                        >
                                          {this.countCallTasks(
                                            this.state.customer
                                          ) == 2 ? (
                                            <i className="mdi mdi-check-circle text-success"></i>
                                          ) : null}
                                          {this.countCallTasks(
                                            this.state.customer
                                          ) == 0 ? (
                                            <i className="mdi mdi-close-circle text-danger"></i>
                                          ) : null}
                                          {this.countCallTasks(
                                            this.state.customer
                                          ) == 1 ? (
                                            <i className="mdi mdi-circle-half-full text-success"></i>
                                          ) : null}
                                        </h3>
                                      </Col>
                                      <Col xs="" className="pl-0">
                                        <p
                                          style={{
                                            fontSize: 14,
                                            lineHeight: 1.2,
                                          }}
                                          className="m-0 text-dark"
                                        >
                                          Completed{" "}
                                          {this.countCallTasks(
                                            this.state.customer
                                          ) > 0
                                            ? `${this.countCallTasks(
                                                this.state.customer
                                              )}/2 Follow-Up Calls In First 30 Days`
                                            : "2 Follow-Up Calls In First 30 Days"}
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                                <Col xs="12" sm="6" className="mt-1">
                                  <div>
                                    <Row className="align-items-center">
                                      <Col xs="auto" className="pr-2">
                                        <h3
                                          style={{
                                            fontSize: 22,
                                            lineHeight: 1,
                                          }}
                                          className="m-0"
                                        >
                                          {this.countOrders(
                                            this.state.customer
                                          ) == 2 ? (
                                            <i className="mdi mdi-check-circle text-success"></i>
                                          ) : null}
                                          {this.countOrders(
                                            this.state.customer
                                          ) == 0 ? (
                                            <i className="mdi mdi-close-circle text-danger"></i>
                                          ) : null}
                                          {this.countOrders(
                                            this.state.customer
                                          ) == 1 ? (
                                            <i className="mdi mdi-circle-half-full text-success"></i>
                                          ) : null}
                                        </h3>
                                      </Col>
                                      <Col xs="" className="pl-0">
                                        <p
                                          style={{
                                            fontSize: 14,
                                            lineHeight: 1.2,
                                          }}
                                          className="m-0 text-dark"
                                        >
                                          Received 2+ Orders In The First 30
                                          Days
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xs="12" sm="12" className="mt-3">
                          <h5 className="text-dark text-capitalize">
                            Personal Note
                          </h5>
                          <Input
                            type="textarea"
                            value={this.state.customerDetails}
                            onChange={this.handleInputChange.bind(this)}
                            name="customerDetails"
                            placeholder="Details"
                          ></Input>
                          {this.state.customer &&
                          this.state.customerDetails !=
                            this.state.customer?.details ? (
                            <div className="text-right mt-3">
                              <Button
                                size="sm"
                                className="btn-icon-only"
                                color="secondary"
                                outline
                                disabled={this.state.submittingCustomerDetails}
                                onClick={() => {
                                  this.setState({
                                    customerDetails: this.state.customer
                                      ?.details
                                      ? this.state.customerDetails
                                      : "",
                                  });
                                }}
                              >
                                <i className="mdi mdi-close" />
                              </Button>
                              <Button
                                size="sm"
                                className="btn-icon-only"
                                color="primary"
                                disabled={this.state.submittingCustomerDetails}
                                onClick={() => this.setCustomerDetails()}
                              >
                                {this.state.submittingCustomerDetails ? (
                                  <Spinner size="sm" color="white" />
                                ) : (
                                  <i className="mdi mdi-check" />
                                )}
                              </Button>
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
              {/** Page navigation */}
              <div
                ref={this.headerRef}
                style={{ position: "sticky", top: "80px" }}
                className={`stickable ${this.state.sticky ? "stuck" : ""}`}
              >
                <Card className="mb-4 shadow border">
                  <CardSelectorHeader
                    id="stickyHeader"
                    value={this.state.tab}
                    options={this.state.allTabs}
                    onChange={(v) => {
                      if (v.id != this.state.tab.id) {
                        this.props.history.push(
                          "/storefront/" +
                            this.props.match?.params?.storeID +
                            "/members/details/" +
                            this.props.match?.params?.customerID +
                            "/" +
                            v.id,
                          { noScroll: true }
                        );
                      }
                    }}
                  ></CardSelectorHeader>
                </Card>
              </div>
              {this.state.tab.id == "summary" ? (
                <>
                  <CustomerSummaryWidget
                    loading={this.state.loading}
                    customer={this.state.customer}
                  />
                </>
              ) : null}
              {/**Primary  Widgets */}
              {this.state.tab.id == "information" ? (
                <>
                  <CustomerInformationWidget
                    loading={this.state.loading}
                    customer={this.state.customer}
                    store={this.state.store}
                  />
                </>
              ) : null}
              {this.state.tab.id == "subscription" ? (
                <>
                  <CustomerSubscriptionWidget
                    customer={this.state.customer}
                    loading={this.state.loading}
                    store={this.state.store}
                  />
                </>
              ) : null}
              {this.state.tab.id == "fulfillment" ? (
                <>
                  <CustomerFulfillmentWidget
                    loading={this.state.loading}
                    customer={this.state.customer}
                    store={this.state.store}
                  />
                </>
              ) : null}
              {this.state.tab.id == "billing" ? (
                <>
                  <CustomerBillingWidget
                    loading={this.state.loading}
                    customer={this.state.customer}
                    store={this.state.store}
                  />
                </>
              ) : null}
              {this.state.tab.id == "tasks" ? (
                <>
                  <CustomerAllTasksWidget
                    customer={this.state.customer}
                    store={this.state.store}
                    loading={this.state.loading}
                  ></CustomerAllTasksWidget>
                </>
              ) : null}
              {this.state.tab.id == "communications" ? (
                <>
                  <CustomerCommunicationTabView
                    customer={this.state.customer}
                    store={this.state.store}
                    loading={this.state.loading}
                  ></CustomerCommunicationTabView>
                </>
              ) : null}
              {this.state.tab.id == "timeline" ? (
                <>
                  <CustomerTimelineWidget
                    loading={this.state.loading}
                    customer={this.state.customer}
                    store={this.state.store}
                  />
                </>
              ) : null}
            </Col>
            <Col xs="12" md="4">
              {/*<Card className="mb-4 shadow">
                <CardHeader className="bg-transparent">
                  <Row>
                    <div className="col">
                      <h3 className=" mb-0">Insights</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody></CardBody>
    </Card>*/}
            </Col>
          </Row>
          <hr className="mt-2 mb-4" />
          <Row>
            <Col xs="auto">
              <Button
                color="danger"
                onClick={() => this.delete()}
                outline
                disabled={this.state.deleting}
              >
                {this.state.deleting ? (
                  <>
                    <Spinner size="sm" color="danger" />
                  </>
                ) : (
                  <>Delete Member</>
                )}
              </Button>
            </Col>
            <Col xs=""></Col>
          </Row>
        </Container>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.state.viewObjectDrawer}
          onClose={() => this.setState({ viewObjectDrawer: false })}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Customer Object
            </h3>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            <div
              className="mt-3 p-2 border bg-white"
              dangerouslySetInnerHTML={{
                __html: StringUtils.jsonToHTML(
                  this.state.customer ? this.state.customer : {}
                ),
              }}
            ></div>
          </Drawer.Body>
        </Drawer>
        <TechSupportTicketDrawer
          store={{ _id: this.props.match?.params?.storeID }}
          open={this.state.supportTicket}
          storeMode={true}
          customer={this.state.customer}
          onClose={() => {
            this.setState({
              supportTicket: false,
            });
          }}
          onCreate={(c) => {
            swal({
              title: "Support Ticket Opened",
              text: "We've received your support ticket and will contact you shortly!",
              icon: "success",
            });
          }}
        ></TechSupportTicketDrawer>
      </>
    );
  }
}

export default withRouter(CustomerView);
