import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  Card,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer, SelectPicker } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Area,
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Line,
  Tooltip,
} from "bizcharts";
import ChartMetricHeader from "./ChartMetricHeader";
import { ca } from "date-fns/locale";
import APIV2 from "lib/APIV2";

class StoreDailyInventoryDetailChartCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "store",
    skuSelector: 'active',
    displayType: "areaStack",
    chartAdjust: [{ type: "stack" }],
    areaStackAdjust: [{ type: "stack" }],
    areaCompareAdjust: [{ type: "dodge", marginRatio: 0, dodgeBy: "x" }],
  };

  /**
   * Fetches the order data by week for the given stores & date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchOrderDataByWeek(
    cubejsApi,
    stores,
    dateRange,
    category = "store"
  ) {
    let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let granularity = "day";

    return new Promise((resolve, reject) => {
      let endDate6 = moment(dateRange[1]);

      endDate6.endOf("day");

      let now = moment();

      if (endDate6.isAfter(now)) {
        endDate6 = now;
      }

      let dimension = "";

      if (category == "store") {
        dimension = "Stores.name";
      } else if (category == "type") {
        dimension = "StoreClosingInventoryReportItems.items_plantype";
      } else if (category == "sku") {
        dimension = "StoreClosingInventoryReportItems.items_sku";
      } else if (category == "protein") {
        dimension =
          "StoreClosingInventoryReportItems.items_primaryproteincategory";
      }

      let filters = [
        {
          member: "StoreClosingInventoryReportItems.storeid",
          operator: "equals",
          values: stores,
        },
      ];

      if (this.state.skuSelector == 'active' && this.state.meals?.length) {
        const skus = this.state.meals.map((meal) => {
            return meal?.sku;
        });

        filters.push({
            member: 'StoreClosingInventoryReportItems.items_sku',
            operator: 'equals',
            values: skus,
        });
      }

      if (this.state.skuSelector == 'retired' && this.state.meals?.length) {
        const skus = this.state.meals.map((meal) => {
            return meal?.sku;
        });

        filters.push({
            member: 'StoreClosingInventoryReportItems.items_sku',
            operator: 'notEquals',
            values: skus,
        
        });

        filters.push({
                member: 'StoreClosingInventoryReportItems.items_quantity',
                operator: 'gt',
                values: [0],
            })
      }

      //console.log(dateRange[0], endDate6?.toDate());

      // Load
      cubejsApi
        .load({
          measures: ["StoreClosingInventoryReportItems.items_quantity"],
          order: {
            "StoreClosingInventoryReportItems.date": "asc",
          },

          timeDimensions: [
            {
              dimension: "StoreClosingInventoryReportItems.date",
              dateRange: [moment(dateRange[0]).toDate(), endDate6?.toDate()],
              granularity,
            },
          ],
          filters: filters,
          dimensions: [dimension],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (!data?.length) {
            return resolve(null);
          }

          let totalCount = 0;

          let dates = [];

          data = data.map((item) => {
            let label = "";

            totalCount +=
              item["StoreClosingInventoryReportItems.items_quantity"];

            if (
              !dates.includes(item[`StoreClosingInventoryReportItems.date`])
            ) {
              dates.push(item[`StoreClosingInventoryReportItems.date`]);
            }

            if (category == "store") {
              label = item["Stores.name"]
                ?.replace("Project LeanNation", "PLN")
                ?.trim();
            } else if (category == "type") {
              label = item["StoreClosingInventoryReportItems.items_plantype"];
            } else if (category == "sku") {
              label = item["StoreClosingInventoryReportItems.items_sku"];
            } else if (category == "protein") {
              label =
                item[
                  "StoreClosingInventoryReportItems.items_primaryproteincategory"
                ];
            }

            return {
              dimension: category,
              label,
              date: moment(
                item[`StoreClosingInventoryReportItems.date.${granularity}`]
              ).toDate(),
              dateString: moment(
                item[`StoreClosingInventoryReportItems.date.${granularity}`]
              ).format("MM/DD/YYYY"),

              count: item["StoreClosingInventoryReportItems.items_quantity"],
            };
          });

          totalCount = totalCount ? Math.round(totalCount / dates?.length) : 0;

          return resolve({
            dimension: category,
            data,
            totalCount,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadDetailedReport(stores, dateRange, dimension = "store") {
    this.setState({
      loading: true,
      dataAvailable: false,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchOrderDataByWeek(
        cubejsApi,
        stores,
        dateRange,
        dimension
      );
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load inventory reports.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });

      return;
    }

    let previousCount = null;

    let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let startMoment = moment(dateRange[0].toISOString());
    let endMoment = moment(dateRange[1].toISOString());

    startMoment.subtract(secondDifference, "seconds");
    endMoment.subtract(secondDifference + 1, "seconds");

    console.log(startMoment.toDate(), endMoment.toDate());

    try {
      previousCount = await this._fetchOrderDataByWeek(
        cubejsApi,
        stores,
        [startMoment.toDate(), endMoment.toDate()],
        dimension
      );
    } catch (e) {}

    if (previousCount !== null) {
      let percentChange =
        (currentCount?.totalCount - previousCount?.totalCount) /
        previousCount?.totalCount;

      let declineMode = "";
      let isNegative = false;

      if (percentChange > 0) {
        if (Math.abs(percentChange) < 0.015) {
          declineMode = "unchanged";
        } else if (Math.abs(percentChange) < 0.1) {
          declineMode = "moderateIncrease";
        } else if (Math.abs(percentChange) >= 0.1) {
          declineMode = "increase";
        }
      } else {
        isNegative = true;

        if (Math.abs(percentChange) < 0.015) {
          declineMode = "unchanged";
        } else if (Math.abs(percentChange) < 0.1) {
          declineMode = "moderateDecrease";
        } else if (Math.abs(percentChange) >= 0.1) {
          declineMode = "decrease";
        }
      }

      this.setState({
        previousCount: previousCount,
        changeIsNegative: isNegative,
        deltaType: declineMode,
        percentChange: percentChange,
        percentChangeString: Math.abs(percentChange * 100).toFixed(1) + "%",
      });
    }

    let performanceTrend = [];

    let labels = [];

    for (let i = 0; i < currentCount?.data?.length; i++) {
      const item = currentCount?.data[i];

      let data = {
        date: item?.date,
        dateString: moment(item?.date).format("MM/DD/YYYY"),
        label: item?.label,
        count: item?.count,
      };

      performanceTrend.push(data);
    }

    performanceTrend = _.sortBy(performanceTrend, "date");

    this.setState({
      currentCount: currentCount,
      loading: false,
      data: performanceTrend,
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.dateRange != prevProps?.dateRange
    ) {
      this.loadDetailedReport(
        this.props.stores,
        this.props.dateRange,
        this.state.tab
      );
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadDetailedReport(
        this.props.stores,
        this.props.dateRange,
        this.state.tab
      );
    }
  }

    async getAllMeals() {
        APIV2.getMeals().then((data) => {

            
            this.setState({
                meals: data?.data?.meals,
            });
        }).catch((e) => {
            console.error(e);
        })
    }

  componentDidMount() {
    this.getAllMeals();

        if (this.props.stores && this.props.dateRange) {
      this.loadDetailedReport(
        this.props.stores,
        this.props.dateRange,
        this.state.tab
      );
    }

    if (this.props.reload) {
      this.loadDetailedReport(
        this.props.stores,
        this.props.dateRange,
        this.state.tab
      );
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <ChartMetricHeader
            title="Daily Inventory Summary"
            actions={
              <>
              <SelectPicker className="mr-2" size="sm" style={{width: 200}} data={[
                {label: 'On Menu SKUs', value: 'active'},
                {label: 'Retired SKUs', value: 'retired'},
                {label: 'All SKUs', value: 'all'},
              ]} loading={!this.state.meals?.length} value={this.state.skuSelector} onChange={(val) => {
                this.setState({
                  skuSelector: val,
                }, () => {
                    this.loadDetailedReport(
                        this.props.stores,
                        this.props.dateRange,
                        this.state.tab
                    );
                });
              }} cleanable={false} searchable={false}></SelectPicker>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  onClick={() => {
                    if (this.state.displayType == "areaStack") {
                      this.setState({
                        displayType: "areaCompare",
                      });
                    } else {
                      this.setState({
                        displayType: "areaStack",
                      });
                    }
                  }}
                  disabled={this.state.loading || !this.state.dataAvailable}
                >
                  {this.state.displayType == "areaStack" ? (
                    <i className="mdi mdi-chart-areaspline"></i>
                  ) : this.state.displayType == "areaCompare" ? (
                    <i className="mdi mdi-chart-line"></i>
                  ) : null}
                </Button>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading}
                  onClick={() => {
                    this.loadDetailedReport(
                      this.props.stores,
                      this.props.dateRange,
                      this.state.tab
                    );
                  }}
                >
                  {this.state.loading ? (
                    <Spinner size="sm"></Spinner>
                  ) : (
                    <i className="mdi mdi-refresh"></i>
                  )}
                </Button>
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={StringUtils.numberFormat(
              this.state.currentCount?.totalCount
            )}
            comparisonMetric={
              this.state.previousCount !== null
                ? StringUtils.numberFormat(this.state.previousCount?.totalCount)
                : null
            }
            dateRange={this.props.dateRange}
            deltaType={this.state.deltaType}
            percentChange={this.state.percentChangeString}
            showPercentChange={true}
          ></ChartMetricHeader>

          <TabList
            color="orange"
            defaultValue="store"
            handleSelect={(value) => {
              this.setState({
                tab: value,
              });

              this.loadDetailedReport(
                this.props.stores,
                this.props.dateRange,
                value
              );
            }}
            marginTop="mt-3"
            disabled={this.state.loading || !this.state.dataAvailable}
          >
            <Tab value="store" text="Units By Store" />
            {/*<Tab value="type" text="Units By Type" />*/}
            <Tab value="sku" text="Units by SKU" />
            <Tab value="protein" text="Units By Protein" />
          </TabList>
          {this.state.loading ? (
            <div
              className="skeleton"
              style={{ height: "calc(320px + 1rem)", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                  <div className="mt-3">
                    {this.state.displayType == "areaStack" ? (
                      <div>
                        <Chart
                        height={320}
                        appendPadding={[20, 0, 10, 30]}
                        data={this.state.data}
                        autoFit
                      >
                        <Coordinate transposed></Coordinate>
                        <Tooltip
                          itemTpl={`<li style="padding-bottom: 8px; color: var(--dark);" data-index={index}><span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>{name}:<span style="padding-left: 5px;">\{value} Units</span></li>`}
                          shared
                        ></Tooltip>
                        <Axis name="dateString" label={{ offset: 12 }}></Axis>
                        <Axis
                          name="count"
                          grid={{
                            line: {
                              style: {
                                stroke: "#CCC",
                                lineDash: [3, 3],
                              },
                            },
                          }}
                        ></Axis>
                        <Legend
                          marker={{ symbol: "circle" }}
                          label={{}}
                          position="top-right"
                          layout={"horizontal"}
                          offsetY={0}
                        ></Legend>
                        <Area
                          shape="area"
                          adjust={this.state.areaStackAdjust}
                          color="label"
                          position="dateString*count"
                        ></Area>
                        <Interaction type="active-region"></Interaction>
                      </Chart>
                      </div>
                    ) : (
                      <Chart
                        height={320}
                        appendPadding={[20, 0, 10, 30]}
                        data={this.state.data}
                        autoFit
                      >
                        <Coordinate transposed></Coordinate>
                        <Tooltip
                          itemTpl={`<li style="padding-bottom: 8px; color: var(--dark);" data-index={index}><span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>{name}:<span style="padding-left: 5px;">\{value} Units</span></li>`}
                          shared
                        ></Tooltip>
                        <Axis name="dateString" label={{ offset: 12 }}></Axis>
                        <Axis
                          name="count"
                          grid={{
                            line: {
                              style: {
                                stroke: "#CCC",
                                lineDash: [3, 3],
                              },
                            },
                          }}
                        ></Axis>
                        <Legend
                          marker={{ symbol: "circle" }}
                          label={{}}
                          position="top-right"
                          layout={"horizontal"}
                          offsetY={0}
                        ></Legend>
                        <Line color="label" position="dateString*count"></Line>
                        <Interaction type="active-region"></Interaction>
                      </Chart>
                    )}
                  </div>
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(320px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default StoreDailyInventoryDetailChartCard;
