import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import AffiliateCreateDrawer from "components/Drawers/AffiliateCreateDrawer";

class StorefrontAffiliateListView extends React.Component {
  state = {
    active: {
      id: "All",
      name: "All",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 25,
    filters: null,
  };

  componentDidUpdate() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }

    PubSub.subscribe(Event.LEAD.DELETED, (data) => {
      this.handleTabChange(this.state.active, true);
    });

    PubSub.subscribe(Event.LEAD.CREATED, (m) => {
      this.handleTabChange(this.state.active, true);
    });

    PubSub.subscribe(Event.LEAD.MODIFIED, (a) => {
      this.handleTabChange(this.state.active, true);
    });
  }

  onFileChange(e) {
    this.setState({ selectedFile: e.target.files[0] });
  }

  testImageUpload(e) {
    e.preventDefault();

    // Create an object of formData
    let formData = new FormData();

    formData.append("file", this.state.selectedFile);

    console.log(formData);

    API.setMealImage(1, formData).then(
      (data) => {
        console.log(data);
      },
      (e) => {
        console.error(e);
      }
    );
  }

  loadAffiliates(page = 1, count = 25, filters = null) {
    this.setState({
      loadingAffiliates: true,
    });

    APIV2.getAffiliatesForStore(
      this.props?.match?.params?.storeID,
      page,
      count,
      filters
    )
      .then(
        (data) => {
          if (data && data.data) {
            let affiliates = [];

            if (data.data.affiliates.length) {
              affiliates = data.data.affiliates;
            }

            this.setState({
              affiliates,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the community partners. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingAffiliates: false,
        });
      });
  }

  performSearch() {
    console.log(this.state.search);

    APIV2.simpleSearchAffiliatesForStore(
      this.props?.match?.params?.storeID,
      this.state.search
    )
      .then(
        (data) => {
          this.setState({
            searchResultAffiliates: data.data.affiliates,
            searchResults: true,
          });
        },
        (e) => {
          console.error(e);

          this.setState({ searchResults: true, searchResultAffiliates: [] });
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
    });
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    } else {
      this.setState({
        searchLoading: false,
        searchResults: false,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadAffiliates(page, this.state.count, this.state.filters);
  }

  handleTabChange(option, force = false) {
    let filters = null;

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    if (option.id == "All") {
      filters = null;
    } else if (option.id == "Active") {
      filters = {
        lastContactedOn: {
          $gte: { $date: moment().subtract(90, "days").toISOString() },
        },
      };
    } else if (option.id == "Inactive") {
      filters = {
        $or: [
          {
            lastContactedOn: {
              $lt: { $date: moment().subtract(90, "days").toISOString() },
            },
          },
          {
            lastContactedOn: null,
          },
        ],
      };
    }

    this.setState({
      filters,
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadAffiliates(1, this.state.count, filters);
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Community Partners"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  createOpen: true,
                });
              },
            }}
            tabs={
              <CardSelectorHeader
                compact={true}
                value={this.state.active}
                options={[
                  {
                    name: "All",
                    id: "All",
                  },
                  {
                    name: "Active (Event Within 90 Days)",
                    id: "Active",
                  },
                  {
                    name: "Inactive",
                    id: "Inactive",
                  },
                ]}
                onChange={this.handleTabChange.bind(this)}
              ></CardSelectorHeader>
            }
          />

          <Row>
            <div className="col">
              <Card
                className="border-0 mt--3"
                style={{
                  minHeight: "500px",
                  marginLeft: -15,
                  marginRight: -15,
                }}
              >
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="12" sm="">
                      <Input
                        type="text"
                        placeholder="Search community partners..."
                        size="sm"
                        name="search"
                        value={this.state.search}
                        onChange={this.handleSearchChange.bind(this)}
                      ></Input>
                    </Col>
                  </Row>
                  <Row className="align-items-center mt-3">
                    {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                    <Col xs="" sm="" className="">
                      {this.state.searchResults ? (
                        <>
                          Found{" "}
                          {this.state.searchResultAffiliates?.length
                            ? StringUtils.numberFormat(
                                this.state.searchResultAffiliates?.length
                              )
                            : "0"}{" "}
                          affiliate
                          {this.state.searchResultAffiliates?.length == 1
                            ? ""
                            : "s"}{" "}
                          matching your search.
                        </>
                      ) : (
                        <div>
                          {this.state.result?.start &&
                          this.state.result?.end ? (
                            <>
                              Showing{" "}
                              {StringUtils.numberFormat(
                                this.state.result?.start
                              )}
                              -
                              {StringUtils.numberFormat(this.state.result?.end)}
                              {this.state.result?.total ? (
                                <>
                                  &nbsp;of{" "}
                                  {StringUtils.numberFormat(
                                    this.state.result?.total
                                  )}{" "}
                                  community partners
                                </>
                              ) : null}
                            </>
                          ) : (
                            <>---</>
                          )}
                        </div>
                      )}
                    </Col>
                    <Col
                      xs="auto"
                      sm="auto"
                      className="pl-2 pl-sm-3 text-right"
                    >
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        className="btn-icon-only"
                        outline
                        disabled={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious ||
                          this.state.searchResults
                        }
                        onClick={() => {
                          this.incrementPage(-1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-left"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        disabled={
                          !this.state.pagination?.hasNext ||
                          this.state.searchResults
                        }
                        className="btn-icon-only"
                        outline
                        onClick={() => {
                          this.incrementPage(1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-right"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
                    <Row className="align-items-center">
                      <Col xs="12" sm="12" md="4">
                        <h4 className="mb-0">Partner</h4>
                      </Col>

                      <Col xs="6" sm="6" md="3">
                        <h4 className="mb-0">Type</h4>
                      </Col>
                      <Col xs="6" sm="6" md="5">
                        <h4 className="mb-0">Last Contacted</h4>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    {this.state.loadingAffiliates ||
                    this.state.searchLoading ? (
                      <>
                        <div
                          className="text-center p-3"
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            background: "rgba(255, 255, 255, 0.6)",
                          }}
                        >
                          <Spinner
                            style={{ position: "relative", top: "15vh" }}
                            color="medium"
                            size=""
                          ></Spinner>
                        </div>
                      </>
                    ) : null}
                    {this.state.searchResults ? (
                      <div>
                        {!this.state.searchResultAffiliates?.length &&
                        !this.state.searchLoading ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>
                                No community partners found matching your search
                                "{this.state.search}"
                              </Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.searchResultAffiliates?.map((c, i) => (
                          <div
                            className={`px-3 py-2 bg-superlight--hover cursor-pointer ${
                              i != this.state.searchResultAffiliates.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i}
                            onClick={() => {
                              this.props.history.push(
                                "/storefront/" +
                                  this.props?.match?.params?.storeID +
                                  "/affiliates/details/" +
                                  c._id +
                                  "/summary"
                              );
                            }}
                          >
                            <Row className="align-items-center">
                              <Col
                                xs="12"
                                sm="12"
                                md="4"
                                className="mb-1 mb-md-0"
                              >
                                <h4
                                  className={`mb-0 ${
                                    c.name
                                      ? "text-capitalize"
                                      : "text-lowercase"
                                  }`}
                                >
                                  {c.name}
                                </h4>
                                {c.phone ? (
                                  <>
                                    <p
                                      className="mb-0"
                                      style={{ fontSize: "13px" }}
                                    >
                                      {StringUtils.formatPhoneNumber(
                                        c.phone?.replace("+1", "")
                                      )}
                                    </p>
                                  </>
                                ) : null}
                              </Col>
                              <Col xs="6" sm="6" md="3">
                                {c.type}
                              </Col>
                              <Col xs="6" sm="6" md="5">
                                <p
                                  className={`mb-0 ${
                                    (!c?.lastContactedOn ||
                                      moment(c?.lastContactedOn).diff(
                                        moment(),
                                        "days"
                                      ) >= 90) &&
                                    "text-danger"
                                  }`}
                                >
                                  {c.lastContactedOn
                                    ? `${moment(c?.lastContactedOn).format(
                                        "MM/DD/YYYY"
                                      )} (${moment(
                                        c?.lastContactedOn
                                      ).fromNow()})`
                                    : "(not contacted)"}
                                </p>
                                {c?.totalContactCount ? (
                                  <div className="text-lowercase small">
                                    {c?.totalContactCount} total event
                                    {c?.totalContactCount != 1 ? "s" : ""}
                                  </div>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        {!this.state.affiliates?.length &&
                        !this.state.loadingAffiliates ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>
                                Get started by creating your first affiliate.
                              </Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.affiliates?.map((c, i) => (
                          <div
                            className={`px-3 py-2 bg-superlight--hover cursor-pointer ${
                              i != this.state.affiliates.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i}
                            onClick={() => {
                              this.props.history.push(
                                "/storefront/" +
                                  this.props?.match?.params?.storeID +
                                  "/affiliates/details/" +
                                  c._id +
                                  "/summary"
                              );
                            }}
                          >
                            <Row className="align-items-center">
                              <Col
                                xs="12"
                                sm="12"
                                md="4"
                                className="mb-1 mb-md-0"
                              >
                                <h4
                                  className={`mb-0 ${
                                    c.name
                                      ? "text-capitalize"
                                      : "text-lowercase"
                                  }`}
                                >
                                  {c.name}
                                </h4>
                                {c.phone ? (
                                  <>
                                    <p
                                      className="mb-0"
                                      style={{ fontSize: "13px" }}
                                    >
                                      {StringUtils.formatPhoneNumber(
                                        c.phone?.replace("+1", "")
                                      )}
                                    </p>
                                  </>
                                ) : null}
                              </Col>
                              <Col xs="6" sm="6" md="3">
                                {c.type}
                              </Col>
                              <Col xs="6" sm="6" md="5">
                                <p
                                  className={`mb-0 ${
                                    !c?.lastContactedOn ||
                                    moment(c?.lastContactedOn).diff(
                                      moment(),
                                      "days"
                                    ) >= 90
                                      ? "text-danger"
                                      : "text-dark"
                                  }`}
                                >
                                  {c.lastContactedOn
                                    ? `${moment(c?.lastContactedOn).format(
                                        "MM/DD/YYYY"
                                      )} (${moment(
                                        c?.lastContactedOn
                                      ).fromNow()})`
                                    : "(not contacted)"}
                                </p>
                                {c?.totalContactCount ? (
                                  <div className="text-lowercase small text-dark">
                                    {c?.totalContactCount} total event
                                    {c?.totalContactCount != 1 ? "s" : ""}
                                  </div>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <AffiliateCreateDrawer
          store={{ _id: this?.props?.match?.params?.storeID }}
          open={this.state.createOpen}
          onClose={() => {
            this.setState({
              createOpen: false,
            });
          }}
        ></AffiliateCreateDrawer>
      </>
    );
  }
}

export default StorefrontAffiliateListView;
