import React, { useState } from "react";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
} from "date-fns";
import { Button, Col, Row } from "reactstrap";
import moment from "moment-timezone";
import Constant from "lib/Constant";
import { get } from "underscore";

const OutreachCard = ({ appointment, onClick, nameField }) => {
  const renderTime = (dateString) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const date = moment.utc(dateString).tz(timezone);

    return date.format("h:mm A");
  };

  const getAppointmentType = (typeString) => {
    return Constant.APPOINTMENT_TYPES.hasOwnProperty(typeString)
      ? Constant.APPOINTMENT_TYPES[typeString]
      : null;
  };

  return (
    <div
      onClick={onClick}
      style={{ whiteSpace: "pre-wrap", background: "#CCF4FB" }}
      className={`p-2 m-2 appointment-card border border-info shadow--hover cursor-pointer rounded`}
    >
      <Row className="align-items-center" style={{ lineHeight: 1 }}>
        <Col xs="">
          <p
            className={`text-dark m-0 ${
              appointment?.status == "COMPLETED" ||
              appointment?.status == "NO SHOW"
                ? "text-through"
                : ""
            }`}
            style={{ fontSize: 14, lineHeight: 1 }}
          >
            {renderTime(appointment?.startsAt)}
            {appointment?.endsAt ? (
              <> - {renderTime(appointment?.endsAt)}</>
            ) : null}
          </p>
        </Col>
        {appointment?.status == "COMPLETED" ? (
          <Col xs="auto">
            <i
              className={`mdi mdi-check-circle result-icon`}
              style={{
                fontSize: 16,
                lineHeight: 1,
                position: "relative",
                top: -3,
              }}
            ></i>
          </Col>
        ) : null}
        {appointment?.status == "CONFIRMED" ? (
          <Col xs="auto">
            <i
              className={`mdi mdi-circle-outline result-icon`}
              style={{
                fontSize: 16,
                lineHeight: 1,
                position: "relative",
                top: -3,
              }}
            ></i>
          </Col>
        ) : null}
      </Row>
      <h4
        className={`m-0 text-dark ${
          appointment?.status == "COMPLETED" || appointment?.status == "NO SHOW"
            ? "text-through"
            : ""
        }`}
      >
        {nameField &&
        getAppointmentType(appointment?.appointmentType).hasOwnProperty(
          nameField
        )
          ? getAppointmentType(appointment?.appointmentType)[nameField]
          : appointment?.name}
      </h4>
      <p
        className={`text-dark m-0 ${
          appointment?.status == "COMPLETED" || appointment?.status == "NO SHOW"
            ? "text-through"
            : ""
        }`}
        style={{ fontSize: 14 }}
      >
        {appointment?.affiliate?.name
          ? `${
              appointment?.affiliate?.type
                ? `(${appointment?.affiliate?.type}) `
                : ""
            }${appointment?.affiliate?.name}`
          : "--"}
      </p>
    </div>
  );
};

export default OutreachCard;
