import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  CheckPicker,
  InputNumber,
  Checkbox,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Constant from "lib/Constant";
import Editor from "components/NovelEditor/ui/editor";
import { parse } from "date-fns";
import FileUploadDrawer from "../FileUploadDrawer";
import LinkUtils from "lib/LinkUtils";

class NSOTemplateDrawer extends React.Component {
  state = {
    message: "",
    name: "",
    link: "",
    quantity: 1,
    description: {},
    frequency: "Once",
    estimatedCost: {
      value: null,
    },
    required: true,
    specific: true,
    files: [],
  };

  componentDidUpdate(prevProps) {
    if (this.props.item != prevProps?.item && this.props.item) {
      this.setState({
        name: this.props.item?.name,
        description: this.props.item?.description,
        quantity: this.props.item?.quantity,
        frequency: this.props.item?.frequency
          ? this.props.item?.frequency
          : "Once",
        estimatedCost: {
          value: this.props.item?.estimatedCost / 100,
        },
        link: this.props.item?.link,
        itemLoad: true,
        required: this.props.item?.required,
        specific: this.props.item?.specific,
        purchaseGroup: this.props.item?.purchaseGroup,
        files: this.props.item?.files?.length ? this.props.item?.files : [],
      });

      if (this.props.item?.description && this.props.open) {
        setTimeout(() => {
          PubSub.publish("editor:contentUpdated", {
            event: "contentUpdated",
            data: this.props.item?.description,
            id: "nsoItemTemplateEditor",
            forceHydrate: true,
          });
        }, 0);
      }
    }
  }

  componentDidMount() {
    if (this.props.item) {
      this.setState({
        name: this.props.item?.name,
        description: this.props.item?.description,
        quantity: this.props.item?.quantity,
        frequency: this.props.item?.frequency
          ? this.props.item?.frequency
          : "Once",
        estimatedCost: {
          value: this.props.item?.estimatedCost / 100,
        },
        link: this.props.item?.link,
        itemLoad: true,
        required: this.props.item?.required,
        specific: this.props.item?.specific,
        purchaseGroup: this.props.item?.purchaseGroup,
        files: this.props.item?.files?.length ? this.props.item?.files : [],
        itemLoad: true,
      });
    }
  }

  toggleModal() {
    this.setState({
      name: "",
      quantity: 1,
      frequency: "Once",
      description: {},
      estimatedCost: {
        value: null,
      },
      link: "",
      required: true,
      specific: true,
      files: [],
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  submit() {
    if (this.props.item && !this.props.duplicate) {
      this.modify();
    } else {
      this.create();
    }
  }

  create() {
    this.setState({ submitting: true });

    APIV2.createNSOChecklistItem(this.props.section?.key, {
      name: this.state.name,
      description: this.state.description,
      quantity: this.state.quantity,
      estimatedCost: Math.round(
        parseFloat(
          this.state.estimatedCost?.value ? this.state.estimatedCost?.value : 0
        ) * 100
      ),
      required: this.state.required,
      specific: this.state.specific,
      link: this.state.link,
      purchaseGroup: this.state.purchaseGroup,
      files: this.state.files,
      frequency: this.state.frequency,
    })
      .then(
        (data) => {
          const template = data.data.template;

          this.toggleModal();

          PubSub.publish(Event.NSO_TEMPLATE.MODIFIED, template);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create item - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  modify() {
    this.setState({ submitting: true });

    APIV2.modifyNSOChecklistItem(
      this.props.section?.key,
      this.props.item?._id,
      {
        name: this.state.name,
        description: this.state.description,
        quantity: this.state.quantity,
        estimatedCost: Math.round(
          parseFloat(
            this.state.estimatedCost?.value
              ? this.state.estimatedCost?.value
              : 0
          ) * 100
        ),
        required: this.state.required,
        specific: this.state.specific,
        link: this.state.link,
        purchaseGroup: this.state.purchaseGroup,
        files: this.state.files,
        frequency: this.state.frequency,
      }
    )
      .then(
        (data) => {
          const template = data.data.template;

          this.toggleModal();

          PubSub.publish(Event.NSO_TEMPLATE.MODIFIED, template);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify item - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  removeFile(attachment) {
    swal({
      title: "Remove File",
      text: `Are you sure you want to remove the "${attachment?.fileName}" file?`,
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      let files = this.state?.files?.length ? this.state?.files : [];

      files = _.filter(files, (at) => {
        return (
          at?.fileName != attachment?.fileName && at?.url != attachment?.url
        );
      });

      this.setState({
        files,
      });
    });
  }

  async openFile(attachment) {
    LinkUtils.openPrivateURL(attachment?.url);

    return;
  }

  setFiles(files) {
    let f = this.state?.files?.length ? this.state.files : [];

    f = f.concat(files);

    this.setState({
      files: f,
    });

    return f;
  }

  render() {
    return (
      <>
        <Drawer
          size="md"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.item && !this.props.duplicate
                ? `Modify ${this.props.section?.name} Item`
                : null}

              {!this.props.item ? `New ${this.props.section?.name} Item` : null}
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  !this.state.name ||
                  this.state.quantity <= 0 ||
                  !this.state.estimatedCost?.value ||
                  !this.state.purchaseGroup ||
                  !this.state.frequency
                }
                color="primary"
                onClick={this.submit.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>{this.props.item ? "Save" : "Add"}</>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            <FormGroup>
              <h5>
                Purchase Group{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <SelectPicker
                block
                placement="auto"
                preventOverflow={true}
                searchable={true}
                data={[
                  {
                    label: "Priority List",
                    value: "Priority List",
                  },
                  {
                    label: "Exterior",
                    value: "Exterior",
                  },
                  {
                    label: "Retail Area",
                    value: "Retail Area",
                  },
                  {
                    label: "Retail Floor",
                    value: "Retail Floor",
                  },
                  {
                    label: "Shake Bar",
                    value: "Shake Bar",
                  },
                  {
                    label: "Freezer Area",
                    value: "Freezer Area",
                  },
                  {
                    label: "Bathroom",
                    value: "Bathroom",
                  },
                  {
                    label: "Admin Area",
                    value: "Admin Area",
                  },
                  {
                    label: "Mop Closet",
                    value: "Mop Closet",
                  },
                  {
                    label: "Miscellaneous",
                    value: "Miscellaneous",
                  },
                ]}
                value={this.state.purchaseGroup}
                onChange={(v) => {
                  this.setState({
                    purchaseGroup: v,
                  });
                }}
              ></SelectPicker>
            </FormGroup>
            <FormGroup>
              <h5>
                Item Name{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="text"
                name="name"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Purchase Link</h5>
              <Input
                bsSize="sm"
                type="text"
                name="link"
                placeholder="URL"
                value={this.state.link}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>
                Estimated Unit Price{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <NumberFormat
                className="form-control form-control-sm"
                fixedDecimalScale={true}
                decimalScale={2}
                required={true}
                placeholder="$0.00"
                value={this.state.estimatedCost.value}
                onValueChange={(e) => {
                  this.setState({ estimatedCost: e });
                }}
                thousandSeparator={true}
                prefix={"$"}
              />
            </FormGroup>
            <FormGroup>
              <h5>
                Quantity{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>

              <InputNumber
                value={this.state.quantity}
                onChange={(v) => {
                  this.setState({
                    quantity: v,
                  });
                }}
              ></InputNumber>
            </FormGroup>
            <FormGroup>
              <h5>
                Purchase Frequency{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <SelectPicker
                block
                placement="auto"
                preventOverflow={true}
                searchable={true}
                data={[
                  {
                    label: "Once",
                    value: "Once",
                  },
                  {
                    label: "Weekly",
                    value: "Weekly",
                  },
                  {
                    label: "Bi-Weekly",
                    value: "Bi-Weekly",
                  },
                  {
                    label: "Monthly",
                    value: "Monthly",
                  },
                  {
                    label: "Quarterly",
                    value: "Quarterly",
                  },
                  {
                    label: "Yearly",
                    value: "Yearly",
                  },
                  {
                    label: "Once Per Menu Release",
                    value: "Once Per Menu Release",
                  },
                ]}
                value={this.state.frequency}
                onChange={(v) => {
                  this.setState({
                    frequency: v,
                  });
                }}
              ></SelectPicker>
            </FormGroup>
            <FormGroup>
              <h5>Required Purchase</h5>
              <div className={"border rounded"}>
                <Checkbox
                  checked={this.state.required}
                  onChange={(e, v) => {
                    this.setState({
                      required: v,
                    });
                  }}
                >
                  Required
                </Checkbox>
              </div>
            </FormGroup>
            <FormGroup>
              <h5>Specific</h5>
              <p className="small text-muted" style={{ lineHeight: 1.2 }}>
                The item must be purchased exactly as specified and from the
                purchase link. It cannot be replaced with an alternative or
                generic.
              </p>
              <div className={"border rounded"}>
                <Checkbox
                  checked={this.state.specific}
                  onChange={(e, v) => {
                    this.setState({
                      specific: v,
                    });
                  }}
                >
                  Specific Item
                </Checkbox>
              </div>
            </FormGroup>
            <FormGroup>
              <Row className="align-items-center mb-2">
                <Col xs="">
                  <h5 className="m-0">Files:</h5>
                </Col>
                <Col xs="auto">
                  <Button
                    onClick={() => {
                      this.setState({
                        showFile: true,
                      });
                    }}
                    size="sm"
                    outline
                    color="primary"
                  >
                    Upload
                  </Button>
                </Col>
              </Row>
              <div className="p-2 border rounded">
                {this.state?.files?.length > 0 ? (
                  <>
                    {this.state.files?.map((at, i) => (
                      <div className={`mx-2 mb-2`} key={i}>
                        <Row className="align-items-center">
                          <Col xs="12" sm="">
                            <p className="small m-0 text-dark text-truncate">
                              {at?.fileName}
                            </p>
                          </Col>
                          <Col
                            xs="12"
                            sm="auto"
                            className="mt-3 mt-sm-0 text-right"
                          >
                            <Button
                              className="btn-icon-only"
                              size="sm"
                              outline
                              color="secondary"
                              onClick={() => {
                                this.openFile(at);
                              }}
                            >
                              <i className="mdi mdi-eye"></i>
                            </Button>
                            <Button
                              className="btn-icon-only"
                              size="sm"
                              outline
                              color="danger"
                              onClick={() => {
                                this.removeFile(at);
                              }}
                            >
                              <i className="mdi mdi-close"></i>
                            </Button>
                          </Col>
                        </Row>
                        {i != this.state.files.length - 1 && (
                          <hr className="my-2" />
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <p
                    className="small m-0"
                    style={{ lineHeight: 1.2, fontSize: 15 }}
                  >
                    No files added
                  </p>
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <h5>Description</h5>
              <div className="rounded border p-3">
                <Editor
                  editable={true}
                  allowForceHydration={this.props.item && this.state.itemLoad}
                  id={"nsoItemTemplateEditor"}
                  padding="p-0"
                  autoSave={false}
                  debounceRate={750}
                  value={this.state?.description}
                  onChange={(value) => {
                    if (value?.clear) {
                      this.setState({
                        itemLoad: false,
                      });

                      return;
                    }

                    this.setState({
                      description: value,
                    });
                  }}
                  allowTaskLists={false}
                ></Editor>
              </div>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
        <FileUploadDrawer
          compressUploads={true}
          size="sm"
          onComplete={(files) => {
            console.log(files);

            this.setState({
              showFile: false,
            });

            this.setFiles(files);
          }}
          open={this.state.showFile}
          onClose={() => {
            this.setState({
              showFile: false,
            });
          }}
        ></FileUploadDrawer>
      </>
    );
  }
}

export default withRouter(NSOTemplateDrawer);
