import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker, Tooltip, Whisper } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import LeadCreateDrawer from "components/Drawers/LeadCreateDrawer";

class StorefrontLeadListView extends React.Component {
  state = {
    active: {
      id: "Hot (1-7 Days)",
      name: "Hot (1-7 Days)",
    },
    tabs: [
      {
        name: "Hot (1-7 Days)",
        id: "Hot (1-7 Days)",
      },
      {
        name: "Warm (8-21 Days)",
        id: "Warm (8-21 Days)",
      },
      {
        name: "Not Contacted",
        id: "Not Contacted",
      },
      {
        name: "Attempted",
        id: "Attempted",
      },
      {
        name: "Inactive",
        id: "Inactive",
      },
      {
        name: "Converted",
        id: "Converted",
      },
      {
        name: "All Leads",
        id: "All Leads",
      },
    ],
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 15,
    filters: null,
    sortByOption: "createdAt",
    sortByDirection: "descending",
  };

  componentDidUpdate(prevProps) {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }

    if (
      this.props.match?.params?.storeID != prevProps?.match?.params?.storeID &&
      this.props.match?.params?.storeID &&
      this.props.match?.params?.storeID != "select"
    ) {
      this.loadTabCounts(true);
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }

    //this.loadStores();
    this.loadTabCounts(true);

    PubSub.subscribe(Event.LEAD.DELETED, (data) => {
      this.handleTabChange(this.state.active, true);
    });

    PubSub.subscribe(Event.LEAD.CREATED, (m) => {
      this.handleTabChange(this.state.active, true);
    });

    PubSub.subscribe(Event.LEAD.MODIFIED, (a) => {
      this.handleTabChange(this.state.active, true);
    });
  }

  onFileChange(e) {
    this.setState({ selectedFile: e.target.files[0] });
  }

  testImageUpload(e) {
    e.preventDefault();

    // Create an object of formData
    let formData = new FormData();

    formData.append("file", this.state.selectedFile);

    console.log(formData);

    API.setMealImage(1, formData).then(
      (data) => {
        console.log(data);
      },
      (e) => {
        console.error(e);
      }
    );
  }

  loadLeads(page = 1, count = 15, filters = null, orderBy = null) {
    this.setState({
      loadingLeads: true,
    });

    APIV2.getLeadsForStore(
      this.props?.match?.params?.storeID,
      page,
      count,
      filters,
      orderBy
    )
      .then(
        (data) => {
          if (data && data.data) {
            let leads = [];

            if (data.data.leads.length) {
              leads = data.data.leads;
            }

            console.log(leads);

            this.setState({
              leads,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the leads. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingLeads: false,
        });

        this.loadTabCounts();
      });
  }

  performSearch() {
    console.log(this.state.search);

    APIV2.simpleSearchLeadsForStore(
      this.props?.match?.params?.storeID,
      this.state.search
    )
      .then(
        (data) => {
          this.setState({
            searchResultLeads: data.data.leads,
            searchResults: true,
          });
        },
        (e) => {
          console.error(e);

          this.setState({ searchResults: true, searchResultLeads: [] });
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
    });
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    } else {
      this.setState({
        searchLoading: false,
        searchResults: false,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  loadStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStores()
      .then(
        (data) => {
          if (data && data.data) {
            this.setState({ stores: data.data.stores });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the stores. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadLeads(
      page,
      this.state.count,
      this.state.filters,
      this.state.orderBy
    );
  }

  getFilters(option) {
    let filters = null;

    let orderBy = this.state.orderBy
      ? this.state.orderBy
      : {
          createdAt: -1,
        };

    if (option.id == "All Leads") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
      };
    } else if (option.id == "Hot (1-7 Days)") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        status: { $in: ["Not Contacted", "Attempted", "", null] },
        phone: { $nin: ["", null] },
        email: { $nin: ["", null] },
        createdAt: {
          $gte: {
            $date: moment()
              .subtract(7, "days")
              .hours(0)
              .minutes(0)
              .seconds(0)
              .millisecond(0)
              .toDate()
              .toISOString(),
          },
          $lte: {
            $date: moment()
              .hours(23)
              .minutes(59)
              .seconds(59)
              .millisecond(999)
              .toDate()
              .toISOString(),
          },
        },
      };
    } else if (option.id == "Warm (8-21 Days)") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        status: { $in: ["Not Contacted", "Attempted", "", null] },
        email: { $nin: ["", null] },
        phone: { $nin: ["", null] },
        createdAt: {
          $gte: {
            $date: moment()
              .subtract(28, "days")
              .hours(0)
              .minutes(0)
              .seconds(0)
              .millisecond(0)
              .toDate()
              .toISOString(),
          },
          $lte: {
            $date: moment()
              .subtract(8, "days")
              .hours(23)
              .minutes(59)
              .seconds(59)
              .millisecond(999)
              .toDate()
              .toISOString(),
          },
        },
      };
    } else if (option.id == "Converted") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        status: "closedwon",
      };
    } else if (option.id == "Attempted") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        status: "Attempted",
        name: { $nin: ["", null] },
        phone: { $nin: ["", null] },
      };
    } else if (option.id == "Not Contacted") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        status: "Not Contacted",
        phone: { $nin: ["", null] },
      };
    } else if (option.id == "Inactive") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        status: "Inactive",
        name: { $nin: ["", null] },
        phone: { $nin: ["", null] },
      };
    }

    return { filters, orderBy };
  }

  async loadTabCounts(force = false) {
    if (this.state.tabsLoading) {
      return;
    }

    this.setState(
      {
        tabsLoading: true,
      },
      () => {
        let out = this.state.tabs?.map((tab) => {
          if (force || tab?.countLoading === undefined) {
            tab.count = 0;
            tab.countLoading = true;
          }

          return _.clone(tab);
        });

        this.setState({ tabs: out }, async () => {
          let tabs = this.state.tabs?.map((t) => {
            const { filters } = this.getFilters(t);

            return {
              id: t.id,
              collection: "CustomerLeads",
              query: filters,
            };
          });

          try {
            let data = await APIV2.getTabCounts(tabs);

            let out = this.state.tabs?.map((tab) => {
              const res = _.findWhere(data.data?.tabs, { id: tab.id });

              tab.count = res?.count ? res.count : 0;
              tab.countLoading = false;

              return tab;
            });

            this.setState({
              tabs: out,
            });
          } catch (e) {
            console.error(e);
          }

          this.setState({
            tabsLoading: false,
          });
        });
      }
    );
  }

  handleTabChange(option, force = false) {
    const { filters, orderBy } = this.getFilters(option);

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    this.setState({
      filters,
      active: option,
      orderBy,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadLeads(1, this.state.count, filters, orderBy);
  }

  formatStatus(c) {
    if (c?.status == "Not Contacted") {
      return (
        <Badge color="" className="bg-yellow text-dark">
          {c?.status}
        </Badge>
      );
    }

    if (c?.status == "Attempted") {
      return <Badge color="info">{c?.status}</Badge>;
    }

    if (c?.status == "closedwon") {
      return <Badge color="success">Converted</Badge>;
    }

    if (c?.status == "Inactive") {
      return <Badge color="light">{c?.status}</Badge>;
    }

    return "--";
  }

  row(c, i) {
    return (
      <tr
        className={`px-3 py-2 bg-superlight--hover cursor-pointer`}
        key={i}
        onClick={() => {
          this.props.history.push(
            "/storefront/" +
              this.props?.match?.params?.storeID +
              "/leads/details/" +
              c._id +
              "/summary"
          );
        }}
      >
        <td>
          <h4
            className={`mb-0 ${c.name ? "text-capitalize" : "text-lowercase"}`}
          >
            {c.name ? c.name : c.email}
            {c?.email?.includes("@projectleannation.") ||
            c?.email?.includes("@pln.") ||
            c?.email?.includes("@test.") ? (
              <>
                <Badge
                  color="warning"
                  className="ml-1"
                  style={{
                    position: "relative",
                    top: -1,
                  }}
                >
                  TEST LEAD
                </Badge>
              </>
            ) : null}
          </h4>
          {c.name ? (
            <>
              <p className="mb-0" style={{ fontSize: "13px" }}>
                {c.email}
              </p>
            </>
          ) : (
            <>
              {c.phone ? (
                <>
                  <p className="mb-0" style={{ fontSize: "13px" }}>
                    {StringUtils.formatPhoneNumber(c.phone?.replace("+1", ""))}
                  </p>
                </>
              ) : null}
            </>
          )}
        </td>
        <td style={{ fontSize: 18 }}>{this.formatStatus(c)}</td>

        <td className="text-dark" style={{ fontSize: 16 }}>
          {c.initialSource}
        </td>
        <td className="text-dark" style={{ fontSize: 16 }}>
          {moment(c?.createdAt).format("ddd MMM DD, YYYY hh:mm A")}
        </td>
      </tr>
    );
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Leads"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  createOpen: true,
                });
              },
            }}
            tabs={
              <CardSelectorHeader
                showCount={true}
                compact={true}
                value={this.state.active}
                options={this.state.tabs}
                onChange={this.handleTabChange.bind(this)}
              ></CardSelectorHeader>
            }
          />

          <Row>
            <div className="col">
              <Card
                className="border-0"
                style={{
                  minHeight: "500px",
                  marginLeft: -15,
                  marginRight: -15,
                }}
              >
                <CardHeader className="p-3 mt--3">
                  <Row className="align-items-center">
                    <Col xs="12" sm="">
                      <Input
                        type="text"
                        placeholder="Search leads..."
                        size="sm"
                        name="search"
                        value={this.state.search}
                        onChange={this.handleSearchChange.bind(this)}
                      ></Input>
                    </Col>
                    {/*
                    <Col xs="6" sm="auto" className="mt-3 pr-0 mt-sm-0">
                      <SelectPicker
                        searchable={false}
                        size="sm"
                        placeholder="Sort By"
                        disabled={true}
                        data={[]}
                        block
                        placement="autoVerticalEnd"
                      />
                    </Col>
                    <Col xs="6" sm="auto" className="mt-3 mt-sm-0">
                      <Button
                        color="secondary"
                        size="sm"
                        block
                        outline
                        onClick={() => {
                          PubSub.publish(Event.FILTERS.CUSTOMERS_OPEN);
                        }}
                        disabled
                      >
                        Filter
                      </Button>
                    </Col> */}
                  </Row>
                  <Row className="align-items-center mt-3">
                    {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                    <Col xs="" sm="" className="">
                      {this.state.searchResults ? (
                        <div className="text-dark">
                          Found{" "}
                          {this.state.searchResultLeads?.length
                            ? StringUtils.numberFormat(
                                this.state.searchResultLeads?.length
                              )
                            : "0"}{" "}
                          lead
                          {this.state.searchResultLeads?.length == 1
                            ? ""
                            : "s"}{" "}
                          matching your search.
                        </div>
                      ) : (
                        <div className="text-dark">
                          {this.state.result?.start &&
                          this.state.result?.end ? (
                            <>
                              Showing{" "}
                              {StringUtils.numberFormat(
                                this.state.result?.start
                              )}
                              -
                              {StringUtils.numberFormat(this.state.result?.end)}
                              {this.state.result?.total ? (
                                <>
                                  &nbsp;of{" "}
                                  {StringUtils.numberFormat(
                                    this.state.result?.total
                                  )}{" "}
                                  leads
                                </>
                              ) : null}
                            </>
                          ) : (
                            <>---</>
                          )}
                        </div>
                      )}
                    </Col>
                    <Col
                      xs="auto"
                      sm="auto"
                      className="pl-2 pl-sm-3 text-right"
                    >
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious ||
                          this.state.searchResults ||
                          this.state.loadingLeads
                            ? "light"
                            : "secondary"
                        }
                        className="btn-icon-only"
                        outline
                        disabled={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious ||
                          this.state.searchResults ||
                          this.state.loadingLeads
                        }
                        onClick={() => {
                          this.incrementPage(-1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-left"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color={
                          !this.state.pagination?.hasNext ||
                          this.state.searchResults ||
                          this.state.loadingLeads
                            ? "light"
                            : "secondary"
                        }
                        disabled={
                          !this.state.pagination?.hasNext ||
                          this.state.searchResults ||
                          this.state.loadingLeads
                        }
                        className="btn-icon-only"
                        outline
                        onClick={() => {
                          this.incrementPage(1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-right"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color={
                          this.state.loadingLeads || this.state.searchLoading
                            ? "light"
                            : "dark"
                        }
                        disabled={
                          this.state.loadingLeads || this.state.searchLoading
                        }
                        className="btn-icon-only"
                        outline
                        onClick={() => {
                          this.loadLeads(
                            this.state.page,
                            this.state.count,
                            this.state.filters,
                            this.state.orderBy
                          );
                        }}
                      >
                        {this.state.loadingLeads || this.state.searchLoading ? (
                          <Spinner size="sm"></Spinner>
                        ) : (
                          <i className="mdi mdi-refresh"></i>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>

                <Table
                  className="align-items-center table-flush table-hover"
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th
                        className="text-dark py-2"
                        scope="col"
                        style={{ minWidth: "180px" }}
                      >
                        <h4 className="m-0 text-capitalize">Name</h4>
                      </th>
                      <th className="text-dark py-2" scope="col">
                        <h4 className="m-0 text-capitalize">Status</h4>
                      </th>
                      <th className="text-dark py-2" scope="col">
                        <h4 className="m-0 text-capitalize">Source</h4>
                      </th>

                      <th
                        className="text-dark py-2 cursor-pointer"
                        scope="col"
                        onClick={() => {
                          let dir = "ascending";

                          if (this.state.sortByOption == "createdAt") {
                            if (this.state.sortByDirection == "descending") {
                              return this.setState(
                                {
                                  sortByOption: "",
                                  sortByDirection: "",
                                  orderBy: null,
                                },
                                () => {
                                  this.loadLeads(
                                    1,
                                    this.state.count,
                                    this.state.filters,
                                    this.state.orderBy
                                  );
                                }
                              );
                            } else if (
                              this.state.sortByDirection == "ascending"
                            ) {
                              dir = "descending";
                            }
                          }

                          this.setState(
                            {
                              sortByOption: "createdAt",
                              sortByDirection: dir,
                              orderBy: {
                                createdAt: dir == "descending" ? -1 : 1,
                              },
                            },
                            () => {
                              this.loadLeads(
                                1,
                                this.state.count,
                                this.state.filters,
                                this.state.orderBy
                              );
                            }
                          );
                        }}
                      >
                        <h4 className="m-0 text-capitalize">
                          Created
                          {this.state.sortByOption == "createdAt" ? (
                            <>
                              <i
                                className={`mdi mdi-sort-${this.state.sortByDirection} pl-1`}
                              ></i>
                            </>
                          ) : (
                            <>
                              <i className="mdi mdi-sort pl-1"></i>
                            </>
                          )}
                        </h4>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.searchLoading || this.state.loadingLeads ? (
                      <>
                        <tr>
                          <td colspan={4}>
                            <div
                              className="skeleton rounded"
                              style={{
                                width: "100%",
                                height: `calc(25px + 13px)`,
                              }}
                            ></div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan={4}>
                            <div
                              className="skeleton rounded"
                              style={{
                                width: "100%",
                                height: `calc(25px + 13px)`,
                              }}
                            ></div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan={4}>
                            <div
                              className="skeleton rounded"
                              style={{
                                width: "100%",
                                height: `calc(25px + 13px)`,
                              }}
                            ></div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan={4}>
                            <div
                              className="skeleton rounded"
                              style={{
                                width: "100%",
                                height: `calc(25px + 13px)`,
                              }}
                            ></div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan={4}>
                            <div
                              className="skeleton rounded"
                              style={{
                                width: "100%",
                                height: `calc(25px + 13px)`,
                              }}
                            ></div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan={4}>
                            <div
                              className="skeleton rounded"
                              style={{
                                width: "100%",
                                height: `calc(25px + 13px)`,
                              }}
                            ></div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan={4}>
                            <div
                              className="skeleton rounded"
                              style={{
                                width: "100%",
                                height: `calc(25px + 13px)`,
                              }}
                            ></div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan={4}>
                            <div
                              className="skeleton rounded"
                              style={{
                                width: "100%",
                                height: `calc(25px + 13px)`,
                              }}
                            ></div>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {this.state.searchResults ? (
                          <>
                            {!this.state.searchResultLeads?.length &&
                            !this.state.searchLoading ? (
                              <tr>
                                <td colSpan={4}>
                                  No leads found matching your search "
                                  {this.state.search}"
                                </td>
                              </tr>
                            ) : null}

                            {this.state.searchResultLeads?.map((c, i) =>
                              this.row(c, i)
                            )}
                          </>
                        ) : (
                          <>
                            {!this.state.leads?.length &&
                            !this.state.loadingLeads ? (
                              <tr colSpan={4} className="px-3 py-2">
                                <td>Get started by adding your first lead.</td>
                              </tr>
                            ) : null}

                            {this.state.leads?.map((c, i) => this.row(c, i))}
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
        <LeadCreateDrawer
          store={{ _id: this?.props?.match?.params?.storeID }}
          open={this.state.createOpen}
          onClose={() => {
            this.setState({
              createOpen: false,
            });
          }}
        ></LeadCreateDrawer>
      </>
    );
  }
}

export default StorefrontLeadListView;
