import autosize from "autosize";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter, useHistory } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import StringUtils from "../../lib/StringUtils";
import Constant from "../../lib/Constant";
import LocalStorage from "../../lib/LocalStorage";
import ConversationMessageItem from "./ConversationMessageItem";
import APIV2 from "../../lib/APIV2";
import _ from "underscore";
import PubSub from "../../lib/PubSub";
import PushEvent from "../../lib/Event";
import { Whisper, Popover, Dropdown, Tooltip } from "rsuite";
import swal from "sweetalert";
import LinkUtils from "../../lib/LinkUtils";
import EmojiPicker from "emoji-picker-react";
import { useMemo } from "react";
import ConversationDetailDrawer from "../Drawers/ConversationDetailDrawer";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import ConversationStoreAssignmentDrawer from "components/Drawers/ConversationStoreAssignmentDrawer";

const ConversationMessagePane = ({
  conversation = {},
  newConversation = false,
  emptyState = false,
  loading = false,
  match,
  onCancel,
  store = null,
  drawerMode = false,
  drawerOpen = false,
}) => {
  const [messages, setMessages] = useState([]);
  const [quickLinks, setQuickLinks] = useState([]);
  const [bottomed, setBottomed] = useState(false);
  const [quickReplySearch, setQuickReplySearch] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [viewNewButton, setViewNewButton] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);
  const [unsubscribing, setUnsubscribing] = useState(false);
  const [unsubContactID, setUnsubContactID] = useState(null);
  const [isBlocked, setIsBlocked] = useState(false);
  const [blockedContactID, setBlockedContactID] = useState(null);
  const [blocking, setBlocking] = useState(false);
  const [noteMode, setNoteMode] = useState(false);
  const [activeConversation, setActiveConversation] = useState(conversation);
  const [activeDrawerOpen, setActiveDrawerOpen] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [scroll, setScroll] = useState(0);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [blockScroll, setBlockScroll] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [messageScrollRef, setMessageScrollRef] = useState(null);
  const bottomRef = useRef();
  const [textareaRef, setTextareaRef] = useState(null);
  const [messageAreaRef, setMessageAreaRef] = useState(null);
  const [searchModified, setSearchModified] = useState(false);
  const [changeStoreDrawer, setChangeStoreDrawer] = useState(false);
  const [markingRead, setMarkingRead] = useState(false);
  const [modifyingStatus, setModifyingStatus] = useState(false);
  const [creating, setCreating] = useState(false);
  const [quickReplyFilter, setQuickReplyFilter] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(false);
  const history = useHistory();
  const [body, setBody] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");
  const [subscribeFunctionNumber, setSubscribeFunctionNumber] = useState("");
  const [subscribeModifyFunctionNumber, setSubscribeModifyFunctionNumber] =
    useState("");
  const [quickReplySearchValue, setQuickReplySearchValue] = useState("");
  const [newConversationSearch, setNewConversationSearch] = useState("");
  const searchRef = useRef();
  const [error, setErrorMessage] = useState("");
  const [quickReplyOpen, setQuickReplyOpen] = useState(false);
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const [pagination, setPagination] = useState(null);
  const [results, setResults] = useState(null);
  const [templates, setTemplates] = useState([]);
  const actionDropdownRef = useRef();
  const quickReplyRef = useRef();
  const savedReplyRef = useRef();
  const emojiRef = useRef();
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const [keyboardResizeMode, setKeyboardResizeMode] = useState("native");
  const [actionsHeight, setActionsHeight] = useState("0");
  const [creatingContact, setCreatingContact] = useState(false);

  const pageCount = 25; // Number of templates per page

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      //console.log("TURN OFF RESIZE");

      Keyboard.setResizeMode({ mode: "none" });

      setKeyboardResizeMode("none");

      Keyboard.addListener("keyboardWillHide", () => {
        setTimeout(() => {
          if (document.activeElement != textareaRef) {
            setKeyboardOpen(false);
          }
        }, 1);
      });

      Keyboard.addListener("keyboardDidHide", () => {
        setTimeout(() => {
          if (quickReplyRef?.current) {
            quickReplyRef.current.updatePosition();
          }

          if (savedReplyRef?.current) {
            savedReplyRef.current.updatePosition();
          }

          if (emojiRef?.current) {
            emojiRef.current.updatePosition();
          }
        }, 0);
      });

      Keyboard.addListener("keyboardWillShow", (info) => {
        setKeyboardOpen(info?.keyboardHeight);
      });

      Keyboard.addListener("keyboardDidShow", (info) => {
        setTimeout(() => {
          if (quickReplyRef?.current) {
            quickReplyRef.current.updatePosition();
          }

          if (savedReplyRef?.current) {
            savedReplyRef.current.updatePosition();
          }

          if (emojiRef?.current) {
            emojiRef.current.updatePosition();
          }
        }, 0);
      });

      return function cleanup() {
        //console.log("TURN ON RESIZE");

        Keyboard.setResizeMode({ mode: "native" });

        setKeyboardResizeMode("native");
      };
    }
  });

  const setError = (message) => {
    if (!message) {
      setErrorMessage("");

      return;
    }

    setErrorMessage(message);

    setTimeout(() => {
      setError("");
    }, 4000);
  };

  const loadTemplates = (storeID, page, count, filters, orderBy) => {
    setLoadingTemplates(true);

    APIV2.getConversationTemplatesForStore(
      storeID,
      page,
      count,
      filters,
      orderBy
    )
      .then((data) => {
        let templates = data.data.templates;

        setTemplates(templates);
        setPagination(data.data.pagination);
        setResults(data.data.result);
      })
      .finally(() => {
        setLoadingTemplates(false);
      });
  };

  const getAttachmentFileName = (url) => {
    return StringUtils.getAttachmentFileName(url);
  };

  /**
   * Fetches any existing suppressions for the conversation.
   *
   * @param {*} storeID
   * @param {*} page
   * @param {*} count
   * @param {*} filters
   * @param {*} orderBy
   */
  const loadSuppressions = (conversation) => {
    let unsubscribe = conversation?.unsubscribeSuppressionID ?? null;
    let block = conversation?.blockSuppressionID ?? null;

    if (unsubscribe) {
      setIsUnsubscribed(true);
      setUnsubContactID(unsubscribe);
    } else {
      setIsUnsubscribed(false);
      setUnsubContactID(null);
    }

    if (block) {
      setIsBlocked(true);
      setBlockedContactID(block);
    } else {
      setIsBlocked(false);
      setBlockedContactID(null);
    }
  };

  /**
   * Unsubscribes the contact from messages
   */
  const unsubscribe = () => {
    swal({
      title: "Unsubscribe Contact",
      text: `Are you sure you want to unsubscribe this contact?`,
      icon: "warning",
      buttons: ["Nevermind", "Unsubscribe"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      setUnsubscribing(true);

      APIV2.bulkCreateContactSuppressions(
        conversation?.storeID,
        "UNSUBSCRIBED",
        [conversation?.recipientPhone]
      )
        .then(
          (data) => {
            setIsUnsubscribed(true);
            setUnsubContactID(data?.data?.contacts[0]?._id);
          },
          (e) => {
            window.alert(
              e?.response?.message ??
                "Unable to unsubscribe contact. Please try again."
            );
          }
        )
        .finally(() => {
          setUnsubscribing(false);
        });
    });
  };

  const updateQuickLinks = (conversation, store) => {
    let links = [
      {
        name: "Website: Store Location Page",
        url: `https://projectleannation.com/locations/${StringUtils.getStoreSlug(
          store
        )}`,
      },
      {
        name: "App Download Link",
        url: "https://pln.fit/download",
      },
    ];

    if (conversation?.customer) {
      links.push({
        name: "Member: Schedule Member Check-In",
        url: `https://account.projectleannation.com/schedule/${store?._id}/ec-consult`,
      });
      links.push({
        name: "Member: Manage Account",
        url: `https://account.projectleannation.com/manage`,
      });
      links.push({
        name: "Member: Reset Password",
        url: `https://account.projectleannation.com/login/forgot/select`,
      });
    } else if (conversation?.lead) {
      links.push({
        name: "Lead: Schedule New Client Consultation",
        url: `https://account.projectleannation.com/schedule/${store?._id}/nc-consult`,
      });
      links.push({
        name: "Lead: Online Checkout",
        url: `https://account.projectleannation.com/start?leadRestore=${conversation?.lead?._id}`,
      });
    }

    if (store?.googleMapsReviewURL) {
      links.push({
        name: "Google Maps: Review URL",
        url: store?.googleMapsReviewURL,
      });
    }

    setQuickLinks(links);
  };

  /**
   * Blocks the contact from sending messages to the store
   */
  const block = () => {
    swal({
      title: "Block Contact",
      text: `Are you sure you want to block this contact?`,
      icon: "warning",
      buttons: ["Nevermind", "Block"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      setBlocking(true);

      APIV2.bulkCreateContactSuppressions(conversation?.storeID, "BLOCKED", [
        conversation?.recipientPhone,
      ])
        .then(
          (data) => {
            setIsBlocked(true);
            setBlockedContactID(data?.data?.contacts[0]?._id);
          },
          (e) => {
            window.alert(
              e?.response?.message ??
                "Unable to block contact. Please try again."
            );
          }
        )
        .finally(() => {
          setBlocking(false);
        });
    });
  };

  /**
   * Unblocks the contact
   */
  const unblock = () => {
    swal({
      title: "Unblock Contact",
      text: `Are you sure you want to unblock this contact?`,
      icon: "warning",
      buttons: ["Nevermind", "Unblock"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      setBlocking(true);

      APIV2.deleteContactSuppressionForStore(
        conversation?.storeID,
        blockedContactID
      )
        .then(
          (data) => {
            setIsBlocked(false);
            setBlockedContactID(null);
          },
          (e) => {
            window.alert(
              e?.response?.message ??
                "Unable to unblock contact. Please try again."
            );
          }
        )
        .finally(() => {
          setBlocking(false);
        });
    });
  };

  /**
   * Gets the conversation's name
   *
   * @param {*} conversation
   * @returns
   */
  const getConversationName = (conversation) => {
    if (emptyState) {
      return "Select A Conversation";
    }

    if (!conversation) {
      return "(unknown sender)";
    }

    if (conversation?.customer?.name) {
      return conversation?.customer?.name;
    }

    if (conversation?.lead?.name) {
      return conversation?.lead?.name;
    }

    if (conversation?.recipientPhone) {
      return StringUtils.formatPhoneNumber(
        conversation?.recipientPhone?.replace("+1", "")
      );
    }

    if (conversation?.recipientName) {
      return conversation?.recipientName;
    }

    return "(unknown sender)";
  };

  /**
   * Gets the conversation description
   *
   * @param {*} conversation
   * @returns
   */
  const getConversationDescription = (conversation) => {
    if (conversation?.customer) {
      return "Member";
    }

    if (conversation?.lead) {
      return "Lead";
    }
  };

  const scrollToBottom = () => {
    //console.log("SCROLL TO BOTTOM");

    try {
      document.getElementsByClassName(
        "conversation-content-messages"
      )[0].scrollTop = document.getElementsByClassName(
        "conversation-content-messages"
      )[0].scrollHeight;

      setScroll(
        document.getElementsByClassName("conversation-content-messages")[0]
          .scrollHeight
      );
    } catch (e) {}
  };

  const setScrollPosition = (scrollPos) => {
    try {
      document.getElementsByClassName(
        "conversation-content-messages"
      )[0].scrollTop = scrollPos;

      setScroll(scrollPos);
    } catch (e) {}
  };

  /**
   * Fetches messages
   *
   * @param {*} page
   * @param {*} count
   * @param {*} replace
   */
  const getMessages = (page, count, replace = true) => {
    if (!page) {
      return;
    }

    setBlockScroll(true);

    if (replace) {
      setLoadingMessages(true);
    }

    let filters = {
      conversationID: conversation?._id?.toString(),
    };

    let orderBy = {
      messageTimestamp: -1,
    };

    APIV2.getConversationMessages(page, count, filters, orderBy)
      .then((data) => {
        let msg = data.data.messages;

        msg = _.sortBy(msg, "messageTimestamp");

        if (!replace) {
          msg = messages?.length ? msg.concat(messages) : msg;
        }

        setMessages(msg);

        if (replace) {
          setTimeout(() => {
            if (
              document.getElementsByClassName("conversation-content-messages")
                ?.length
            ) {
              scrollToBottom();

              setTimeout(() => {
                setHasMore(data?.data?.pagination?.hasNext);
                setNextPage(data?.data?.pagination?.next);
                setBottomed(true);

                scrollToBottom();
                setBlockScroll(false);
              }, 500);
            }
          }, 0);
        } else {
          setHasMore(data?.data?.pagination?.hasNext);
          setNextPage(data?.data?.pagination?.next);
          setBlockScroll(false);
        }
      })
      .finally(() => {
        setLoadingMessages(false);
      });
  };

  const sendMessage = () => {
    setSubmitting(true);

    APIV2.sendConversationMessage(conversation?._id, body, mediaUrl)
      .then(
        (data) => {
          let conversation = data?.data?.conversation;
          let message = data?.data?.message;

          PubSub.publish(PushEvent.CONVERSATION.MODIFIED, conversation);

          let allMessages = messages?.length ? messages : [];

          allMessages.push(message);

          setMessages(allMessages);

          setTimeout(() => {
            if (
              document.getElementsByClassName("conversation-content-messages")
                ?.length
            ) {
              document.getElementsByClassName(
                "conversation-content-messages"
              )[0].scrollTop = document.getElementsByClassName(
                "conversation-content-messages"
              )[0].scrollHeight;
            }
          }, 0);

          setBody("");
          setMediaUrl("");
        },
        (e) => {}
      )
      .finally(() => {
        setSubmitting(false);
      });
  };

  const createNote = () => {};

  const submitForm = () => {
    if (!body) {
      return;
    }

    if (noteMode) {
      createNote();
    } else {
      sendMessage();
    }
  };

  const refocusTextArea = () => {
    if (Capacitor.isNativePlatform()) {
    }

    textareaRef.focus();

    if (quickReplyRef?.current) {
      quickReplyRef.current.updatePosition();
    }

    if (savedReplyRef?.current) {
      savedReplyRef.current.updatePosition();
    }

    if (emojiRef?.current) {
      emojiRef.current.updatePosition();
    }

    setTimeout(() => {
      textareaRef.focus();
    }, 0);
  };

  /**
   * Loads a page of message results.
   *
   * @param {*} page
   * @returns
   */
  const loadMessagePage = () => {
    let now = new Date();

    if (blockScroll || !bottomed) {
      //console.log("BLOCKED", blockScroll);

      return;
    }

    //console.log("UNBLOCKED");

    if (nextPage == 2 && scroll == messageScrollRef?.scrollHeight) {
      return;
    }

    getMessages(nextPage, 15, false);
  };

  /**
   * Perform search to find matching existing conversations
   */
  const performSearch = (query) => {
    let numbers = query.replace(/[^\d]/g, ""); //This strips all characters that aren't digits

    let orQuery = [
      { recipientName: { $regex: query, $options: "i" } },
      { leadID: { $regex: query, $options: "i" } },
      {
        customerID: { $regex: query, $options: "i" },
      },
    ];

    if (numbers) {
      orQuery.push({ recipientPhone: { $regex: numbers, $options: "i" } });
    }

    APIV2.simpleSearchConversations(1, 40, store?._id, query)
      .then(
        (data) => {
          let conversations = data.data.conversations;

          //console.log(conversations);

          if (conversations?.length) {
            const members = _.filter(conversations, (c) => {
              return c?.customerID;
            });

            const leads = _.filter(conversations, (c) => {
              return !c?.customerID;
            });

            setSearchResults({
              members,
              leads,
            });
          } else {
            setSearchResults({
              members: [],
              leads: [],
            });
          }
        },
        (e) => {
          console.error(e);

          setSearchResults({
            members: [],
            leads: [],
          });
        }
      )
      .finally(() => {
        setSearchLoading(false);
      });
  };

  /**
   * Marks the existing conversation as read
   */
  const markAsRead = () => {
    setMarkingRead(true);

    APIV2.markConversationAsRead(conversation?._id)
      .then(
        (data) => {},
        (e) => {}
      )
      .finally(() => {
        setMarkingRead(false);
      });
  };

  /**
   * Marks the existing conversation as read
   */
  const modifyStatus = () => {
    setModifyingStatus(true);

    const status = conversation?.status == "OPEN" ? "CLOSED" : "OPEN";

    APIV2.modifyConversationStatus(conversation?._id, status)
      .then(
        (data) => {},
        (e) => {}
      )
      .finally(() => {
        setModifyingStatus(false);
      });
  };

  /**
   * Marks the existing conversation as unread
   */
  const markAsUnread = () => {
    setMarkingRead(true);

    APIV2.markConversationAsUnread(conversation?._id)
      .then(
        (data) => {},
        (e) => {}
      )
      .finally(() => {
        setMarkingRead(false);
      });
  };

  /**
   * Creates a new conversation
   *
   * @param {*} phone
   * @returns
   */
  const createConversation = (phone, customerID = null, leadID = null) => {
    if (!StringUtils.isValidPhoneNumber(phone)) {
      return;
    }

    // TODO: strip phone to just numbers

    let numbers = phone.replace(/[^\d]/g, ""); //This strips all characters that aren't digits
    if (numbers.length != 10) {
      return;
    }

    let parsedPhone = `+1${numbers}`;

    let payload = {
      unreadCount: 0,
      latestMessageID: null,
      latestMessageTimestamp: new Date(),
      customerID: customerID ? customerID : null,
      leadID: leadID ? leadID : null,
      storeID: store?._id?.toString(),
      status: "OPEN",
      recipientName: parsedPhone,
      recipientPhone: parsedPhone,
    };

    setCreating(true);
    setCreatingContact("+1" + phone);

    APIV2.createConversation(payload)
      .then(
        (data) => {
          history.push(
            `/storefront/${store?._id}/conversations/${data?.data?.conversation?._id}${window?.location?.search}`
          );
        },
        (e) => {
          setError(
            e?.response?.body?.message ??
              "Unable to create new conversation - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        setCreating(false);
        setCreatingContact(false);
      });
  };

  /**
   * Handle event changes for new conversations
   *
   * @param {*} e
   */
  const handleSearchChange = (e) => {
    const value = e?.target?.value;

    setNewConversationSearch(e?.target?.value);

    setSearchModified(true);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (e?.target?.value) {
      setSearchLoading(true);
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        performSearch(value);
      }, 1200);

      setSearchTimeout(timeout);
    }
  };

  const isGlobal = () => {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  };

  /**
   * Handle event changes for new conversations
   *
   * @param {*} e
   */
  const handleQuickReplySearchChange = (e) => {
    const value = e?.target?.value;

    setQuickReplySearchValue(e?.target?.value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (e?.target?.value) {
      setLoadingTemplates(true);
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        let filter = {
          $or: [
            { name: { $regex: value, $options: "i" } },
            { message: { $regex: value, $options: "i" } },
          ],
        };

        setQuickReplyFilter(filter);

        loadTemplates(conversation?.storeID, 1, pageCount, filter, {
          name: -1,
        });
      }, 1200);

      setSearchTimeout(timeout);
    } else {
      let timeout = setTimeout(() => {
        loadTemplates(conversation?.storeID, 1, pageCount, null, {
          name: -1,
        });
      }, 1200);

      setSearchTimeout(timeout);
    }
  };

  useEffect(() => {
    autosize(textareaRef);
  }, [textareaRef]);

  useEffect(() => {
    autosize(messageAreaRef);
  }, [messageAreaRef]);

  useEffect(() => {
    if (
      !conversation ||
      (loading && conversation?._id != activeConversation?._id)
    ) {
      setMessages([]);
      setNextPage(1);
    }

    if (conversation?.status == "CLOSED") {
      setIsClosed(true);
    } else {
      setIsClosed(false);
    }

    if (
      !loading &&
      conversation?._id &&
      (conversation?._id != activeConversation?._id ||
        (drawerMode && drawerOpen != activeDrawerOpen))
    ) {
      if (conversation?.storeID) {
        loadTemplates(conversation?.storeID, 1, pageCount, null, { name: -1 });
      }

      loadSuppressions(conversation);

      setMessages([]);
      setNextPage(1);
      setHasMore(false);
      setBottomed(false);
      setBlockScroll(true);
      getMessages(1, 15, true);
      setActiveConversation(conversation);
      setActiveDrawerOpen(drawerOpen);
      setBody("");
    }

    if (conversation?._id && conversation?._id != "new" && !loading) {
      //console.log("HERE", conversation?._id, activeConversation?._id);

      loadSuppressions(conversation);
    }
  }, [conversation, loading, drawerOpen]);

  useEffect(() => {
    if (store && conversation) {
      updateQuickLinks(conversation, store);
    }
  }, [store, conversation]);

  const onMessageWebsocket = useCallback(
    (data) => {
      if (
        data &&
        conversation &&
        data?.conversationID == conversation?._id &&
        data?.storeID == conversation?.storeID &&
        data?.storeID == store?._id
      ) {
        let allMessages = messages?.length ? messages : [];

        if (!_.findWhere(allMessages, { _id: data?._id })) {
          allMessages.push(data);

          setBottomed(false);
          setViewNewButton(true);

          let scrollPos = 0;

          try {
            if (
              document.getElementsByClassName("conversation-content-messages")
                ?.length
            ) {
              scrollPos = document.getElementsByClassName(
                "conversation-content-messages"
              )[0].scrollTop;
            }
          } catch (e) {}

          setMessages(allMessages);

          setScrollPosition(scrollPos);

          setTimeout(() => {
            setBottomed(true);
          }, 5);
        }
      }
    },
    [conversation, messages]
  );

  const onMessageModifyWebsocket = useCallback(
    (data) => {
      if (
        data &&
        conversation &&
        data?.conversationID == conversation?._id &&
        data?.storeID &&
        data?.storeID == store?._id
      ) {
        let allMessages = messages?.length ? messages : [];

        const idx = _.findIndex(allMessages, { _id: data?._id });

        if (idx >= 0) {
          allMessages[idx] = data;
        }
      }
    },
    [conversation, messages]
  );

  useEffect(() => {
    if (!conversation?._id) {
      return;
    }

    if (subscribeFunctionNumber) {
      PubSub.removeSubscriber(
        `ws_conversation-messages:received`,
        subscribeFunctionNumber
      );
    }

    let num = PubSub.subscribe(`ws_conversation-messages:received`, (data) => {
      onMessageWebsocket(data);
      loadSuppressions(conversation);
    });

    setSubscribeFunctionNumber(num);

    if (subscribeModifyFunctionNumber) {
      PubSub.removeSubscriber(
        `ws_conversation-messages:modified`,
        subscribeModifyFunctionNumber
      );
    }

    let num2 = PubSub.subscribe(`ws_conversation-messages:modified`, (data) => {
      onMessageModifyWebsocket(data);
    });

    setSubscribeModifyFunctionNumber(num2);
  }, [conversation, messages]);

  useEffect(() => {
    PubSub.subscribe(PushEvent.CONVERSATION.MESSAGE_MODIFIED, (message) => {
      let allMessages = messages;

      const idx = _.findIndex(allMessages, { _id: message?._id });

      if (idx >= 0) {
        allMessages[idx] = message;

        setMessages(allMessages);
      }
    });
  }, [messages]);

  useEffect(() => {
    setNewConversationSearch("");
    setSearchResults(null);
  }, [newConversation]);

  useEffect(() => {
    try {
      if (
        document.getElementsByClassName("conversation-content-messages")?.length
      ) {
        let element = document.getElementsByClassName(
          "conversation-content-messages"
        )[0];
        let scrollHeight = element.scrollHeight;

        let outerHeight = element.offsetHeight;
        outerHeight += parseInt(
          window.getComputedStyle(element).getPropertyValue("margin-top")
        );
        outerHeight += parseInt(
          window.getComputedStyle(element).getPropertyValue("margin-bottom")
        );

        if (scrollHeight - scroll == outerHeight && viewNewButton) {
          setViewNewButton(false);

          if (conversation?._id) {
            APIV2.markConversationAsRead(conversation?._id);
          }
        }
      }
    } catch (e) {}
  }, [scroll, viewNewButton, conversation]);

  return (
    <>
      <div
        className="message-pane-inner"
        style={{
          height: `calc(100% - ${
            keyboardOpen ? keyboardOpen : "0"
          }px - ${actionsHeight}px)`,
        }}
      >
        <div
          className={`conversation-content-header ${
            drawerMode ? "drawer-mode" : ""
          } border-bottom p-3`}
        >
          {loading ? (
            <>
              <div
                className="skeleton"
                style={{ height: 20, width: 150 }}
              ></div>
              <div
                className="skeleton mt-2"
                style={{ height: 12, width: 60 }}
              ></div>
            </>
          ) : (
            <>
              <Row className="align-items-center">
                <Col
                  xs="auto"
                  className={`d-md-none pr-0 ${drawerMode && "d-none"}`}
                >
                  <Button
                    color="dark"
                    outline
                    className="btn-icon-only border-0"
                    onClick={() => {
                      if (!drawerMode) {
                        return onCancel();
                      }

                      history.push(
                        `/storefront/${match?.params?.storeID}/conversations${window.location.search}`
                      );
                    }}
                  >
                    <i className="mdi mdi-chevron-left"></i>
                  </Button>
                </Col>
                <Col xs="">
                  {newConversation ? (
                    <>
                      <div style={{ position: "relative" }}>
                        <Whisper
                          placement="autoVerticalStart"
                          trigger={["focus", "click"]}
                          ref={searchRef}
                          onOpen={() => {
                            if (!drawerMode) {
                              PubSub.publish("enableBodyScroll");
                            }
                          }}
                          onClose={() => {
                            if (!drawerMode) {
                              PubSub.publish("disableBodyScroll");
                            }
                          }}
                          enterable
                          speaker={
                            <Popover className="p-0 new-conversation-search-popover">
                              <div>
                                {searchLoading ||
                                !newConversationSearch ||
                                searchResults?.members?.length ||
                                searchResults?.leads?.length ? (
                                  <div className="border-bottom p-2">
                                    <h4 className="mb-0">Conversations</h4>
                                    <div className="mb-0">
                                      {!newConversationSearch ? (
                                        <>
                                          Search conversations or start a new
                                          one
                                        </>
                                      ) : null}
                                      {newConversationSearch &&
                                      searchLoading ? (
                                        <>Searching conversations...</>
                                      ) : null}
                                      {newConversationSearch &&
                                      !searchLoading &&
                                      !searchResults?.members?.length &&
                                      !searchResults?.leads?.length ? (
                                        <>
                                          No conversations matching your search.
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                ) : null}
                                <div
                                  className=""
                                  style={{
                                    maxHeight: "calc(100vh - 300px)",
                                    overflowY: "scroll",
                                    position: "relative",
                                  }}
                                >
                                  {!searchLoading &&
                                  !searchResults?.members?.length &&
                                  !searchResults?.leads?.length &&
                                  newConversationSearch ? (
                                    <>
                                      <div className="p-2 border-bottom bg-white">
                                        <h4 className="text-dark m-0">
                                          Start New Conversation
                                        </h4>
                                        {!StringUtils.isValidPhoneNumber(
                                          newConversationSearch
                                        ) ? (
                                          <p
                                            className="m-0 text-muted"
                                            style={{ fontSize: 12 }}
                                          >
                                            Enter a valid phone number to start
                                            a conversation
                                          </p>
                                        ) : (
                                          <p
                                            className="m-0 text-muted"
                                            style={{ fontSize: 12 }}
                                          >
                                            Start a new conversation with{" "}
                                            {StringUtils.formatPhoneNumber(
                                              newConversationSearch
                                            )}
                                          </p>
                                        )}
                                      </div>
                                      <div
                                        className={`cursor-pointer ${
                                          StringUtils.isValidPhoneNumber(
                                            newConversationSearch
                                          ) && "bg-superlight--hover"
                                        } px-2 py-1`}
                                        style={{ height: 40 }}
                                        onClick={() => {
                                          if (
                                            !StringUtils.isValidPhoneNumber(
                                              newConversationSearch
                                            )
                                          ) {
                                            return;
                                          }

                                          createConversation(
                                            newConversationSearch
                                          );
                                        }}
                                      >
                                        <Row className="align-items-center">
                                          <Col xs="">
                                            <h4
                                              className="m-0 text-dark"
                                              style={{
                                                paddingTop:
                                                  StringUtils.isValidPhoneNumber(
                                                    newConversationSearch
                                                  )
                                                    ? ""
                                                    : "0.4rem",
                                              }}
                                            >
                                              {StringUtils.isValidPhoneNumber(
                                                newConversationSearch
                                              )
                                                ? StringUtils.formatPhoneNumber(
                                                    newConversationSearch
                                                  )
                                                : newConversationSearch}
                                            </h4>
                                          </Col>
                                          <Col xs="auto">
                                            {StringUtils.isValidPhoneNumber(
                                              newConversationSearch
                                            ) ? (
                                              <Button
                                                size="sm"
                                                outline
                                                color="dark"
                                                className="btn-icon-only border-0"
                                                disabled={creating}
                                              >
                                                {creating ? (
                                                  <Spinner size="sm"></Spinner>
                                                ) : (
                                                  <i className="mdi mdi-chevron-right"></i>
                                                )}
                                              </Button>
                                            ) : null}
                                          </Col>
                                        </Row>
                                      </div>
                                      {error ? (
                                        <div className={`px-2 py-1`}>
                                          <p
                                            className="m-0 text-danger"
                                            style={{ fontSize: 12 }}
                                          >
                                            {error}
                                          </p>
                                        </div>
                                      ) : null}
                                    </>
                                  ) : null}
                                  {!searchLoading &&
                                  searchResults?.members?.length ? (
                                    <>
                                      <div
                                        className="p-2 border-bottom bg-white"
                                        style={{
                                          position: "sticky",
                                          top: 0,
                                          zIndex: 1,
                                        }}
                                      >
                                        <h4 className="text-dark m-0">
                                          Members
                                        </h4>
                                      </div>
                                      {searchResults?.members?.map((c, i) => (
                                        <>
                                          <div
                                            className="cursor-pointer bg-superlight--hover px-2 py-1"
                                            key={c?._id}
                                            onClick={() => {
                                              if (c?.newConversation) {
                                                return createConversation(
                                                  c?.customer?.phone?.replace(
                                                    "+1",
                                                    ""
                                                  ),
                                                  c?.customerID
                                                );
                                              }

                                              history.push(
                                                `/storefront/${c?.storeID}/conversations/${c?._id}${window.location.search}`
                                              );
                                            }}
                                          >
                                            <Row className="align-items-center">
                                              <Col xs="">
                                                <h4 className="m-0 text-dark">
                                                  {c?.customer?.name}
                                                </h4>
                                                <p
                                                  className="m-0 text-muted small"
                                                  style={{
                                                    lineHeight: 1.2,
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                  }}
                                                >
                                                  {StringUtils.formatPhoneNumber(
                                                    c?.customer?.phone
                                                  )}
                                                </p>
                                              </Col>
                                              <Col xs="auto">
                                                <Button
                                                  size="sm"
                                                  outline
                                                  color="dark"
                                                  className="btn-icon-only border-0"
                                                  disabled={creatingContact}
                                                >
                                                  {creatingContact ==
                                                  c?.recipientPhone ? (
                                                    <Spinner size="sm"></Spinner>
                                                  ) : (
                                                    <i className="mdi mdi-chevron-right"></i>
                                                  )}
                                                </Button>
                                              </Col>
                                            </Row>
                                          </div>
                                          {i !=
                                          searchResults?.members?.length - 1 ? (
                                            <hr className="m-0"></hr>
                                          ) : null}
                                        </>
                                      ))}
                                    </>
                                  ) : null}
                                  {!searchLoading &&
                                  searchResults?.leads?.length ? (
                                    <>
                                      <div
                                        className={`p-2 border-bottom bg-white ${
                                          searchResults?.members?.length &&
                                          "mt-3"
                                        }`}
                                        style={{
                                          position: "sticky",
                                          top: 0,
                                          zIndex: 1,
                                        }}
                                      >
                                        <h4 className={`text-dark m-0`}>
                                          Leads
                                        </h4>
                                      </div>
                                      {searchResults?.leads?.map((c, i) => (
                                        <>
                                          <div
                                            className="cursor-pointer bg-superlight--hover px-2 py-1"
                                            key={c?._id}
                                            onClick={() => {
                                              if (c?.newConversation) {
                                                return createConversation(
                                                  c?.lead?.phone?.replace(
                                                    "+1",
                                                    ""
                                                  ),
                                                  null,
                                                  c?.leadID
                                                );
                                              }

                                              history.push(
                                                `/storefront/${c?.storeID}/conversations/${c?._id}${window.location.search}`
                                              );
                                            }}
                                          >
                                            <Row className="align-items-center">
                                              <Col xs="">
                                                <h4 className="m-0 text-dark">
                                                  {c?.lead?.name
                                                    ? c?.lead?.name
                                                    : StringUtils.formatPhoneNumber(
                                                        c?.lead?.phone
                                                          ? c?.lead?.phone
                                                          : c?.recipientPhone
                                                      )}
                                                </h4>
                                                <p
                                                  className="m-0 text-muted small"
                                                  style={{
                                                    lineHeight: 1.2,
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                  }}
                                                >
                                                  {c?.lead?.name
                                                    ? StringUtils.formatPhoneNumber(
                                                        c?.lead?.phone
                                                      )
                                                    : c?.lead?.email
                                                    ? c?.lead?.email
                                                    : "Lead"}
                                                </p>
                                              </Col>
                                              <Col xs="auto">
                                                <Button
                                                  size="sm"
                                                  outline
                                                  color="dark"
                                                  className="btn-icon-only border-0"
                                                  disabled={creatingContact}
                                                >
                                                  {creatingContact ==
                                                  c?.recipientPhone ? (
                                                    <Spinner size="sm"></Spinner>
                                                  ) : (
                                                    <i className="mdi mdi-chevron-right"></i>
                                                  )}
                                                </Button>
                                              </Col>
                                            </Row>
                                          </div>
                                          {i !=
                                          searchResults?.members?.length - 1 ? (
                                            <hr className="m-0"></hr>
                                          ) : null}
                                        </>
                                      ))}
                                    </>
                                  ) : null}
                                  {searchLoading ? (
                                    <>
                                      <div className="text-center py-4">
                                        <Spinner
                                          size="sm"
                                          color="dark"
                                        ></Spinner>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </Popover>
                          }
                        >
                          <div>
                            <InputGroup>
                              <InputGroupAddon
                                size="small"
                                addonType="prepend"
                                style={{ height: "38px" }}
                                className="new-conversation-to"
                              >
                                To:
                              </InputGroupAddon>
                              <Input
                                type="text"
                                placeholder="Recipient"
                                className="text-dark"
                                name="newConversationSearch"
                                value={newConversationSearch}
                                onChange={(e) => {
                                  handleSearchChange(e);
                                }}
                                style={{ height: 38 }}
                              ></Input>
                            </InputGroup>
                          </div>
                        </Whisper>
                      </div>
                    </>
                  ) : (
                    <>
                      <h2 className="m-0 text-truncate">
                        {getConversationName(conversation)}
                        {isUnsubscribed ? (
                          <>
                            <Whisper
                              trigger={["click", "hover"]}
                              delayOpen={400}
                              placement="autoHorizontal"
                              speaker={<Tooltip>Unsubscribed</Tooltip>}
                            >
                              <i
                                className="mdi mdi-close-octagon-outline cursor-pointer text-dark pl-2"
                                style={{
                                  lineHeight: 1,
                                  position: "relative",
                                  top: 2,
                                }}
                              ></i>
                            </Whisper>
                          </>
                        ) : null}
                        {isBlocked ? (
                          <>
                            <Whisper
                              trigger={["click", "hover"]}
                              delayOpen={400}
                              placement="autoHorizontal"
                              speaker={<Tooltip>Blocked</Tooltip>}
                            >
                              <i
                                className="mdi mdi-account-cancel cursor-pointer text-dark pl-2"
                                style={{
                                  lineHeight: 1,
                                  position: "relative",
                                  top: 2,
                                }}
                              ></i>
                            </Whisper>
                          </>
                        ) : null}
                        {isClosed ? (
                          <>
                            <Whisper
                              trigger={["click", "hover"]}
                              delayOpen={400}
                              placement="autoHorizontal"
                              speaker={
                                <Tooltip>Conversation Currently Closed</Tooltip>
                              }
                            >
                              <i
                                className="mdi mdi-account-cancel cursor-pointer text-dark pl-2"
                                style={{
                                  lineHeight: 1,
                                  position: "relative",
                                  top: 2,
                                }}
                              ></i>
                            </Whisper>
                          </>
                        ) : null}
                      </h2>
                      <p
                        className="small mb-0 text-truncate"
                        style={{ lineHeight: 1.2 }}
                      >
                        {getConversationDescription(conversation)}
                      </p>
                    </>
                  )}
                </Col>
                <Col xs="auto" className={`${newConversation && "pl-0"}`}>
                  {newConversation ? (
                    <>
                      <Button
                        disabled={emptyState}
                        color="dark"
                        outline
                        className="btn-icon-only border-0"
                        onClick={() => {
                          if (typeof onCancel == "function") {
                            onCancel();
                          }
                        }}
                      >
                        <i className="mdi mdi-close"></i>
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        disabled={emptyState}
                        color="dark"
                        outline
                        className="btn-icon-only border-0"
                        onClick={() => {
                          setOpenDetails(true);
                        }}
                      >
                        <i className="mdi mdi-information-outline"></i>
                      </Button>
                      <Whisper
                        enterable={true}
                        trigger="click"
                        ref={actionDropdownRef}
                        placement="autoVerticalEnd"
                        speaker={
                          <Popover className="dropdown-popover" full>
                            <Dropdown.Menu
                              style={{
                                minWidth: 150,
                                paddingBottom: 0,
                              }}
                            >
                              <Dropdown.Item
                                panel
                                className="font-weight-800 text-uppercase"
                                style={{
                                  padding: 12,
                                }}
                              >
                                <h5 className="m-0 text-dark">Actions</h5>
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="my-0"
                                divider
                              ></Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  //this.settingsDropdownRef.current.close();

                                  if (conversation?.unreadCount > 0) {
                                    markAsRead();
                                  } else {
                                    markAsUnread();
                                  }

                                  actionDropdownRef.current.close();
                                }}
                                className="text-dark bg-superlight--hover"
                                disabled={markingRead}
                              >
                                <Row className="align-items-center">
                                  <Col xs="auto" className="pr-0">
                                    <i
                                      className={`mdi mdi-${
                                        conversation?.unreadCount > 0
                                          ? "checkbox-marked-outline"
                                          : "checkbox-blank-badge-outline"
                                      }`}
                                      style={{
                                        fontSize: 16,
                                        lineHeight: 1.3,
                                      }}
                                    ></i>
                                  </Col>
                                  <Col xs="" className="pl-2">
                                    <p className="m-0" style={{ fontSize: 14 }}>
                                      Mark As{" "}
                                      {conversation?.unreadCount > 0
                                        ? "Read"
                                        : "Unread"}
                                    </p>
                                  </Col>
                                </Row>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  //this.settingsDropdownRef.current.close();

                                  modifyStatus();

                                  actionDropdownRef.current.close();
                                }}
                                disabled={modifyingStatus}
                                className="text-dark bg-superlight--hover"
                              >
                                <Row className="align-items-center">
                                  <Col xs="auto" className="pr-0">
                                    <i
                                      className={`mdi mdi-${
                                        conversation?.status == "OPEN"
                                          ? "close-circle-outline"
                                          : "check-circle-outline"
                                      }`}
                                      style={{
                                        fontSize: 16,
                                        lineHeight: 1.3,
                                      }}
                                    ></i>
                                  </Col>
                                  <Col xs="" className="pl-2">
                                    <p
                                      className="m-0"
                                      style={{ fontSize: 14, lineHeight: 1 }}
                                    >
                                      {conversation?.status == "OPEN"
                                        ? "Close"
                                        : "Reopen"}
                                      &nbsp;Conversation
                                    </p>
                                  </Col>
                                </Row>
                              </Dropdown.Item>
                              {isGlobal() ? (
                                <Dropdown.Item
                                  className="text-dark bg-superlight--hover"
                                  onClick={() => {
                                    setChangeStoreDrawer(true);

                                    actionDropdownRef.current.close();
                                  }}
                                >
                                  <Row className="align-items-center">
                                    <Col xs="auto" className="pr-0">
                                      <i
                                        className={`mdi mdi-storefront`}
                                        style={{
                                          fontSize: 16,
                                          lineHeight: 1.3,
                                        }}
                                      ></i>
                                    </Col>
                                    <Col xs="" className="pl-2">
                                      <p
                                        className="m-0"
                                        style={{ fontSize: 14, lineHeight: 1 }}
                                      >
                                        Change Store Assignment
                                      </p>
                                    </Col>
                                  </Row>
                                </Dropdown.Item>
                              ) : null}
                              <Dropdown.Item
                                className="my-0"
                                divider
                              ></Dropdown.Item>

                              {isBlocked ? (
                                <Dropdown.Item
                                  disabled={blocking}
                                  onClick={() => {
                                    actionDropdownRef.current.close();

                                    unblock();
                                  }}
                                  className="text-dark bg-superlight--hover"
                                >
                                  <Row className="align-items-center">
                                    <Col xs="auto" className="pr-0">
                                      <i
                                        className={`mdi mdi-check-circle-outline`}
                                        style={{
                                          fontSize: 16,
                                          lineHeight: 1.3,
                                        }}
                                      ></i>
                                    </Col>
                                    <Col xs="" className="pl-2">
                                      <p
                                        className="m-0"
                                        style={{ fontSize: 14 }}
                                      >
                                        Unblock Sender
                                      </p>
                                    </Col>
                                  </Row>
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  disabled={blocking}
                                  onClick={() => {
                                    actionDropdownRef.current.close();

                                    block();
                                  }}
                                  className="text-danger bg-superlight--hover"
                                >
                                  <Row className="align-items-center">
                                    <Col xs="auto" className="pr-0">
                                      <i
                                        className={`mdi mdi-account-cancel`}
                                        style={{
                                          fontSize: 16,
                                          lineHeight: 1.3,
                                        }}
                                      ></i>
                                    </Col>
                                    <Col xs="" className="pl-2">
                                      <p
                                        className="m-0"
                                        style={{ fontSize: 14 }}
                                      >
                                        Block Sender
                                      </p>
                                    </Col>
                                  </Row>
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Popover>
                        }
                      >
                        <Button
                          color="dark"
                          outline
                          className="btn-icon-only border-0"
                          disabled={emptyState}
                        >
                          <i className="mdi mdi-dots-vertical"></i>
                        </Button>
                      </Whisper>
                    </>
                  )}
                </Col>
              </Row>
            </>
          )}
        </div>
        <div
          className="conversation-content-messages p-3"
          ref={(ref) => {
            if (conversation?._id && !messageScrollRef && ref) {
              setMessageScrollRef(ref);
            }
          }}
          onScroll={(e) => {
            if (
              document.getElementsByClassName("conversation-content-messages")
                ?.length
            ) {
              try {
                setScroll(
                  document.getElementsByClassName(
                    "conversation-content-messages"
                  )[0].scrollTop
                );
              } catch (e) {}
            }
          }}
        >
          {loading || loadingMessages ? (
            <>
              {!newConversation ? (
                <>
                  <ConversationMessageItem
                    skeleton={true}
                  ></ConversationMessageItem>
                  <ConversationMessageItem
                    skeleton={true}
                  ></ConversationMessageItem>
                  <ConversationMessageItem
                    skeleton={true}
                    sent={false}
                  ></ConversationMessageItem>
                </>
              ) : null}
            </>
          ) : (
            <>
              <div>
                <InfiniteScroll
                  threshold={100}
                  pageStart={0}
                  loadMore={(v) => loadMessagePage(v)}
                  hasMore={hasMore}
                  getScrollParent={() => messageScrollRef}
                  loader={
                    <div key={0} className="p-3 text-center">
                      <Spinner size="sm"></Spinner>
                    </div>
                  }
                  isReverse={true}
                  useWindow={false}
                >
                  {messages?.map((item, i) => (
                    <ConversationMessageItem
                      key={i}
                      message={item}
                      sent={item?.storeSent}
                    ></ConversationMessageItem>
                  ))}
                  <div ref={bottomRef}></div>
                  <div
                    className="text-center text-muted text-lowercase small px-3 pt-2 pb-2 mb-2 rounded border d-inline-block"
                    style={{
                      position: "relative",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    {emptyState ? (
                      <>Select A Conversation</>
                    ) : (
                      <>
                        {messages?.length
                          ? "up to date"
                          : "start the conversation with your first message"}
                      </>
                    )}
                  </div>
                </InfiniteScroll>
              </div>
            </>
          )}
        </div>

        {viewNewButton ? (
          <>
            <div className="conversation-view-new-messages-button-container">
              <Button
                onClick={() => {
                  scrollToBottom();
                  setViewNewButton(false);

                  APIV2.markConversationAsRead(conversation?._id).then(
                    (data) => {},
                    (e) => {
                      console.error(e);
                    }
                  );
                }}
                size="sm"
                className="shadow-none"
                color="primary"
              >
                View New Messages
              </Button>
            </div>
          </>
        ) : null}

        <div
          className="conversation-content-actions bg-white border-top"
          style={{ zIndex: 2 }}
          ref={(c) => {
            if (conversation?._id && !messageAreaRef && c) {
              setMessageAreaRef(c);
            }
          }}
        >
          {loading ? (
            <>
              <div
                className="skeleton m-3"
                style={{
                  height: 90,
                  width: "calc(100% - 2rem)",
                }}
              ></div>
            </>
          ) : (
            <>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();

                  submitForm();
                }}
              >
                <div className="pl-2 pt-2 pr-3">
                  <textarea
                    className="border-0 form-control"
                    disabled={emptyState || isUnsubscribed || isBlocked}
                    type="textarea"
                    id="message-textarea"
                    placeholder={
                      isUnsubscribed || isBlocked
                        ? `This ${
                            conversation?.leadID || conversation?.customerID
                              ? conversation?.customerID
                                ? "member"
                                : "lead"
                              : "contact"
                          } is ${
                            isBlocked ? "blocked" : "unsubscribed"
                          } and cannot receive messages.`
                        : "Type something..."
                    }
                    ref={(c) => {
                      if (conversation?._id && c && !textareaRef) {
                        setTextareaRef(c);
                      }
                    }}
                    value={body}
                    style={{
                      minHeight: 41,
                      maxHeight: 124,
                      resize: "none",
                    }}
                    rows={1}
                    onChange={(e) => {
                      setBody(e?.target?.value);
                    }}
                    required={true}
                  ></textarea>

                  {mediaUrl ? (
                    <>
                      <div
                        className="p-2 border rounded bg-white mx-2"
                        style={{ fontSize: 12 }}
                      >
                        Attached:{" "}
                        <a
                          href={mediaUrl}
                          target="_blank"
                          onClick={(e) => {
                            e.preventDefault();

                            LinkUtils.openInNewTab(mediaUrl);
                          }}
                        >
                          {getAttachmentFileName(mediaUrl)}
                        </a>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="px-3 pb-3 pt-2">
                  <Row className="align-items-center">
                    <Col xs="">
                      <Whisper
                        trigger={["click"]}
                        ref={emojiRef}
                        onClose={() => {
                          refocusTextArea();
                          setEmojiOpen(false);
                          if (!drawerMode) {
                            PubSub.publish("disableBodyScroll");
                          }
                        }}
                        placement={"topStart"}
                        preventOverflow={true}
                        onOpen={() => {
                          if (!drawerMode) {
                            PubSub.publish("enableBodyScroll");
                          }
                        }}
                        speaker={
                          <Popover
                            full
                            className="dropdown-popover quick-reply"
                            style={{
                              height: `calc(100% - 6.5rem - ${
                                keyboardOpen ? `${keyboardOpen}px` : "0px"
                              })`,
                              maxHeight: 500,
                            }}
                          >
                            <div style={{ position: "relative" }}>
                              <EmojiPicker
                                emojiStyle="apple"
                                defaultSkinTone="neutral"
                                skinTonesDisabled={true}
                                theme="light"
                                getEmojiUrl={(data, e) => {
                                  return `/emojis/apple/64/${data}.png`;
                                }}
                                onEmojiClick={(v) => {
                                  let b = body;

                                  b = b + v?.emoji;

                                  setBody(b);

                                  emojiRef.current.close();

                                  setTimeout(() => {
                                    var len = b.length;

                                    textareaRef.focus();

                                    textareaRef.selectionEnd = len;

                                    autosize.update(textareaRef);
                                  }, 0);
                                }}
                                searchDisabled={true}
                                autoFocusSearch={false}
                              ></EmojiPicker>
                            </div>
                          </Popover>
                        }
                      >
                        <Button
                          size="sm"
                          outline
                          color="dark"
                          className={`btn-icon-only border-0 mr-0 text-dark bg-white shadow-none ${
                            Capacitor.isNativePlatform() && "d-none"
                          }`}
                          type="button"
                          disabled={emptyState}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            refocusTextArea();

                            if (emojiOpen) {
                              return setEmojiOpen(false);
                            }

                            setEmojiOpen(false);
                          }}
                        >
                          <i className="mdi mdi-emoticon-outline"></i>
                        </Button>
                      </Whisper>
                      <Whisper
                        trigger={["click"]}
                        ref={savedReplyRef}
                        onClose={() => {
                          setQuickReplyOpen(false);
                          setQuickReplyFilter(null);
                          setQuickReplySearchValue(null);
                          setQuickReplySearch(false);
                          refocusTextArea();

                          if (!drawerMode) {
                            PubSub.publish("disableBodyScroll");
                          }
                        }}
                        placement={"topStart"}
                        preventOverflow={true}
                        onOpen={() => {
                          if (!drawerMode) {
                            PubSub.publish("enableBodyScroll");
                          }
                        }}
                        speaker={
                          <Popover
                            full
                            className="dropdown-popover quick-reply"
                            style={{
                              width: "calc(100%)",
                              maxWidth: 650,
                              height: `calc(100% - 6.5rem - ${
                                keyboardOpen ? `${keyboardOpen}px` : "0px"
                              })`,
                              maxHeight: 650,
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                height: "100%",
                                width: "100%",
                              }}
                            >
                              <div
                                className="p-3 border-bottom bg-white"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  zIndex: 1,
                                }}
                              >
                                <Row className="align-items-center">
                                  <Col xs="auto" className="pr-0">
                                    <i
                                      className="mdi mdi-inbox-arrow-up text-dark"
                                      style={{
                                        fontSize: 22,
                                        lineHeight: 1,
                                        position: "relative",
                                        top: 1,
                                      }}
                                    ></i>
                                  </Col>
                                  <Col xs="" className="pl-2">
                                    {quickReplySearch ? (
                                      <Input
                                        bsSize="sm"
                                        placeholder="Search Templates..."
                                        onChange={(e) => {
                                          handleQuickReplySearchChange(e);
                                        }}
                                        value={quickReplySearchValue}
                                        name="search"
                                      ></Input>
                                    ) : (
                                      <h4 className="m-0 text-dark">
                                        Saved Replies
                                      </h4>
                                    )}
                                  </Col>
                                  <Col xs="auto" className="pl-0 pr-2">
                                    <Button
                                      size="sm"
                                      outline
                                      color="dark"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        refocusTextArea();

                                        if (quickReplySearch) {
                                          setQuickReplySearch(false);

                                          setQuickReplySearchValue("");

                                          loadTemplates(
                                            conversation?.storeID,
                                            1,
                                            pageCount,
                                            null,
                                            { name: -1 }
                                          );

                                          setQuickReplyFilter(null);

                                          return;
                                        }

                                        setQuickReplySearch(true);
                                      }}
                                      className="btn-icon-only border-0 mr-0"
                                    >
                                      {quickReplySearch ? (
                                        <i className="mdi mdi-cancel"></i>
                                      ) : (
                                        <i className="mdi mdi-magnify"></i>
                                      )}
                                    </Button>
                                    <Button
                                      size="sm"
                                      outline
                                      color="dark"
                                      onClick={(e) => {
                                        savedReplyRef.current.close();
                                        e.stopPropagation();
                                        refocusTextArea();
                                      }}
                                      className="btn-icon-only border-0"
                                    >
                                      <i className="mdi mdi-close"></i>
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                              <div
                                style={{
                                  overflowX: "hidden",
                                  overflowY: "auto",
                                  position: "absolute",
                                  top: 65,
                                  left: 0,
                                  width: "100%",
                                  height: "calc(100% - 65px - 65px)",
                                }}
                              >
                                {loadingTemplates ? (
                                  <>
                                    <div
                                      className="p-3"
                                      style={{
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                      }}
                                    >
                                      <div
                                        className="text-center"
                                        style={{
                                          position: "relative",
                                          top: "50%",
                                          transform: "translateY(-50%)",
                                        }}
                                      >
                                        <Spinner></Spinner>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {templates?.length ? (
                                      <>
                                        {templates?.map((t, i) => (
                                          <div
                                            className={`p-3 bg-superlight--hover cursor-pointer ${
                                              i != templates?.length &&
                                              "border-bottom"
                                            }`}
                                            onClick={(e) => {
                                              savedReplyRef.current.close();
                                              e.stopPropagation();
                                              refocusTextArea();

                                              setBody(t.message);

                                              setTimeout(() => {
                                                var len = t.message.length;

                                                textareaRef.focus();

                                                textareaRef.selectionEnd = len;

                                                autosize.update(textareaRef);
                                              }, 0);
                                            }}
                                          >
                                            <Row className="align-items-center">
                                              <Col
                                                xs=""
                                                sm=""
                                                className="pr-0"
                                                style={{
                                                  maxWidth: "calc(100% - 62px)",
                                                }}
                                              >
                                                <p
                                                  className="mb-0 text-muted font-weight-500 text-truncate"
                                                  style={{ fontSize: 12 }}
                                                >
                                                  {!t.storeID &&
                                                    "Built-In Template: "}
                                                  {t.name}
                                                </p>
                                                <p
                                                  className="m-0 text-dark"
                                                  style={{
                                                    wordBreak: "break-all",
                                                  }}
                                                  dangerouslySetInnerHTML={{
                                                    __html: t?.message?.replace(
                                                      /\n/g,
                                                      "<br />"
                                                    ),
                                                  }}
                                                ></p>
                                              </Col>
                                              <Col xs="auto" sm="auto">
                                                <div>
                                                  <Button
                                                    size="sm"
                                                    outline
                                                    color="dark"
                                                    className="btn-icon-only border-0"
                                                    onClick={(e) => {
                                                      savedReplyRef.current.close();
                                                      e.stopPropagation();
                                                      refocusTextArea();

                                                      setBody(t.message);

                                                      setTimeout(() => {
                                                        var len =
                                                          t.message.length;

                                                        textareaRef.focus();

                                                        textareaRef.selectionEnd =
                                                          len;

                                                        autosize.update(
                                                          textareaRef
                                                        );
                                                      }, 0);
                                                    }}
                                                  >
                                                    <i className="mdi mdi-chevron-right"></i>
                                                  </Button>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        ))}
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          className="p-3 text-center"
                                          style={{
                                            position: "relative",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                          }}
                                        >
                                          No conversation templates available.
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                              <div
                                className="p-3 border-top"
                                style={{
                                  position: "absolute",
                                  bottom: 0,
                                  left: 0,
                                  width: "100%",
                                }}
                              >
                                <Row className="align-items-center">
                                  <Col xs="">
                                    {loadingTemplates ? (
                                      <p
                                        style={{ fontSize: 14 }}
                                        className="m-0 text-dark"
                                      >
                                        Loading Templates...
                                      </p>
                                    ) : (
                                      <p
                                        style={{ fontSize: 14 }}
                                        className="m-0 text-dark"
                                      >
                                        {results?.start} - {results?.end} of{" "}
                                        {results.total}
                                      </p>
                                    )}
                                  </Col>
                                  <Col xs="auto">
                                    <Button
                                      className="btn-icon-only border-0"
                                      size="sm"
                                      outline
                                      color="dark"
                                      disabled={!pagination?.hasPrevious}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        refocusTextArea();
                                        loadTemplates(
                                          conversation?.storeID,
                                          pagination?.previous,
                                          pageCount,
                                          quickReplyFilter,
                                          {
                                            name: -1,
                                          }
                                        );
                                      }}
                                    >
                                      <i className="mdi mdi-chevron-left"></i>
                                    </Button>
                                    <Button
                                      className="btn-icon-only border-0"
                                      size="sm"
                                      outline
                                      color="dark"
                                      disabled={!pagination?.hasNext}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        refocusTextArea();
                                        loadTemplates(
                                          conversation?.storeID,
                                          pagination?.next,
                                          pageCount,
                                          quickReplyFilter,
                                          {
                                            name: -1,
                                          }
                                        );
                                      }}
                                    >
                                      <i className="mdi mdi-chevron-right"></i>
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Popover>
                        }
                      >
                        <Button
                          size="sm"
                          outline
                          color="dark"
                          className="btn-icon-only border-0 text-dark bg-white shadow-none"
                          type="button"
                          disabled={emptyState}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            refocusTextArea();

                            if (quickReplyOpen) {
                              return setQuickReplyOpen(false);
                            }

                            loadTemplates(
                              conversation?.storeID,
                              1,
                              pageCount,
                              null,
                              { name: -1 }
                            );

                            setQuickReplyOpen(true);
                          }}
                        >
                          <i className="mdi mdi-inbox-arrow-up"></i>
                        </Button>
                      </Whisper>
                      <Whisper
                        trigger={["click"]}
                        ref={quickReplyRef}
                        onClose={() => {
                          setQuickReplyOpen(false);
                          setQuickReplyFilter(null);
                          setQuickReplySearchValue(null);
                          setQuickReplySearch(false);
                          refocusTextArea();

                          if (!drawerMode) {
                            PubSub.publish("disableBodyScroll");
                          }
                        }}
                        onOpen={() => {
                          if (!drawerMode) {
                            PubSub.publish("enableBodyScroll");
                          }
                        }}
                        placement={"topStart"}
                        preventOverflow={true}
                        speaker={
                          <Popover
                            full
                            className="dropdown-popover quick-reply"
                            style={{
                              width: "calc(100%)",
                              maxWidth: 650,
                              height: `calc(100% - 6.5rem - ${
                                keyboardOpen ? `${keyboardOpen}px` : "0px"
                              })`,
                              maxHeight: 650,
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              <div
                                className="p-3 border-bottom"
                                style={{
                                  position: "absolute",
                                  width: "100%",
                                  left: 0,
                                  top: 0,
                                }}
                              >
                                <Row className="align-items-center">
                                  <Col xs="auto" className="pr-0">
                                    <i
                                      className="mdi mdi-link-plus text-dark"
                                      style={{
                                        fontSize: 22,
                                        lineHeight: 1,
                                        position: "relative",
                                        top: 1,
                                      }}
                                    ></i>
                                  </Col>
                                  <Col xs="" className="pl-2">
                                    <h4 className="m-0 text-dark">
                                      Quick Links
                                    </h4>
                                  </Col>
                                  <Col xs="auto" className="pl-0">
                                    <Button
                                      size="sm"
                                      outline
                                      color="dark"
                                      onClick={(e) => {
                                        quickReplyRef.current.close();
                                        e.stopPropagation();
                                        refocusTextArea();
                                      }}
                                      className="btn-icon-only border-0"
                                    >
                                      <i className="mdi mdi-close"></i>
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </div>

                            <div
                              style={{
                                overflowX: "hidden",
                                overflowY: "auto",
                                position: "absolute",
                                top: 65,
                                height: "calc(100% - 65px)",
                                width: "100%",
                                left: 0,
                              }}
                            >
                              {quickLinks?.length ? (
                                <>
                                  {quickLinks?.map((t, i) => (
                                    <div
                                      className={`p-3 bg-superlight--hover cursor-pointer ${
                                        i != templates?.length &&
                                        "border-bottom"
                                      }`}
                                      onClick={(e) => {
                                        quickReplyRef.current.close();
                                        e.stopPropagation();
                                        refocusTextArea();

                                        const mess =
                                          body +
                                          `${body?.length > 0 ? " " : ""}${
                                            t.url
                                          }`;

                                        setBody(mess);

                                        setTimeout(() => {
                                          var len = mess.length;

                                          textareaRef.focus();

                                          textareaRef.selectionEnd = len;

                                          autosize.update(textareaRef);
                                        }, 0);
                                      }}
                                    >
                                      <Row className="align-items-center">
                                        <Col
                                          xs=""
                                          sm=""
                                          className="pr-0"
                                          style={{
                                            maxWidth: "calc(100% - 62px)",
                                          }}
                                        >
                                          <p
                                            className="mb-0 text-dark font-weight-500 text-truncate"
                                            style={{ fontSize: 16 }}
                                          >
                                            {t.name}
                                          </p>
                                          <p
                                            className="m-0 text-muted text-truncate"
                                            style={{ fontSize: 12 }}
                                          >
                                            {t.url}
                                          </p>
                                        </Col>
                                        <Col xs="auto" sm="auto">
                                          <Button
                                            onClick={(e) => {
                                              quickReplyRef.current.close();
                                              e.stopPropagation();
                                              refocusTextArea();

                                              const mess =
                                                body +
                                                `${
                                                  body?.length > 0 ? " " : ""
                                                }${t.url}`;

                                              setBody(mess);

                                              setTimeout(() => {
                                                var len = mess.length;

                                                textareaRef.focus();

                                                textareaRef.selectionEnd = len;

                                                autosize.update(textareaRef);
                                              }, 0);
                                            }}
                                            size="sm"
                                            outline
                                            color="dark"
                                            className="btn-icon-only border-0"
                                          >
                                            <i className="mdi mdi-plus"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <>
                                  <div
                                    className="p-3 text-center"
                                    style={{
                                      position: "relative",
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                    }}
                                  >
                                    No quick links available.
                                  </div>
                                </>
                              )}
                            </div>
                          </Popover>
                        }
                      >
                        <Button
                          size="sm"
                          outline
                          color="dark"
                          className="btn-icon-only border-0 text-dark bg-white shadow-none"
                          type="button"
                          disabled={emptyState}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            refocusTextArea();

                            if (quickReplyOpen) {
                              return setQuickReplyOpen(false);
                            }

                            loadTemplates(
                              conversation?.storeID,
                              1,
                              pageCount,
                              null,
                              { name: -1 }
                            );

                            setQuickReplyOpen(true);
                          }}
                        >
                          <i className="mdi mdi-link-plus"></i>
                        </Button>
                      </Whisper>
                      <Button
                        size="sm"
                        outline={mediaUrl != store?.vcardURL}
                        color="dark"
                        className="btn-icon-only border-0 ml-0 text-dark bg-white shadow-none"
                        type="button"
                        disabled={!store?.vcardURL || emptyState}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          refocusTextArea();

                          if (mediaUrl == store?.vcardURL) {
                            return setMediaUrl("");
                          }

                          setMediaUrl(store?.vcardURL);
                        }}
                      >
                        <i className="mdi mdi-card-account-phone-outline"></i>
                      </Button>
                      {/*<Button
                          size="sm"
                          outline
                          color="dark"
                          className="btn-icon-only border-0"
                        >
                          <i className="mdi mdi-image"></i>
                    </Button>*/}

                      {/*<Button
                          size="sm"
                          outline
                          color="dark"
                          className="btn-icon-only border-0"
                        >
                          <i className="mdi mdi-note-plus-outline"></i>
                </Button>*/}
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        color="primary"
                        disabled={!body || submitting || quickReplyOpen}
                        className="btn-icon-only"
                        type="submit"
                      >
                        {submitting ? (
                          <Spinner size="sm"></Spinner>
                        ) : (
                          <i className="mdi mdi-arrow-up"></i>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            </>
          )}
        </div>
      </div>
      <ConversationDetailDrawer
        open={openDetails}
        onClose={() => {
          setOpenDetails(false);

          if (!drawerMode) {
            PubSub.publish("disableBodyScroll");
          }
        }}
        conversation={conversation}
        drawerMode={drawerMode}
        onOpen={() => {
          if (!drawerMode) {
            PubSub.publish("enableBodyScroll");
          }
        }}
      ></ConversationDetailDrawer>
      <ConversationStoreAssignmentDrawer
        conversation={conversation}
        open={changeStoreDrawer}
        onClose={() => {
          setChangeStoreDrawer(false);
        }}
        onModify={(c) => {
          console.log(c);

          PubSub.publish(PushEvent.CONVERSATION.REASSIGNED, c?.conversation);

          swal({
            title: "Store Updated",
            text: `The conversation has been successfully reassigned to ${c?.store?.name}`,
            icon: "success",
          });
        }}
      ></ConversationStoreAssignmentDrawer>
    </>
  );
};

export default withRouter(ConversationMessagePane);
