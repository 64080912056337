import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  CheckPicker,
  Tooltip,
  Checkbox,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Constant from "lib/Constant";
import LinkUtils from "../../lib/LinkUtils";
import EmojiPicker from "emoji-picker-react";
import autosize from "autosize";
import { count } from "sms-length";
import { SegmentedMessage } from "sms-segments-calculator";
import LocalStorage from "lib/LocalStorage";
import FileUploadDrawer from "components/FileUploadDrawer";
import EditorV2 from "components/NovelEditor/ui/editor";

class CohortDrawer extends React.Component {
  state = {
    message: "",
    name: "",
    list: "",
    scheduledSend: null,
    phone: {
      value: "",
    },
    channel: "conversations",
    listOptions: [
      {
        value: "dynamic:all_actives",
        label: "All Active Members",
        group: "Members",
      },
      {
        value: `dynamic:all_actives_this_week`,
        label: `Active This Week (${moment().day(6).format("MMM DD, YYYY")})`,
        group: "Members",
      },
      {
        value: `dynamic:delivery_actives_this_week_${moment()
          .day(6)
          .format("YYYY-MM-DD")}`,
        label: `Active This Week - Delivery List (${moment()
          .day(6)
          .format("MMM DD, YYYY")})`,
        group: "Members",
      },
      {
        value: `dynamic:pickup_actives_this_week_${moment()
          .day(6)
          .format("YYYY-MM-DD")}`,
        label: `Active This Week - Pickup List (${moment()
          .day(6)
          .format("MMM DD, YYYY")})`,
        group: "Members",
      },
      {
        value: "dynamic:all_hfns",
        label: "All HFNs",
        group: "HFNs",
      },
      {
        value: "dynamic:all_leads",
        label: "All Leads",
        group: "Leads",
      },
      {
        value: "dynamic:all_retail_customers",
        label: "All Retail Customers",
        group: "Retail Customers",
      },
      {
        value: "dynamic:retail_customers_last_30",
        label: "Customers Last Order Within 30 Days",
        group: "Retail Customers",
      },
      {
        value: "dynamic:retail_customers_over_30",
        label: "Customers Last Order Over 30 Days Ago",
        group: "Retail Customers",
      },
      {
        value: "dynamic:retail_customers_first_time",
        label: "First Time Customers (1 Order)",
        group: "Retail Customers",
      },
      {
        value: "dynamic:retail_customers_repeat",
        label: "Repeat Customers (3+ Orders)",
        group: "Retail Customers",
      },
    ],
    results: {},
    pagination: {},
    templates: [],
  };

  testMessageRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (this.props.cohort != prevProps?.cohort && this.props.cohort) {
      this.setState({
        name: this.props.cohort?.name,
        description: this.props.cohort?.description
          ? this.props.cohort?.description
          : "",
        collectionName: this.props.cohort?.collectionName
          ? this.props.cohort?.collectionName
          : "",
        script: this.props.cohort?.script ? this.props.cohort?.script : "",
        key: this.props.cohort?.key ? this.props.cohort?.key : "",
      });
    }
  }

  componentDidMount() {
    if (this.props.cohort) {
      this.setState({
        name: this.props.cohort?.name,
        description: this.props.cohort?.description
          ? this.props.cohort?.description
          : "",
        collectionName: this.props.cohort?.collectionName
          ? this.props.cohort?.collectionName
          : "",
        script: this.props.cohort?.script ? this.props.cohort?.script : "",
        key: this.props.cohort?.key ? this.props.cohort?.key : "",
      });
    }
  }

  toggleModal() {
    this.setState({
      name: "",
      description: "",
      script: "",
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });

    if (name == "message") {
      if (value?.length) {
        this.setState({
          messageLength: count(value),
        });
      } else {
        this.setState({
          messageLength: null,
        });
      }
    }
  }

  submit() {
    if (this.props.cohort && !this.props.duplicate) {
      this.modify();
    } else {
      this.create();
    }
  }

  create() {
    this.setState({ submitting: true });

    let payload = {
      name: this.state.name,
      description: this.state.description,
      script: this.state.script,
      collectionName: this.state.collectionName,
      key: this.state.key,
    };

    APIV2.createMarketingCohort(payload)
      .then(
        (data) => {
          const broadcast = data.data.cohort;

          this.toggleModal();

          PubSub.publish(Event.COHORT.CREATED, broadcast);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create cohort - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  modify() {
    this.setState({ submitting: true });

    let payload = {
      name: this.state.name,
      description: this.state.description,
      script: this.state.script,
      collectionName: this.state.collectionName,
      key: this.state.key,
    };

    console.log(payload);

    APIV2.modifyMarketingCohort(this.props?.cohort?._id, payload)
      .then(
        (data) => {
          this.toggleModal();

          PubSub.publish(Event.COHORT.MODIFIED, data.data.cohort);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify cohort - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.cohort && !this.props.duplicate
                ? "Modify Cohort"
                : null}

              {!this.props.cohort ? "New Cohort" : null}
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  !this.state.name ||
                  !this.state.script ||
                  !this.state.collectionName
                }
                color="primary"
                onClick={this.submit.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>
                    {this.props.cohort && !this.props.duplicate
                      ? "Save"
                      : "Add"}
                  </>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            {this.state.testSuccess ? (
              <>
                <Alert color="success">{this.state.testSuccess}</Alert>
              </>
            ) : null}
            <FormGroup>
              <h5>
                Cohort Name{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="text"
                name="name"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Cohort Description</h5>
              <Input
                bsSize="sm"
                type="textarea"
                rows={2}
                name="description"
                placeholder="Description"
                value={this.state.description}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>
                MongoDB Cohort Key{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="text"
                name="key"
                placeholder="MongoDB Cohort Key"
                value={this.state.key}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>
                MongoDB Collection Name{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="text"
                name="collectionName"
                placeholder="MongoDB Collection Name"
                value={this.state.collectionName}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>
                MongDB Aggregation Query{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                type="textarea"
                style={{ minHeight: "70vh" }}
                value={this.state.script}
                onChange={this.handleInputChange.bind(this)}
                name="script"
                placeholder="MongoDB Aggregation Query"
              ></Input>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(CohortDrawer);
