import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import { Badge, Button, Col, Row } from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import swal from "sweetalert";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import TaskModifyDrawer from "./TaskModifyDrawer";
import CustomerInformationWidget from "components/Widgets/CustomerInformationWidget";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import NoteBody from "components/NoteBody";
import Spinner from "reactstrap/lib/Spinner";
import LeadContactInformationWidget from "components/Widgets/LeadContactInformationWidget";
import AffiliateAboutWidget from "components/Widgets/AffiliateAboutWidget";
import LinkUtils from "lib/LinkUtils";
import Linkify from "components/Linkify";
import FranLeadMessageDrawer from "../../components/Drawers/FranLeadMessageDrawer";

class TaskDetailDrawer extends React.Component {
  state = {
    dueDate: moment().day(6).toDate(),
    subject: "",
    description: "",
    relatedToType: "",
    type: "",
    priority: "Normal",
    status: "Not Started",
    assignedTo: null,
    timeToContact: "",
    customers: [],
    recentFirst: "recentFirst",
  };

  dropdownRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (prevProps?.task != this.props.task && this.props.task) {
      this.fetchNotes(true);
    }
  }

  componentDidMount() {
    if (this.props.task) {
      this.fetchNotes(true);
    }
  }

  toggleModal() {
    this.setState({
      name: "",
      email: "",
      phone: {
        value: "",
      },
      plan: null,
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  modifyStatus(status) {
    this.setState({ submitting: true });

    if (this.props.task?.storeTask) {
      APIV2.modifyGlobalTask(
        this.props?.task?.storeID,
        this?.props?.task?._id,
        this.props.task?.subject,
        this.props.task?.type,
        this.props.task?.description,
        this.props.task?.priority,
        this.props.task?.customerID ?? null,
        this.props.task?.leadID ?? null,
        this.props.task?.affiliateID ?? null,
        this.props.task?.frandevLeadID ?? null,
        status,
        this.props.task?.dueDate,
        this.props.task?.assignedTo ? this.props.task?.assignedTo?._id : null,
        this.props.task?.timeToContact,
        this.props.task?.storeTask,
        this.props.task?.activity ? this.props.task?.activity : null
      )
        .then(
          (data) => {
            const task = data.data.task;

            //this.toggleModal();

            PubSub.publish(Event.TASK.MODIFIED, task);
          },
          (e) => {
            this.setError(
              "error",
              e?.response?.body?.message ??
                "Unable to update task status - unknown error occurred. Try again."
            );
          }
        )
        .finally(() => {
          this.setState({ submitting: false });
        });
    } else {
      APIV2.modifyTask(
        this.props?.task?.storeID,
        this?.props?.task?._id,
        this.props.task?.subject,
        this.props.task?.type,
        this.props.task?.description,
        this.props.task?.priority,
        this.props.task?.customerID ?? null,
        this.props.task?.leadID ?? null,
        this.props.task?.affiliateID ?? null,
        status,
        this.props.task?.dueDate,
        this.props.task?.assignedTo ? this.props.task?.assignedTo?._id : null,
        this.props.task?.timeToContact,
        this.props.task?.storeTask
      )
        .then(
          (data) => {
            const task = data.data.task;

            //this.toggleModal();

            PubSub.publish(Event.TASK.MODIFIED, task);
          },
          (e) => {
            this.setError(
              "error",
              e?.response?.body?.message ??
                "Unable to update task status - unknown error occurred. Try again."
            );
          }
        )
        .finally(() => {
          this.setState({ submitting: false });
        });
    }
  }

  delete() {
    swal({
      title: "Delete Task",
      text: "Are you sure you want delete this task?",
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({ deleting: true });

      if (this.props.task?.storeTask) {
        APIV2.deleteGlobalTask(this?.props?.task?._id)
          .then(
            (data) => {
              this.toggleModal();

              PubSub.publish(Event.TASK.DELETED);

              this.toggleModal();
            },
            (e) => {
              this.setError(
                "error",
                e?.response?.body?.message ??
                  "Unable to delete task - unknown error occurred. Try again."
              );
            }
          )
          .finally(() => {
            this.setState({ deleting: false });
          });
      } else {
        APIV2.deleteTask(this.props?.task?.storeID, this?.props?.task?._id)
          .then(
            (data) => {
              this.toggleModal();

              PubSub.publish(Event.TASK.DELETED);

              this.toggleModal();
            },
            (e) => {
              this.setError(
                "error",
                e?.response?.body?.message ??
                  "Unable to delete task - unknown error occurred. Try again."
              );
            }
          )
          .finally(() => {
            this.setState({ deleting: false });
          });
      }
    });
  }

  formatDueDate(dateString, status) {
    if (!dateString) {
      return "--";
    }

    //console.log(dateString);

    let now = moment();
    let due = moment(dateString);

    if (now.toDate() > due.toDate() && status != "Completed") {
      return (
        <Badge color="" className="bg-yellow text-dark">
          {due.format("MMM DD, YYYY")}
        </Badge>
      );
    }

    return due.format("MMM DD, YYYY");
  }

  formatStatus(status) {
    if (!status) {
      return "--";
    }

    if (status == "Not Started") {
      return (
        <Badge
          style={{ fontSize: "0.75rem" }}
          color=""
          className="bg-yellow text-dark"
        >
          {status}
        </Badge>
      );
    }

    if (status == "In Progress") {
      return (
        <Badge style={{ fontSize: "0.75rem" }} color="info">
          {status}
        </Badge>
      );
    }

    if (status == "Completed") {
      return (
        <Badge style={{ fontSize: "0.75rem" }} color="light">
          {status}
        </Badge>
      );
    }

    if (status == "On Hold") {
      return (
        <Badge style={{ fontSize: "0.75rem" }} color="medium">
          {status}
        </Badge>
      );
    }
  }

  fetchNotes(forceLoad = false) {
    if (!this.props.task) {
      return;
    }

    if (forceLoad) {
      this.setState({
        loading: true,
      });
    }

    let query = {
      taskID: this?.props?.task?._id,
    };

    if (this.props.task?.leadID) {
      query = {
        leadID: this.props.task?.leadID,
      };
    }

    if (this.props.task?.customerID) {
      query = {
        customerID: this.props.task?.customerID,
      };
    }

    let sortBy = {
      createdAt: this.state.recentFirst == "recentFirst" ? -1 : 1,
    };

    APIV2.getNotesForStore("none", this.state.page, 20, query, sortBy)
      .then(
        (data) => {
          let notes = forceLoad ? [] : this.state.notes;

          notes = data?.data?.notes?.length
            ? notes.concat(data?.data?.notes)
            : notes;

          let notesOut = [];

          for (let i = 0; i < notes?.length; i++) {
            if (!_.findWhere(notesOut, { _id: notes[i]?._id })) {
              notesOut.push(notes[i]);
            }
          }

          this.setState({
            notes: notesOut,
            pagination: data?.data?.pagination,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  handleRecentFirstChange(v) {
    this.setState(
      {
        recentFirst: v,
        page: 1,
      },
      () => {
        this.fetchNotes(true);
      }
    );
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Task Details
            </h3>
            <Drawer.Actions>
              <Whisper
                ref={this.dropdownRef}
                trigger="click"
                placement={"autoVerticalEnd"}
                speaker={
                  <Popover full>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        panel
                        className="py-2 font-weight-800 text-uppercase"
                        style={{ paddingLeft: 12, paddingRight: 12 }}
                      >
                        <h5 className="m-0">Status</h5>
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={
                          this.props.task?.status == "Not Started" ||
                          this.state.submitting
                        }
                        onClick={() => {
                          this.modifyStatus("Not Started");

                          this.dropdownRef.current.close();
                        }}
                      >
                        Mark as Not Started
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={
                          this.props.task?.status == "In Progress" ||
                          this.state.submitting
                        }
                        onClick={() => {
                          this.modifyStatus("In Progress");

                          this.dropdownRef.current.close();
                        }}
                      >
                        Mark as In Progress
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={
                          this.props.task?.status == "Completed" ||
                          this.state.submitting
                        }
                        onClick={() => {
                          this.modifyStatus("Completed");

                          this.dropdownRef.current.close();
                        }}
                      >
                        Mark as Completed
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={
                          this.props.task?.status == "On Hold" ||
                          this.state.submitting
                        }
                        onClick={() => {
                          this.modifyStatus("On Hold");

                          this.dropdownRef.current.close();
                        }}
                      >
                        Mark as On Hold
                      </Dropdown.Item>

                      {(!this.props.preventModify ||
                        !this.props.preventDelete) && (
                        <>
                          <Dropdown.Item divider></Dropdown.Item>
                          <Dropdown.Item
                            panel
                            className="py-2 font-weight-800 text-uppercase"
                            style={{ paddingLeft: 12, paddingRight: 12 }}
                          >
                            <h5 className="m-0">Actions</h5>
                          </Dropdown.Item>
                        </>
                      )}
                      {!this.props.preventModify && (
                        <Dropdown.Item
                          onClick={() => {
                            this.setState({
                              modifyOpen: true,
                              modifyTask: this.props.task,
                            });

                            this.dropdownRef.current.close();
                          }}
                        >
                          Modify
                        </Dropdown.Item>
                      )}
                      {!this.props.preventDelete && (
                        <Dropdown.Item
                          className="text-danger"
                          disabled={this.state.deleting}
                          onClick={() => {
                            this.delete();

                            this.dropdownRef.current.close();
                          }}
                        >
                          Delete
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Popover>
                }
              >
                <Button size="sm" outline color="secondary">
                  Actions <i className="mdi mdi-chevron-down pl-2"></i>
                </Button>
              </Whisper>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}

            <FormGroup>
              <h4>{this.formatStatus(this.props.task?.status)}</h4>
              {this.props.task?.storeTask && (
                <>
                  <p className="mt-1 mb-0 text-dark">
                    {this.props?.task?.store?.name
                      ? this.props?.task?.store?.name
                      : "FranDev Team"}
                  </p>
                </>
              )}
              <h2 className="m-0">{this.props.task?.subject}</h2>
              {this.props.task?.description ? (
                <div className="mt-2">
                  <Linkify
                    body={this.props.task?.description}
                    showPreviews={false}
                    wrap={true}
                  ></Linkify>
                </div>
              ) : null}
            </FormGroup>

            <Row className="align-items-top">
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>Type</h5>
                  <p className="m-0">{this.props.task?.type}</p>
                </FormGroup>
              </Col>
              {this.props.task?.storeTask || !this.props.task?.storeID ? (
                <Col xs="12" md="6">
                  <FormGroup>
                    <h5>Activity</h5>
                    <p className="m-0">
                      {this.props.task?.activity
                        ? this.props.task?.activity
                        : "--"}
                    </p>
                  </FormGroup>
                </Col>
              ) : null}
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>Priority</h5>
                  <p className="m-0">
                    {this.props.task?.priority == "High" ? (
                      <i
                        className="mdi mdi-exclamation-thick text-danger pr-1"
                        style={{ lineHeight: 1 }}
                      ></i>
                    ) : null}
                    <span
                      className={`${
                        this.props.task?.priority == "High" && "text-danger"
                      }`}
                    >
                      {this.props.task?.priority}
                    </span>
                  </p>
                </FormGroup>
              </Col>
            </Row>
            <div>
              {this.props.task?.customer ||
              this.props.task?.lead ||
              this.props.task?.affiliate ? (
                <>
                  <hr className="my-3"></hr>
                  <Row className="mb-3 align-items-center">
                    <Col xs="">
                      <h4 className="mb-0">
                        Related {this.props.task?.customer ? "Member" : ""}
                        {this.props.task?.lead ? "Lead" : ""}
                        {this.props.task?.affiliate ? "Affiliate" : ""}
                      </h4>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        color="secondary"
                        outline
                        onClick={() => {
                          let url = "";

                          if (this.props.task?.customer?._id) {
                            url = `/storefront/${this.props?.task?.storeID}/members/details/${this.props.task?.customer?._id}/summary`;
                          } else if (this.props.task?.lead?._id) {
                            url = `/storefront/${this.props?.task?.storeID}/leads/details/${this.props.task?.leadID}/summary`; // TODO: fix this once lead page is built
                          } else if (this.props.task?.affiliate?._id) {
                            url = `/storefront/${this.props?.task?.storeID}/affiliates/details/${this.props.task?.affiliateID}/summary`; // TODO: fix this once lead page is built
                          }

                          LinkUtils.openInNewTab(url, true);
                        }}
                      >
                        View
                      </Button>
                    </Col>
                  </Row>
                  {this.props.task?.customer ? (
                    <>
                      <CustomerContactInformationWidget
                        customer={this.props.task?.customer}
                        flat={true}
                      />
                    </>
                  ) : null}
                  {this.props.task?.lead ? (
                    <>
                      <LeadContactInformationWidget
                        lead={this.props.task?.lead}
                        flat={true}
                        withGoal={true}
                        withSource={true}
                      ></LeadContactInformationWidget>
                    </>
                  ) : null}
                  {this.props.task?.affiliate ? (
                    <>
                      <AffiliateAboutWidget
                        flat={true}
                        affiliate={this.props.task?.affiliate}
                      ></AffiliateAboutWidget>
                    </>
                  ) : null}
                </>
              ) : null}
              {this.props.task?.frandevLead ? (
                <>
                  <hr className="my-3"></hr>
                  <h3 className="mb-3">Related FranDev Lead:</h3>
                  <div className="p-2 rounded border">
                    <h3 className="mb-0">
                      {this.props?.task?.frandevLead?.name}
                    </h3>
                    {this.props.task?.frandevLead?.contacts?.length ? (
                      <>
                        <h4 className="mb-1 mt-2">Contacts:</h4>
                        {this.props.task?.frandevLead?.contacts?.map(
                          (contact, i) => (
                            <div
                              className={`${
                                !contact?.email && !contact?.phone && "d-none"
                              }`}
                              key={i}
                            >
                              <Row className="align-items-center">
                                <Col xs="">
                                  <p className="m-0 text-dark">
                                    {contact?.name}
                                  </p>
                                  <p
                                    className="m-0 small"
                                    style={{ lineHeight: 1.2 }}
                                  >
                                    {contact?.phone
                                      ? `Phone: ${StringUtils.formatPhoneNumber(
                                          contact?.phone?.replace("+1", "")
                                        )}`
                                      : null}
                                    {contact?.email
                                      ? `${contact?.phone ? ", " : ""}Email: ${
                                          contact?.email
                                        }`
                                      : null}
                                  </p>
                                </Col>
                                <Col xs="auto">
                                  {contact?.phone ? (
                                    <>
                                      <Button
                                        className="btn-icon-only my-0"
                                        size="sm"
                                        outline
                                        color="dark"
                                        onClick={() => {
                                          window.open(
                                            `tel:${contact?.phone}`,
                                            "_self"
                                          );
                                        }}
                                      >
                                        <i className="mdi mdi-phone"></i>
                                      </Button>
                                      <Button
                                        disabled={
                                          !contact?.smsConversationSupported
                                        }
                                        className="btn-icon-only my-0"
                                        size="sm"
                                        outline
                                        color="dark"
                                        onClick={() => {
                                          this.setState({
                                            messageOpen: true,
                                            messageContact: contact,
                                            messageLead: this.props.task
                                              ?.frandevLead
                                              ? this.props.task?.frandevLead
                                              : {},
                                          });

                                          PubSub.publish(
                                            "editor:contentUpdated",
                                            {
                                              event: "contentUpdated",
                                              forceHydrate: true,
                                              id: "franleadMessageEditor",
                                              data: {},
                                            }
                                          );
                                        }}
                                      >
                                        <i className="mdi mdi-message-text"></i>
                                      </Button>
                                    </>
                                  ) : null}
                                  {contact?.email ? (
                                    <>
                                      <Button
                                        size="sm"
                                        outline
                                        color="dark"
                                        className="btn-icon-only"
                                        onClick={() => {
                                          window.open(
                                            `mailto:${contact?.email}`,
                                            "_self"
                                          );
                                        }}
                                      >
                                        <i className="mdi mdi-send"></i>
                                      </Button>
                                    </>
                                  ) : null}
                                </Col>
                              </Row>
                              {i !=
                              this.props.task?.frandevLead?.contacts?.length -
                                1 ? (
                                <>
                                  <hr className="my-2"></hr>
                                </>
                              ) : null}
                            </div>
                          )
                        )}
                      </>
                    ) : null}
                    {this.props.task?.frandevLead?.businesses?.length ? (
                      <>
                        <h4 className="mb-1 mt-3">Businesses:</h4>
                        {this.props.task?.frandevLead?.businesses?.map(
                          (contact, i) => (
                            <div key={i} className="mb-2">
                              <Row className="align-items-center">
                                <Col xs="">
                                  <p className="m-0">{contact?.name}</p>
                                </Col>
                                <Col xs="auto">
                                  {contact?.websiteURL ? (
                                    <>
                                      <Button
                                        className="btn-icon-only my-0"
                                        size="sm"
                                        outline
                                        color="dark"
                                        onClick={() => {
                                          LinkUtils.openInNewTab(
                                            contact?.websiteURL
                                          );
                                        }}
                                      >
                                        <i className="mdi mdi-open-in-new"></i>
                                      </Button>
                                    </>
                                  ) : null}
                                </Col>
                              </Row>
                              {i !=
                              this.props.task?.frandevLead?.businesses?.length -
                                1 ? (
                                <>
                                  <hr className="my-2"></hr>
                                </>
                              ) : null}
                            </div>
                          )
                        )}
                      </>
                    ) : null}
                  </div>
                  <div className="text-right mt-2">
                    <Button
                      onClick={() => {
                        let url = `/frandev/leads/${this.props.task?.frandevLead?._id}/summary`;

                        this.props.history.push(url);
                      }}
                      size="sm"
                      color="secondary"
                      outline
                    >
                      View
                    </Button>
                  </div>
                </>
              ) : null}
            </div>

            <hr className="my-3"></hr>
            <h4 className="mb-3">Completion Information</h4>
            <Row className="align-items-top">
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>
                    Due Date
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  {this.formatDueDate(
                    this.props.task?.dueDate,
                    this.props.task?.status
                  )}
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>Status</h5>
                  <p className="m-0">{this.props.task?.status}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>Assigned To</h5>

                  <p className="m-0 text-dark text-truncate">
                    {this.props.task?.assignedTo?.name
                      ? this.props.task?.assignedTo?.name
                      : "--"}
                  </p>
                  {this.props.task?.assignedTo?.email ? (
                    <p
                      className="m-0 text-truncate"
                      style={{ fontSize: 12, lineHeight: 1.2 }}
                    >
                      {this.props.task?.assignedTo?.email}
                    </p>
                  ) : null}
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>Created By</h5>
                  <p className="m-0 text-dark text-truncate">
                    {this.props.task?.createdBy?.name
                      ? this.props.task?.createdBy?.name
                      : "--"}
                  </p>
                  {this.props.task?.createdBy?.email ? (
                    <p
                      className="m-0 text-truncate"
                      style={{ fontSize: 12, lineHeight: 1.2 }}
                    >
                      {this.props.task?.createdBy?.email}
                    </p>
                  ) : null}
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>Created At</h5>
                  {this.props.task?.createdAt
                    ? moment(this.props.task?.createdAt)?.format(
                        "MM/DD/YY h:mm A"
                      )
                    : "--"}
                </FormGroup>
              </Col>
            </Row>
            <hr className="my-3"></hr>
            <Row className="align-items-center mb-3">
              <Col>
                {this.props.task?.leadID && !this.props.task?.customerID ? (
                  <>
                    <h3 className="text-dark mb-0">Lead Notes</h3>
                    <p
                      className="mb-0 mt-1 small text-muted"
                      style={{ lineHeight: 1.2 }}
                    >
                      You're viewing &amp; adding notes directly to the lead
                      profile right from inside this task.
                    </p>
                  </>
                ) : null}
                {this.props.task?.customerID ? (
                  <>
                    <h3 className="text-dark mb-0">
                      <Badge color="success" className="mr-2">
                        NEW
                      </Badge>
                      Member Notes
                    </h3>
                    <p
                      className="mb-0 mt-1 small text-muted"
                      style={{ lineHeight: 1.2 }}
                    >
                      You're viewing &amp; adding notes directly to the member
                      profile right from inside this task.
                    </p>
                  </>
                ) : null}
                {!this.props.task?.leadID && !this.props.task?.customerID ? (
                  <h4 className="text-dark mb-0">Notes</h4>
                ) : null}
              </Col>
              <Col xs="auto">
                <SelectPicker
                  size="sm"
                  searchable={false}
                  data={[
                    { label: "Newest First", value: "recentFirst" },
                    { label: "Oldest First", value: "oldestFirst" },
                  ]}
                  value={this.state.recentFirst}
                  onChange={(v) => {
                    this.handleRecentFirstChange(v);
                  }}
                  cleanable={false}
                  placement="auto"
                ></SelectPicker>
              </Col>
            </Row>

            <div>
              {this.props.task?.leadID && !this.props.task?.customerID ? (
                <NoteBody
                  lead={{ _id: this.props.task?.leadID }}
                  onSave={(note) => {
                    //console.log(note);

                    this.setState(
                      {
                        page: 1,
                      },
                      () => {
                        this.fetchNotes(true);
                      }
                    );
                  }}
                ></NoteBody>
              ) : null}
              {this.props.task?.customerID ? (
                <NoteBody
                  customer={{ _id: this.props.task?.customerID }}
                  onSave={(note) => {
                    //console.log(note);

                    this.setState(
                      {
                        page: 1,
                      },
                      () => {
                        this.fetchNotes(true);
                      }
                    );
                  }}
                ></NoteBody>
              ) : null}
              {!this.props.task?.leadID && !this.props.task?.customerID ? (
                <NoteBody
                  task={this.props.task}
                  onSave={(note) => {
                    // console.log(note);

                    this.setState(
                      {
                        page: 1,
                      },
                      () => {
                        this.fetchNotes(true);
                      }
                    );
                  }}
                ></NoteBody>
              ) : null}
            </div>
            <div>
              {this.state?.loading && (
                <div className="px-3 py-5 text-center">
                  <Spinner size="sm" color="dark"></Spinner>
                </div>
              )}
              {!this.state?.notes?.length && !this.state.loading && (
                <div className="px-3 py-5">
                  <Row>
                    <Col xs="12" className="text-center">
                      <p className="m-0">No notes found.</p>
                    </Col>
                  </Row>
                </div>
              )}

              {this.state?.notes?.map((note, i) => (
                <div key={note?._id}>
                  {this.state.editNote == note?._id ? (
                    <>
                      <div className="p-4">
                        <NoteBody
                          note={note}
                          task={this.props.task}
                          forceEdit={true}
                          onSave={(n) => {
                            let notes = this.state.notes;

                            let idx = _.findIndex(notes, { _id: n._id });

                            if (idx >= 0) {
                              notes[idx] = n;
                            }

                            this.setState({
                              editNote: "",
                              notes,
                            });
                          }}
                          onCancel={() => {
                            this.setState({ editNote: "" });
                          }}
                        ></NoteBody>
                      </div>
                    </>
                  ) : (
                    <div className="px-3 cursor-pointer py-3 bg-superlight--hover">
                      <Row>
                        <Col
                          xs=""
                          style={{ maxWidth: "calc(100% - 78px)" }}
                          className="pr-0"
                        >
                          {note?.title ? (
                            <>
                              <h3 className="text-dark mb-1">{note?.title}</h3>
                              <Linkify body={note?.body} wrap={true}></Linkify>
                            </>
                          ) : (
                            <>
                              <Linkify body={note?.body} wrap={true}></Linkify>
                            </>
                          )}
                        </Col>
                        <Col xs="auto">
                          <Button
                            size="sm"
                            color="secondary"
                            outline
                            className="p-1 mr-0"
                            style={{ width: 22, height: 22 }}
                            onClick={() => {
                              this.setState({
                                editNote: note?._id,
                              });
                            }}
                          >
                            <i
                              className="mdi mdi-pencil"
                              style={{
                                fontSize: 16,
                                lineHeight: 1,
                                position: "relative",
                                left: -2,
                                top: -2,
                              }}
                            ></i>
                          </Button>
                          <Button
                            size="sm"
                            color="danger"
                            outline
                            className="p-1"
                            style={{ width: 22, height: 22 }}
                            disabled={this.state.deletingNote == note?._id}
                            onClick={() => {
                              swal({
                                title: "Delete Note?",
                                text: "Are you sure you want to delete this note?",
                                icon: "warning",
                                buttons: ["Nevermind", "Delete"],
                                dangerMode: true,
                              }).then((conf) => {
                                if (!conf) {
                                  return;
                                }

                                APIV2.deleteStoreNote(
                                  note?.storeID,
                                  note?._id
                                ).then(() => {
                                  let notes = this.state.notes;

                                  let idx = _.findIndex(notes, {
                                    _id: note._id,
                                  });

                                  notes.splice(idx, 1);

                                  this.setState({
                                    editNote: "",
                                    notes,
                                  });

                                  this.setState({
                                    deletingNote: "",
                                  });
                                });
                              });
                            }}
                          >
                            <i
                              className="mdi mdi-close"
                              style={{
                                fontSize: 18,
                                lineHeight: 1,
                                position: "relative",
                                left: -3,
                                top: -3,
                              }}
                            ></i>
                          </Button>
                        </Col>
                        {note?.attachments?.length > 0 && (
                          <>
                            <Col className="mt-2" xs="12">
                              <h5>Attachments: </h5>
                              {note?.attachments?.map((at, i) => (
                                <div
                                  className={`p-2 border rounded ${
                                    i != this.state?.attachments?.length - 1 &&
                                    "mb-2"
                                  }`}
                                  key={i}
                                >
                                  <Row className="align-items-center">
                                    <Col xs="">
                                      <p className="small m-0 text-dark">
                                        {at?.fileName}
                                      </p>
                                    </Col>
                                    <Col xs="auto">
                                      <Button
                                        className="btn-icon-only"
                                        size="sm"
                                        outline
                                        color="secondary"
                                        onClick={() => {
                                          LinkUtils.openPrivateURL(at?.url);
                                        }}
                                      >
                                        <i className="mdi mdi-eye"></i>
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                            </Col>
                          </>
                        )}
                      </Row>
                      <div className="mt-2">
                        <small>
                          {moment(note?.createdAt).format("MM/DD/YY h:mmA")} by{" "}
                          {note?.createdBy?.name}
                        </small>
                      </div>
                    </div>
                  )}
                  {i != this.state?.notes?.length - 1 && (
                    <hr className="m-0"></hr>
                  )}
                </div>
              ))}
            </div>
            <div className="text-center p-2">
              <Button
                size="sm"
                color="secondary"
                outline
                onClick={() => {
                  // console.log(this.state.pagination);

                  this.setState(
                    {
                      page: this.state.pagination?.next,
                    },
                    () => {
                      this.fetchNotes();
                    }
                  );
                }}
                disabled={!this.state.pagination?.hasNext}
              >
                {this.state.pagination?.hasNext
                  ? "View More Notes"
                  : "Viewing All Notes"}
              </Button>
            </div>
          </Drawer.Body>
        </Drawer>
        <TaskModifyDrawer
          allowedTypes={this.props.allowedTypes}
          store={this.props.store}
          open={this.state.modifyOpen}
          task={this.state.modifyTask}
          onClose={() => {
            this.setState({
              modifyOpen: false,
              modifyTask: null,
            });
          }}
        ></TaskModifyDrawer>
        <FranLeadMessageDrawer
          open={this.state.messageOpen}
          lead={this.state.messageLead}
          contact={this.state.messageContact}
          onClose={() => {
            this.setState({
              messageOpen: false,
              messageLead: null,
              messageContact: null,
            });
          }}
        ></FranLeadMessageDrawer>
      </>
    );
  }
}

export default withRouter(TaskDetailDrawer);
