import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import {
  Checkbox,
  Dropdown,
  Popover,
  SelectPicker,
  Tooltip,
  Whisper,
} from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import MarketingAutomationDrawer from "../../components/Drawers/MarketingAutomationDrawer";
import swal from "sweetalert";
import Constant from "../../lib/Constant";

class MarketingAutomationListView extends React.Component {
  state = {
    active: {
      id: "All",
      name: "All",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 25,
    filters: null,
    orderBy: {
      scheduledFor: -1,
    },
    sortByOption: "dueDate",
    sortByDirection: "ascending",
    truncate: true,
    truncateStyles: {
      maxHeight: "30px",
    },
    channels: [
      {
        value: "conversations",
        label: "Conversations Automation",
      },
      {
        value: "email",
        label: "Email",
      },
      {
        value: "push",
        label: "Push Notification",
      },
    ],
  };

  deletingAutomation(automation) {
    swal({
      title: "Delete Automation",
      text: `Are you sure you want to delete the "${automation?.name}" marketing automation?`,
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deletingAutomation: automation?._id,
      });

      APIV2.deleteMarketingAutomation(automation?._id)
        .then(
          () => {
            PubSub.publish(Event.COHORT.DELETED, automation);

            swal({
              title: "Automation Deleted",
              text: "Your automation has been successfully deleted",
              icon: "success",
            });
          },
          (e) => {
            window.alert(
              e?.replace?.message ??
                "Unable to delete automation. Please try again."
            );
          }
        )
        .finally(() => {
          this.setState({
            deletingAutomation: null,
          });
        });
    });
  }

  activateAutomation(automation, active) {
    swal({
      title: `${active ? "Activate" : "Deactivate"} Automation?`,
      text: `Are you sure you want to ${
        active ? "activate" : "deactivate"
      } the "${automation?.name}" marketing automation?`,
      icon: "warning",
      buttons: ["Nevermind", `${active ? "Activate" : "Deactivate"}`],
      dangerMode: !active,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        activatingAutomation: automation?._id,
      });

      APIV2.modifyMarketingAutomation(automation?._id, { active })
        .then(
          () => {
            PubSub.publish(Event.COHORT.MODIFIED, automation);

            swal({
              title: `Automation ${active ? "Activated" : "Deactivated"}`,
              text: `Your automation has been successfully ${
                active ? "activated" : "deactivated"
              }`,
              icon: "success",
            });
          },
          (e) => {
            window.alert(
              e?.replace?.message ??
                "Unable to modify automation status. Please try again."
            );
          }
        )
        .finally(() => {
          this.setState({
            activatingAutomation: null,
          });
        });
    });
  }

  componentDidUpdate(prevProps) {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }

    PubSub.subscribe(Event.COHORT.CREATED, (m) => {
      this.loadAutomations(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });

    PubSub.subscribe(Event.COHORT.MODIFIED, (a) => {
      this.loadAutomations(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );

      if (this.state.modifyAutomation?._id == a?._id) {
        this.setState({
          modifyAutomation: a,
        });
      }
    });

    PubSub.subscribe(Event.COHORT.DELETED, (a) => {
      this.loadAutomations(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });
  }

  loadAutomations(page = 1, count = 25, filters = null, orderBy) {
    this.setState({
      loadingAutomations: true,
    });

    APIV2.getMarketingAutomations(page, count, filters, orderBy)
      .then(
        (data) => {
          let automations = [];

          if (data?.data?.automations?.length) {
            automations = data?.data?.automations;
          }

          for (let i = 0; i < automations?.length; i++) {
            automations[i].dropdownRef = React.createRef();
          }

          this.setState({
            automations: automations,
            pagination: data.data.pagination,
            result: data.data.result,
          });
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the automations. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingAutomations: false,
        });
      });
  }

  handleTabChange(option, force = false) {
    let filters = {};
    let orderBy = {
      scheduledFor: -1,
    };

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    if (option.id == "Members") {
      filters.collectionName = "Customers";
    } else if (option.id == "Leads") {
      filters.collectionName = "CustomerLeads";
    } else if (option.id == "Retail Customers") {
      filters.collectionName = "RetailCustomers";
    } else if (option.id == "Franchise Leads") {
      filters.collectionName = "FranchiseLeads";
    }

    this.setState({
      filters,
      orderBy,
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadAutomations(1, this.state.count, filters, orderBy);
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadAutomations(
      page,
      this.state.count,
      this.state.filters,
      this.state.orderBy
    );
  }

  automationRow(c) {
    return (
      <Row className="align-items-center">
        <Col xs="12" sm="6" md="8">
          <Row className="align-items-center">
            <Col xs="auto">
              <Whisper
                trigger="hover"
                speaker={
                  <Tooltip className="p-2">
                    {c?.active ? (
                      <div className="mb-2 pb-2 border-bottom">
                        <h4 className="text-white mb-1">Active Automation</h4>
                        <p
                          className="text-white mb-1"
                          style={{ fontSize: 14, lineHeight: 1.2 }}
                        >
                          This automation is currently active and will be
                          triggered by the system.
                        </p>
                        <p
                          className="text-white m-0"
                          style={{ fontSize: 12, lineHeight: 1.2 }}
                        >
                          Activated On:{" "}
                          {moment(c?.activeOn).format("MMM D, YYYY h:mm A")}
                        </p>
                      </div>
                    ) : null}
                    <div className="mb-2 pb-2 border-bottom">
                      <h4 className="text-white mb-1">Target Cohort</h4>
                      <Row className="align-items-center">
                        <Col xs="" className="pr-0">
                          <p
                            className="m-0 text-white"
                            style={{ fontSize: 14, lineHeight: 1.2 }}
                          >
                            {c?.cohort?.name}
                          </p>
                        </Col>
                        <Col xs="auto" className="pl-1">
                          <p
                            className="m-0 text-white"
                            style={{ fontSize: 14, lineHeight: 1.2 }}
                          >
                            {StringUtils.numberFormat(c?.cohort?.recordCount)}
                          </p>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <h4 className="text-white mb-1">Excluded Cohorts</h4>
                      {c?.excludeCohorts?.length ? (
                        <>
                          {c?.excludeCohorts?.map((ec, j) => (
                            <Row key={j} className="align-items-center mb-2">
                              <Col xs="" className="pr-0">
                                <p
                                  className="m-0 text-white"
                                  style={{ fontSize: 12, lineHeight: 1.2 }}
                                >
                                  {ec?.name}
                                </p>
                              </Col>
                              <Col xs="auto" className="pl-1">
                                <p
                                  className="m-0 text-white"
                                  style={{ fontSize: 12, lineHeight: 1.2 }}
                                >
                                  {StringUtils.numberFormat(ec?.recordCount)}
                                </p>
                              </Col>
                            </Row>
                          ))}
                        </>
                      ) : (
                        <>
                          <p
                            className="text-white m-0"
                            style={{ fontSize: 12, lineHeight: 1.2 }}
                          >
                            None
                          </p>
                        </>
                      )}
                    </div>
                  </Tooltip>
                }
              >
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="btn-icon-only"
                >
                  <i className="mdi mdi-information-outline"></i>
                </Button>
              </Whisper>
            </Col>
            <Col xs="" className="pl-0">
              <h3 className="mb-0">{c.name}</h3>
              {c?.description ? (
                <p className="m-0" style={{ fontSize: 12 }}>
                  {c?.description}
                </p>
              ) : null}
              <p
                className="m-0 text-dark font-weight-medium"
                style={{ fontSize: 13 }}
              >
                <div
                  className={`d-inline-block mr-1 ${
                    c?.active ? "bg-success" : "bg-medium"
                  }`}
                  style={{
                    borderRadius: "100%",
                    height: 10,
                    width: 10,
                    position: "relative",
                    top: 1,
                  }}
                ></div>
                {c?.active ? "Active" : "Inactive"}

                <span className="pl-3">
                  {c?.steps?.length ? c?.steps?.length : 0}&nbsp;Steps
                </span>
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs="6" sm="6" md="2">
          <p className="m-0">
            {c?.active ? (
              <Badge color="success">Active</Badge>
            ) : (
              <Badge color="medium">Inactive</Badge>
            )}
          </p>
        </Col>

        <Col xs="6" sm="6" md="2" className="text-right order-1 order-md-2">
          <Whisper
            onClose={() => {
              this.setState({
                dropdownOpen: null,
              });
            }}
            trigger="click"
            ref={c.dropdownRef}
            placement="autoVerticalEnd"
            speaker={
              <Popover className="dropdown-popover" full>
                <Dropdown.Menu
                  style={{
                    minWidth: 150,
                    paddingBottom: 0,
                  }}
                >
                  <Dropdown.Item
                    panel
                    className="font-weight-800 text-uppercase"
                    style={{
                      padding: 12,
                    }}
                  >
                    <h5 className="m-0 text-dark">Actions</h5>
                  </Dropdown.Item>
                  <Dropdown.Item className="my-0" divider></Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      e.stopPropagation();

                      this.setState({
                        modifyAutomation: c,
                        createOpen: true,
                      });

                      c.dropdownRef.current.close();
                    }}
                    className="text-dark bg-superlight--hover"
                  >
                    <Row className="align-items-center">
                      <Col xs="auto" className="pr-0">
                        <i
                          className={`mdi mdi-pencil-box-outline`}
                          style={{ fontSize: 16, lineHeight: 1.3 }}
                        ></i>
                      </Col>
                      <Col xs="" className="pl-2">
                        <p className="m-0" style={{ fontSize: 14 }}>
                          Modify Information
                        </p>
                      </Col>
                    </Row>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      e.stopPropagation();

                      this.props.history.push(
                        `/global/app/automations/${c._id}`
                      );

                      c.dropdownRef.current.close();
                    }}
                    className="text-dark bg-superlight--hover"
                  >
                    <Row className="align-items-center">
                      <Col xs="auto" className="pr-0">
                        <i
                          className={`mdi mdi-sitemap`}
                          style={{ fontSize: 16, lineHeight: 1.3 }}
                        ></i>
                      </Col>
                      <Col xs="" className="pl-2">
                        <p className="m-0" style={{ fontSize: 14 }}>
                          Modify Steps
                        </p>
                      </Col>
                    </Row>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      e.stopPropagation();

                      this.activateAutomation(c, !c.active);

                      c.dropdownRef.current.close();
                    }}
                    disabled={this.state.activatingAutomation == c?._id}
                    className="text-dark bg-superlight--hover"
                  >
                    <Row className="align-items-center">
                      <Col xs="auto" className="pr-0">
                        <i
                          className={`mdi mdi-${c.active ? "pause" : "play"}`}
                          style={{ fontSize: 16, lineHeight: 1.3 }}
                        ></i>
                      </Col>
                      <Col xs="" className="pl-2">
                        <p className="m-0" style={{ fontSize: 14 }}>
                          {c.active ? "Deactivate" : "Activate"} Automation
                        </p>
                      </Col>
                    </Row>
                  </Dropdown.Item>

                  <Dropdown.Item divider></Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      //this.settingsDropdownRef.current.close();

                      e.stopPropagation();

                      this.deletingAutomation(c);

                      c.dropdownRef.current.close();
                    }}
                    disabled={
                      this.state.deletingAutomation == c?._id || c?.active
                    }
                    className={`${
                      c?.active ? "cursor-notallowed" : "bg-superlight--hover"
                    }`}
                  >
                    <Row className="align-items-center">
                      <Col xs="auto" className="pr-0">
                        <i
                          className={`mdi mdi-trash-can-outline ${
                            c?.active ? "text-light" : "text-danger"
                          }`}
                          style={{ fontSize: 16, lineHeight: 1.3 }}
                        ></i>
                      </Col>
                      <Col xs="" className="pl-2">
                        <p
                          className={`m-0 ${
                            c?.active ? "text-light" : "text-dark"
                          }`}
                          style={{ fontSize: 14, lineHeight: 1 }}
                        >
                          Delete Automation
                        </p>
                      </Col>
                    </Row>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Popover>
            }
          >
            <Button
              size="sm"
              outline
              color="dark"
              className="btn-icon-only"
              onClick={(e) => {
                e.stopPropagation();

                if (this.state.dropdownOpen == c?._id) {
                  this.setState({
                    dropdownOpen: "",
                  });

                  return c.dropdownRef.current.close();
                }

                this.setState({ dropdownOpen: c?._id });
              }}
            >
              <i className="mdi mdi-dots-vertical"></i>
            </Button>
          </Whisper>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Automations"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  createOpen: true,
                });
              },
            }}
          />

          <Row>
            <div className="col">
              <Card className=" shadow" style={{ minHeight: "500px" }}>
                <CardSelectorHeader
                  compact={true}
                  value={this.state.active}
                  options={[
                    {
                      name: "All",
                      id: "All",
                    },
                    {
                      name: "Members / HFNs",
                      id: "Members",
                    },
                    {
                      name: "Leads",
                      id: "Leads",
                    },
                    {
                      name: "Retail Customers",
                      id: "Retail Customers",
                    },
                    {
                      name: "Franchise Leads",
                      id: "Franchise Leads",
                    },
                  ]}
                  onChange={this.handleTabChange.bind(this)}
                ></CardSelectorHeader>
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                    <Col xs="" sm="" className="">
                      {this.state.searchResults ? (
                        <>
                          Found{" "}
                          {this.state.searchResultAutomations?.length
                            ? StringUtils.numberFormat(
                                this.state.searchResultAutomations?.length
                              )
                            : "0"}{" "}
                          automation
                          {this.state.searchResultAutomations?.length == 1
                            ? ""
                            : "s"}{" "}
                          matching your search.
                        </>
                      ) : (
                        <div>
                          {this.state.result?.start &&
                          this.state.result?.end ? (
                            <>
                              Showing{" "}
                              {StringUtils.numberFormat(
                                this.state.result?.start
                              )}
                              -
                              {StringUtils.numberFormat(this.state.result?.end)}
                              {this.state.result?.total ? (
                                <>
                                  &nbsp;of{" "}
                                  {StringUtils.numberFormat(
                                    this.state.result?.total
                                  )}{" "}
                                  automations
                                </>
                              ) : null}
                            </>
                          ) : (
                            <>---</>
                          )}
                        </div>
                      )}
                    </Col>
                    <Col
                      xs="auto"
                      sm="auto"
                      className="pl-2 pl-sm-3 text-right"
                    >
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        className="btn-icon-only"
                        outline
                        disabled={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious ||
                          this.state.searchResults
                        }
                        onClick={() => {
                          this.incrementPage(-1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-left"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        disabled={
                          !this.state.pagination?.hasNext ||
                          this.state.searchResults
                        }
                        className="btn-icon-only"
                        outline
                        onClick={() => {
                          this.incrementPage(1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-right"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
                    <Row className="align-items-center">
                      <Col xs="12" sm="12" md="8">
                        <h4 className="mb-0">Automation</h4>
                      </Col>

                      <Col xs="12" sm="12" md="2">
                        <h4 className="mb-0">Status</h4>
                      </Col>

                      <Col
                        xs="12"
                        sm="12"
                        md="2"
                        className="text-left text-md-right d-none d-md-block"
                      >
                        <h4 className="mb-0">Actions</h4>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    {this.state.loadingAutomations ||
                    this.state.searchLoading ? (
                      <>
                        <div
                          className="text-center p-3"
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            background: "rgba(255, 255, 255, 0.6)",
                          }}
                        >
                          <Spinner
                            style={{ position: "relative", top: "15vh" }}
                            color="medium"
                            size=""
                          ></Spinner>
                        </div>
                      </>
                    ) : null}
                    {this.state.searchResults ? (
                      <div>
                        {!this.state.searchResultAutomations?.length &&
                        !this.state.searchLoading ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>
                                No automations found matching your search "
                                {this.state.search}"
                              </Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.searchResultAutomations?.map((c, i) => (
                          <div
                            className={`px-3 py-2 ${
                              i != this.state.searchResultAutomations.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i}
                          >
                            {this.automationRow(c)}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        {!this.state.automations?.length &&
                        !this.state.loadingAutomations ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>
                                Get started by adding your first automation.
                              </Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.automations?.map((c, i) => (
                          <div
                            className={`px-3 py-2 ${
                              i != this.state.automations.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i}
                          >
                            {this.automationRow(c)}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <MarketingAutomationDrawer
          open={this.state.createOpen}
          onClose={() => {
            this.setState({
              createOpen: false,
              duplicate: false,
              modifyAutomation: null,
            });
          }}
          automation={this.state.modifyAutomation}
        ></MarketingAutomationDrawer>
      </>
    );
  }
}

export default MarketingAutomationListView;
