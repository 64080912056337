import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
  Badge,
  FormGroup,
  Collapse,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import { DatePicker, SelectPicker } from "rsuite";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";
import Constant from "../../lib/Constant";
import _ from "underscore";
import moment from "moment";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import LinkUtils from "lib/LinkUtils";
import { de } from "date-fns/locale";
import swal from "sweetalert";

class FranDevLeadDatesWidget extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    name: "",
    email: "",
  };

  modifyFranDevLeadMarket(id, data) {
    let payload = {
      ...data,
    };

    if (payload?.hasOwnProperty("expanded")) {
      delete payload.expanded;
    }

    if (payload.priority) {
      payload.priority = parseInt(payload.priority);
    }

    if (payload?._id) {
      delete payload?._id;
    }

    this.setState({
      valSubmitting: true,
    });

    APIV2.modifyFranDevLeadMarket(this.props.lead?._id, id, payload)
      .then(
        (data) => {
          PubSub.publish(Event.LEAD.MODIFIED, data.data.lead);

          this.setState({
            [`editing${id}`]: false,
            [`values${id}`]: {},
          });
        },
        (e) => {
          this.setError(
            id,
            `Error modifying market: ${
              e?.response?.data?.error?.message ?? `Unknown error`
            }`
          );
        }
      )
      .finally(() => {
        this.setState({
          valSubmitting: false,
        });
      });
  }

  createFranDevLeadMarket(data) {
    this.setState({
      valSubmitting: true,
    });

    let payload = {
      ...data,
    };

    if (payload?.hasOwnProperty("expanded")) {
      delete payload.expanded;
    }

    APIV2.createFranDevLeadMarket(this.props.lead?._id, payload)
      .then(
        (data) => {
          PubSub.publish(Event.LEAD.MODIFIED, data.data.lead);

          this.setState({
            showCreate: false,
            [`valuesCreate`]: {},
          });
        },
        (e) => {
          this.setError(
            "create",
            `Error creating market: ${
              e?.response?.data?.error?.message ?? `Unknown error`
            }`
          );
        }
      )
      .finally(() => {
        this.setState({
          valSubmitting: false,
        });
      });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  deleteFranDevLeadMarket(id) {
    swal({
      title: "Delete Market",
      text: `Are you sure you want to delete this market?`,
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deletingMarket: id,
      });

      APIV2.deleteFranDevLeadMarket(this.props.lead?._id, id)
        .then(
          (data) => {
            PubSub.publish(Event.LEAD.MODIFIED, data.data.lead);

            this.setState({
              [`editing${id}`]: false,
              [`values${id}`]: {},
            });
          },
          (e) => {
            this.setError(
              id,
              `Error deleting market: ${
                e?.response?.data?.error?.message ?? `Unknown error`
              }`
            );
          }
        )
        .finally(() => {
          this.setState({
            deletingMarket: false,
          });
        });
    });
  }

  render() {
    return (
      <>
        <Card className="mb-4 shadow border">
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Markets</h3>
              </div>
              <Col xs="auto">
                {this.state.showCreate ? (
                  <>
                    <Button
                      size="sm"
                      outline
                      color="dark"
                      onClick={() => {
                        this.setState({
                          showCreate: false,
                          [`valuesCreate`]: {},
                        });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={
                        this.state.valSubmitting ||
                        !this.state.valuesCreate?.name
                      }
                      size="sm"
                      color="primary"
                      onClick={() => {
                        this.createFranDevLeadMarket(this.state.valuesCreate);
                      }}
                    >
                      {this.state.valSubmitting ? (
                        <Spinner size="sm"></Spinner>
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    size="sm"
                    outline
                    color="primary"
                    className="btn-icon-only"
                    onClick={() => {
                      this.setState({
                        showCreate: true,
                        [`valuesCreate`]: {},
                      });
                    }}
                  >
                    <i className="mdi mdi-plus"></i>
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>
          {this.props.loading ? (
            <CardBody>
              <Spinner size="sm" color="dark" className="mr-2" /> Loading...
            </CardBody>
          ) : (
            <>
              {this.state.error ? (
                <>
                  <CardBody>
                    <Alert color="danger" className="mb-0 mt-0">
                      {this.state.error}
                    </Alert>
                  </CardBody>
                </>
              ) : null}
              {this.state.showCreate ? (
                <CardBody className="border-bottom py-3">
                  <div className="mt-3">
                    <Row className="align-items-center mb-3">
                      <Col xs="">
                        <h4 className="m-0">Create Market</h4>
                      </Col>
                    </Row>

                    <FormGroup className="mb-2">
                      <h5>Market Name</h5>
                      <ReactGoogleAutocomplete
                        required
                        placeholder="Address"
                        apiKey={"AIzaSyA5-fbnedb5uXDBwbjnz0J_2eDe_9Gi2Cs"}
                        onPlaceSelected={(place) => {
                          console.log(place);

                          let c = this.state[`valuesCreate`];

                          c.address = place.formatted_address;
                          c.name = place.formatted_address;
                          c.addressComponents = place.address_components;
                          c.geometry = place.geometry;
                          c.placeID = place.place_id;

                          this.setState({
                            [`valuesCreate`]: c,
                          });
                        }}
                        onChange={(e) => {
                          let c = this.state[`valuesCreate`];

                          c.address = e.target.value;
                          c.name = e.target.value;

                          this.setState({
                            [`valuesCreate`]: c,
                          });
                        }}
                        name="marketName"
                        value={this.state[`valuesCreate`]?.name}
                        className="form-control form-control-sm"
                        //value={this.state.address1}
                      ></ReactGoogleAutocomplete>
                    </FormGroup>
                    <FormGroup className="mb-2">
                      <h5>Priority</h5>
                      <SelectPicker
                        placeholder="Select a Priority"
                        value={this.state[`valuesCreate`]?.priority}
                        data={[
                          { label: "1" },
                          {
                            label: "2",
                          },
                          {
                            label: "3",
                          },
                          {
                            label: "4",
                          },
                          {
                            label: "5",
                          },
                          {
                            label: "6",
                          },
                          {
                            label: "7",
                          },
                          {
                            label: "8",
                          },
                          {
                            label: "9",
                          },
                          {
                            label: "10",
                          },
                        ]}
                        block
                        size="sm"
                        placement="auto"
                        preventOverflow={true}
                        searchable={false}
                        valueKey="label"
                        onChange={(value) => {
                          let m = this.state.valuesCreate;

                          m.priority = value;

                          this.setState({
                            [`valuesCreate`]: m,
                          });
                        }}
                      ></SelectPicker>
                    </FormGroup>
                  </div>
                </CardBody>
              ) : null}
              {_.sortBy(
                this.props.lead?.markets ? this.props.lead?.markets : [],
                "priority"
              )?.map((market, i) => (
                <CardBody
                  key={i}
                  className={`py-3 ${
                    i != this.props?.lead?.markets?.length - 1
                      ? "border-bottom"
                      : ""
                  }`}
                >
                  <Row className="align-items-center">
                    <Col
                      className="cursor-pointer"
                      onClick={() => {
                        if (this.state[`editing${market?._id}`]) {
                          return;
                        }

                        this.setState({
                          [`open${market?._id}`]:
                            !this.state[`open${market?._id}`],
                        });
                      }}
                    >
                      <h3 className="mb-0">
                        {market?.priority ? `#${market?.priority}: ` : ""}
                        {market?.name}
                      </h3>
                    </Col>
                    <Col xs="auto">
                      {this.state[`editing${market?._id}`] ? (
                        <>
                          <Button
                            size="sm"
                            color="dark"
                            className="btn-icon-only"
                            onClick={() => {
                              this.setState({
                                [`editing${market?._id}`]:
                                  !this.state[`editing${market?._id}`],
                                [`open${market?._id}`]: true,
                              });
                            }}
                          >
                            <i className="mdi mdi-close"></i>
                          </Button>
                          <Button
                            size="sm"
                            color="primary"
                            className="btn-icon-only"
                            disabled={
                              this.state.valSubmitting ||
                              !this.state[`values${market?._id}`]?.name ||
                              (_.findWhere(this.props.lead?.markets, {
                                name: this.state[`values${market?._id}`]?.name,
                              }) &&
                                _.findWhere(this.props.lead?.markets, {
                                  name: this.state[`values${market?._id}`]
                                    ?.name,
                                })?._id != market?._id)
                            }
                            onClick={() => {
                              this.modifyFranDevLeadMarket(
                                market?._id,
                                this.state[`values${market?._id}`]
                              );
                            }}
                          >
                            {this.state.valSubmitting ? (
                              <Spinner size="sm" />
                            ) : (
                              <i className="mdi mdi-check"></i>
                            )}
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            size="sm"
                            outline
                            color="dark"
                            className="btn-icon-only"
                            onClick={() => {
                              let c = _.clone(market);

                              if (c?.priority) {
                                c.priority = c.priority?.toString();
                              }

                              console.log(c);

                              this.setState({
                                [`editing${market?._id}`]:
                                  !this.state[`editing${market?._id}`],
                                [`open${market?._id}`]: true,
                                [`values${market?._id}`]: c,
                              });
                            }}
                          >
                            <i className="mdi mdi-pencil"></i>
                          </Button>
                          <Button
                            size="sm"
                            outline
                            color="dark"
                            className="btn-icon-only"
                            onClick={() => {
                              this.setState({
                                [`open${market?._id}`]:
                                  !this.state[`open${market?._id}`],
                              });
                            }}
                          >
                            <i
                              className={`mdi mdi-chevron-${
                                this.state[`open${market?._id}`]
                                  ? "down"
                                  : "right"
                              }`}
                            ></i>
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Collapse isOpen={this.state[`open${market?._id}`]}>
                    {this.state[`editing${market?._id}`] ? (
                      <>
                        <div className="mt-3">
                          <Row className="align-items-center mb-3">
                            <Col xs="">
                              <h4 className="m-0">Modify Market</h4>
                            </Col>
                            <Col xs="auto">
                              <Button
                                size="sm"
                                outline
                                color="danger"
                                className="btn-icon-only"
                                disabled={
                                  this.state.deletingMarket == market?._id
                                }
                                onClick={() => {
                                  this.deleteFranDevLeadMarket(market?._id);
                                }}
                              >
                                {this.state.deletingMarket == market?._id ? (
                                  <Spinner size="sm" />
                                ) : (
                                  <i className="mdi mdi-trash-can"></i>
                                )}
                              </Button>
                            </Col>
                          </Row>

                          <FormGroup className="mb-2">
                            <h5>
                              Market Name <span className="text-danger">*</span>
                            </h5>
                            <ReactGoogleAutocomplete
                              required
                              placeholder="Market Name"
                              apiKey={"AIzaSyA5-fbnedb5uXDBwbjnz0J_2eDe_9Gi2Cs"}
                              onPlaceSelected={(place) => {
                                console.log(place);

                                let c = _.clone(
                                  this.state[`values${market?._id}`]
                                );

                                c.address = place.formatted_address;
                                c.name = place.formatted_address;
                                c.addressComponents = place.address_components;
                                c.geometry = place.geometry;
                                c.placeID = place.place_id;

                                this.setState({
                                  [`values${market?._id}`]: c,
                                });
                              }}
                              onChange={(e) => {
                                let c = _.clone(
                                  this.state[`values${market?._id}`]
                                );

                                c.address = e.target.value;
                                c.name = e?.target?.value;

                                this.setState({
                                  [`values${market?._id}`]: c,
                                });
                              }}
                              name="marketName"
                              value={this.state[`values${market?._id}`]?.name}
                              className="form-control form-control-sm"
                              //value={this.state.address1}
                            ></ReactGoogleAutocomplete>
                            {market?.placeID ? (
                              <>
                                <Button
                                  className="mt-2"
                                  onClick={() => {
                                    LinkUtils.openInNewTab(
                                      `https://www.google.com/maps/search/?api=1&query=${market?.name
                                        ?.split(" ")
                                        .join("+")}`
                                    );
                                  }}
                                  size="sm"
                                  color="dark"
                                  outline
                                >
                                  Open In Google Maps
                                </Button>
                              </>
                            ) : null}
                          </FormGroup>
                          <FormGroup className="mb-2">
                            <h5>Priority</h5>
                            <SelectPicker
                              placeholder="Select a Priority"
                              value={
                                this.state[`values${market?._id}`]?.priority
                              }
                              data={[
                                { label: "1" },
                                {
                                  label: "2",
                                },
                                {
                                  label: "3",
                                },
                                {
                                  label: "4",
                                },
                                {
                                  label: "5",
                                },
                                {
                                  label: "6",
                                },
                                {
                                  label: "7",
                                },
                                {
                                  label: "8",
                                },
                                {
                                  label: "9",
                                },
                                {
                                  label: "10",
                                },
                              ]}
                              block
                              size="sm"
                              placement="auto"
                              preventOverflow={true}
                              searchable={false}
                              valueKey="label"
                              onChange={(value) => {
                                let m = _.clone(market);

                                m.priority = value;

                                this.setState({
                                  [`values${market?._id}`]: m,
                                });
                              }}
                            ></SelectPicker>
                          </FormGroup>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="mt-3 border rounded p-2">
                          <Row className="align-items-center">
                            <Col>
                              <h5 className="mb-0">Narket Name</h5>
                              <p className="text-muted mb-0">
                                {market?.name ? <>{market?.name}</> : "--"}
                              </p>
                            </Col>
                            {market?.name ? (
                              <Col xs="auto">
                                <Button
                                  className="btn-icon-only my-0"
                                  size="sm"
                                  outline
                                  color="dark"
                                  onClick={() => {
                                    navigator.clipboard.writeText(market?.name);
                                  }}
                                >
                                  <i className="mdi mdi-content-copy"></i>
                                </Button>
                                <Button
                                  className="btn-icon-only my-0"
                                  size="sm"
                                  outline
                                  color="dark"
                                  onClick={() => {
                                    LinkUtils.openInNewTab(
                                      `https://www.google.com/maps/search/?api=1&query=${market?.name
                                        ?.split(" ")
                                        .join("+")}`
                                    );
                                  }}
                                >
                                  <i className="mdi mdi-open-in-new"></i>
                                </Button>
                              </Col>
                            ) : null}
                          </Row>
                        </div>
                      </>
                    )}
                  </Collapse>
                </CardBody>
              ))}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default withRouter(FranDevLeadDatesWidget);
