import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  CheckPicker,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Constant from "lib/Constant";
import Editor from "components/NovelEditor/ui/editor";

class NoteTemplateDrawer extends React.Component {
  state = {
    message: "",
    name: "",
    phone: {
      value: "",
    },
    template: {},
  };

  componentDidUpdate(prevProps) {
    if (this.props.contact?.phone != prevProps.contact?.phone) {
      this.setState({
        phone: {
          value: this.props.contact?.phone?.replace("+1", ""),
        },
      });
    }
  }

  componentDidMount() {
    this.loadTemplates();
  }

  toggleModal() {
    this.setState({
      template: {},
      phone: {
        value: "",
      },
    });

    PubSub.publish("editor:contentUpdated", {
      event: "contentUpdated",
      forceHydrate: true,
      id: "franleadMessageEditor",
      data: {},
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  loadTemplates() {
    this.setState({
      loadingTemplates: true,
    });

    let filters = {
      $or: [
        {
          type: "FranDev Lead Text Message",
        },
      ],
    };

    APIV2.getGlobalNoteTemplates(1, 500, filters, { name: -1 })
      .then(
        (data) => {
          if (data && data.data) {
            let templates = [];

            if (data.data.templates.length) {
              templates = data.data.templates;
            }

            this.setState({
              templates,
            });
          }
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loadingTemplates: false,
        });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="md"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Send Message
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  !this.state.phone?.value ||
                  !this.state.template?.text
                }
                color="primary"
                onClick={() => {
                  //console.log(this.state.template?.text);

                  window.open(
                    `sms:${this.state.phone.value}?body=${encodeURIComponent(
                      this.state.template.text
                    )}`,
                    "_self"
                  );
                }}
              >
                Send
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}

            <FormGroup>
              <h5>
                Phone Number{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <NumberFormat
                className="form-control mb-3"
                format="(###) ###-####"
                name="phone"
                placeholder="Phone Number"
                onValueChange={(v) => {
                  this.setState({
                    phone: v,
                  });
                }}
                value={this.state.phone.value}
                type="tel"
                mask="_"
              />
            </FormGroup>

            <FormGroup>
              <Row className="mb-3 align-items-center">
                <Col xs="">
                  <h3 className="text-dark">Topic Editor</h3>
                </Col>
                <Col xs="auto">
                  <Button
                    color="secondary"
                    size="sm"
                    outline
                    onClick={() => {
                      this.setState({
                        openTemplates: true,
                      });
                    }}
                  >
                    Use Template
                  </Button>
                </Col>
              </Row>
              <div className="rounded border p-3">
                <Editor
                  editable={true}
                  allowForceHydration={
                    this.props.template && this.state.templateLoad
                  }
                  id={"franleadMessageEditor"}
                  padding="p-0"
                  autoSave={false}
                  debounceRate={750}
                  value={this.state?.template}
                  onChange={(value) => {
                    if (value?.clear) {
                      this.setState({
                        templateLoad: false,
                      });

                      return;
                    }

                    this.setState({
                      template: value,
                    });
                  }}
                  allowTaskLists={false}
                ></Editor>
              </div>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
        <Drawer
          style={{ maxWidth: "100%" }}
          open={this.state.openTemplates}
          onClose={() => {
            this.setState({
              openTemplates: false,
            });
          }}
          size="sm"
        >
          <Drawer.Header className="pr-4 border-bottom-0">
            <h3 className="mb-2" style={{ position: "relative", top: "7px" }}>
              Message Templates
            </h3>
          </Drawer.Header>
          <Drawer.Body className="p-0" style={{ height: "calc(100% - 70px)" }}>
            {this.state.loadingTemplates ? (
              <div className="py-3 text-center">
                <Spinner size="sm"></Spinner>
              </div>
            ) : (
              <div>
                {this.state.templates?.map((template, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      let jsonString = JSON.stringify(template?.template);

                      if (this.props.contact) {
                        let firstName = StringUtils.getFirstName(
                          this.props.contact?.name
                        );
                        let lastName = StringUtils.getLastName(
                          this.props.contact?.name
                        );

                        jsonString = jsonString.replace(
                          /{{contact.first_name}}/g,
                          firstName
                        );
                        jsonString = jsonString.replace(
                          /{{contact.last_name}}/g,
                          lastName
                        );
                      }

                      let marketName = "your area";

                      if (this.props.lead) {
                        if (this.props.lead?.markets?.length == 1) {
                          marketName =
                            this.props.lead?.markets[0]?.name?.replace(
                              ", USA",
                              ""
                            );
                        } else if (this.props.lead?.markets?.length > 1) {
                          marketName = "a few markets";
                        }
                      }

                      jsonString = jsonString.replace(
                        /{{contact.market_name}}/g,
                        marketName
                      );

                      //console.log(JSON.parse(jsonString));

                      this.setState({
                        openTemplates: false,
                        template: JSON.parse(jsonString),
                      });

                      //console.log("HERE");

                      PubSub.publish("editor:contentUpdated", {
                        event: "contentUpdated",
                        forceHydrate: true,
                        id: "franleadMessageEditor",
                        data: JSON.parse(jsonString),
                      });
                    }}
                    className={`p-3 cursor-pointer bg-superlight--hover border-bottom ${
                      i == 0 ? "border-top" : ""
                    }`}
                  >
                    <Row className="align-items-center">
                      <Col xs="">
                        <h3 className="m-0">{template?.name}</h3>
                        {template?.description ? (
                          <p className="m-0">{template?.description}</p>
                        ) : null}
                      </Col>
                      <Col xs="auto">
                        <Button
                          color="dark"
                          outline
                          className="btn-icon-only"
                          size="sm"
                        >
                          <i className="mdi mdi-chevron-right"></i>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
            )}
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(NoteTemplateDrawer);
