import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  Tooltip,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Toolbar from "rsuite/esm/DatePicker/Toolbar";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";

class LibraryAllModulesDrawer extends React.Component {
  state = {
    name: "",
    description: "",
  };

  getModules() {
    this.setState({
      loadingModules: true,
    });

    APIV2.getLibraryModules()
      .then(
        (data) => {
          this.setState({
            modules: data?.data?.modules,
          });
        },
        () => {
          window.alert("Failed to get modules. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          loadingModules: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.open != prevProps.open && this.props.open) {
      this.getModules();
    }
  }

  componentDidMount() {
    this.getModules();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  deleteModule(module) {
    swal({
      title: "Delete Module",
      text: "Are you sure you want delete this module? This will remove it from all categories, as well as delete all topics inside the module.",
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deletingModule: module?._id,
      });

      APIV2.deleteLibraryModule(module?._id)
        .then(
          () => {
            let modules = this.state.modules;

            modules = _.filter(modules, (cat) => {
              return cat?._id != module?._id;
            });

            this.setState({ modules: _.sortBy(modules, "name") });
          },
          (e) => {
            alert("Unable to delete module. Please try again.");
          }
        )
        .finally(() => {
          this.setState({ deletingModule: "" });
        });
    });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => {
            if (typeof this.props.onClose == "function") {
              this.props.onClose();
            }
          }}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              All Modules
            </h3>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            {this.state.modules?.map((module, i) => (
              <>
                <div
                  className={`pb-3 ${i != 0 && "pt-3"} ${
                    i != this.state.modules?.length - 1 && "border-bottom"
                  }`}
                  key={module?._id}
                >
                  <Row className="align-items-center">
                    <Col xs="">
                      <h4 className="m-0">
                        {module?.internal ? (
                          <Badge color="light" className="border mr-2">
                            INTERNAL
                          </Badge>
                        ) : null}
                        {module?.name}
                      </h4>
                      {module.description ? (
                        <>
                          <p className="small m-0" style={{ lineHeight: 1.2 }}>
                            {module?.description}
                          </p>
                        </>
                      ) : null}
                      <p
                        className="small mt-1 mb-0"
                        style={{ lineHeight: 1.2 }}
                      >
                        Created:{" "}
                        {moment(module?.createdAt)?.format("MM/DD/YYYY")}
                      </p>
                    </Col>
                    <Col xs="auto">
                      {this.isGlobal() && (
                        <>
                          <Whisper
                            placement="autoHorizontal"
                            trigger="hover"
                            delayOpen={400}
                            speaker={
                              <Tooltip>
                                <p className="m-0 text-white">
                                  Delete module &amp; remove from categories.
                                </p>
                              </Tooltip>
                            }
                          >
                            <Button
                              size="sm"
                              color="danger"
                              outline
                              className="btn-icon-only"
                              disabled={
                                this.state.deletingModule == module?._id
                              }
                              onClick={() => {
                                this.deleteModule(module);
                              }}
                            >
                              {this.state.deletingModule == module?._id ? (
                                <Spinner size="sm"></Spinner>
                              ) : (
                                <i className="mdi mdi-trash-can"></i>
                              )}
                            </Button>
                          </Whisper>
                        </>
                      )}
                      <Button
                        size="sm"
                        color="dark"
                        outline
                        disabled={this.state.deletingModule == module?._id}
                        onClick={() => {
                          this.props.history.push(
                            `/university/library/modules/${module?._id}`
                          );
                        }}
                      >
                        View
                      </Button>
                    </Col>
                  </Row>
                </div>
              </>
            ))}
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(LibraryAllModulesDrawer);
