import React, { useState } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Drivers, Storage } from "@ionic/storage";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import {
  Checkbox,
  Dropdown,
  Popover,
  SelectPicker,
  Tooltip,
  Whisper,
} from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CampaignDrawer from "../../components/Drawers/CampaignDrawer";
import swal from "sweetalert";
import Constant from "../../lib/Constant";
import {
  Map,
  LayerProps,
  SymbolLayer,
  FullscreenControl,
  NavigationControl,
  Marker,
  Popup,
  useMap,
  Source,
  Layer,
} from "react-map-gl";

import "mapbox-gl/dist/mapbox-gl.css";
import { Capacitor } from "@capacitor/core";
import LocationSearchInput from "../../components/LocationSearchInput";

function LocationSearch(props) {
  const googleMapsProps = {
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyA5-fbnedb5uXDBwbjnz0J_2eDe_9Gi2Cs",
    libraries: ["places"],
  };

  return (
    <div>
      {window?.google?.maps ? (
        <>
          <LocationSearchInput {...props}></LocationSearchInput>
        </>
      ) : (
        <>
          <div className="rounded bg-superlight"></div>
        </>
      )}
    </div>
  );
}

class FranDevTerritoryView extends React.Component {
  state = {
    active: {
      id: "All",
      name: "All",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    showHoverInfo: true,
    showSelectedInfo: true,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 25,
    filters: null,
    orderBy: {
      scheduledFor: -1,
    },
    sortByOption: "dueDate",
    sortByDirection: "ascending",
    truncate: true,
    truncateStyles: {
      maxHeight: "30px",
    },

    defaultViewState: {
      longitude: -87.29267964419937,
      latitude: 37.4316,
      zoom: 4,
    },
    viewState: {
      longitude: -87.29267964419937,
      latitude: 37.4316,
      zoom: 4,
    },
    geometry: {
      type: "FeatureCollection",
      features: [],
    },
    selectedFeatures: {
      type: "FeatureCollection",
      features: [],
    },
    mileMarkers: {
      type: "FeatureCollection",
      features: [],
    },
    storeDataLayer: {
      type: "FeatureCollection",
      features: [],
    },
    mileMarkerLayer: {
      type: "line",
      id: "mileMarkers",
      paint: {
        "line-color": "#000000",
        "line-width": 1,
        "line-opacity": 0.5,
      },
    },
    dataLayer: {
      type: "fill",
      id: "territoryData",
      paint: {
        "fill-color": {
          property: "status",
          stops: [
            [0, "transparent"],
            [1, "#FB6340"],
            [2, "#FB6340"],
            [3, "#FBDB40"],
          ],
        },
        "fill-opacity": {
          property: "status",
          stops: [
            [0, 0.4],
            [1, 0.4],
            [2, 0.75],
            [3, 0.4],
          ],
        },
        "fill-outline-color": {
          property: "status",
          stops: [
            [0, "#666666"],
            [1, "#FB6340"],
            [2, "#FB6340"],
            [3, "#CDAA00"],
          ],
        },
      },
    },
    selectedDataLayer: {
      type: "fill",
      id: "selectedTerritoryData",
      paint: {
        "fill-color": "#0293e6",
        "fill-opacity": 0.6,
        "fill-outline-color": "#0293e6",
      },
    },
    storeDataLayerStyles: {
      type: "circle",
      id: "storeDataLayerData",
      paint: {
        "circle-color": "#FB6340",
        "circle-opacity": 0.9,
        "circle-stroke-color": "#000000",
        "circle-stroke-width": 1,
        "circle-radius": 5,
      },
    },
    loadingTerritories: true,
  };

  locationMapRef = React.createRef();

  componentDidUpdate(prevProps) {}

  jsonToArr(json) {
    try {
      let parsed = JSON.parse(json);

      return parsed;
    } catch (e) {
      return [];
    }
  }

  pointsToGeoJSONCircles(center, radiiInMiles) {
    const earthRadiusInMiles = 3958.8; // Earth's radius in miles
    const numSides = 120; // Increase for smoother polygon
    const features = [];

    radiiInMiles.forEach((radiusInMiles) => {
      const radiusInRadians = radiusInMiles / earthRadiusInMiles;
      const circleCoords = [];

      for (let i = 0; i < numSides; i++) {
        const bearing = (2 * Math.PI * i) / numSides;
        const circleLatRad = Math.asin(
          Math.sin((center.lat * Math.PI) / 180) * Math.cos(radiusInRadians) +
            Math.cos((center.lat * Math.PI) / 180) *
              Math.sin(radiusInRadians) *
              Math.cos(bearing)
        );
        const circleLngRad =
          (center.lng * Math.PI) / 180 +
          Math.atan2(
            Math.sin(bearing) *
              Math.sin(radiusInRadians) *
              Math.cos((center.lat * Math.PI) / 180),
            Math.cos(radiusInRadians) -
              Math.sin((center.lat * Math.PI) / 180) * Math.sin(circleLatRad)
          );

        circleCoords.push([
          (circleLngRad * 180) / Math.PI,
          (circleLatRad * 180) / Math.PI,
        ]);
      }
      // Ensure the polygon is closed by repeating the first point
      circleCoords.push(circleCoords[0]);

      features.push({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [circleCoords],
        },
        properties: {
          radiusInMiles,
        },
      });
    });

    return {
      type: "FeatureCollection",
      features,
    };
  }

  async loadAllTerritories(lat, lng, force = false) {
    let page = 1;
    let count = 3000;
    let hasNext = true;

    this.setState({
      loadingTerritories: true,
    });

    if (force) {
      this.setState({
        loadedCount: 0,
      });
    }

    let territories = this.state.territories?.length
      ? this.state.territories
      : [];
    let geometry = this.state.geometry
      ? this.state.geometry
      : {
          type: "FeatureCollection",
          features: [],
        };

    if (!territories?.length || force) {
      territories = [];

      while (hasNext) {
        let data = await this.loadTerritories(
          page,
          count,
          {
            lat,
            lng,
            meters: this.state.userLocation ? 120000 : 150000,
          },
          null
        );

        this.setState({
          totalCount: data?.result?.total,
          loadedCount: data?.result?.end,
        });

        if (data?.territories?.length) {
          territories = territories.concat(data.territories);

          //console.log(geometry);
        }

        hasNext = data?.pagination?.hasNext;

        if (hasNext) {
          page++;
        }
      }
    }

    let tg = territories.map((t) => {
      //console.log(t.geometry);

      if (t.geometry.features?.length) {
        t.geometry.features[0].properties._id = t._id;
        t.geometry.features[0].properties.totalPopulation = t.populationTotal;
        t.geometry.features[0].properties.totalHouseholds = t.householdsTotal;
        t.geometry.features[0].properties.totalHouseholds = t.householdsTotal;
        t.geometry.features[0].properties.totalFamilies =
          t.householdsTotalFamilies;
        t.geometry.features[0].properties.medianAge = t.populationAgeMedian;
        t.geometry.features[0].properties.medianIncome =
          t.incomeHouseholdMedian * 100;
        t.geometry.features[0].properties.averageIncome =
          t.incomeHouseholdAverage * 100;

        let coreDemo = 0;

        if (t.populationAge25to34) {
          coreDemo += t.populationAge25to34;
        }

        if (t.populationAge35to44) {
          coreDemo += t.populationAge35to44;
        }

        if (t.populationAge45to54) {
          coreDemo += t.populationAge45to54;
        }

        t.geometry.features[0].properties.corePopulation = coreDemo;

        let dedicated = _.filter(this.state.stores, (st) => {
          return st?.dedicatedTerritories?.includes(t.zip);
        });
        let allocated = _.filter(this.state.stores, (st) => {
          return st?.operations?.territories?.includes(t.zip);
        });

        let isDedicated = dedicated?.length > 0;
        let isAllocated = allocated?.length > 0;

        let status = 0;

        if (isDedicated) {
          status = 2;
        } else if (isAllocated) {
          status = 1;
        }

        if (allocated?.length > 1) {
          status = 3;
        }

        t.geometry.features[0].properties.type = "unallocated";

        if (isDedicated) {
          t.geometry.features[0].properties.type = "dedicated";
        } else if (allocated?.length > 1) {
          t.geometry.features[0].properties.type = "pivot";
        } else if (isAllocated) {
          t.geometry.features[0].properties.type = "allocated";
        }

        if (dedicated?.length) {
          t.geometry.features[0].properties.dedicated = dedicated;
        }

        if (allocated?.length) {
          t.geometry.features[0].properties.allocated = _.filter(
            allocated,
            (st) => {
              return !_.findWhere(dedicated, { _id: st._id });
            }
          );
        }

        t.geometry.features[0].properties.status = status;

        return t.geometry?.features[0];
      } else {
        //console.log(t);
      }

      return null;
    });

    tg = _.filter(tg, (t) => {
      return t;
    });

    geometry.features = tg;

    //console.log(geometry, territories);

    this.locationMapRef.current
      .getMap()
      .getSource("territories")
      .setData(geometry);

    this.setState({
      loadingTerritories: false,
      territories: territories,
      geometry,
    });
  }

  loadTerritories(page, count, filters, orderBy) {
    return new Promise((resolve, reject) => {
      APIV2.getTerritories(page, count, filters, orderBy, {
        geometry: 1,
        _id: 1,
        populationTotal: 1,
        householdsTotal: 1,
        householdsTotalFamilies: 1,
        populationAgeMedian: 1,
        incomeHouseholdMedian: 1,
        incomeHouseholdAverage: 1,
        zip: 1,
      }).then(
        (data) => {
          //console.log(data.data.territories);

          return resolve(data?.data);
        },
        (e) => {
          console.error(e);

          return reject(e);
        }
      );
    });
  }

  onHover(event) {
    const {
      features,
      point: { x, y },
    } = event;
    let hoveredFeature = features && features[0];

    //console.log(hoveredFeature);

    if (
      typeof hoveredFeature?.properties?.allocated == "string" &&
      hoveredFeature?.properties?.allocated
    ) {
      hoveredFeature.properties.allocated = this.jsonToArr(
        hoveredFeature.properties.allocated
      );
    }

    if (
      typeof hoveredFeature?.properties?.dedicated == "string" &&
      hoveredFeature?.properties?.dedicated
    ) {
      hoveredFeature.properties.dedicated = this.jsonToArr(
        hoveredFeature.properties.dedicated
      );
    }

    // prettier-ignore
    if (hoveredFeature) {
        this.setState({hoverInfo: {feature: hoveredFeature, x, y}});
      } else {
        this.setState({hoverInfo: null});
      }
  }

  getAllStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStores()
      .then((data) => {
        const now = new Date();

        let stores = _.filter(data.data.stores, (st) => {
          return !st?.closed;
        });

        let storeOptions = stores?.map((st) => {
          return {
            label: st?.name,
            value: st?._id,
            groupBy: st?.nsoStage,
          };
        });

        let geojsonData = stores?.map((st) => {
          return {
            storeName: st?.name?.replace("Project LeanNation", "PLN"),
            nsoStage: st?.nsoStage,
            formattedAddress: st?.location?.address?.formattedAddress,
            lat: st?.location?.geographic?.lat,
            lng: st?.location?.geographic?.lng,
          };
        });

        let geojson = this.toGeoJSONFeatureCollection(geojsonData);

        this.setState({
          stores,
          storeOptions,
          storeDataLayer: geojson,
          loadingTerritories: false,
        });
      })
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  componentDidMount() {
    this.getAllStores();
  }

  clearLocation() {
    this.locationMapRef.current.flyTo(this.state.defaultViewState);

    this.setState({
      location: null,
      userLocation: null,
      mileMarkers: {
        type: "FeatureCollection",
        features: [],
      },
      geometry: {
        type: "FeatureCollection",
        features: [],
      },
    });

    this.locationMapRef.current.getMap().getSource("mileMarkerSource").setData({
      type: "FeatureCollection",
      features: [],
    });

    this.locationMapRef.current.getMap().getSource("territories").setData({
      type: "FeatureCollection",
      features: [],
    });
  }

  toGeoJSONFeatureCollection(arr) {
    const featureCollection = {
      type: "FeatureCollection",
      features: arr.map((item) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [item.lng, item.lat],
        },
        properties: Object.keys(item).reduce((props, key) => {
          if (key !== "lat" && key !== "lng") {
            props[key] = item[key];
          }
          return props;
        }, {}),
      })),
    };
    return featureCollection;
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="px-0" fluid>
          {/* Table */}

          <div
            className="mt--3"
            style={{
              width: "100%",
              height: "calc(100vh - 80px)",
              position: "relative",
              top: 95,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0.75rem",
                left: "0.75rem",
                width: "calc(100% - 10rem)",
                zIndex: 100,
              }}
              className="p-2 rounded shadow bg-white border border-lighter"
            >
              <Row className="align-items-center">
                <Col xs="">
                  {this.state.loadingTerritories ? (
                    <>
                      <h3 className="m-0">
                        Loading Territories...
                        {this.state.totalCount ? (
                          <>
                            &nbsp;({this.state.loadedCount} of{" "}
                            {this.state.totalCount})
                          </>
                        ) : null}
                      </h3>
                    </>
                  ) : (
                    <div>
                      {this.state.userLocation?.formattedAddress ? (
                        <>
                          <Row
                            style={{ maxWidth: 500 }}
                            className="align-items-center"
                          >
                            <Col xs="auto">
                              <h3 className="font-weight-500 m-0">
                                {this.state.userLocation?.formattedAddress}
                              </h3>
                            </Col>
                            <Col xs="auto">
                              <Button
                                color="dark"
                                outline
                                size="sm"
                                className="btn-icon-only"
                                onClick={() => {
                                  this.clearLocation();
                                }}
                              >
                                <i className="mdi mdi-close"></i>
                              </Button>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          <LocationSearch
                            onSelect={(data) => {
                              this.setState(
                                {
                                  userLocation: {
                                    formattedAddress:
                                      data?.result?.formatted_address,
                                    latitude: data?.geographic?.lat,
                                    longitude: data?.geographic?.lng,
                                    showMarker: true,
                                  },
                                },
                                () => {
                                  this.loadAllTerritories(
                                    data?.geographic?.lat,
                                    data?.geographic?.lng,
                                    true
                                  );
                                }
                              );

                              let viewState = _.clone(
                                this.state.defaultViewState
                              );

                              //console.log(data);

                              viewState.latitude = data?.geographic?.lat;
                              viewState.longitude = data?.geographic?.lng;
                              viewState.zoom = 8;

                              //console.log(viewState);

                              this.locationMapRef.current.flyTo({
                                center: [
                                  data?.geographic?.lng,
                                  data?.geographic?.lat,
                                ],
                                zoom: viewState.zoom,
                              });

                              let markerJSON = this.pointsToGeoJSONCircles(
                                {
                                  lat: data?.geographic?.lat,
                                  lng: data?.geographic?.lng,
                                },
                                [3, 5, 7, 9]
                              );

                              console.log(markerJSON);

                              this.locationMapRef.current
                                .getMap()
                                .getSource("mileMarkerSource")
                                .setData(markerJSON);

                              this.setState({
                                mileMarkers: markerJSON,
                              });
                            }}
                            onChange={(data) => {
                              this.setState({
                                location: data?.address,
                              });
                            }}
                          ></LocationSearch>
                        </>
                      )}
                    </div>
                  )}
                </Col>
                <Col xs="auto">
                  <Button
                    size="sm"
                    disabled={true}
                    outline
                    color="dark"
                    className="btn-icon-only mr-1"
                    onClick={() => {
                      this.setState({
                        showHoverInfo: !this.state.showHoverInfo,
                      });
                    }}
                  >
                    <i className="mdi mdi-layers-outline"></i>
                  </Button>
                  <Button
                    size="sm"
                    disabled={this.state.loadingTerritories}
                    outline
                    color="dark"
                    className="btn-icon-only mr-1"
                    onClick={() => {
                      this.setState({
                        showHoverInfo: !this.state.showHoverInfo,
                      });
                    }}
                  >
                    {this.state.showHoverInfo ? (
                      <i className="mdi mdi-message-outline"></i>
                    ) : (
                      <i className="mdi mdi-message-off-outline"></i>
                    )}
                  </Button>
                  <Button
                    size="sm"
                    disabled={
                      this.state.loadingTerritories ||
                      (!this.state.userLocation &&
                        this.state.viewState?.zoom < 6.5)
                    }
                    outline
                    color="dark"
                    className="btn-icon-only"
                    onClick={() => {
                      this.loadAllTerritories(
                        this.state.userLocation
                          ? this.state.userLocation?.latitude
                          : this.state.viewState?.latitude,
                        this.state.userLocation
                          ? this.state.userLocation?.longitude
                          : this.state.viewState?.longitude,
                        true
                      );
                    }}
                  >
                    {this.state.loadingTerritories ? (
                      <Spinner size="sm"></Spinner>
                    ) : (
                      <i className="mdi mdi-refresh"></i>
                    )}
                  </Button>
                </Col>
              </Row>
            </div>
            {this.state.selectedFeatures?.features?.length ? (
              <div
                style={{
                  position: "absolute",
                  top: "calc(0.75rem + 50px + 0.5rem)",
                  left: "0.75rem",
                  width: "100%",
                  maxWidth: 300,
                  zIndex: 100,
                }}
                className="p-2 rounded shadow bg-white border border-lighter"
              >
                <Row className="align-items-center">
                  <Col xs="">
                    <h3 className="m-0">
                      {this.state.selectedFeatures?.features?.length} Selected
                    </h3>
                  </Col>
                  <Col xs="auto">
                    <Button
                      size="sm"
                      disabled={this.state.loadingTerritories}
                      outline
                      color="dark"
                      cla
                      className="btn-icon-only"
                      onClick={() => {
                        this.setState({
                          showSelectedInfo: !this.state.showSelectedInfo,
                        });
                      }}
                    >
                      {this.state.showSelectedInfo ? (
                        <i className="mdi mdi-chevron-up"></i>
                      ) : (
                        <i className="mdi mdi-chevron-down"></i>
                      )}
                    </Button>
                    <Button
                      size="sm"
                      disabled={this.state.loadingTerritories}
                      outline
                      color="danger"
                      className="btn-icon-only"
                      onClick={() => {
                        this.setState({
                          selectedFeatures: {
                            type: "FeatureCollection",
                            features: [],
                          },
                        });

                        this.locationMapRef.current
                          .getMap()
                          .getSource("selectedTerritories")
                          .setData({
                            type: "FeatureCollection",
                            features: [],
                          });
                      }}
                    >
                      <i className="mdi mdi-trash-can"></i>
                    </Button>
                  </Col>
                </Row>
                {this.state.showSelectedInfo ? (
                  <div className="border-top mt-2 pt-2">
                    <div>
                      <h3 className="text-dark mb-0">Population</h3>
                      <p className="text-dark mb-1">
                        Total:{" "}
                        {StringUtils.numberFormat(
                          this.state.selectedFeatures?.features?.reduce(
                            (acc, f) => {
                              return acc + f.properties.totalPopulation;
                            },
                            0
                          )
                        )}
                      </p>

                      <p
                        className="text-dark small m-0"
                        style={{ lineHeight: 1.4 }}
                      >
                        Households:{" "}
                        {StringUtils.numberFormat(
                          this.state.selectedFeatures?.features?.reduce(
                            (acc, f) => {
                              return acc + f.properties.totalHouseholds;
                            },
                            0
                          )
                        )}{" "}
                      </p>
                      <p
                        className="text-dark small m-0"
                        style={{ lineHeight: 1.4 }}
                      >
                        Families:{" "}
                        {StringUtils.numberFormat(
                          this.state.selectedFeatures?.features?.reduce(
                            (acc, f) => {
                              return acc + f.properties.totalFamilies;
                            },
                            0
                          )
                        )}
                      </p>
                      <p
                        className="text-dark small m-0"
                        style={{ lineHeight: 1.4 }}
                      >
                        Median Age:{" "}
                        {StringUtils.numberFormat(
                          Math.round(
                            this.state.selectedFeatures?.features?.reduce(
                              (acc, f) => {
                                return acc + f.properties.medianAge;
                              },
                              0
                            ) / this.state.selectedFeatures?.features?.length
                          )
                        )}
                        &nbsp;yo
                      </p>
                    </div>
                    <div className="mt-2 pt-2 border-top">
                      <h3 className="text-dark mb-0">Income</h3>
                      <p className="text-dark mb-1">
                        Average:{" "}
                        {StringUtils.centsToCurrency(
                          Math.round(
                            this.state.selectedFeatures?.features?.reduce(
                              (acc, f) => {
                                return (
                                  acc +
                                  (f.properties.averageIncome
                                    ? f.properties.averageIncome
                                    : 0)
                                );
                              },
                              0
                            ) / this.state.selectedFeatures?.features?.length
                          )
                        )}
                      </p>
                      <p
                        className="text-dark small m-0"
                        style={{ lineHeight: 1.4 }}
                      >
                        Median:{" "}
                        {StringUtils.centsToCurrency(
                          Math.round(
                            this.state.selectedFeatures?.features?.reduce(
                              (acc, f) => {
                                return (
                                  acc +
                                  (f.properties.medianIncome
                                    ? f.properties.medianIncome
                                    : 0)
                                );
                              },
                              0
                            ) / this.state.selectedFeatures?.features?.length
                          )
                        )}
                      </p>
                    </div>
                    <div className="mt-2 pt-2 border-top">
                      <h3 className="text-dark mb-0">Territories</h3>
                      <p
                        className="text-dark mb-0 small"
                        style={{ lineHeight: 1.2 }}
                      >
                        {this.state.selectedFeatures?.features
                          ?.map((feat) => feat?.properties?.zipCode)
                          ?.join(", ")}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
            <Map
              interactiveLayerIds={["territoryData"]}
              mapboxAccessToken={
                "pk.eyJ1IjoiYnJhbmRvbnBsbiIsImEiOiJjbG45aDFiaTAwNWQxMmptZzAzOG1uaXY5In0.XXjMdPbzVh4zxnSxAP6akw"
              }
              {...this.state.viewState}
              onMove={(evt) => {
                this.setState({
                  bounds: this.locationMapRef?.current?.getBounds(),
                });

                this.setState({ viewState: evt.viewState });
              }}
              onMouseMove={this.onHover.bind(this)}
              onMouseLeave={() => {
                this.setState({ hoverInfo: null });
              }}
              onMouseOut={() => {
                this.setState({ hoverInfo: null });
              }}
              onClick={(evt) => {
                //console.log(evt);

                const feature = evt.features.find(
                  (f) => f.layer.id === "territoryData"
                );

                let selected = this.state.selectedFeatures?.features?.length
                  ? this.state.selectedFeatures
                  : {
                      type: "FeatureCollection",
                      features: [],
                    };

                if (feature) {
                  //console.log(feature);

                  if (!selected.features?.length) {
                    this.setState({
                      showSelectedInfo: true,
                    });
                  }

                  let index = findIndex(selected.features, (f) => {
                    return f.properties._id == feature.properties._id;
                  });

                  if (index > -1) {
                    selected.features.splice(index, 1);
                  } else {
                    selected.features.push(feature);
                  }

                  this.setState({
                    selectedFeatures: selected,
                    loadingSelected: true,
                  });

                  this.locationMapRef.current
                    .getMap()
                    .getSource("selectedTerritories")
                    .setData(selected);

                  setTimeout(() => {
                    this.setState({
                      loadingSelected: false,
                    });
                  }, 0);
                }
              }}
              style={{ width: "100%", height: "100%" }}
              mapStyle="mapbox://styles/brandonpln/clnckvms707vb01qi8o4h8fo5"
              maxZoom={17}
              minZoom={3}
              id="locationMap"
              ref={this.locationMapRef}
              onZoom={(e) => {
                this.setState({
                  viewState: e.viewState,
                });

                if (!this.state.userLocation) {
                  if (
                    !this.state.loadingTerritories &&
                    e?.viewState?.zoom > 6.5 &&
                    !this.state.geometry?.features?.length
                  ) {
                    this.loadAllTerritories(
                      e?.viewState?.latitude,
                      e?.viewState?.longitude,
                      true
                    );
                  } else if (
                    !this.state.loadingTerritories &&
                    e?.viewState?.zoom < 6.5 &&
                    this.state.geometry?.features?.length
                  ) {
                    this.setState({
                      geometry: {
                        type: "FeatureCollection",
                        features: [],
                      },
                    });

                    this.locationMapRef.current
                      .getMap()
                      .getSource("territories")
                      .setData({
                        type: "FeatureCollection",
                        features: [],
                      });
                  }
                }
              }}
            >
              <Source
                type="geojson"
                id="territories"
                data={this.state.geometry}
              >
                <Layer before="storeDataLayerData" {...this.state.dataLayer} />
              </Source>

              <Source
                type="geojson"
                id="selectedTerritories"
                data={this.state.selectedFeatures}
              >
                <Layer
                  before="storeDataLayerData"
                  {...this.state.selectedDataLayer}
                />
              </Source>
              <Source
                type="geojson"
                id="storeLocations"
                data={this.state.storeDataLayer}
              >
                <Layer {...this.state.storeDataLayerStyles} />
              </Source>
              <Source
                type="geojson"
                id="mileMarkerSource"
                data={this.state.mileMarkers}
              >
                <Layer {...this.state.mileMarkerLayer} />
              </Source>

              <FullscreenControl position="top-right" />
              <NavigationControl showCompass={false} position="top-right" />
              {this.state.hoverInfo && this.state.showHoverInfo && (
                <div
                  className="map-tooltip rounded shadow p-2 border"
                  style={{
                    left: this.state.hoverInfo.x,
                    top: this.state.hoverInfo.y,
                  }}
                >
                  <Row className="align-items-top">
                    <Col sm="6" xs="12" className="pb-2 sm-pr-0">
                      <h4 className="text-dark mb-0">Location:</h4>
                      <div
                        className="text-capitalize"
                        style={{ fontSize: 14, lineHeight: 1.2 }}
                      >
                        {this.state.hoverInfo?.feature?.properties?.city},{" "}
                        {this.state.hoverInfo?.feature?.properties?.state}
                      </div>
                      <div className="text-dark" style={{ fontSize: 12 }}>
                        {this.state.hoverInfo?.feature?.properties?.zipCode}
                      </div>
                    </Col>
                    <Col xs="12" sm="6" className="pb-2">
                      <h4 className="text-dark mb-0">Population:</h4>
                      <div className="text-capitalize" style={{ fontSize: 14 }}>
                        <div className="mb-0">
                          Total:{" "}
                          {StringUtils.numberFormat(
                            this.state.hoverInfo?.feature?.properties
                              ?.totalPopulation
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col xs="12" sm="6" className="pb-2 pb-sm-0 sm-pr-0">
                      <h4 className="text-dark mb-0">Income:</h4>
                      <div className="text-capitalize" style={{ fontSize: 14 }}>
                        <div className="mb-0">
                          Avg:{" "}
                          {StringUtils.centsToCurrency(
                            this.state.hoverInfo?.feature?.properties
                              ?.averageIncome
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col xs="12" sm="6">
                      <h4 className="text-dark mb-0">Status:</h4>
                      <div className="mb-2" style={{ fontSize: 14 }}>
                        {this.state.hoverInfo?.feature?.properties?.type ==
                        "unallocated"
                          ? "Unallocated"
                          : null}
                        {this.state.hoverInfo?.feature?.properties?.type ==
                        "allocated"
                          ? "Allocated"
                          : null}
                        {this.state.hoverInfo?.feature?.properties?.type ==
                        "pivot"
                          ? "Pivot"
                          : null}
                        {this.state.hoverInfo?.feature?.properties?.type ==
                        "dedicated"
                          ? "Dedicated"
                          : null}
                      </div>
                    </Col>
                    {this.state.hoverInfo?.feature?.properties?.dedicated
                      ?.length ||
                    this.state.hoverInfo?.feature?.properties?.allocated
                      ?.length ? (
                      <Col xs="12" className="pb-2">
                        <div>
                          {this.state.hoverInfo?.feature?.properties?.allocated
                            ?.length ? (
                            <>
                              <h4 className="text-dark">Allocated To:</h4>
                              <div className="mb-2" style={{ fontSize: 14 }}>
                                {this.state.hoverInfo?.feature?.properties?.allocated
                                  ?.map((st) => {
                                    return st?.name?.replace(
                                      "Project LeanNation",
                                      "PLN"
                                    );
                                  })
                                  ?.join(", ")}
                              </div>
                            </>
                          ) : null}
                          {this.state.hoverInfo?.feature?.properties?.dedicated
                            ?.length ? (
                            <>
                              <h4 className="text-dark">Dedicated To:</h4>
                              <div className="mb-2" style={{ fontSize: 14 }}>
                                {this.state.hoverInfo?.feature?.properties?.dedicated
                                  ?.map((st) => {
                                    return st?.name?.replace(
                                      "Project LeanNation",
                                      "PLN"
                                    );
                                  })
                                  ?.join(", ")}
                              </div>
                            </>
                          ) : null}
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                </div>
              )}

              {/**
           * {popupInfo && (
            <Popup
              anchor="top"
              latitude={popupInfo?.location?.geographic?.lat}
              longitude={popupInfo?.location?.geographic?.lng}
              onClose={() => {
                setPopupInfo(null);

                if (userLocation?.isStore) {
                  setUserLocation(null);
                }

                locationMapRef?.current?.flyTo({
                  center: [priorViewState?.longitude, priorViewState?.latitude],
                  zoom: priorViewState?.zoom,
                });

                setPriorViewState(null);
              }}
              maxWidth="450px"
              className="rounded shadow bg-white"
              style={{
                width: "90%",
                maxWidth: 450,
                padding: 0,
                fontFamily: "Karla, sans-serif",
              }}
              closeButton={false}
              offset={20}
            >
              <div className="-my-[15px] -mx-[10px]">
                <div className="p-4 bg-white rounded-t">
                  <h2 className="text-lg grow -mt-2 sm:text-xl md:text-2xl font-bold leading-6 max-w-[90%]  tracking-tight">
                    {popupInfo?.name
                      ?.replace("Project LeanNation", "PLN")
                      ?.trim()}
                  </h2>

                  <div className="absolute -top-[0px] right-[10px]">
                    <button
                      className="font-bold outline-none text-gray-900 pr-0 rounded border border-gray-100 hover:bg-gray-25 transition-all"
                      onClick={(e: any) => {
                        e.preventDefault();
                        setPopupInfo(null);

                        if (userLocation?.isStore) {
                          setUserLocation(null);
                        }

                        locationMapRef?.current?.flyTo({
                          center: [
                            priorViewState?.longitude,
                            priorViewState?.latitude,
                          ],
                          zoom: priorViewState?.zoom,
                        });

                        setPriorViewState(null);
                      }}
                    >
                      <XIcon className="h-7 w-7"></XIcon>
                    </button>
                  </div>

                  <div
                    onClick={() => {
                      if (
                        popupInfo?.location?.address?.address_1 &&
                        typeof window != "undefined"
                      ) {
                        window.open(
                          StringUtils.generateGoogleMapsLink(
                            popupInfo?.location?.address
                          ),
                          " _blank"
                        );
                      }
                    }}
                    className={`flex gap-x-3 rounded items-center p-1 mt-1 -mx-1 border border-transparent ${
                      popupInfo?.location?.address?.address_1
                        ? "hover:border-gray-50 transition-all cursor-pointer hover:bg-gray-25"
                        : ""
                    }`}
                    style={{ lineHeight: 1 }}
                  >
                    <p className="text-gray-900 text-sm md:text-base tracking-tight grow leading-5">
                      {popupInfo?.location?.address?.formattedAddress}
                    </p>
                    {popupInfo?.location?.address?.address_1 ? (
                      <div>
                        <button className="text-gray-900 leading-6">
                          <ChevronRight className="h-4 w-4 md:h-5 md:w-5"></ChevronRight>
                        </button>
                      </div>
                    ) : null}
                  </div>
                  {popupInfo?.contact?.phone || popupInfo?.twilioNumber ? (
                    <div
                      onClick={() => {
                        if (typeof window != "undefined") {
                          window.open(
                            `tel:${StringUtils.formatPhoneForDisplay(
                              popupInfo?.twilioNumber
                                ? popupInfo?.twilioNumber
                                : popupInfo?.contact?.phone
                            )}`
                          );
                        }
                      }}
                      className={`flex gap-x-3 rounded items-center p-1 -mx-1 border border-transparent ${
                        popupInfo?.twilioNumber
                          ? popupInfo?.twilioNumber
                          : popupInfo?.contact?.phone
                          ? "hover:border-gray-50 transition-all cursor-pointer hover:bg-gray-25"
                          : ""
                      }`}
                      style={{ lineHeight: 1 }}
                    >
                      <p className="text-gray-900 text-sm md:text-base tracking-tight grow leading-5">
                        {StringUtils.formatPhoneForDisplay(
                          popupInfo?.twilioNumber
                            ? popupInfo?.twilioNumber
                            : popupInfo?.contact?.phone
                        )}
                      </p>
                      <div>
                        <button className="text-gray-900 leading-6">
                          <Phone className="h-4 w-4 md:h-5 md:w-5"></Phone>
                        </button>
                      </div>
                    </div>
                  ) : null}
                  <div>
                    <p className="text-gray-900 text-sm mt-1 md:text-base tracking-tight grow leading-5">
                      {isOpen(popupInfo) ? (
                        <>{getHours(popupInfo)}</>
                      ) : (
                        <>{getComingSoonTime(popupInfo)}</>
                      )}
                    </p>
                    {userLocation?.isGeolocation ? (
                      <>
                        <p className="text-gray-900 text-sm md:text-base tracking-tight grow leading-5">
                          {StringUtils.numberFormat(
                            MapUtils.calculateDistanceInMiles(
                              userLocation?.latitude,
                              userLocation?.longitude,
                              popupInfo?.location?.geographic?.lat,
                              popupInfo?.location?.geographic?.lng
                            ).toFixed(1)
                          )}{" "}
                          miles away
                        </p>
                      </>
                    ) : null}

                    {getDefaultOffer(popupInfo) ? (
                      <>
                        <div
                          onClick={() => {
                            PubSub.publish("lead-popup", {
                              store: popupInfo,
                            });
                          }}
                          className="mt-3 border border-gray-200 p-2 rounded shadow bg-white "
                        >
                          <p className="text-gray-900 text-xs sm:text-sm font-bold tracking-tight">
                            LIMITED TIME OFFER:
                          </p>
                          <p
                            className="text-gray-900 text-xs sm:text-sm tracking-tight mt-1"
                            style={{ lineHeight: 1.3 }}
                          >
                            {DateUtils.storeIsPrelaunch(popupInfo)
                              ? `Take advantage of our Founding Membership offer, which gives you first access to PLN, swag, and an exclusive lifetime discount.`
                              : "Take advantage of our limited-time offer before it's too late to save on your PLN membership!"}
                          </p>
                          <div className="cursor-pointer rounded text-center font-bold text-sm sm:text-base mt-3 text-white bg-orange-500 hover:bg-orange-600 transition-all p-2">
                            {DateUtils.storeIsPrelaunch(popupInfo) ? (
                              <>
                                <div>Claim Founding Member Offer</div>
                                <div className="text-xs sm:text-sm font-medium">
                                  Receive {getDefaultOffer(popupInfo)?.terms}
                                </div>
                              </>
                            ) : (
                              <>
                                <div>Claim Offer</div>
                                <div className="text-xs sm:text-sm font-medium">
                                  Receive {getDefaultOffer(popupInfo)?.terms}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
                <div
                  className={`rounded-b grid  items-top ${
                    (DateUtils.storeIsLive(popupInfo) ||
                      DateUtils.storeIsPrelaunch(popupInfo)) &&
                    popupInfo?.location?.address?.address_1
                      ? "grid-cols-3"
                      : ""
                  } ${
                    popupInfo?.location?.address?.address_1 &&
                    !(
                      DateUtils.storeIsLive(popupInfo) ||
                      DateUtils.storeIsPrelaunch(popupInfo)
                    )
                      ? "grid-cols-2"
                      : ""
                  } ${
                    !popupInfo?.location?.address?.address_1 &&
                    (DateUtils.storeIsLive(popupInfo) ||
                      DateUtils.storeIsPrelaunch(popupInfo))
                      ? "grid-cols-2"
                      : ""
                  } ${
                    !popupInfo?.location?.address?.address_1 &&
                    !(
                      DateUtils.storeIsLive(popupInfo) ||
                      DateUtils.storeIsPrelaunch(popupInfo)
                    )
                      ? "grid-cols-1"
                      : ""
                  }`}
                >
                  {DateUtils.storeIsLive(popupInfo) ||
                  DateUtils.storeIsPrelaunch(popupInfo) ? (
                    <div
                      onClick={() => {
                        SegmentUtils.track("Button Clicked", {
                          color: "solid",
                          fill: "bg-orange-500",
                          text: "Signup Now",
                          type: "event",
                        });

                        PubSub.publish("lead-popup", {
                          store: popupInfo,
                        });
                      }}
                      className="bg-orange-500 cursor-pointer hover:bg-orange-600 transition-all h-full border rounded-bl border-orange-700 p-4 "
                    >
                      <div className="text-white leading-5">
                        <Utensils className="h-7 w-7 md:h-8 md:w-8 mx-auto"></Utensils>
                      </div>
                      <div
                        style={{ lineHeight: 1.2 }}
                        className="text-xs sm:text-sm text-white text-center mt-3 font-bold"
                      >
                        Signup Now
                      </div>
                    </div>
                  ) : null}
                  <div
                    onClick={() => {
                      SegmentUtils.track("Button Clicked", {
                        color: "solid",
                        fill: "bg-orange-500",
                        text: "View Store",
                        type: "event",
                      });

                      router.push(`/locations/${popupInfo?.websiteSlug}`);
                    }}
                    className="bg-orange-500 cursor-pointer hover:bg-orange-600 transition-all h-full p-4 border-y border-orange-700 text-center"
                  >
                    <div className="text-white leading-5">
                      <Info className="h-7 w-7 md:h-8 md:w-8 mx-auto"></Info>
                    </div>
                    <div
                      style={{ lineHeight: 1.2 }}
                      className="text-xs sm:text-sm text-white text-center mt-3 font-bold"
                    >
                      View Store
                    </div>
                  </div>
                  {popupInfo?.location?.address?.address_1 ? (
                    <div
                      onClick={() => {
                        SegmentUtils.track("Button Clicked", {
                          color: "solid",
                          fill: "bg-orange-500",
                          text: "Directions",
                          type: "event",
                        });

                        if (typeof window != "undefined") {
                          window.open(
                            StringUtils.generateGoogleMapsLink(
                              popupInfo?.location?.address
                            ),
                            " _blank"
                          );
                        }
                      }}
                      className="bg-orange-500 cursor-pointer hover:bg-orange-600 transition-all h-full border rounded-br border-orange-700 p-4"
                    >
                      <div className="text-white leading-5">
                        <Navigation className="h-7 w-7 md:h-8 md:w-8 mx-auto"></Navigation>
                      </div>
                      <div
                        style={{ lineHeight: 1.2 }}
                        className="text-xs sm:text-sm text-white text-center mt-3 font-bold"
                      >
                        Directions
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </Popup>
          )}
           */}
            </Map>
          </div>
        </Container>
      </>
    );
  }
  f;
}

export default FranDevTerritoryView;
