import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import MemberExperienceScoreCard from "components/Widgets/MemberExperienceScoreCard";
import LinkUtils from "lib/LinkUtils";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";

class CustomerOrderDrawer extends React.Component {
  state = {
    paidOptions: [
      {
        value: "credit",
        label: (
          <>
            <p className="text-dark mb-0">Account Credit</p>
            <p className="m-0 small text-dark">
              Creates an account credit for the total invoice amount, applied to
              the next order.
            </p>
          </>
        ),
      },
      {
        value: "refund",
        label: (
          <>
            <p className="text-dark mb-0">Refund Invoice - Restock Fee</p>
            <p className="m-0 small text-dark">
              Use for members who want to suspend services with no future bill
              date. Refunds the invoice minus the $20 restocking fee.
            </p>
          </>
        ),
      },
      {
        value: "fullrefund",
        label: (
          <>
            <p className="text-dark mb-0">Refund Invoice - No Restock Fee</p>
            <p className="m-0 small text-dark">
              Use for members who claimed they communicated suspension but
              action wasn't taken. Refunds the total invoice.
            </p>
          </>
        ),
      },
    ],
    unpaidOptions: [
      {
        value: "cancelnofee",
        label: (
          <>
            <p className="text-dark mb-0">Cancel & No Restock Fee</p>
            <p className="m-0 small text-dark">
              Use for members who are Unclaimed & Unpaid with no way to collect
              payment. Cancel unpaid invoice.
            </p>
          </>
        ),
      },
      {
        value: "cancel",
        label: (
          <>
            <p className="text-dark mb-0">Cancel & Charge Restock Fee</p>
            <p className="m-0 small text-dark">
              Use for members who are Unclaimed & Unpaid. Cancel unpaid invoice
              & charge $20 restock fee.
            </p>
          </>
        ),
      },
    ],
  };

  constructor() {
    super();

    this.dropdownRef = React.createRef();
    this.invoiceRef = React.createRef();
  }

  componentDidUpdate(prevProps) {}

  componentDidMount() {}

  toggleModal() {
    this.props.onClose();
  }

  cancelOrder() {
    this.dropdownRef.current.close();

    swal({
      title: "Cancel order",
      text: "Are you sure you want cancel this order?",
      icon: "warning",
      buttons: ["Nevermind", "Cancel Order"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        cancelSubmitting: true,
      });

      APIV2.cancelOrder(this.props.order.storeID, this.props.order._id)
        .then(
          (data) => {
            console.log(data); //debug

            PubSub.publish(Event.ORDER.MODIFIED, data.data.order);

            this.toggleModal();
          },
          (e) => {
            console.error(e); //debug
          }
        )
        .finally(() => {
          this.setState({ cancelSubmitting: false });
        });
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  setInvoiceStatus(status) {
    this.setState({
      invoiceStatusSubmitting: true,
    });

    APIV2.updateInvoiceStatus(
      this.props.order.storeID,
      this.props.order?.invoice?._id,
      status
    )
      .then(
        (data) => {
          const invoice = data.data.invoice;

          let order = this.props.order;
          order.invoice = invoice;

          PubSub.publish(Event.ORDER.MODIFIED, order);
        },
        (e) => {
          console.error(e); //debug

          window.alert("Unable to update invoice status. Try again.");
        }
      )
      .finally(() => {
        this.setState({ invoiceStatusSubmitting: false });
      });
  }

  isInPast() {
    let billDate = moment(this.props?.order?.fulfillmentDateString);

    return new Date() > billDate.toDate();
  }

  refund() {
    this.setState({ refunding: true, invoiceStatusSubmitting: true });

    APIV2.refundInvoice(this.props?.invoice?.storeID, this.props.invoice?._id)
      .then(
        (data) => {
          const invoice = data.data.invoice;

          let order = this.props.order;
          order.invoice = invoice;

          PubSub.publish(Event.ORDER.MODIFIED, order);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to refund invoice - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ refunding: false });
      });
  }

  process() {
    this.setState({ processing: true, invoiceStatusSubmitting: true });

    console.log(this.props.order);

    APIV2.processInvoice(
      this.props?.order?.storeID,
      this.props.order?.invoiceID
    )
      .then(
        (data) => {
          const invoice = data.data.invoice;

          let order = this.props.order;
          order.invoice = invoice;

          PubSub.publish(Event.ORDER.MODIFIED, order);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to collect payment for invoice - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ processing: false, invoiceStatusSubmitting: false });
      });
  }

  updateStatus(status) {
    this.setState({ statusSubmitting: true });

    APIV2.updateOrderStatus(
      this.props.order.storeID,
      this.props.order._id,
      status
    )
      .then(
        (data) => {
          console.log(data); //debug

          PubSub.publish(Event.ORDER.MODIFIED, data.data.order);
        },
        (e) => {
          console.error(e); //debug

          // TODO: setError here
        }
      )
      .finally(() => {
        this.setState({ statusSubmitting: false });
      });
  }

  breakdownOrder() {
    this.setState({
      breakdownSubmitting: true,
    });

    APIV2.breakdownOrder(
      this.props.order.storeID,
      this.props.order._id,
      this.state.breakdownInvoice
    )
      .then(
        (data) => {
          console.log(data); //debug

          PubSub.publish(Event.ORDER.MODIFIED, data.data.order);

          this.setState({
            breakdownDrawer: false,
            breakdownSubmitting: false,
            breakdownInvoice: null,
          });
        },
        (e) => {
          console.error(e); //debug

          // TODO: setError here
        }
      )
      .finally(() => {
        this.setState({ breakdownSubmitting: false });
      });
  }

  userIsGlobal = () => {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  };

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.order?.customer?.name}
              <br></br>
            </h3>
            <Drawer.Actions>
              <Whisper
                ref={this.dropdownRef}
                trigger="click"
                placement={"autoVerticalEnd"}
                speaker={
                  <Popover full>
                    <Dropdown.Menu style={{ minWidth: 150 }}>
                      <Dropdown.Item
                        disabled={this.props.order?.status == "CANCELLED"}
                        onClick={() => {
                          LinkUtils.openInNewTab(
                            "/storefront-print/" +
                              this.props.order?.storeID +
                              "/fulfillment/orders/labels?date=" +
                              this.props.order?.fulfillmentDateString +
                              "&orders=" +
                              this.props.order?._id,
                            true
                          );

                          this.dropdownRef.current.close();
                        }}
                      >
                        Print Label
                      </Dropdown.Item>
                      <Dropdown.Item divider></Dropdown.Item>
                      {(this.props?.order?.status == "CLAIMED" ||
                        this.props?.order?.status == "UNCLAIMED") && (
                        <Dropdown.Item
                          className="text-capitalize"
                          onClick={() => {
                            this.updateStatus("PENDING");

                            this.dropdownRef.current.close();
                          }}
                        >
                          Mark As Pending{" "}
                          {this.props.order?.subscription?.plan?.procurement}
                        </Dropdown.Item>
                      )}
                      {this.props?.order?.status == "PENDING" && (
                        <>
                          <Dropdown.Item
                            className="text-capitalize"
                            onClick={() => {
                              this.updateStatus("CLAIMED");

                              this.dropdownRef.current.close();
                            }}
                          >
                            Mark As Claimed
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="text-capitalize"
                            onClick={() => {
                              this.updateStatus("UNCLAIMED");

                              this.dropdownRef.current.close();
                            }}
                          >
                            Mark As Unclaimed
                          </Dropdown.Item>
                        </>
                      )}
                      <Dropdown.Item divider></Dropdown.Item>
                      <Dropdown.Item
                        onClick={this.cancelOrder.bind(this)}
                        className={
                          this.props.order?.status == "CANCELLED"
                            ? "text-medium"
                            : "text-danger"
                        }
                        disabled={
                          this.props.order?.status == "CANCELLED" ||
                          this.state.cancelSubmitting
                        }
                      >
                        Cancel Order
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Popover>
                }
              >
                <Button
                  size="sm"
                  outline
                  color="secondary"
                  className="btn-icon-only"
                >
                  <i className="mdi mdi-dots-vertical"></i>
                </Button>
              </Whisper>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            <div>
              <Row className="align-items-center">
                <Col xs="">
                  <div>
                    <p className="text-dark m-0">
                      {this.props.order?.fulfillmentDateString
                        ? moment(
                            this.props.order?.fulfillmentDateString
                          ).format("dddd, MMM Do YYYY")
                        : "--"}
                    </p>
                  </div>
                  <div>
                    <h2 className="text-dark">
                      {this.props.order?.subscription?.plan?.name}
                    </h2>
                  </div>
                </Col>
                <Col xs="auto" className="text-right">
                  <div>
                    <Badge
                      color={`${
                        this.props.order?.status == "PENDING" ||
                        this.props.order?.status == "CANCELLED" ||
                        this.props.order?.status == "BREAKDOWN"
                          ? "medium"
                          : ""
                      }${
                        this.props.order?.status == "CLAIMED" ? "success" : ""
                      }${
                        this.props.order?.status == "UNCLAIMED" ? "danger" : ""
                      }`}
                    >
                      {this.props.order?.status}&nbsp;Order
                    </Badge>
                  </div>
                  <div>
                    {this.props?.order?.invoice?.status == "UNPAID" ? (
                      <>
                        <Badge color="" className="bg-yellow text-dark">
                          {this.props?.order?.invoice?.status}
                          <span>&nbsp;Invoice</span>
                        </Badge>
                      </>
                    ) : null}

                    {this.props?.order?.invoice?.status == "PAID" ? (
                      <>
                        <Badge color="success">
                          {this.props?.order?.invoice?.status}
                          <span>&nbsp;Invoice</span>
                        </Badge>
                      </>
                    ) : null}
                    {this.props?.order?.invoice?.status == "REFUNDED" ||
                    this.props?.order?.invoice?.status == "CANCELLED" ? (
                      <>
                        <Badge color="medium">
                          {this.props?.order?.invoice?.status}
                          <span>&nbsp;Invoice</span>
                        </Badge>
                      </>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>
            {(this.props.order?.status == "PENDING" ||
              this.props.order?.status == "UNCLAIMED") && (
              <div className="mt-3">
                <Card className="border-lighter mb-3">
                  <CardHeader className="p-3">
                    <h3 className="m-0">Manage Order</h3>
                  </CardHeader>
                  <CardBody>
                    <Button
                      onClick={() => {
                        this.updateStatus("CLAIMED");
                      }}
                      block
                      disabled={this.state.statusSubmitting}
                      color="primary"
                    >
                      {this.state.statusSubmitting ? (
                        <Spinner size="sm"></Spinner>
                      ) : (
                        <>
                          {" "}
                          Mark As{" "}
                          {this.props.order?.subscription?.plan?.procurement ==
                          "pickup"
                            ? "Claimed"
                            : "Delivered"}
                        </>
                      )}
                    </Button>
                  </CardBody>{" "}
                  {this.props.order?.status == "UNCLAIMED" && (
                    <CardBody className="pt-0">
                      <Button
                        onClick={() => {
                          this.setState({
                            breakdownDrawer: true,
                          });
                        }}
                        block
                        disabled={this.state.statusSubmitting}
                        color="dark"
                        outline
                      >
                        {this.state.statusSubmitting ? (
                          <Spinner size="sm"></Spinner>
                        ) : (
                          <>Breakdown Order</>
                        )}
                      </Button>
                    </CardBody>
                  )}
                </Card>
              </div>
            )}

            <div className="mt-3">
              <Card className="border-lighter mb-3">
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col
                      xs=""
                      onClick={() => {
                        this.setState({ boxExpanded: !this.state.boxExpanded });
                      }}
                      className="cursor-pointer"
                    >
                      <h3 className="m-0">
                        <i
                          className={`${
                            this.state.boxExpanded
                              ? "mdi mdi-chevron-down"
                              : "mdi mdi-chevron-right"
                          } pr-2`}
                        ></i>
                        Order ({this.props.order?.quantity} Meals)
                      </h3>
                    </Col>
                    {this.props.order?.status != "CANCELLED" ? (
                      <Col xs="auto">
                        <Button
                          size="sm"
                          color="secondary"
                          outline
                          disabled={this.isInPast()}
                          onClick={() => {
                            this.setState({ openEditBox: true });
                          }}
                        >
                          Edit
                        </Button>
                      </Col>
                    ) : null}
                  </Row>
                </CardHeader>
                <Collapse isOpen={this.state.boxExpanded}>
                  <CardBody className="p-0">
                    <Row className="align-items-center">
                      {this.props.order?.bom?.map((meal, i) => (
                        <Col key={i} xs="12">
                          <div className={`pl-3 pr-3`}>
                            <SelectedMealCheckoutRow
                              meal={meal}
                              selected={meal.quantity}
                              modifiable={false}
                            ></SelectedMealCheckoutRow>
                          </div>
                          {i != this.props.order?.bom?.length - 1 ? (
                            <hr className={`my-1`}></hr>
                          ) : null}
                        </Col>
                      ))}
                    </Row>
                  </CardBody>
                </Collapse>
              </Card>
            </div>
            <div>
              <Card className="border-lighter mb-3">
                <CardHeader className="p-3">
                  <div className="cursor-pointer">
                    <Row className="align-items-center">
                      <Col
                        xs=""
                        onClick={() => {
                          this.setState({
                            invoiceExpanded: !this.state.invoiceExpanded,
                          });
                        }}
                      >
                        <h3 className="mb-0">
                          <i
                            className={`${
                              this.state.invoiceExpanded
                                ? "mdi mdi-chevron-down"
                                : "mdi mdi-chevron-right"
                            } pr-2`}
                          ></i>
                          Invoice (
                          {StringUtils.centsToCurrency(
                            this.props.order?.invoice?.totalPrice
                          )}
                          )
                        </h3>
                      </Col>
                      <Col xs="auto" className="pr-0">
                        <h2 className="m-0" style={{ lineHeight: 1 }}>
                          {this.props?.order?.invoice?.status == "UNPAID" ? (
                            <>
                              <Badge
                                color=""
                                className="bg-yellow text-dark py-2 border border-dark"
                              >
                                {this.props?.order?.invoice?.status}
                              </Badge>
                            </>
                          ) : null}
                          {this.props?.order?.invoice?.status == "PAID" ? (
                            <>
                              <Badge
                                color="success"
                                className="py-2 border border-success"
                              >
                                {this.props?.order?.invoice?.status}
                              </Badge>
                            </>
                          ) : null}
                          {this.props?.order?.invoice?.status == "REFUNDED" ||
                          this.props?.order?.invoice?.status == "CANCELLED" ? (
                            <>
                              <Badge
                                color="medium"
                                className="py-2 border border-medium"
                              >
                                {this.props?.order?.invoice?.status}
                              </Badge>
                            </>
                          ) : null}
                        </h2>
                      </Col>
                      <Col xs="auto" className="pl-2">
                        <Whisper
                          ref={this.invoiceRef}
                          trigger="click"
                          placement={"autoVerticalEnd"}
                          speaker={
                            <Popover full>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  panel
                                  className="py-2 font-weight-800 text-uppercase"
                                  style={{ paddingLeft: 12, paddingRight: 12 }}
                                >
                                  <h5 className="m-0">Payment</h5>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  disabled={
                                    this.props.order?.invoice?.status ==
                                      "PAID" ||
                                    this.props.order?.invoice?.status ==
                                      "REFUNDED" ||
                                    this.props.order?.invoice?.status ==
                                      "CANCELLED" ||
                                    this.state.processing
                                  }
                                  onClick={() => {
                                    this.process();

                                    this.invoiceRef.current.close();
                                  }}
                                >
                                  {this.state.processing
                                    ? "Collecting..."
                                    : "Collect Payment"}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  disabled={
                                    this.props.order?.invoice?.status !=
                                      "PAID" || this.state.refunding
                                  }
                                  onClick={() => {
                                    this.refund();

                                    this.invoiceRef.current.close();
                                  }}
                                >
                                  Refund
                                </Dropdown.Item>
                                <Dropdown.Item divider></Dropdown.Item>
                                <Dropdown.Item
                                  panel
                                  className="py-2 font-weight-800 text-uppercase"
                                  style={{ paddingLeft: 12, paddingRight: 12 }}
                                >
                                  <h5 className="m-0">Status</h5>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  disabled={
                                    this.props.order?.invoice?.status == "PAID"
                                  }
                                  onClick={() => {
                                    this.setInvoiceStatus("PAID");

                                    this.invoiceRef.current.close();
                                  }}
                                >
                                  Mark as PAID
                                </Dropdown.Item>
                                <Dropdown.Item
                                  disabled={
                                    this.props.order?.invoice?.status ==
                                    "UNPAID"
                                  }
                                  onClick={() => {
                                    this.setInvoiceStatus("UNPAID");

                                    this.invoiceRef.current.close();
                                  }}
                                >
                                  Mark as UNPAID
                                </Dropdown.Item>
                                {this.userIsGlobal() ? (
                                  <Dropdown.Item
                                    disabled={
                                      this.props.order?.invoice?.status ==
                                      "REFUNDED"
                                    }
                                    onClick={() => {
                                      this.setInvoiceStatus("REFUNDED");

                                      this.invoiceRef.current.close();
                                    }}
                                  >
                                    Mark as REFUNDED
                                  </Dropdown.Item>
                                ) : null}
                                <Dropdown.Item
                                  disabled={
                                    this.props.order?.invoice?.status ==
                                    "CANCELLED"
                                  }
                                  onClick={() => {
                                    this.setInvoiceStatus("CANCELLED");

                                    this.invoiceRef.current.close();
                                  }}
                                >
                                  Mark as CANCELLED
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Popover>
                          }
                        >
                          <Button
                            disabled={this.state.invoiceStatusSubmitting}
                            size="sm"
                            outline
                            color="secondary"
                            className="btn-icon-only"
                          >
                            <i className="mdi mdi-dots-vertical"></i>
                          </Button>
                        </Whisper>
                      </Col>
                    </Row>
                  </div>
                </CardHeader>
                <Collapse isOpen={this.state.invoiceExpanded}>
                  <CardBody>
                    <Invoice invoice={this.props.order?.invoice}></Invoice>
                  </CardBody>
                </Collapse>
              </Card>
            </div>
            <div className="mb-3">
              <MemberExperienceScoreCard
                customer={this.props.order?.customer}
              ></MemberExperienceScoreCard>
              <div className="mt-2">
                <p className="small m-0 text-dark" style={{ lineHeight: 1.2 }}>
                  Score updated as of {moment().format("MM/DD/YY hh:mm A")}
                </p>
              </div>
            </div>
            <div>
              <div
                onClick={() => {
                  this.setState({
                    customerExpanded: !this.state.customerExpanded,
                  });
                }}
                className="cursor-pointer mb-3"
              >
                <Row className="align-items-center">
                  <Col xs="">
                    <h3 className="mb-0">
                      <i
                        className={`${
                          this.state.customerExpanded
                            ? "mdi mdi-chevron-down"
                            : "mdi mdi-chevron-right"
                        } pr-2`}
                      ></i>
                      Member Information
                    </h3>
                  </Col>
                  <Col xs="auto">
                    <Button
                      size="sm"
                      onClick={() => {
                        LinkUtils.openInNewTab(
                          "/storefront/" +
                            this.props.order?.storeID +
                            "/members/details/" +
                            this.props.order?.customer?._id +
                            "/summary",
                          true
                        );
                      }}
                      color="secondary"
                      outline
                    >
                      View
                    </Button>
                  </Col>
                </Row>
              </div>
              <Collapse isOpen={this.state.customerExpanded}>
                <div>
                  <CustomerContactInformationWidget
                    modifiable={false}
                    customer={this.props.order?.customer}
                  ></CustomerContactInformationWidget>
                </div>
                <div className="">
                  <CustomerBillingAddressWidget
                    customer={this.props.order?.customer}
                  ></CustomerBillingAddressWidget>
                </div>
                {this.props.order?.subscription?.plan?.procurement !=
                "pickup" ? (
                  <div className="mt-3">
                    <CustomerShippingAddressWidget
                      customer={this.props.order?.customer}
                    ></CustomerShippingAddressWidget>
                  </div>
                ) : null}
              </Collapse>
            </div>

            <hr className="my-4"></hr>
            <div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  this.setState({
                    historyExpanded: !this.state.historyExpanded,
                  });
                }}
              >
                <Row className="align-items-center mb-3">
                  <Col xs="">
                    <h3 className="mb-0">
                      <i
                        className={`${
                          this.state.historyExpanded
                            ? "mdi mdi-chevron-down"
                            : "mdi mdi-chevron-right"
                        } pr-2`}
                      ></i>
                      Order History
                    </h3>
                  </Col>
                </Row>
              </div>
              <Collapse isOpen={this.state.historyExpanded}>
                <Card className="border-lighter mb-3">
                  <CardBody>
                    {this.props.order?.history?.map((h, i) => (
                      <div key={i}>
                        <div className="text-muted">
                          <small>
                            {moment(h.eventTimestamp).format("MM/DD/YY hh:mmA")}
                          </small>
                        </div>
                        <div>
                          <h4 className="mb-0">{h.title}</h4>
                        </div>
                        {i != this.props.order?.history.length - 1 ? (
                          <hr className="my-2" />
                        ) : null}
                      </div>
                    ))}
                  </CardBody>
                </Card>
              </Collapse>
            </div>
          </Drawer.Body>
        </Drawer>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.state.breakdownDrawer}
          onClose={() => {
            this.setState({
              breakdownDrawer: false,
              breakdownInvoice: null,
            });
          }}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Breakdown Order
            </h3>
            <Drawer.Actions>
              <Button
                onClick={() => {
                  this.breakdownOrder();
                }}
                size="sm"
                disabled={
                  !this.state.breakdownInvoice || this.state.breakdownSubmitting
                }
                color="primary"
              >
                {this.state.breakdownSubmitting ? (
                  <Spinner size="sm"></Spinner>
                ) : (
                  "Breakdown"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            <Card className="border">
              <CardBody className="p-3">
                <h3 className="mb-1">{this.props.order?.customer?.name}</h3>
                <div>
                  <p className="text-dark m-0">
                    {this.props.order?.fulfillmentDateString
                      ? moment(this.props.order?.fulfillmentDateString).format(
                          "dddd, MMM Do YYYY"
                        )
                      : "--"}
                  </p>
                  <p className="text-dark m-0">
                    {this.props.order?.subscription?.plan?.name}
                  </p>
                </div>
              </CardBody>
            </Card>
            <h3 className="mt-3 mb-0">Payment Management</h3>
            <p className="">
              How would you like to manage the invoice for this order?
            </p>
            <SelectPicker
              data={
                this.props.order?.invoice?.status == "PAID"
                  ? this.state.paidOptions
                  : this.state.unpaidOptions
              }
              value={this.state.breakdownInvoice}
              placeholder="Select An Option"
              placement="autoVertical"
              searchable={false}
              block
              preventOverflow={true}
              onChange={(v) => {
                this.setState({
                  breakdownInvoice: v,
                });
              }}
            ></SelectPicker>
          </Drawer.Body>
        </Drawer>
        <EditBoxDrawer
          customer={this.props.order?.customer}
          bom={this.props.order?.bom}
          open={this.state.openEditBox}
          quantity={this.props.order?.quantity}
          planTypeID={this.props.order?.subscription?.plan?.planTypeID}
          onClose={() => {
            this.setState({
              openEditBox: false,
            });
          }}
          onSubmit={(bom) => {
            return new Promise((resolve) => {
              APIV2.updateOrderBOM(
                this.props.order?.storeID,
                this.props.order?._id,
                bom
              )
                .then(
                  (data) => {
                    console.log(data.data.order);

                    PubSub.publish(Event.ORDER.MODIFIED, data.data.order);

                    this.setState({
                      openEditBox: false,
                    });
                  },
                  (e) => {}
                )
                .finally(() => {
                  resolve();
                });
            });
          }}
        ></EditBoxDrawer>
      </>
    );
  }
}

export default withRouter(CustomerOrderDrawer);
