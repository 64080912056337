import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminFooter from "components/Footers/AdminFooter.js";

import routes from "routes.js";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import User from "lib/Model/User";
import PubSub from "lib/PubSub";
import API from "lib/API";
import APIV2 from "lib/APIV2";
import AdminNavbarV2 from "components/Navbars/AdminNavbarV2";
import StorefrontSidebarV2 from "components/Sidebar/StorefrontSidebarV2";
import StoreList from "views/examples/StoreList";
import MealList from "views/examples/MealList";
import MenuList from "views/examples/MenuList";
import BillingAccountList from "views/examples/BillingAccountList";
import ProductGroupList from "views/examples/ProductGroupList";
import SignupLinkCreatorView from "views/examples/SignupLinkCreatorView";
import StoreView from "views/examples/StoreView";
import MealView from "views/examples/MealView";
import routesv2 from "routesv2";
import DashboardTestView from "views/examples/DashboardTestView";
import MealCreateView from "views/examples/MealCreateView";
import MealCategoryList from "views/examples/MealCategoryList";
import MealAllergenList from "views/examples/MealAllergenList";
import CustomerView from "views/storefront/CustomerView";
import { Capacitor } from "@capacitor/core";
import FulfillmentSummary from "views/reports/FulfillmentSummary";
import StorefrontCustomerListView from "views/storefront/StorefrontCustomerListView";
import StoreFulfillmentSummary from "views/storefront/StoreFulfillmentSummary";
import StoreFulfillmentOrderListView from "views/storefront/StoreFulfillmentOrderListView";
import StorefrontMealListView from "views/storefront/StorefrontMealListView";
import StoreOrderLabelView from "views/storefront/StoreOrderLabelView";
import StorefrontInvoiceListView from "views/storefront/StorefrontInvoiceListView";
import StorefrontLeadListView from "views/storefront/StorefrontLeadListView";
import StorefrontStaffListView from "views/storefront/StorefrontStaffListView";
import StorefrontTaskListView from "views/storefront/StorefrontTaskListView";
import LeadView from "views/storefront/LeadView";
import AffiliateView from "views/storefront/AffiliateView";
import StorefrontUserListView from "views/storefront/StorefrontUserListView";
import StoreFulfillmentPickupListView from "views/storefront/StoreFulfillmentPickupListView";
import StoreOrderPickupListPrintView from "views/storefront/StoreOrderPickupListPrintView";
import StoreFulfillmentDeliveryListView from "views/storefront/StoreFulfillmentDeliveryListView";
import StoreFulfillmentProjectionView from "views/storefront/StoreFulfillmentProjectionView";
import StorefrontHomeView from "views/storefront/StorefrontHomeView";
import StorefrontAffiliateListView from "views/storefront/StorefrontAffiliateListView";
import StoreScorecard from "views/storefront/StoreScorecard";
import LeadSummary from "views/reports/LeadSummary";
import StorefrontCouponListView from "views/storefront/StorefrontCouponListView";
import StorefrontOnlinePresenceView from "views/storefront/StorefrontOnlinePresenceView";
import StorefrontSupportTicketListView from "views/storefront/StorefrontSupportTicketListView";
import StorefrontConversationView from "views/storefront/StorefrontConversationView";
import StorefrontConversationTemplateListView from "views/storefront/StorefrontConversationTemplateListView";
import StorefrontSupressionListView from "views/storefront/StorefrontSupressionListView";
import StorefrontConversationBroadcastListView from "../views/storefront/StorefrontConversationBroadcastListView";
import StorefrontMealInventoryView from "../views/storefront/StorefrontMealInventoryView";
import StorefrontProductOrderListView from "../views/storefront/StorefrontProductOrderListView";
import StorefrontQualityListView from "../views/storefront/StorefrontQualityListView";
import StorefrontRetailChecklistView from "../views/storefront/StorefrontRetailChecklistView";
import StorefrontNSOChecklistView from "../views/storefront/StorefrontNSOChecklistView";
import StorefrontTerritoryView from "../views/storefront/StorefrontTerritoryView";
import StorefrontApprovedRetailItemsView from "../views/storefront/StorefrontApprovedRetailItemsView";
import StorefrontPaymentProcessingView from "../views/storefront/StorefrontPaymentProcessingView";

import { toast, Toaster } from "sonner";
import LooseSealScanner from "components/Scanners/LooseSealScanner";

class Storefront extends React.Component {
  componentDidUpdate(e) {
    if (e.history?.location?.state?.noScroll) {
      return;
    }

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  getStoresForUser() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStoresForUser({ _id: 1, name: 1, nsoStage: 1 })
      .then(
        (data) => {
          if (data?.data?.stores?.length) {
            localStorage.setItem("selected_store", data.data.stores[0]._id);

            console.log("HERE SF", data?.data?.stores);

            this.props.history.push(
              "/storefront/" + data.data.stores[0]._id + "/home"
            );
          }
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  componentWillMount() {
    if (Capacitor.getPlatform() == "ios") {
      document.body.className += " ios-padding";
    }

    if (LocalStorage.get(Constant.CACHE.TOKEN)) {
      API.setAuthToken(LocalStorage.get(Constant.CACHE.TOKEN));
      APIV2.setAuthToken(LocalStorage.get(Constant.CACHE.TOKEN));

      APIV2._wsConnect();
    }

    if (LocalStorage.get(Constant.CACHE.USER)) {
      window[Constant.CACHE.USER] = User._fromData(
        LocalStorage.get(Constant.CACHE.USER)
      );
      PubSub.publish(Event.USER_LOADED, window[Constant.CACHE.USER]);
    }

    APIV2.getAccount().then(
      (data) => {
        if (data && data.data && data.data.user) {
          data.data.user.id = data.data.user._id;

          let user = data.data.user;

          window[Constant.CACHE.USER] = user;
          LocalStorage.set(Constant.CACHE.USER, user);

          console.log("fetched user");

          PubSub.publish(Event.USER_LOADED, user);
        }
      },
      (error) => {
        console.error(error);
      }
    );

    if (
      !this.props.match?.params?.storeID ||
      this.props.match?.params?.storeID == "select" ||
      this.props.match?.params?.storeID == "undefined"
    ) {
      console.log("here SF");

      //this.getStoresForUser();
    }
  }

  render() {
    if (!LocalStorage.get(Constant.CACHE.TOKEN)) {
      console.log("SF PUSH AUTH");

      this.props.history.push("/auth");
    }

    //console.log(this.props.location);

    return (
      <>
        <Toaster theme="light" closeButton position="bottom-right" />
        <StorefrontSidebarV2
          {...this.props}
          routes={routesv2.storefrontRoutes}
          basePath={"/storefront/" + this?.props?.match?.params?.storeID}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbarV2
            searchMode="storefront"
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {/*<Route path="/reports/home" component={StoreList} exact />*/}
            {
              <Route
                path="/storefront/:storeID/home"
                component={StorefrontHomeView}
                exact
              />
            }
            <Route
              path="/storefront/:storeID/members"
              component={StorefrontCustomerListView}
              exact
            />
            <Route
              path="/storefront/:storeID/members/details/:customerID/:tabID"
              component={CustomerView}
              exact
            />
            <Route
              path="/storefront/:storeID/leads"
              component={StorefrontLeadListView}
              exact
            />
            <Route
              path="/storefront/:storeID/leads/details/:leadID/:tabID"
              component={LeadView}
              exact
            />
            <Route
              path="/storefront/:storeID/affiliates"
              component={StorefrontAffiliateListView}
              exact
            />
            <Route
              path="/storefront/:storeID/conversations/templates"
              component={StorefrontConversationTemplateListView}
              exact
            />
            <Route
              path="/storefront/:storeID/conversations/broadcasts"
              component={StorefrontConversationBroadcastListView}
              exact
            />
            <Route
              path="/storefront/:storeID/conversations/suppressions"
              component={StorefrontSupressionListView}
              exact
            />
            <Route
              path="/storefront/:storeID/conversations"
              component={StorefrontConversationView}
            />
            <Route
              path="/storefront/:storeID/affiliates/details/:affiliateID/:tabID"
              component={AffiliateView}
              exact
            />
            <Route
              path="/storefront/:storeID/tasks"
              component={StorefrontTaskListView}
              exact
            />
            <Route
              path="/storefront/:storeID/fulfillment"
              component={StoreFulfillmentSummary}
              exact
            />
            <Route
              path="/storefront/:storeID/fulfillment/orders"
              component={StoreFulfillmentOrderListView}
              exact
            />
            <Route
              path="/storefront/:storeID/fulfillment/pickup"
              component={StoreFulfillmentPickupListView}
              exact
            />
            <Route
              path="/storefront/:storeID/fulfillment/delivery"
              component={StoreFulfillmentDeliveryListView}
              exact
            />
            <Route
              path="/storefront/:storeID/fulfillment/projections"
              component={StoreFulfillmentProjectionView}
              exact
            />
            <Route
              path="/storefront/:storeID/invoices"
              component={StorefrontInvoiceListView}
              exact
            />
            <Route
              path="/storefront/:storeID/settings"
              component={StorefrontOnlinePresenceView}
              exact
            />
            <Route
              path="/storefront/:storeID/settings/staff"
              component={StorefrontStaffListView}
              exact
            />
            <Route
              path="/storefront/:storeID/settings/coupons"
              component={StorefrontCouponListView}
              exact
            />
            <Route
              path="/storefront/:storeID/settings/payment-processing"
              component={StorefrontPaymentProcessingView}
              exact
            />
            <Route
              path="/storefront/:storeID/settings/users"
              component={StorefrontUserListView}
              exact
            />

            <Route
              path="/storefront/:storeID/products"
              component={StorefrontMealInventoryView}
              exact
            />
            <Route
              path="/storefront/:storeID/products/orders"
              component={StorefrontProductOrderListView}
              exact
            />
            <Route
              path="/storefront/:storeID/products/quality"
              component={StorefrontQualityListView}
              exact
            />
            <Route
              path="/storefront/:storeID/products/retail-list"
              component={StorefrontRetailChecklistView}
              exact
            />
            <Route
              path="/storefront/:storeID/products/nso-list"
              component={StorefrontNSOChecklistView}
              exact
            />
            <Route
              path="/storefront/:storeID/products/retail-items"
              component={StorefrontApprovedRetailItemsView}
              exact
            />
            <Route
              path="/storefront/:storeID/products/menus/meals"
              exact
              component={StorefrontMealListView}
            ></Route>
            <Route
              path="/storefront/:storeID/reports/lead-summary"
              component={LeadSummary}
              exact
            />
            <Route
              path="/storefront/:storeID/support/franchise"
              component={StorefrontSupportTicketListView}
              exact
            />
            <Route
              path="/storefront/:storeID/settings/territories"
              component={StorefrontTerritoryView}
              exact
            />

            <Redirect
              from="*"
              to={`/storefront/${
                this.props.match?.params?.storeID
                  ? this.props.match?.params?.storeID
                  : "select"
              }/home`}
            />
          </Switch>
          {!this.props?.location?.pathname?.includes("conversations") ? (
            <Container fluid>
              <AdminFooter />
            </Container>
          ) : null}
        </div>
        <LooseSealScanner></LooseSealScanner>
      </>
    );
  }
}

export default Storefront;
