import { Editor } from "@tiptap/core";
import {
  Check,
  ChevronDown,
  Heading1,
  Heading2,
  Heading3,
  TextQuote,
  ListOrdered,
  TextIcon,
  Code,
  CheckSquare,
} from "lucide-react";
import React from "react";

import { BubbleMenuItem } from "./EditorBubbleMenu";

/** 
interface NodeSelectorProps {
  editor: Editor;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
*/

export const NodeSelector = ({ editor, isOpen, setIsOpen, allowTaskLists }) => {
  let items = [
    {
      name: "Text",
      icon: TextIcon,
      command: () =>
        editor.chain().focus().toggleNode("paragraph", "paragraph").run(),
      // I feel like there has to be a more efficient way to do this – feel free to PR if you know how!
      isActive: () =>
        editor.isActive("paragraph") &&
        !editor.isActive("bulletList") &&
        !editor.isActive("orderedList"),
    },
    {
      name: "Heading 1",
      icon: Heading1,
      command: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
      isActive: () => editor.isActive("heading", { level: 1 }),
    },
    {
      name: "Heading 2",
      icon: Heading2,
      command: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      isActive: () => editor.isActive("heading", { level: 2 }),
    },
    {
      name: "Heading 3",
      icon: Heading3,
      command: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
      isActive: () => editor.isActive("heading", { level: 3 }),
    },

    {
      name: "Bullet List",
      icon: ListOrdered,
      command: () => editor.chain().focus().toggleBulletList().run(),
      isActive: () => editor.isActive("bulletList"),
    },
    {
      name: "Numbered List",
      icon: ListOrdered,
      command: () => editor.chain().focus().toggleOrderedList().run(),
      isActive: () => editor.isActive("orderedList"),
    },
    {
      name: "Quote",
      icon: TextQuote,
      command: () =>
        editor
          .chain()
          .focus()
          .toggleNode("paragraph", "paragraph")
          .toggleBlockquote()
          .run(),
      isActive: () => editor.isActive("blockquote"),
    },
    {
      name: "Code",
      icon: Code,
      command: () => editor.chain().focus().toggleCodeBlock().run(),
      isActive: () => editor.isActive("codeBlock"),
    },
  ];

  if (allowTaskLists) {
    items.push({
      name: "To-do List",
      icon: CheckSquare,
      command: () => editor.chain().focus().toggleTaskList().run(),
      isActive: () => editor.isActive("taskItem"),
    });
  }

  const activeItem = items.filter((item) => item.isActive()).pop() ?? {
    name: "Multiple",
  };

  return (
    <div className="relative">
      <button
        className="d-flex align-items-center gap-1 whitespace-nowrap p-2 text-sm font-medium text-dark bg-white bg-primary-ultralight--hover"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span style={{ whiteSpace: "nowrap" }}>{activeItem?.name}</span>
        <ChevronDown className="h-4 w-4" />
      </button>

      {isOpen && (
        <section
          style={{
            position: "fixed",
            height: "auto",
            maxHeight: 300,
            overflowX: "hidden",
            overflowY: "auto",
          }}
          className="mt-1 rounded border shadow bg-white p-1 animate-in fade-in slide-in-from-top-1"
        >
          {items.map((item, index) => (
            <button
              key={index}
              onClick={() => {
                item.command();
                setIsOpen(false);
              }}
              className="d-flex w-100 align-items-center justify-between rounded px-2 py-1 text-sm text-dark bg-white bg-primary-ultralight--hover"
            >
              <div className="d-flex align-items-center">
                <div className="rounded-sm border p-1">
                  <item.icon style={{ width: 16, height: 16 }} />
                </div>
                <span className="pl-1">{item.name}</span>
              </div>
              {activeItem.name === item.name && (
                <Check className="pl-2" style={{ width: 16, height: 16 }} />
              )}
            </button>
          ))}
        </section>
      )}
    </div>
  );
};
