import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import API from "lib/API";
import BillingAccountRow from "components/BillingAccountRow";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import CreateBillingAccountModal from "components/CreateBillingAccountModal";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import Form from "reactstrap/lib/Form";
import FormGroup from "reactstrap/lib/FormGroup";
import Input from "reactstrap/lib/Input";

import {
  BarcodeScanner,
  BarcodeFormat,
  LensFacing,
} from "@projectleannation/barcode-scanning";
import { Capacitor } from "@capacitor/core";

class ScannerTestView extends React.Component {
  state = {
    promoCode: "",
    leadSource: "",
    loading: true,
  };

  componentDidMount() {}

  async testScan() {
    console.log("TEST SCAN");

    // The camera is visible behind the WebView, so that you can customize the UI in the WebView.
    // However, this means that you have to hide all elements that should not be visible.
    // You can find an example in our demo repository.
    // In this case we set a class `barcode-scanner-active`, which then contains certain CSS rules for our app.

    PubSub.publish(Event.LOOSE_SEAL_SCANNER.OPEN);
  }

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow" style={{ minHeight: "500px" }}>
                <CardHeader className=" bg-transparent">
                  <Row>
                    <div className="col">
                      <h3 className=" mb-0">AI Scanner Test</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Button
                    disabled={!Capacitor.isNativePlatform()}
                    onClick={() => {
                      this.testScan();
                    }}
                    color="primary"
                  >
                    Test Scanner
                  </Button>
                  <Button
                    onClick={() => {
                      window.location.reload();
                    }}
                    outline
                    color="primary"
                  >
                    Reload Page
                  </Button>
                </CardBody>
                <CardBody>
                  {this.state.image ? (
                    <>
                      <img
                        src={`data:image/jpg;base64,${this.state.image}`}
                        style={{ width: "100%" }}
                      ></img>
                    </>
                  ) : null}
                </CardBody>
                <CardBody>
                  {this.state.barcodes ? (
                    <>{JSON.stringify(this.state.barcodes)}</>
                  ) : null}
                </CardBody>
                <CardBody>
                  {this.state.text ? (
                    <>{JSON.stringify(this.state.text)}</>
                  ) : null}
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default ScannerTestView;
