import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  CheckPicker,
  Tooltip,
  Checkbox,
  Toggle,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Constant from "lib/Constant";
import LinkUtils from "../../lib/LinkUtils";
import EmojiPicker from "emoji-picker-react";
import autosize from "autosize";
import { count } from "sms-length";
import { SegmentedMessage } from "sms-segments-calculator";
import LocalStorage from "lib/LocalStorage";
import FileUploadDrawer from "components/FileUploadDrawer";
import EditorV2 from "components/NovelEditor/ui/editor";

class SystemNotificationDrawer extends React.Component {
  state = {
    draft: true,
    message: "",
    name: "",
    list: "",
    pushTestUser: "",
    scheduledSend: null,
    phone: {
      value: "",
    },
    lists: ["development-team", "store-emails"],
    listOptions: [
      {
        value: "development-team",
        label: (
          <>
            <div
              className="d-inline-block"
              style={{ position: "relative", top: -4 }}
            >
              Development Team
              <p className="small m-0" style={{ lineHeight: 1.3 }}>
                All members of the franchise development team.
              </p>
            </div>
          </>
        ),
      },
      {
        value: "store-emails",
        label: (
          <>
            <div
              className="d-inline-block"
              style={{ position: "relative", top: -4 }}
            >
              Store Admin Users
              <p className="small m-0" style={{ lineHeight: 1.3 }}>
                All store projectleannation.com users & emails
              </p>
            </div>
          </>
        ),
      },
      {
        value: "franchise-partners",
        label: (
          <>
            <div
              className="d-inline-block"
              style={{ position: "relative", top: -4 }}
            >
              Franchise Partners
              <p className="small m-0" style={{ lineHeight: 1.3 }}>
                All franchise partner users & personal emails
              </p>
            </div>
          </>
        ),
      },
      {
        value: "store-staff",
        label: (
          <>
            <div
              className="d-inline-block"
              style={{ position: "relative", top: -4 }}
            >
              Store Staff Members
              <p className="small m-0" style={{ lineHeight: 1.3 }}>
                All store staff users & personal emails
              </p>
            </div>
          </>
        ),
      },
    ],
    results: {},
    pagination: {},
    templates: [],
  };

  testMessageRef = React.createRef();

  getAllStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStores()
      .then((data) => {
        const now = new Date();

        let stores = _.filter(data.data.stores, (st) => {
          return (
            st.nsoStage &&
            st.nsoStage != "Lead" &&
            st.nsoStage != "Funding" &&
            st.nsoStage != "Cold" &&
            st.nsoStage != "Discovery"
          );
        });

        let storeOptions = stores?.map((st) => {
          return {
            label: st?.name,
            value: st?._id,
            groupBy: st?.nsoStage,
          };
        });

        this.setState({
          stores,
          storeOptions,
        });
      })
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  loadTemplates() {
    this.setState({
      loadingTemplates: true,
    });

    let filters = {
      $or: [
        {
          type: "System Update",
        },
      ],
    };

    APIV2.getGlobalNoteTemplates(1, 500, filters, { name: -1 })
      .then(
        (data) => {
          if (data && data.data) {
            let templates = [];

            if (data.data.templates.length) {
              templates = data.data.templates;
            }

            this.setState({
              templates,
            });
          }
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loadingTemplates: false,
        });
      });
  }

  getPushTestUsers() {
    this.setState({
      loadingTestUsers: true,
    });

    APIV2.getCampaignPushTestUsers("storefront")
      .then((data) => {
        this.setState({
          pushTestUsers: data.data.users,
          pushTestUserOptions: data.data.users?.map((u) => {
            return {
              label: u?.name,
              value: u?.userID,
            };
          }),
        });
      })
      .finally(() => {
        this.setState({
          loadingTestUsers: false,
        });
      });
  }

  constructor(props) {
    super(props);

    this.emojiRef = React.createRef();
    this.quickReplyRef = React.createRef();
    this.searchRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.campaign != prevProps?.campaign && this.props.campaign) {
      this.setState({
        pushTestUser: "",
        draft: this.props.campaign?.status == "DRAFT",
        name: this.props.campaign?.name + (this.props.duplicate ? " Copy" : ""),
        description: this.props.campaign?.description
          ? this.props.campaign?.description
          : "",
        channel: this.props.campaign?.channel
          ? this.props.campaign?.channel
          : "conversations",
        selectedStores: this.props.campaign?.storeIDs
          ? this.props?.campaign?.storeIDs
          : [],
        message: this.props.campaign?.message,
        lists: this.props.campaign?.lists?.length
          ? this.props.campaign?.lists
          : [],
        announcement: this.props.campaign?.announcement
          ? this.props.campaign?.announcement
          : {},
        hasAnnouncement: this.props.campaign?.announcement ? true : false,
        announcementEmbed: this.props.campaign?.announcementEmbed
          ? this.props.campaign?.announcementEmbed
          : "",
        announcementVideoURL: this.props.campaign?.announcementVideoURL
          ? this.props.campaign?.announcementVideoURL
          : "",
        scheduledSend: this.props.campaign.sendImmediately
          ? "now"
          : "scheduled",
        scheduledDate: moment(this.props.campaign?.dateTime)
          .hours(0)
          .seconds(0)
          .minutes(0)
          .millisecond(0)
          .toDate(),
        scheduledTime: moment(this.props.campaign?.dateTime).toDate(),

        recipients: "list",
        mediaUrl: this.props.campaign?.mediaUrl
          ? this.props.campaign?.mediaUrl
          : "",
        announcementVideoURL: this.props.campaign?.announcementVideoURL
          ? this.props.campaign?.announcementVideoURL
          : "",
        announcementEmbed: this.props.campaign?.announcementEmbed
          ? this.props.campaign?.announcementEmbed
          : "",
        expanded:
          this.props.campaign?.announcementEmbed ||
          this.props.campaign?.announcementVideoURL
            ? true
            : false,
        interactive:
          this.props.campaign?.action?.eventType &&
          !this.props.campaign?.announcement
            ? true
            : false,
      });

      if (this.props.campaign?.message?.length) {
        this.setState({
          messageLength: count(this.props.campaign?.message),
        });
      } else {
        this.setState({
          messageLength: null,
        });
      }

      if (this.props.campaign?.announcement) {
        setTimeout(() => {
          PubSub.publish("editor:contentUpdated", {
            event: "contentUpdated",
            forceHydrate: true,
            id: "announcementTemplateEditor",
            data: this.props.campaign?.announcement,
          });
        }, 50);
      }

      this._loadNotificationState();
    }
  }

  estimateMessageCost(messageLength) {
    if (!messageLength) {
      return StringUtils.centsToCurrency(0);
    }

    let cost = messageLength?.messages * Constant.TWILIO_MESSAGE_COST;

    cost = Math.ceil(cost * 100) / 100;

    return StringUtils.centsToCurrency(cost * 100);
  }

  componentDidMount() {
    this.getAllStores();
    this.loadTemplates();
    this.getPushTestUsers();

    if (this.props.campaign) {
      this.setState({
        draft: this.props.campaign?.status == "DRAFT",
        name: this.props.campaign?.name + (this.props.duplicate ? " Copy" : ""),
        description: this.props.campaign?.description
          ? this.props.campaign?.description
          : "",
        channel: this.props.campaign?.channel
          ? this.props.campaign?.channel
          : "conversations",
        selectedStores: this.props.campaign?.storeIDs
          ? this.props?.campaign?.storeIDs
          : [],
        message: this.props.campaign?.message,
        lists: this.props.campaign?.lists?.length
          ? this.props.campaign?.lists
          : [],
        scheduledSend: this.props.campaign.sendImmediately
          ? "now"
          : "scheduled",
        scheduledDate: moment(this.props.campaign?.dateTime)
          .hours(0)
          .seconds(0)
          .minutes(0)
          .millisecond(0)
          .toDate(),
        scheduledTime: moment(this.props.campaign?.dateTime).toDate(),

        recipients: "list",
        mediaUrl: this.props.campaign?.mediaUrl
          ? this.props.campaign?.mediaUrl
          : "",
        announcementVideoURL: this.props.campaign?.announcementVideoURL
          ? this.props.campaign?.announcementVideoURL
          : "",
        announcementEmbed: this.props.campaign?.announcementEmbed
          ? this.props.campaign?.announcementEmbed
          : "",
        announcement: this.props.campaign?.announcement
          ? this.props.campaign?.announcement
          : {},
      });
    }
  }

  toggleModal() {
    this.setState({
      name: "",
      draft: true,
      description: "",
      message: "",
      channel: "conversations",
      selectedStores: [],
      lists: ["development-team", "store-emails"],
      mediaUrl: "",
      scheduledDate: null,
      scheduledTime: null,
      scheduledSend: null,
      recipients: null,
      title: "",
      dynamicTitle: false,
      dynamicTitleScript: null,
      message: "",
      dynamicBody: false,
      dynamicBodyScript: null,
      image: null,
      dynamicImage: false,
      dynamicImageScript: null,
      interactive: false,
      interactiveEventType: "",
      interactiveButtonText: "",
      interactiveButtonTextDynamic: false,
      interactiveButtonTextDynamicScript: null,
      interactiveEventData: "",
      interactiveEventDataDynamic: false,
      interactiveEventDataDynamicScript: null,
      interactiveEventMetadata: "",
      interactiveEventMetadataDynamic: false,
      pushTestUser: "",
    });

    PubSub.publish("editor:contentUpdated", {
      event: "contentUpdated",
      forceHydrate: true,
      id: "announcementTemplateEditor",
      data: {},
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });

    if (name == "message") {
      if (value?.length) {
        this.setState({
          messageLength: count(value),
        });
      } else {
        this.setState({
          messageLength: null,
        });
      }
    }
  }

  submit() {
    if (this.props.campaign && !this.props.duplicate) {
      this.modify();
    } else {
      this.create();
    }
  }

  async getListCount(listName) {
    return new Promise((resolve) => {
      APIV2.getCampaignListCount(
        this.state.selectedStores,
        listName,
        this.state.channel
      ).then(
        (data) => {
          resolve(data.data.count);
        },
        (e) => {
          resolve(0);
        }
      );
    });
  }

  updateListCounts(lists) {
    let promises = [];

    this.setState({
      setUpdatingCounts: true,
    });

    for (let i = 0; i < lists?.length; i++) {
      promises.push(this.getListCount(lists[i]));
    }

    Promise.all(promises).then((values) => {
      let total = 0;

      for (let i = 0; i < values?.length; i++) {
        console.log(values[i]);

        total += values[i];
      }

      this.setState({
        totalContactCount: total,
        setUpdatingCounts: false,
      });
    });
  }

  calculateSegments(message) {
    if (!message) {
      return 0;
    }

    let seg = new SegmentedMessage(message);

    return seg.segmentsCount;
  }

  create() {
    this.setState({ submitting: true });

    let dateTime = "now";

    if (this.state.scheduledSend != "now" && !this.state.draft) {
      dateTime = moment(this.state.scheduledDate?.toISOString())
        .hours(this.state.scheduledTime.getHours())
        .minutes(this.state.scheduledTime.getMinutes())
        .seconds(0)
        .millisecond(0)
        .toDate();

      if (dateTime < moment().seconds(0).millisecond(0).toDate()) {
        this.setError("error", "The date/time selected is in the past.");

        return this.setState({ submitting: false });
      }

      dateTime = dateTime?.toISOString();
    }

    let payload = {
      name: this.state.name,
      description: this.state.description,
      storeIDs: this.state.selectedStores,
      channel: this.state.channel,
      draft: this.state.draft,
      dateTime: this.state.draft ? null : dateTime,
      lists: this.state.lists,
      platform: "storefront",
      ...this.bakeNotification(),
    };

    APIV2.createCampaign(payload)
      .then(
        (data) => {
          const broadcast = data.data.campaign;

          this.toggleModal();

          PubSub.publish(Event.CONVERSATION_BROADCAST.CREATED, broadcast);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create update notification - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  _loadNotificationState() {
    this.setState({
      title: this.props.campaign?.title ? this.props.campaign?.title : "",
      dynamicTitle: this.props.campaign?.dynamicTitle
        ? this.props.campaign?.dynamicTitle
        : false,
      dynamicTitleScript: this.props.campaign?.dynamicTitleScript
        ? this.props.campaign?.dynamicTitleScript
        : null,
      message: this.props.campaign?.body ? this.props.campaign?.body : "",
      dynamicBody: this.props.campaign?.dynamicBody
        ? this.props.campaign?.dynamicBody
        : false,
      dynamicBodyScript: this.props.campaign?.dynamicBodyScript
        ? this.props.campaign?.dynamicBodyScript
        : null,
      image: this.props.campaign?.image
        ? { url: this.props.campaign?.image }
        : null,
      dynamicImage: this.props.campaign?.dynamicImage
        ? this.props.campaign?.dynamicImage
        : false,
      dynamicImageScript: this.props.campaign?.dynamicImageScript
        ? this.props.campaign?.dynamicImageScript
        : null,
      interactive:
        this.props.campaign?.action && !this.props.campaign?.announcement
          ? true
          : false,
      interactiveEventType: this.props.campaign?.action?.eventType
        ? this.props.campaign?.action?.eventType
        : "",
      interactiveButtonText: this.props.campaign?.action?.buttonText,
      interactiveButtonTextDynamic: this.props.campaign?.action
        ?.buttonTextDynamic
        ? this.props.campaign?.action?.buttonTextDynamic
        : false,
      interactiveButtonTextDynamicScript: this.props.campaign?.action
        ?.buttonTextDynamicScript
        ? this.props.campaign?.action?.buttonTextDynamicScript
        : null,
      interactiveEventData: this.props.campaign?.action?.eventData,
      interactiveEventDataDynamic: this.props.campaign?.action?.eventDataDynamic
        ? this.props.campaign?.action?.eventDataDynamic
        : false,
      interactiveEventDataDynamicScript: this.props.campaign?.action
        ?.eventDataDynamicScript
        ? this.props.campaign?.action?.eventDataDynamicScript
        : null,
      interactiveEventMetadata: this.props.campaign?.action?.eventMetadata,
      interactiveEventMetadataDynamic: this.props.campaign?.action
        ?.eventMetadataDynamic
        ? this.props.campaign?.action?.eventMetadataDynamic
        : false,
      interactiveEventMetadataDynamicScript: this.props.campaign?.action
        ?.eventMetadataDynamicScript
        ? this.props.campaign?.action?.eventMetadataDynamicScript
        : null,
    });
  }

  bakeNotification() {
    let payload = {};

    payload.platform = "storefront";
    payload.title = this.state.title ? this.state.title : null;
    payload.dynamicTitle = this.state.dynamicTitle ? true : false;
    payload.dynamicTitleScript = this.state.dynamicTitleScript
      ? this.state.dynamicTitleScript
      : null;

    payload.body = this.state.message ? this.state.message : null;
    payload.dynamicBody = this.state.dynamicBody ? true : false;
    payload.dynamicBodyScript = this.state.dynamicBodyScript
      ? this.state.dynamicBodyScript
      : null;

    if (this.state.hasAnnouncement) {
      payload.action = {
        eventType: "announcement",
        buttonText: "View",
      };

      payload.announcement = this.state.announcement;
      payload.announcementVideoURL = this.state.announcementVideoURL
        ? this.state.announcementVideoURL
        : null;
      payload.announcementEmbed = this.state.announcementEmbed
        ? this.state.announcementEmbed
        : null;
    } else if (this.state.interactive) {
      payload.announcement = null;
      payload.announcementVideoURL = null;
      payload.announcementEmbed = null;
      payload.action = {
        eventType: this.state.interactiveEventType,
        buttonText:
          this.state.interactiveButtonText &&
          !this.state.dynamicInteractiveEventData
            ? this.state.interactiveButtonText
            : null,
        buttonTextDynamic: this.state.dynamicInteractiveButtonText
          ? true
          : false,
        buttonTextDynamicScript: this.state.interactiveButtonTextDynamicScript
          ? this.state.interactiveButtonTextDynamicScript
          : null,
        eventData: this.state.interactiveEventData
          ? this.state.interactiveEventData
          : null,
        eventDataDynamic: this.state.dynamicInteractiveEventData ? true : false,
        eventDataDynamicScript: this.state.interactiveEventDataDynamicScript
          ? this.state.interactiveEventDataDynamicScript
          : null,
        eventMetadata: this.state.interactiveEventMetadata
          ? this.state.interactiveEventMetadata
          : null,
        eventMetadataDynamic: this.state.dynamicInteractiveEventMetadata
          ? true
          : false,
        eventMetadataDynamicScript: this.state
          .dynamicInteractiveEventMetadataScript
          ? this.state.dynamicInteractiveEventMetadataScript
          : null,
      };
    }

    return payload;
  }

  sendTestMessage() {
    //let dateTime = "now";

    let payload = {
      ...this.bakeNotification(),
    };

    let out = {
      campaign: payload,
      userID: this.state.pushTestUser,
    };

    this.setState({
      sendingTest: true,
    });

    APIV2.sendCampaignTestMessage(out)
      .then(
        (data) => {
          this.setError("testSuccess", "Successfully sent test campaign.");

          this.setState({
            phone: {
              value: "",
            },
          });

          this.testMessageRef.current.close();
        },
        (e) => {
          this.setError(
            "testError",
            e?.response?.body?.message ??
              "Unable to send test campaign - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          sendingTest: false,
        });
      });
  }

  modify() {
    this.setState({ submitting: true });

    let dateTime = "now";

    if (this.state.scheduledSend != "now" && !this.state.draft) {
      dateTime = moment(this.state.scheduledDate?.toISOString())
        .hours(this.state.scheduledTime.getHours())
        .minutes(this.state.scheduledTime.getMinutes())
        .seconds(0)
        .millisecond(0)
        .toDate();

      if (dateTime < moment().seconds(0).millisecond(0).toDate()) {
        this.setError("error", "The date/time selected is in the past.");

        return this.setState({ submitting: false });
      }

      dateTime = dateTime?.toISOString();
    }

    let payload = {
      name: this.state.name,
      description: this.state.description,
      storeIDs: this.state.selectedStores,
      channel: this.state.channel,
      draft: this.state.draft,
      dateTime: this.state.draft ? null : dateTime,
      lists: this.state.lists,
      platform: "storefront",
      ...this.bakeNotification(),
    };

    APIV2.modifyCampaign(this.props?.campaign?._id, payload)
      .then(
        (data) => {
          const broadcast = data.data.campaign;

          this.toggleModal();

          PubSub.publish(Event.CONVERSATION_BROADCAST.MODIFIED, broadcast);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify campaign - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  userIsGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  llmPromptTitle() {
    let model = "gpt-3.5-turbo";
    let systemPrompt = `You are a helpful AI assistant that is an expert copywriter responsible for writing impactful email subject lines and notification titles. You never use insensitive information and you always use proper grammar. Never include the word 'Subject' in your response. Respond in json format with the completion set to the 'value'.  For Example, your response should be formatted {"value": "_response_here_"}. Make the response follow industry best practices for email subject line length.`;
    let prompt = `Write me an email subject line based on the following content that starts with the phrase and summarizes the content concisely. The name of this announcement is "${this.state.name}" Content: ${this.state.announcement?.text}`;
    let maxTokens = 1024;

    this.setState({
      loadingTitle: true,
    });

    APIV2.llmPrompt(model, systemPrompt, prompt, maxTokens)
      .then(
        (data) => {
          //console.log(data);

          let value = data?.data?.content?.value;

          console.log(value);

          this.setState({
            title: value,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loadingTitle: false,
        });
      });
  }

  llmPromptSummary() {
    let model = "gpt-3.5-turbo";
    let systemPrompt = `You are a helpful AI assistant that is an expert copywriter responsible for writing impactful email subject and preheader lines and notification summaries. You never use insensitive information and you always use proper grammar. Never include the word 'Summary' or 'subject' in your response. Respond in json format with the completion set to the 'value'. For Example, your response should be formatted {"value": "_response_here_"}. Make the response follow industry best practices for notification body length.`;
    let prompt = `Write me a short summary that is no more than one sentence long and is based on the following content. and summarizes the content concisely. The name of this announcement is "${this.state.name}" Content: ${this.state.announcement?.text}`;
    let maxTokens = 1024;

    this.setState({
      loadingSummary: true,
    });

    APIV2.llmPrompt(model, systemPrompt, prompt, maxTokens)
      .then(
        (data) => {
          //console.log(data);

          let value = data?.data?.content?.value;

          //console.log(value);

          this.setState({
            message: value,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loadingSummary: false,
        });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.campaign && !this.props.duplicate
                ? "Modify Notification"
                : null}
              {this.props.campaign && this.props.duplicate
                ? "Duplicate Notification"
                : null}
              {!this.props.campaign ? "New Notification" : null}
            </h3>
            <Drawer.Actions>
              <Whisper
                trigger={"click"}
                placement="autoVertical"
                preventOverflow={true}
                ref={this.testMessageRef}
                speaker={
                  <Popover
                    full
                    className="bg-white p-2"
                    style={{ minWidth: 200, maxWidth: 300 }}
                  >
                    <FormGroup className="mb-3">
                      <h5 className="text-uppercase">Selct A Test User</h5>
                      <Input
                        className="form-control form-control-sm"
                        bsSize="sm"
                        type="select"
                        name="pushTestUser"
                        value={this.state.pushTestUser}
                        onChange={this.handleInputChange.bind(this)}
                      >
                        <option value="" disabled>
                          Select A User
                        </option>
                        {this.state.pushTestUserOptions?.map((opt) => (
                          <option value={opt?.value}>{opt?.label}</option>
                        ))}
                      </Input>
                    </FormGroup>
                    <Button
                      size="sm"
                      block
                      color="primary"
                      onClick={() => {
                        this.sendTestMessage();
                      }}
                      disabled={
                        !this.state.pushTestUser || this.state.sendingTest
                      }
                    >
                      {this.state.sendingTest ? (
                        <Spinner size="sm" color="white"></Spinner>
                      ) : (
                        <>Send Test Notification</>
                      )}
                    </Button>
                    {this.state.testError ? (
                      <>
                        <Alert color="danger">{this.state.testError}</Alert>
                      </>
                    ) : null}
                  </Popover>
                }
              >
                <Button
                  size="sm"
                  disabled={this.state.submitting || this.state.sendingTest}
                  color="dark"
                  outline
                >
                  Send Test
                </Button>
              </Whisper>

              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  this.state.sendingTest ||
                  !this.state.lists?.length ||
                  !this.state.selectedStores?.length ||
                  !this.state.name ||
                  !this.state.message ||
                  (this.state.draft != true &&
                    (!this.state.scheduledSend ||
                      (this.state.scheduledSend == "scheduled" &&
                        (!this.state.scheduledDate ||
                          !this.state.scheduledTime))))
                }
                color="primary"
                onClick={this.submit.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>
                    {this.props.campaign && !this.props.duplicate
                      ? "Save"
                      : "Add"}
                  </>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            {this.state.testSuccess ? (
              <>
                <Alert color="success">{this.state.testSuccess}</Alert>
              </>
            ) : null}
            <FormGroup>
              <h5>
                Notifiction Name{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="text"
                name="name"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Notification Description</h5>
              <Input
                bsSize="sm"
                type="textarea"
                rows={2}
                name="description"
                placeholder="Description"
                value={this.state.description}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>

            <FormGroup>
              <h5>
                Targeted Stores
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <CheckPicker
                className="mt-2"
                loading={this.state.loadingStores}
                groupBy="groupBy"
                block
                ref={this.storePickerRef}
                cleanable={true}
                label="Stores"
                data={this.state.storeOptions}
                placement="autoVertical"
                preventOverflow={true}
                placeholder="Select Stores"
                value={this.state.selectedStores}
                onChange={(v) => {
                  this.setState({
                    selectedStores: v,
                  });
                }}
                sort={(isGroup) => {
                  if (isGroup) {
                    return (a, b) => {
                      if (b.groupTitle == "Live") {
                        return 1;
                      }

                      if (b.groupTitle == "Prelaunch") {
                        if (a.groupTitle == "Live") {
                          return -1;
                        }

                        return 1;
                      }

                      if (b.groupTitle == "Buildout") {
                        if (
                          a.groupTitle == "Live" ||
                          a.groupTitle == "Prelaunch"
                        ) {
                          return -1;
                        }

                        return 1;
                      }

                      if (b.groupTitle == "Site Selection") {
                        if (
                          a.groupTitle == "Live" ||
                          a.groupTitle == "Prelaunch" ||
                          a.groupTitle == "Buildout"
                        ) {
                          return -1;
                        }

                        return 1;
                      }

                      return a.groupTitle < b.groupTitle ? -1 : 1;
                    };
                  }

                  return (a, b) => {
                    return a.label < b.label ? -1 : 1;
                  };
                }}
              ></CheckPicker>
            </FormGroup>
            <FormGroup>
              <h5>
                Recipients{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <CheckPicker
                className="mt-2"
                searchable={false}
                data={this.state.listOptions}
                value={this.state.lists}
                onChange={(v) => {
                  this.setState({ lists: v });
                }}
                placement="autoVertical"
                block
                cleanable={true}
                disabledItemValues={this.state.disabledListOptions}
              ></CheckPicker>
            </FormGroup>
            <FormGroup className={`${this.state.interactive && "d-none"}`}>
              <Checkbox
                checked={this.state.hasAnnouncement}
                onChange={(e, v) => {
                  this.setState({
                    hasAnnouncement: v,
                    interactive: false,
                    dynamicBody: false,
                    dynamicTitle: false,
                  });
                }}
              >
                {" "}
                Add Announcement
              </Checkbox>
            </FormGroup>
            <FormGroup>
              <Row className="align-items-center mb-1">
                <Col xs="">
                  <h5 className="m-0">
                    Notification Title
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{
                        fontSize: "80%",
                        position: "relative",
                        top: -1,
                      }}
                    ></i>
                  </h5>
                </Col>
                <Col xs="auto">
                  {this.state.hasAnnouncement ? (
                    <Button
                      size="sm"
                      outline
                      color="dark"
                      style={{ fontSize: 16, padding: 4, lineHeight: 1 }}
                      onClick={() => {
                        this.llmPromptTitle();
                      }}
                      disabled={this.state.loadingTitle}
                    >
                      {this.state.loadingTitle ? (
                        <Spinner size="sm"></Spinner>
                      ) : (
                        <i className="mdi mdi-auto-fix"></i>
                      )}
                    </Button>
                  ) : (
                    <Checkbox
                      checked={this.state.dynamicTitle}
                      onChange={(e, v) => {
                        this.setState({
                          dynamicTitle: v,
                        });
                      }}
                    >
                      {" "}
                      Dynamic
                    </Checkbox>
                  )}
                </Col>
              </Row>

              {this.state.dynamicTitle ? (
                <Input
                  bsSize="sm"
                  type="textarea"
                  name="dynamicTitleScript"
                  value={this.state.dynamicTitleScript}
                  onChange={(e) => {
                    this.setState({
                      dynamicTitleScript: e.target.value,
                    });
                  }}
                  rows={4}
                  placeholder="Javascript here"
                ></Input>
              ) : (
                <Input
                  bsSize="sm"
                  type="text"
                  name="title"
                  placeholder="Title"
                  value={this.state.title}
                  onChange={this.handleInputChange.bind(this)}
                ></Input>
              )}
            </FormGroup>
            <FormGroup>
              <Row className="align-items-center mb-1">
                <Col xs="">
                  <h5 className="m-0">
                    Notification{" "}
                    {this.state.hasAnnouncement ? "Summary" : "Body"}{" "}
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{
                        fontSize: "80%",
                        position: "relative",
                        top: -1,
                      }}
                    ></i>
                  </h5>
                </Col>
                <Col xs="auto">
                  {this.state.hasAnnouncement ? (
                    <Button
                      size="sm"
                      outline
                      color="dark"
                      style={{ fontSize: 16, padding: 4, lineHeight: 1 }}
                      onClick={() => {
                        this.llmPromptSummary();
                      }}
                      disabled={this.state.loadingSummary}
                    >
                      {this.state.loadingSummary ? (
                        <Spinner size="sm"></Spinner>
                      ) : (
                        <i className="mdi mdi-auto-fix"></i>
                      )}
                    </Button>
                  ) : (
                    <Checkbox
                      checked={this.state.dynamicBody}
                      onChange={(e, v) => {
                        this.setState({
                          dynamicBody: v,
                        });
                      }}
                    >
                      {" "}
                      Dynamic
                    </Checkbox>
                  )}
                </Col>
              </Row>

              {this.state.dynamicBody ? (
                <Input
                  bsSize="sm"
                  type="textarea"
                  name="dynamicBodyScript"
                  value={this.state.dynamicBodyScript}
                  onChange={(e) => {
                    this.setState({
                      dynamicBodyScript: e.target.value,
                    });
                  }}
                  rows={4}
                  placeholder="Javascript here"
                ></Input>
              ) : (
                <>
                  <div className="">
                    <textarea
                      className="form-control"
                      type="textarea"
                      id="message-textarea"
                      placeholder="Type something..."
                      ref={(c) => {
                        if (!this.state.textareaRef) {
                          this.setState({ textareaRef: c }, () => {
                            autosize(this.state.textareaRef);
                          });
                        }
                      }}
                      value={this.state.message}
                      style={{
                        minHeight: 82,
                        maxHeight: 248,
                        resize: "none",
                      }}
                      rows={1}
                      name="message"
                      onChange={this.handleInputChange.bind(this)}
                      required={true}
                    ></textarea>
                  </div>
                  <div className="p-2 rounded border mt-2 border-lighter">
                    <Row className="align-items-center">
                      <Col xs="">
                        <Whisper
                          trigger={["click"]}
                          ref={this.emojiRef}
                          onClose={() => {
                            this.setState({
                              emojiOpen: false,
                            });
                          }}
                          placement={"autoVerticalStart"}
                          preventOverflow={true}
                          speaker={
                            <Popover
                              full
                              className="dropdown-popover quick-reply"
                              style={{}}
                            >
                              <EmojiPicker
                                emojiStyle="apple"
                                defaultSkinTone="neutral"
                                skinTonesDisabled={true}
                                theme="light"
                                getEmojiUrl={(data, e) => {
                                  return `/emojis/apple/64/${data}.png`;
                                }}
                                onEmojiClick={(v) => {
                                  let b = this.state.message;

                                  b = b + v?.emoji;

                                  if (b?.length) {
                                    this.setState({
                                      messageLength: count(b),
                                    });
                                  } else {
                                    this.setState({
                                      messageLength: null,
                                    });
                                  }

                                  this.setState({ message: b });

                                  this.emojiRef.current.close();
                                }}
                              ></EmojiPicker>
                            </Popover>
                          }
                        >
                          <Button
                            size="sm"
                            outline
                            color="dark"
                            className="btn-icon-only border-0 mr-0"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();

                              if (this.state.emojiOpen) {
                                return this.setState({ emojiOpen: false });
                              }

                              this.setState({ emojiOpen: true });
                            }}
                          >
                            <i className="mdi mdi-emoticon-outline"></i>
                          </Button>
                        </Whisper>

                        {/*<Button
                          size="sm"
                          outline
                          color="dark"
                          className="btn-icon-only border-0"
                        >
                          <i className="mdi mdi-image"></i>
                    </Button>*/}

                        {/*<Button
                          size="sm"
                          outline
                          color="dark"
                          className="btn-icon-only border-0"
                        >
                          <i className="mdi mdi-note-plus-outline"></i>
                </Button>*/}
                      </Col>
                      <Col xs="auto">
                        <div style={{ fontSize: 12 }}>
                          {this.state.messageLength ? (
                            <>
                              Chars:{" "}
                              {StringUtils.numberFormat(
                                this.state.messageLength?.length
                              )}{" "}
                            </>
                          ) : (
                            <>Chars: 0</>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            </FormGroup>
            {/**
             * <FormGroup>
              <Row className="align-items-center">
                <Col xs="">
                  <h5 className="mb-0">
                    Image{" "}
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{
                        fontSize: "80%",
                        position: "relative",
                        top: -1,
                      }}
                    ></i>
                  </h5>
                </Col>
                <Col xs="auto">
                  <Checkbox
                    checked={this.state.dynamicImage}
                    onChange={(e, v) => {
                      this.setState({
                        dynamicImage: v,
                      });
                    }}
                  >
                    {" "}
                    Dynamic
                  </Checkbox>
                </Col>
              </Row>
              {this.state.dynamicImage ? (
                <Input
                  bsSize="sm"
                  type="textarea"
                  name="dynamicImageScript"
                  value={this.state.dynamicImageScript}
                  onChange={(e) => {
                    this.setState({
                      dynamicImageScript: e.target.value,
                    });
                  }}
                  rows={4}
                  placeholder="Javascript here"
                ></Input>
              ) : (
                <>
                  {this.state.image ? (
                    <div
                      style={{
                        width: "100%",
                        height: 200,
                        position: "relative",
                      }}
                      className="border text-center border-lighter"
                    >
                      <div style={{ position: "absolute", top: 10, right: 10 }}>
                        <Button
                          size="sm"
                          outline
                          color="danger"
                          onClick={() => {
                            this.setState({
                              image: null,
                            });
                          }}
                        >
                          <i className="mdi mdi-close"></i>
                        </Button>
                      </div>
                      <img
                        src={this.state.image?.url}
                        alt="Image"
                        style={{ height: "200px", width: "auto" }}
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        this.setState({
                          showImagePicker: true,
                        });
                      }}
                      style={{ height: "200px" }}
                      className="cursor-pointer border border-lighter text-center d-flex align-items-center text-center"
                    >
                      <div
                        style={{ width: "100%" }}
                        className="text-dark text-center"
                      >
                        Click here to add an image
                      </div>
                    </div>
                  )}
                </>
              )}
            </FormGroup>
             * 
             */}

            {this.state.hasAnnouncement ? (
              <>
                <Card
                  className={`mb-4 border mt-2 ${
                    this.state.expanded ? "shadow" : ""
                  }`}
                >
                  <CardHeader
                    className={`cursor-pointer p-2`}
                    onClick={() => {
                      this.setState({ expanded: !this.state.expanded });
                    }}
                  >
                    <Row className="align-items-center">
                      <Col xs="">
                        <h4 className="m-0 text-dark">
                          Add Video / Media To Announcement
                        </h4>
                      </Col>
                      <Col xs="auto">
                        <h3 className="text-dark m-0">
                          <i
                            style={{ fontSize: 22 }}
                            className={`mdi mdi-${
                              this.state.expanded
                                ? "chevron-down"
                                : "chevron-right"
                            }`}
                          ></i>
                        </h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={this.state.expanded}>
                    <CardBody className="p-2">
                      <FormGroup>
                        <h4 className="text-dark mb-0">Youtube Video URL</h4>
                        <p className="small mt-0 mb-2">
                          Paste a youtube video URL here to embed it at the
                          beginning of the topic.
                        </p>
                        <Input
                          type="text"
                          placeholder="https://youtube.com"
                          className="form-control form-control-sm"
                          name="announcementVideoURL"
                          value={this.state.announcementVideoURL}
                          onChange={(e) => {
                            const { name, value } = e.target;

                            this.setState({
                              [name]: value,
                            });
                          }}
                        ></Input>
                      </FormGroup>
                      <div className="mb-2 mt--1 small text-dark">
                        -or include-
                      </div>
                      <FormGroup>
                        <h4 className="text-dark mb-0">Embedded Video Code</h4>
                        <p className="small mt-0 mb-2">
                          Paste embedded code from any tool such as Loom, etc
                          here to publish at the top of the page.
                        </p>
                        <Input
                          type="textarea"
                          placeholder="<iframe>..."
                          className="form-control form-control-sm"
                          name="announcementEmbed"
                          value={this.state.announcementEmbed}
                          onChange={(e) => {
                            const { name, value } = e.target;

                            this.setState({
                              [name]: value,
                            });
                          }}
                        ></Input>
                      </FormGroup>
                    </CardBody>
                  </Collapse>
                </Card>
                <Row className="mb-3 align-items-center">
                  <Col xs="">
                    <h3 className="text-dark mb-0">Annoucement Editor</h3>
                  </Col>
                  <Col xs="auto">
                    <Button
                      color="secondary"
                      outline
                      size="sm"
                      onClick={() => {
                        this.setState({
                          openTemplates: true,
                        });
                      }}
                    >
                      Use Template
                    </Button>
                  </Col>
                </Row>
                <div
                  className="rounded p-3 border mb-3"
                  style={{ minHeight: 300 }}
                >
                  <EditorV2
                    editable={true}
                    allowForceHydration={
                      this.state.announcement && this.state.templateLoad
                    }
                    id={"announcementTemplateEditor"}
                    padding="p-0"
                    autoSave={false}
                    debounceRate={750}
                    value={this.state?.announcement}
                    onChange={(value) => {
                      if (value?.clear) {
                        this.setState({
                          templateLoad: false,
                        });

                        return;
                      }

                      //console.log("CHANGE", value);

                      this.setState({
                        announcement: value,
                      });
                    }}
                    allowTaskLists={false}
                  ></EditorV2>
                </div>
              </>
            ) : null}
            <FormGroup className={`${this.state.hasAnnouncement && "d-none"}`}>
              <Checkbox
                checked={this.state.interactive}
                onChange={(e, v) => {
                  this.setState({
                    interactive: v,
                    hasAnnouncement: false,
                  });
                }}
              >
                {" "}
                Add In-App Action
              </Checkbox>
            </FormGroup>
            {this.state.interactive && (
              <>
                <FormGroup>
                  <Row className="align-items-center">
                    <Col xs="">
                      <h5 className="mb-0">
                        Action Button Text{" "}
                        <i
                          className="mdi mdi-octagram text-danger pl-1"
                          style={{
                            fontSize: "80%",
                            position: "relative",
                            top: -1,
                          }}
                        ></i>
                      </h5>
                    </Col>
                    <Col xs="auto">
                      <Checkbox
                        checked={this.state.dynamicInteractiveButtonText}
                        onChange={(e, v) => {
                          this.setState({
                            dynamicInteractiveButtonText: v,
                          });
                        }}
                      >
                        {" "}
                        Dynamic
                      </Checkbox>
                    </Col>
                  </Row>
                  {this.state.dynamicInteractiveButtonText ? (
                    <Input
                      bsSize="sm"
                      type="textarea"
                      name="interactiveButtonTextDynamicScript"
                      value={this.state.interactiveButtonTextDynamicScript}
                      onChange={(e) => {
                        this.setState({
                          interactiveButtonTextDynamicScript: e.target.value,
                        });
                      }}
                      rows={4}
                      placeholder="Javascript here"
                    ></Input>
                  ) : (
                    <Input
                      bsSize="sm"
                      type="text"
                      name="interactiveButtonText"
                      placeholder="Interactive Button Text"
                      value={this.state.interactiveButtonText}
                      onChange={this.handleInputChange.bind(this)}
                    ></Input>
                  )}
                </FormGroup>
                <FormGroup>
                  <Row className="align-items-center">
                    <Col xs="">
                      <h5 className="mb-0">
                        Action Type{" "}
                        <i
                          className="mdi mdi-octagram text-danger pl-1"
                          style={{
                            fontSize: "80%",
                            position: "relative",
                            top: -1,
                          }}
                        ></i>
                      </h5>
                    </Col>
                  </Row>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="interactiveEventType"
                    placeholder="InteractiveEventType"
                    value={this.state.interactiveEventType}
                    onChange={this.handleInputChange.bind(this)}
                  >
                    <option value="" disabled>
                      Select An Action
                    </option>
                    <option value="in-app-page">In-App Page Change</option>
                    <option value="in-app-event">In-App Event</option>
                    <option value="website-link">Website Link</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Row className="align-items-center">
                    <Col xs="">
                      <h5 className="mb-0">
                        {!this.state.interactiveEventType ? "Event Data" : ""}
                        {this.state.interactiveEventType == "in-app-page"
                          ? "In-App URL Path"
                          : ""}
                        {this.state.interactiveEventType == "in-app-event"
                          ? "In-App Event Name"
                          : ""}
                        {this.state.interactiveEventType == "website-link"
                          ? "Website URL"
                          : ""}{" "}
                        <i
                          className="mdi mdi-octagram text-danger pl-1"
                          style={{
                            fontSize: "80%",
                            position: "relative",
                            top: -1,
                          }}
                        ></i>
                      </h5>
                    </Col>
                    <Col xs="auto">
                      <Checkbox
                        checked={this.state.dynamicInteractiveEventData}
                        onChange={(e, v) => {
                          this.setState({
                            dynamicInteractiveEventData: v,
                          });
                        }}
                      >
                        {" "}
                        Dynamic
                      </Checkbox>
                    </Col>
                  </Row>
                  {this.state.dynamicInteractiveEventData ? (
                    <Input
                      bsSize="sm"
                      type="textarea"
                      name="interactiveEventDataDynamicScript"
                      value={this.state.interactiveEventDataDynamicScript}
                      onChange={(e) => {
                        this.setState({
                          interactiveEventDataDynamicScript: e.target.value,
                        });
                      }}
                      rows={4}
                      placeholder="Javascript here"
                    ></Input>
                  ) : (
                    <Input
                      bsSize="sm"
                      type="text"
                      name="interactiveEventData"
                      placeholder={`${
                        !this.state.interactiveEventType ? "Event Data" : ""
                      }${
                        this.state.interactiveEventType == "in-app-page"
                          ? "In-App URL Path"
                          : ""
                      }${
                        this.state.interactiveEventType == "in-app-event"
                          ? "In-App Event Name"
                          : ""
                      }${
                        this.state.interactiveEventType == "website-link"
                          ? "Website URL"
                          : ""
                      }`}
                      value={this.state.interactiveEventData}
                      onChange={this.handleInputChange.bind(this)}
                    ></Input>
                  )}
                </FormGroup>
                {this.state.interactiveEventType == "in-app-event" && (
                  <FormGroup>
                    <Row className="align-items-center">
                      <Col xs="">
                        <h5 className="mb-0">
                          In-App Action Metadata JSON{" "}
                          <i
                            className="mdi mdi-octagram text-danger pl-1"
                            style={{
                              fontSize: "80%",
                              position: "relative",
                              top: -1,
                            }}
                          ></i>
                        </h5>
                      </Col>
                      <Col xs="auto">
                        <Checkbox
                          checked={this.state.dynamicInteractiveEventMetadata}
                          onChange={(e, v) => {
                            this.setState({
                              dynamicInteractiveEventMetadata: v,
                            });
                          }}
                        >
                          {" "}
                          Dynamic
                        </Checkbox>
                      </Col>
                    </Row>
                    {this.state.dynamicInteractiveEventMetadata ? (
                      <Input
                        bsSize="sm"
                        type="textarea"
                        name="dynamicInteractiveEventMetadataScript"
                        value={this.state.dynamicInteractiveEventMetadataScript}
                        onChange={(e) => {
                          this.setState({
                            dynamicInteractiveEventMetadataScript:
                              e.target.value,
                          });
                        }}
                        rows={4}
                        placeholder="Javascript here"
                      ></Input>
                    ) : (
                      <Input
                        bsSize="sm"
                        type="textarea"
                        name="interactiveEventMetadata"
                        placeholder="In-App Event Metaata JSON"
                        rows={4}
                        value={this.state.interactiveEventMetadata}
                        onChange={this.handleInputChange.bind(this)}
                      ></Input>
                    )}
                  </FormGroup>
                )}
              </>
            )}
            <FormGroup>
              <h5>Publish Notification</h5>
              <p className="small m-0">
                Would you like to publish and schedule the release of this
                notification?
              </p>
              <Toggle
                checked={!this.state.draft}
                onChange={(v) => {
                  if (v) {
                    this.setState({
                      scheduledSend: "now",
                      scheduledDate: new Date(),
                      scheduledTime: new Date(),
                    });
                  }

                  this.setState({
                    draft: !v,
                  });
                }}
              >
                Publish
              </Toggle>
            </FormGroup>
            {!this.state.draft ? (
              <>
                <FormGroup>
                  <h5>
                    Schedule{" "}
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <SelectPicker
                    block
                    placement="auto"
                    value={this.state.scheduledSend}
                    placeholder="Choose An Option"
                    data={[
                      {
                        value: "now",
                        label: "Send Immediately",
                      },
                      {
                        value: "scheduled",
                        label: "Specify A Date & Time",
                      },
                    ]}
                    onChange={(v) => {
                      this.setState({
                        scheduledSend: v,
                      });
                    }}
                    cleanable={false}
                    searchable={false}
                  ></SelectPicker>
                </FormGroup>
                {this.state.scheduledSend == "scheduled" ? (
                  <>
                    <FormGroup>
                      <h5>
                        Date{" "}
                        <i
                          className="mdi mdi-octagram text-danger pl-1"
                          style={{
                            fontSize: "80%",
                            position: "relative",
                            top: -1,
                          }}
                        ></i>
                      </h5>
                      <DatePicker
                        block
                        placement="auto"
                        value={this.state.scheduledDate}
                        onChange={(v) => {
                          this.setState({
                            scheduledDate: v,
                          });
                        }}
                        disabledDate={(date) => {
                          return (
                            date <
                            moment()
                              .hours(0)
                              .minutes(0)
                              .seconds(0)
                              .millisecond(0)
                              .toDate()
                          );
                        }}
                        placeholder="MM/DD/YYYY"
                        format="MM/dd/yyyy"
                        oneTap
                      ></DatePicker>
                    </FormGroup>
                    <FormGroup>
                      <h5>
                        Time{" "}
                        <i
                          className="mdi mdi-octagram text-danger pl-1"
                          style={{
                            fontSize: "80%",
                            position: "relative",
                            top: -1,
                          }}
                        ></i>
                      </h5>
                      <DatePicker
                        block
                        placement="auto"
                        value={this.state.scheduledTime}
                        onChange={(v) => {
                          this.setState({
                            scheduledTime: v,
                          });
                        }}
                        placeholder="HH:MM"
                        format="hh:mm a"
                        showMeridian={true}
                      ></DatePicker>
                      <div>
                        <p className="m-0 text-muted" style={{ fontSize: 12 }}>
                          Timezone: America/New_York
                        </p>
                      </div>
                    </FormGroup>
                  </>
                ) : null}
              </>
            ) : null}
          </Drawer.Body>
        </Drawer>
        <Drawer
          style={{ maxWidth: "100%" }}
          open={this.state.openTemplates}
          onClose={() => {
            this.setState({
              openTemplates: false,
            });
          }}
          size="sm"
        >
          <Drawer.Header className="pr-4 border-bottom-0">
            <h3 className="mb-2" style={{ position: "relative", top: "7px" }}>
              Topic Templates
            </h3>
          </Drawer.Header>
          <Drawer.Body className="p-0" style={{ height: "calc(100% - 70px)" }}>
            {this.state.loadingTemplates ? (
              <div className="py-3 text-center">
                <Spinner size="sm"></Spinner>
              </div>
            ) : (
              <div>
                {this.state.templates?.map((template, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      this.setState({
                        openTemplates: false,
                        topicContent: template?.template,
                      });

                      //console.log("HERE");

                      PubSub.publish("editor:contentUpdated", {
                        event: "contentUpdated",
                        forceHydrate: true,
                        id: "announcementTemplateEditor",
                        data: template?.template ? template?.template : {},
                      });
                    }}
                    className={`p-3 cursor-pointer bg-superlight--hover border-bottom ${
                      i == 0 ? "border-top" : ""
                    }`}
                  >
                    <Row className="align-items-center">
                      <Col xs="">
                        <h3 className="m-0">{template?.name}</h3>
                        {template?.description ? (
                          <p className="m-0">{template?.description}</p>
                        ) : null}
                      </Col>
                      <Col xs="auto">
                        <Button
                          color="dark"
                          outline
                          className="btn-icon-only"
                          size="sm"
                        >
                          <i className="mdi mdi-chevron-right"></i>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
            )}
          </Drawer.Body>
        </Drawer>
        {/*<FileUploadDrawer
          open={this.state.showImagePicker}
          cdn="public"
          size="sm"
          singleFile={true}
          compressUploads={true}
          allowedFileTypes={["image/jpeg", "image/png", "image/jpg"]}
          onComplete={(files) => {
            console.log(files);

            this.setState({
              showImagePicker: false,
              image: files[0],
            });
          }}
        ></FileUploadDrawer>*/}
      </>
    );
  }
}

export default withRouter(SystemNotificationDrawer);
