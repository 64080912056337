import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import _ from "underscore";
import MealMenuRow from "../../components/MealMenuRow";
import DataAPI from "lib/DataAPI";
import cubejs from "@cubejs-client/core";
import LinkUtils from "lib/LinkUtils";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";

class StorefrontMealListView extends React.Component {
  state = {
    active: {
      id: "Lifestyle",
      name: "Lifestyle",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
  };

  loadRatings(meals) {
    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          order: {
            "MealFeedback.rating": "desc",
          },
          limit: 25000,
          measures: ["MealFeedback.rating", "MealFeedback.count"],
          timeDimensions: [
            {
              dimension: "MealFeedback.createdat",
            },
          ],
          filters: [
            {
              member: "MealFeedback.mealid",
              operator: "equals",
              values: meals?.map((m) => m?._id),
            },
          ],
          dimensions: ["MealFeedback.mealid"],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (!data?.length) {
            this.setState({
              ratings: [],
            });

            return;
          }

          this.setState({
            ratings: data?.map((d) => {
              return {
                mealID: d["MealFeedback.mealid"],
                rating: d["MealFeedback.rating"]
                  ? Math.round(d["MealFeedback.rating"] * 10) / 10
                  : 0,
                count: d["MealFeedback.count"] ? d["MealFeedback.count"] : 0,
              };
            }),
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  componentWillMount() {
    APIV2.getProductGroups().then(
      (data) => {
        if (data.data && data.data.planTypes) {
          let active = data.data.planTypes.length ? data.data.planTypes[0] : {};

          active.id = active.name;

          let options = data.data.planTypes.map((p) => {
            return {
              id: p.name,
              name: p.name,
              _id: p._id,
            };
          });

          if (_.findWhere(options, { id: this.state.active?.id })) {
            active = _.findWhere(options, { id: this.state.active?.id });
          }

          this.setState({
            productGroups: data.data.planTypes,
            options: options,
            loadingGroups: false,
          });

          this.handleTabChange(active, true);
        }
      },
      () => {
        window.alert("Failed to get product groups. Please try again.");
      }
    );

    this.loadStore();
  }

  componentDidUpdate(prevProps) {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    let user = LocalStorage.get(Constant.CACHE.USER);

    this.setState({
      user,
    });

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    } else {
      this.handleTabChange(this.state.active);
    }
  }

  handleTabChange(tab, force = false) {
    if (tab?.id == this.state.active?.id && !force) {
      return;
    }

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(tab.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadMeals(tab);
  }

  loadStore() {
    APIV2.getStoreByID(this.props.match.params.storeID).then(
      (data) => {
        this.setState({
          store: data.data.store,
        });
      },
      (e) => {
        alert("Unable to load store settings. Reload and try again.");
      }
    );
  }

  loadMeals(group) {
    if (!group) {
      return;
    }

    this.setState({
      meals: [],
      active: group,
      loadingMeals: true,
    });

    let meals = [];

    console.log(group);

    let menu =
      this.state.store?.menus?.meals?.hasOwnProperty(group?._id) &&
      this.state.store.menus.meals[group?._id]?.active?.meals?.length
        ? this.state.store.menus.meals[group?._id]?.active?.meals
        : [];

    console.log(menu);

    menu = _.sortBy(menu, "name");

    this.loadRatings(menu);

    this.setState({
      meals: menu,
      loadingMeals: false,
    });
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Meal Menus"
            showBackButton={false}
            primaryAction={{
              name: <>Submit An Idea</>,
              onClick: () => {
                LinkUtils.openInNewTab(
                  `https://docs.google.com/forms/d/e/1FAIpQLSeY6g8XxmIiYy-YnauzjwN0tgAPvtwgAvLOyCLS1UTOuzXv6A/viewform?usp=pp_url&entry.1789364980=${this.state.user?.name}&entry.1809296284=${this.state.user?.email}`
                );
              },
            }}
          />
          <Row>
            <div className="col">
              <Card className=" shadow" style={{ minHeight: "500px" }}>
                <CardSelectorHeader
                  loading={this.state.loadingGroups}
                  value={this.state.active}
                  options={this.state.options ? this.state.options : []}
                  onChange={(option) => {
                    this.handleTabChange(option);
                  }}
                ></CardSelectorHeader>

                {this.state.active ? (
                  <Table
                    className="table-hover"
                    style={{ minHeight: "300px" }}
                    responsive={true}
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col-auto"></th>
                        <th scope="col">Name</th>
                        <th scope="col">Categories</th>
                        <th scope="col">Allergens</th>
                        <th scope="col">Primary Protein</th>
                        <th scope="col">Secondary Protein</th>
                        <th scope="col">Calories</th>
                        <th scope="col">Carbs</th>
                        <th scope="col">Protein</th>
                        <th scope="col">Fats</th>
                        <th scope="col">Saturated Fat</th>
                        <th scope="col">Trans Fat</th>
                        <th scope="col">Total Sugar</th>
                        <th scope="col">Dietary Fiber</th>
                        <th scope="col">Sodium</th>
                        <th scope="col">Cholesterol</th>
                        <th scope="col">Vitamin D</th>
                        <th scope="col">Potassium</th>
                        <th scope="col">Calcium</th>
                        <th scope="col">Iron</th>
                        <th scope="col">Food Weight</th>
                        <th scope="col" style={{ width: 600 }}>
                          Ingredients
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.loadingMeals ? (
                        <tr>
                          <td colSpan="8">Loading...</td>
                        </tr>
                      ) : null}
                      {!this.state.loadingMeals && !this.state.meals.length ? (
                        <tr>
                          <td colSpan="8">
                            Get started by adding the first{" "}
                            {this.state.active ? this.state.active.name : ""}
                            &nbsp;meal.
                          </td>
                        </tr>
                      ) : null}
                      {this.state.meals.map((meal, i) => (
                        <MealMenuRow
                          key={i}
                          meal={meal}
                          ratings={this.state.ratings}
                        ></MealMenuRow>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light"></thead>
                    <tbody>
                      <tr>
                        <td>Loading...</td>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default StorefrontMealListView;
