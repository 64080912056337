import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  CheckPicker,
  Tooltip,
  Checkbox,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Constant from "lib/Constant";
import LinkUtils from "../../lib/LinkUtils";
import EmojiPicker from "emoji-picker-react";
import autosize from "autosize";
import { count } from "sms-length";
import { SegmentedMessage } from "sms-segments-calculator";
import LocalStorage from "lib/LocalStorage";
import FileUploadDrawer from "components/FileUploadDrawer";
import EditorV2 from "components/NovelEditor/ui/editor";

class CampaignDrawer extends React.Component {
  state = {
    message: "",
    name: "",
    list: "",
    scheduledSend: null,
    phone: {
      value: "",
    },
    channel: "conversations",
    listOptions: [
      {
        value: "dynamic:all_actives",
        label: "All Active Members",
        group: "Members",
      },
      {
        value: `dynamic:all_actives_this_week`,
        label: `Active This Week (${moment().day(6).format("MMM DD, YYYY")})`,
        group: "Members",
      },
      {
        value: `dynamic:delivery_actives_this_week_${moment()
          .day(6)
          .format("YYYY-MM-DD")}`,
        label: `Active This Week - Delivery List (${moment()
          .day(6)
          .format("MMM DD, YYYY")})`,
        group: "Members",
      },
      {
        value: `dynamic:pickup_actives_this_week_${moment()
          .day(6)
          .format("YYYY-MM-DD")}`,
        label: `Active This Week - Pickup List (${moment()
          .day(6)
          .format("MMM DD, YYYY")})`,
        group: "Members",
      },
      {
        value: "dynamic:all_hfns",
        label: "All HFNs",
        group: "HFNs",
      },
      {
        value: "dynamic:all_leads",
        label: "All Leads",
        group: "Leads",
      },
      {
        value: "dynamic:all_retail_customers",
        label: "All Retail Customers",
        group: "Retail Customers",
      },
      {
        value: "dynamic:retail_customers_last_30",
        label: "Customers Last Order Within 30 Days",
        group: "Retail Customers",
      },
      {
        value: "dynamic:retail_customers_over_30",
        label: "Customers Last Order Over 30 Days Ago",
        group: "Retail Customers",
      },
      {
        value: "dynamic:retail_customers_first_time",
        label: "First Time Customers (1 Order)",
        group: "Retail Customers",
      },
      {
        value: "dynamic:retail_customers_repeat",
        label: "Repeat Customers (3+ Orders)",
        group: "Retail Customers",
      },
    ],
    results: {},
    pagination: {},
    templates: [],
  };

  testMessageRef = React.createRef();

  getAllStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStores()
      .then((data) => {
        const now = new Date();

        let stores = _.filter(data.data.stores, (st) => {
          return (
            st.nsoStage &&
            st.nsoStage != "Lead" &&
            st.nsoStage != "Funding" &&
            st.nsoStage != "Cold" &&
            st.nsoStage != "Discovery"
          );
        });

        let storeOptions = stores?.map((st) => {
          return {
            label: st?.name,
            value: st?._id,
            groupBy: st?.nsoStage,
          };
        });

        this.setState({
          stores,
          storeOptions,
        });
      })
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  getPushTestUsers() {
    this.setState({
      loadingTestUsers: true,
    });

    APIV2.getCampaignPushTestUsers()
      .then((data) => {
        this.setState({
          pushTestUsers: data.data.users,
          pushTestUserOptions: data.data.users?.map((u) => {
            return {
              label: u?.name,
              value: u?.userID,
            };
          }),
        });
      })
      .finally(() => {
        this.setState({
          loadingTestUsers: false,
        });
      });
  }

  constructor(props) {
    super(props);

    this.emojiRef = React.createRef();
    this.quickReplyRef = React.createRef();
    this.searchRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.campaign != prevProps?.campaign && this.props.campaign) {
      let message = this.props.campaign?.message
        ? this.props.campaign?.message
        : this.props.campaign?.body
        ? this.props.campaign?.body
        : "";

      this.setState(
        {
          pushTestUser: "",
          name:
            this.props.campaign?.name + (this.props.duplicate ? " Copy" : ""),
          description: this.props.campaign?.description
            ? this.props.campaign?.description
            : "",
          channel: this.props.campaign?.channel
            ? this.props.campaign?.channel
            : "conversations",
          selectedStores: this.props.campaign?.storeIDs
            ? this.props?.campaign?.storeIDs
            : [],
          message,
          lists: this.props.campaign?.lists?.length
            ? this.props.campaign?.lists
            : [],
          scheduledSend: this.props.campaign.sendImmediately
            ? "now"
            : "scheduled",
          scheduledDate: moment(this.props.campaign?.dateTime)
            .hours(0)
            .seconds(0)
            .minutes(0)
            .millisecond(0)
            .toDate(),
          scheduledTime: moment(this.props.campaign?.dateTime).toDate(),
          emailType:
            this.props.campaign?.channel == "email" &&
            typeof this.props.campaign?.body == "object"
              ? "formatted"
              : "html",

          recipients: "list",
          mediaUrl: this.props.campaign?.mediaUrl
            ? this.props.campaign?.mediaUrl
            : "",
        },
        () => {
          if (typeof message == "object" && this.state.channel == "email") {
            setTimeout(() => {
              //console.log("HYDRATE", message);

              PubSub.publish("editor:contentUpdated", {
                event: "contentUpdated",
                forceHydrate: true,
                id: "campaignEmailEditor",
                data: message,
              });
            }, 0);
          }
        }
      );

      if (this.props.campaign?.message?.length) {
        this.setState({
          messageLength: count(this.props.campaign?.message),
        });
      } else {
        this.setState({
          messageLength: null,
        });
      }

      if (
        this.props.campaign?.channel == "push" ||
        this.props.campaign?.channel == "email"
      ) {
        this._loadNotificationState();
      }
    }
  }

  estimateMessageCost(messageLength) {
    if (!messageLength) {
      return StringUtils.centsToCurrency(0);
    }

    let cost = messageLength?.messages * Constant.TWILIO_MESSAGE_COST;

    cost = Math.ceil(cost * 100) / 100;

    return StringUtils.centsToCurrency(cost * 100);
  }

  componentDidMount() {
    this.getAllStores();
    this.getPushTestUsers();

    if (this.props.campaign) {
      this.setState({
        name: this.props.campaign?.name + (this.props.duplicate ? " Copy" : ""),
        description: this.props.campaign?.description
          ? this.props.campaign?.description
          : "",
        channel: this.props.campaign?.channel
          ? this.props.campaign?.channel
          : "conversations",
        selectedStores: this.props.campaign?.storeIDs
          ? this.props?.campaign?.storeIDs
          : [],
        message: this.props.campaign?.message,
        lists: this.props.campaign?.lists?.length
          ? this.props.campaign?.lists
          : [],
        scheduledSend: this.props.campaign.sendImmediately
          ? "now"
          : "scheduled",
        scheduledDate: moment(this.props.campaign?.dateTime)
          .hours(0)
          .seconds(0)
          .minutes(0)
          .millisecond(0)
          .toDate(),
        scheduledTime: moment(this.props.campaign?.dateTime).toDate(),

        recipients: "list",
        mediaUrl: this.props.campaign?.mediaUrl
          ? this.props.campaign?.mediaUrl
          : "",
      });
    }
  }

  toggleModal() {
    this.setState({
      name: "",
      description: "",
      message: "",
      channel: "conversations",
      selectedStores: [],
      lists: [],
      mediaUrl: "",
      scheduledDate: null,
      scheduledTime: null,
      scheduledSend: null,
      recipients: null,
      title: "",
      dynamicTitle: false,
      dynamicTitleScript: null,
      message: "",
      dynamicBody: false,
      dynamicBodyScript: null,
      image: null,
      dynamicImage: false,
      dynamicImageScript: null,
      interactive: false,
      interactiveEventType: "",
      interactiveButtonText: "",
      interactiveButtonTextDynamic: false,
      interactiveButtonTextDynamicScript: null,
      interactiveEventData: "",
      interactiveEventDataDynamic: false,
      interactiveEventDataDynamicScript: null,
      interactiveEventMetadata: "",
      interactiveEventMetadataDynamic: false,
      pushTestUser: "",
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });

    if (name == "message") {
      if (value?.length) {
        this.setState({
          messageLength: count(value),
        });
      } else {
        this.setState({
          messageLength: null,
        });
      }
    }
  }

  submit() {
    if (this.props.campaign && !this.props.duplicate) {
      this.modify();
    } else {
      this.create();
    }
  }

  async getListCount(listName) {
    return new Promise((resolve) => {
      APIV2.getCampaignListCount(
        this.state.selectedStores,
        listName,
        this.state.channel
      ).then(
        (data) => {
          resolve(data.data.count);
        },
        (e) => {
          resolve(0);
        }
      );
    });
  }

  updateListCounts(lists) {
    let promises = [];

    this.setState({
      setUpdatingCounts: true,
    });

    for (let i = 0; i < lists?.length; i++) {
      promises.push(this.getListCount(lists[i]));
    }

    Promise.all(promises).then((values) => {
      let total = 0;

      for (let i = 0; i < values?.length; i++) {
        console.log(values[i]);

        total += values[i];
      }

      this.setState({
        totalContactCount: total,
        setUpdatingCounts: false,
      });
    });
  }

  calculateSegments(message) {
    if (!message) {
      return 0;
    }

    let seg = new SegmentedMessage(message);

    return seg.segmentsCount;
  }

  create() {
    this.setState({ submitting: true });

    let dateTime = "now";

    if (this.state.scheduledSend != "now") {
      dateTime = moment(this.state.scheduledDate?.toISOString())
        .hours(this.state.scheduledTime.getHours())
        .minutes(this.state.scheduledTime.getMinutes())
        .seconds(0)
        .millisecond(0)
        .toDate();

      if (dateTime < moment().seconds(0).millisecond(0).toDate()) {
        this.setError("error", "The date/time selected is in the past.");

        return this.setState({ submitting: false });
      }

      dateTime = dateTime?.toISOString();
    }

    let payload = {
      name: this.state.name,
      description: this.state.description,
      storeIDs: this.state.selectedStores,
      channel: this.state.channel,
      dateTime,
      lists: this.state.lists,
    };

    if (this.state.channel == "conversations") {
      payload.message = this.state.message;
      payload.mediaUrl = this.state.mediaUrl ? this.state.mediaUrl : null;
    }

    if (this.state.channel == "push" || this.state.channel == "email") {
      payload = {
        ...payload,
        ...this.bakeNotification(),
      };
    }

    APIV2.createCampaign(payload)
      .then(
        (data) => {
          const broadcast = data.data.campaign;

          this.toggleModal();

          PubSub.publish(Event.CONVERSATION_BROADCAST.CREATED, broadcast);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create campaign - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  _loadNotificationState() {
    this.setState({
      title: this.props.campaign?.title ? this.props.campaign?.title : "",
      dynamicTitle: this.props.campaign?.dynamicTitle
        ? this.props.campaign?.dynamicTitle
        : false,
      preheader: this.props.campaign?.preheader,
      emailType:
        this.props.campaign?.channel == "email" &&
        typeof this.props.campaign?.body == "object"
          ? "formatted"
          : "html",
      dynamicTitleScript: this.props.campaign?.dynamicTitleScript
        ? this.props.campaign?.dynamicTitleScript
        : null,
      message: this.props.campaign?.body ? this.props.campaign?.body : "",
      dynamicBody: this.props.campaign?.dynamicBody
        ? this.props.campaign?.dynamicBody
        : false,
      dynamicBodyScript: this.props.campaign?.dynamicBodyScript
        ? this.props.campaign?.dynamicBodyScript
        : null,
      image: this.props.campaign?.image
        ? { url: this.props.campaign?.image }
        : null,
      dynamicImage: this.props.campaign?.dynamicImage
        ? this.props.campaign?.dynamicImage
        : false,
      dynamicImageScript: this.props.campaign?.dynamicImageScript
        ? this.props.campaign?.dynamicImageScript
        : null,
      interactive: this.props.campaign?.action ? true : false,
      interactiveEventType: this.props.campaign?.action?.eventType
        ? this.props.campaign?.action?.eventType
        : "",
      interactiveButtonText: this.props.campaign?.action?.buttonText,
      interactiveButtonTextDynamic: this.props.campaign?.action
        ?.buttonTextDynamic
        ? this.props.campaign?.action?.buttonTextDynamic
        : false,
      interactiveButtonTextDynamicScript: this.props.campaign?.action
        ?.buttonTextDynamicScript
        ? this.props.campaign?.action?.buttonTextDynamicScript
        : null,
      interactiveEventData: this.props.campaign?.action?.eventData,
      interactiveEventDataDynamic: this.props.campaign?.action?.eventDataDynamic
        ? this.props.campaign?.action?.eventDataDynamic
        : false,
      interactiveEventDataDynamicScript: this.props.campaign?.action
        ?.eventDataDynamicScript
        ? this.props.campaign?.action?.eventDataDynamicScript
        : null,
      interactiveEventMetadata: this.props.campaign?.action?.eventMetadata,
      interactiveEventMetadataDynamic: this.props.campaign?.action
        ?.eventMetadataDynamic
        ? this.props.campaign?.action?.eventMetadataDynamic
        : false,
      interactiveEventMetadataDynamicScript: this.props.campaign?.action
        ?.eventMetadataDynamicScript
        ? this.props.campaign?.action?.eventMetadataDynamicScript
        : null,
    });
  }

  bakeNotification() {
    let payload = {};

    payload.title = this.state.title ? this.state.title : null;
    payload.preheader =
      this.state.channel == "email" && this.state.preheader
        ? this.state.preheader
        : null;
    payload.dynamicTitle = this.state.dynamicTitle ? true : false;
    payload.dynamicTitleScript = this.state.dynamicTitleScript
      ? this.state.dynamicTitleScript
      : null;

    payload.body = this.state.message ? this.state.message : null;
    payload.dynamicBody = this.state.dynamicBody ? true : false;
    payload.dynamicBodyScript = this.state.dynamicBodyScript
      ? this.state.dynamicBodyScript
      : null;

    payload.image = this.state.image ? this.state.image?.url : null;
    payload.dynamicImage = this.state.dynamicImage ? true : false;
    payload.dynamicImageScript = this.state.dynamicImageScript
      ? this.state.dynamicImageScript
      : null;

    // TODO: add media URL
    if (this.state.interactive) {
      payload.action = {
        eventType: this.state.interactiveEventType,
        buttonText:
          this.state.interactiveButtonText &&
          !this.state.dynamicInteractiveEventData
            ? this.state.interactiveButtonText
            : null,
        buttonTextDynamic: this.state.dynamicInteractiveButtonText
          ? true
          : false,
        buttonTextDynamicScript: this.state.interactiveButtonTextDynamicScript
          ? this.state.interactiveButtonTextDynamicScript
          : null,
        eventData: this.state.interactiveEventData
          ? this.state.interactiveEventData
          : null,
        eventDataDynamic: this.state.dynamicInteractiveEventData ? true : false,
        eventDataDynamicScript: this.state.interactiveEventDataDynamicScript
          ? this.state.interactiveEventDataDynamicScript
          : null,
        eventMetadata: this.state.interactiveEventMetadata
          ? this.state.interactiveEventMetadata
          : null,
        eventMetadataDynamic: this.state.dynamicInteractiveEventMetadata
          ? true
          : false,
        eventMetadataDynamicScript: this.state
          .dynamicInteractiveEventMetadataScript
          ? this.state.dynamicInteractiveEventMetadataScript
          : null,
      };
    }

    return payload;
  }

  sendTestMessage() {
    let dateTime = "now";

    let payload = {
      //name: this.state.name,
      //description: this.state.description,
      //storeIDs: this.state.selectedStores,
      channel: this.state.channel,
      //dateTime,
      //lists: this.state.lists,
    };

    if (this.state.channel == "conversations") {
      payload.message = this.state.message;
      payload.mediaUrl = this.state.mediaUrl ? this.state.mediaUrl : null;
    }

    if (this.state.channel == "push" || this.state.channel == "email") {
      payload = {
        ...payload,
        ...this.bakeNotification(),
      };
    }

    let out = {
      campaign: payload,
    };

    if (payload?.channel == "conversations") {
      out.phoneNumber = `+1${this.state.phone?.value}`;
    }

    if (payload?.channel == "push") {
      out.userID = this.state.pushTestUser;
    }

    if (payload.channel == "email") {
      out.email = this.state.email;
    }

    console.log(out);

    this.setState({
      sendingTest: true,
    });

    APIV2.sendCampaignTestMessage(out)
      .then(
        (data) => {
          this.setError("testSuccess", "Successfully sent test campaign.");

          this.setState({
            phone: {
              value: "",
            },
          });

          this.testMessageRef.current.close();
        },
        (e) => {
          this.setError(
            "testError",
            e?.response?.body?.message ??
              "Unable to send test campaign - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          sendingTest: false,
        });
      });
  }

  modify() {
    this.setState({ submitting: true });

    let dateTime = "now";

    if (this.state.scheduledSend != "now") {
      dateTime = moment(this.state.scheduledDate?.toISOString())
        .hours(this.state.scheduledTime.getHours())
        .minutes(this.state.scheduledTime.getMinutes())
        .seconds(0)
        .millisecond(0)
        .toDate();

      if (dateTime < moment().seconds(0).millisecond(0).toDate()) {
        this.setError("error", "The date/time selected is in the past.");

        return this.setState({ submitting: false });
      }

      dateTime = dateTime?.toISOString();
    }

    let payload = {
      name: this.state.name,
      description: this.state.description,
      storeIDs: this.state.selectedStores,
      channel: this.state.channel,
      dateTime,
      lists: this.state.lists,
    };

    if (this.state.channel == "conversations") {
      payload.message = this.state.message;
      payload.mediaUrl = this.state.mediaUrl ? this.state.mediaUrl : null;
    }

    if (this.state.channel == "push" || this.state.channel == "email") {
      payload = {
        ...payload,
        ...this.bakeNotification(),
      };
    }

    APIV2.modifyCampaign(this.props?.campaign?._id, payload)
      .then(
        (data) => {
          const broadcast = data.data.campaign;

          this.toggleModal();

          PubSub.publish(Event.CONVERSATION_BROADCAST.MODIFIED, broadcast);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify campaign - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  userIsGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.campaign && !this.props.duplicate
                ? "Modify Campaign"
                : null}
              {this.props.campaign && this.props.duplicate
                ? "Duplicate Campaign"
                : null}
              {!this.props.campaign ? "New Campaign" : null}
            </h3>
            <Drawer.Actions>
              {this.state.channel == "conversations" && (
                <>
                  <Whisper
                    trigger={"click"}
                    placement="autoVertical"
                    preventOverflow={true}
                    ref={this.testMessageRef}
                    speaker={
                      <Popover
                        full
                        className="bg-white p-2"
                        style={{ maxWidth: 300 }}
                      >
                        <FormGroup className="mb-3">
                          <h5 className="text-uppercase">Phone</h5>

                          <NumberFormat
                            className="form-control form-control-sm mb-0"
                            format="+1 (###) ###-####"
                            mask=""
                            name="phoneNumberInput"
                            placeholder="Phone Number"
                            onValueChange={(e) => {
                              console.log(e);

                              this.setState({ phone: e });
                            }}
                            value={this.state.phone.value}
                            type="tel"
                          />
                        </FormGroup>
                        <Button
                          size="sm"
                          block
                          color="primary"
                          onClick={() => {
                            this.sendTestMessage();
                          }}
                          disabled={
                            this.state.phone?.value?.length != 10 ||
                            this.state.sendingTest
                          }
                        >
                          {this.state.sendingTest ? (
                            <Spinner size="sm" color="white"></Spinner>
                          ) : (
                            <>Send Test Message</>
                          )}
                        </Button>
                        {this.state.testError ? (
                          <>
                            <Alert color="danger">{this.state.testError}</Alert>
                          </>
                        ) : null}
                      </Popover>
                    }
                  >
                    <Button
                      size="sm"
                      disabled={
                        this.state.submitting ||
                        this.state.sendingTest ||
                        !this.state.channel ||
                        (this.state.channel == "conversations" &&
                          !this.state.message) ||
                        (this.state.channel == "conversations" &&
                          !this.state.message
                            ?.toLowerCase()
                            ?.includes("project leannation"))
                      }
                      color="dark"
                      outline
                    >
                      Send Test
                    </Button>
                  </Whisper>
                </>
              )}
              {this.state.channel == "push" && (
                <>
                  <Whisper
                    trigger={"click"}
                    placement="autoVertical"
                    preventOverflow={true}
                    ref={this.testMessageRef}
                    speaker={
                      <Popover
                        full
                        className="bg-white p-2"
                        style={{ minWidth: 200, maxWidth: 300 }}
                      >
                        <FormGroup className="mb-3">
                          <h5 className="text-uppercase">Select A Test User</h5>
                          <Input
                            className="form-control form-control-sm"
                            bsSize="sm"
                            type="select"
                            name="pushTestUser"
                            value={this.state.pushTestUser}
                            onChange={this.handleInputChange.bind(this)}
                          >
                            <option value="" disabled>
                              Select A User
                            </option>
                            {this.state.pushTestUserOptions?.map((opt) => (
                              <option value={opt?.value}>{opt?.label}</option>
                            ))}
                          </Input>
                        </FormGroup>
                        <Button
                          size="sm"
                          block
                          color="primary"
                          onClick={() => {
                            this.sendTestMessage();
                          }}
                          disabled={
                            !this.state.pushTestUser || this.state.sendingTest
                          }
                        >
                          {this.state.sendingTest ? (
                            <Spinner size="sm" color="white"></Spinner>
                          ) : (
                            <>Send Test Push</>
                          )}
                        </Button>
                        {this.state.testError ? (
                          <>
                            <Alert color="danger">{this.state.testError}</Alert>
                          </>
                        ) : null}
                      </Popover>
                    }
                  >
                    <Button
                      size="sm"
                      disabled={
                        this.state.submitting ||
                        this.state.sendingTest ||
                        !this.state.channel ||
                        (this.state.channel == "conversations" &&
                          !this.state.message) ||
                        (this.state.channel == "conversations" &&
                          !this.state.message
                            ?.toLowerCase()
                            ?.includes("project leannation"))
                      }
                      color="dark"
                      outline
                    >
                      Send Test
                    </Button>
                  </Whisper>
                </>
              )}
              {this.state.channel == "email" && (
                <>
                  <Whisper
                    trigger={"click"}
                    placement="autoVertical"
                    preventOverflow={true}
                    ref={this.testMessageRef}
                    speaker={
                      <Popover
                        full
                        className="bg-white p-2"
                        style={{ minWidth: 200, maxWidth: 300 }}
                      >
                        <FormGroup className="mb-3">
                          <h5 className="text-uppercase">Email</h5>

                          <Input
                            className="form-control form-control-sm mb-0"
                            name="email"
                            placeholder="you@projectleannation.com"
                            onChange={(e) => {
                              this.setState({ email: e?.target?.value });
                            }}
                            value={this.state.email}
                            type="tel"
                          />
                        </FormGroup>
                        <Button
                          size="sm"
                          block
                          color="primary"
                          onClick={() => {
                            this.sendTestMessage();
                          }}
                          disabled={!this.state.email || this.state.sendingTest}
                        >
                          {this.state.sendingTest ? (
                            <Spinner size="sm" color="white"></Spinner>
                          ) : (
                            <>Send Test Email</>
                          )}
                        </Button>
                        {this.state.testError ? (
                          <>
                            <Alert color="danger">{this.state.testError}</Alert>
                          </>
                        ) : null}
                      </Popover>
                    }
                  >
                    <Button
                      size="sm"
                      disabled={
                        this.state.submitting ||
                        this.state.sendingTest ||
                        !this.state.channel ||
                        (this.state.channel == "conversations" &&
                          !this.state.message) ||
                        (this.state.channel == "conversations" &&
                          !this.state.message
                            ?.toLowerCase()
                            ?.includes("project leannation"))
                      }
                      color="dark"
                      outline
                    >
                      Send Test
                    </Button>
                  </Whisper>
                </>
              )}

              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  this.state.sendingTest ||
                  !this.state.lists?.length ||
                  !this.state.channel ||
                  !this.state.selectedStores?.length ||
                  !this.state.name ||
                  (this.state.channel == "conversations" &&
                    !this.state.message) ||
                  !this.state.scheduledSend ||
                  (this.state.scheduledSend == "scheduled" &&
                    (!this.state.scheduledDate || !this.state.scheduledTime)) ||
                  (this.state.channel == "conversations" &&
                    !this.state.message
                      ?.toLowerCase()
                      ?.includes("project leannation"))
                }
                color="primary"
                onClick={this.submit.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>
                    {this.props.campaign && !this.props.duplicate
                      ? "Save"
                      : "Add"}
                  </>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            {this.state.testSuccess ? (
              <>
                <Alert color="success">{this.state.testSuccess}</Alert>
              </>
            ) : null}
            <FormGroup>
              <h5>
                Campaign Name{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="text"
                name="name"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Campaign Description</h5>
              <Input
                bsSize="sm"
                type="textarea"
                rows={2}
                name="description"
                placeholder="Description"
                value={this.state.description}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>
                Distribution Channel
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <SelectPicker
                searchable={false}
                data={[
                  {
                    value: "conversations",
                    label: "Conversations Broadcast",
                  },
                  {
                    value: "email",
                    label: "Email",
                  },
                  {
                    value: "push",
                    label: "Push Notification",
                  },
                ]}
                value={this.state.channel}
                cleanable={false}
                block
                placement="autoVertical"
                onChange={(v) => {
                  let payload = { channel: v, message: "" };

                  if (v == "email") {
                    payload.emailType = "formatted";
                    payload.message = {};

                    PubSub.publish("editor:contentUpdated", {
                      event: "contentUpdated",
                      forceHydrate: true,
                      id: "campaignEmailEditor",
                      data: {},
                    });
                  }

                  this.setState(payload, () => {
                    this.updateListCounts(this.state.lists);
                  });
                }}
              ></SelectPicker>
            </FormGroup>
            <FormGroup>
              <h5>
                Targeted Stores
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <CheckPicker
                className="mt-2"
                loading={this.state.loadingStores}
                groupBy="groupBy"
                block
                ref={this.storePickerRef}
                cleanable={true}
                label="Stores"
                data={this.state.storeOptions}
                placement="autoVertical"
                preventOverflow={true}
                placeholder="Select Stores"
                value={this.state.selectedStores}
                onChange={(v) => {
                  this.setState({
                    selectedStores: v,
                  });
                }}
                sort={(isGroup) => {
                  if (isGroup) {
                    return (a, b) => {
                      if (b.groupTitle == "Live") {
                        return 1;
                      }

                      if (b.groupTitle == "Prelaunch") {
                        if (a.groupTitle == "Live") {
                          return -1;
                        }

                        return 1;
                      }

                      if (b.groupTitle == "Buildout") {
                        if (
                          a.groupTitle == "Live" ||
                          a.groupTitle == "Prelaunch"
                        ) {
                          return -1;
                        }

                        return 1;
                      }

                      if (b.groupTitle == "Site Selection") {
                        if (
                          a.groupTitle == "Live" ||
                          a.groupTitle == "Prelaunch" ||
                          a.groupTitle == "Buildout"
                        ) {
                          return -1;
                        }

                        return 1;
                      }

                      return a.groupTitle < b.groupTitle ? -1 : 1;
                    };
                  }

                  return (a, b) => {
                    return a.label < b.label ? -1 : 1;
                  };
                }}
              ></CheckPicker>
            </FormGroup>
            <FormGroup>
              <h5>
                Recipients{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <CheckPicker
                className="mt-2"
                data={this.state.listOptions}
                value={this.state.lists}
                onChange={(v) => {
                  let disabledOptions = [];

                  this.updateListCounts(v);

                  if (v?.includes("dynamic:all_actives")) {
                    v = _.filter(v, (opt) => {
                      return (
                        opt != "dynamic:all_actives_this_week" &&
                        opt != "dynamic:delivery_actives_this_week" &&
                        opt != "dynamic:pickup_actives_this_week"
                      );
                    });

                    disabledOptions.push("dynamic:all_actives_this_week");
                    disabledOptions.push("dynamic:delivery_actives_this_week");
                    disabledOptions.push("dynamic:pickup_actives_this_week");
                  }

                  if (v?.includes("dynamic:all_actives_this_week")) {
                    v = _.filter(v, (opt) => {
                      return (
                        opt != "dynamic:delivery_actives_this_week" &&
                        opt != "dynamic:pickup_actives_this_week"
                      );
                    });

                    disabledOptions.push("dynamic:delivery_actives_this_week");
                    disabledOptions.push("dynamic:pickup_actives_this_week");
                  }

                  this.setState({ lists: v, disabledOptions });
                }}
                placement="autoVertical"
                block
                cleanable={true}
                searchable={true}
                groupBy="group"
                disabledItemValues={this.state.disabledListOptions}
              ></CheckPicker>
              {this.state.lists?.length ? (
                <div>
                  <p className="m-0 text-muted" style={{ fontSize: 12 }}>
                    {this.state.updateListCounts
                      ? "Estimating contact count..."
                      : `Est. ${StringUtils.numberFormat(
                          this.state.totalContactCount
                        )} contacts`}
                  </p>
                </div>
              ) : null}
            </FormGroup>

            {this.state.channel == "conversations" ? (
              <>
                <FormGroup>
                  <h5>
                    Conversation Message{" "}
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  {this.state.message &&
                  !this.state.message
                    ?.toLowerCase()
                    ?.includes("project leannation") ? (
                    <>
                      <p
                        className="mb-2 text-danger small"
                        style={{ lineHeight: 1.2 }}
                      >
                        Your message must include "Project LeanNation"
                      </p>
                    </>
                  ) : null}

                  <div className="">
                    <textarea
                      className="form-control"
                      type="textarea"
                      id="message-textarea"
                      placeholder="Type something..."
                      ref={(c) => {
                        if (!this.state.textareaRef) {
                          this.setState({ textareaRef: c }, () => {
                            autosize(this.state.textareaRef);
                          });
                        }
                      }}
                      value={this.state.message}
                      style={{
                        minHeight: 82,
                        maxHeight: 248,
                        resize: "none",
                      }}
                      rows={1}
                      name="message"
                      onChange={this.handleInputChange.bind(this)}
                      required={true}
                    ></textarea>

                    {this.state.mediaUrl ? (
                      <>
                        <div
                          className="p-2 border rounded bg-white mt-2"
                          style={{ fontSize: 12 }}
                        >
                          Attached:{" "}
                          <a
                            href={this.state.mediaUrl}
                            target="_blank"
                            onClick={(e) => {
                              e.preventDefault();

                              LinkUtils.openInNewTab(this.state.mediaUrl);
                            }}
                          >
                            {StringUtils.getAttachmentFileName(
                              this.state.mediaUrl
                            )}
                          </a>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="p-2 rounded border mt-2 border-lighter">
                    <Row className="align-items-center">
                      <Col xs="">
                        <Whisper
                          trigger={["click"]}
                          ref={this.emojiRef}
                          onClose={() => {
                            this.setState({
                              emojiOpen: false,
                            });
                          }}
                          placement={"autoVerticalStart"}
                          preventOverflow={true}
                          speaker={
                            <Popover
                              full
                              className="dropdown-popover quick-reply"
                              style={{}}
                            >
                              <EmojiPicker
                                emojiStyle="apple"
                                defaultSkinTone="neutral"
                                skinTonesDisabled={true}
                                theme="light"
                                getEmojiUrl={(data, e) => {
                                  return `/emojis/apple/64/${data}.png`;
                                }}
                                onEmojiClick={(v) => {
                                  let b = this.state.message;

                                  b = b + v?.emoji;

                                  if (b?.length) {
                                    this.setState({
                                      messageLength: count(b),
                                    });
                                  } else {
                                    this.setState({
                                      messageLength: null,
                                    });
                                  }

                                  this.setState({ message: b });

                                  this.emojiRef.current.close();
                                }}
                              ></EmojiPicker>
                            </Popover>
                          }
                        >
                          <Button
                            size="sm"
                            outline
                            color="dark"
                            className="btn-icon-only border-0 mr-0"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();

                              if (this.state.emojiOpen) {
                                return this.setState({ emojiOpen: false });
                              }

                              this.setState({ emojiOpen: true });
                            }}
                          >
                            <i className="mdi mdi-emoticon-outline"></i>
                          </Button>
                        </Whisper>

                        {/*<Button
                          size="sm"
                          outline
                          color="dark"
                          className="btn-icon-only border-0"
                        >
                          <i className="mdi mdi-image"></i>
                    </Button>*/}

                        {/*<Button
                          size="sm"
                          outline
                          color="dark"
                          className="btn-icon-only border-0"
                        >
                          <i className="mdi mdi-note-plus-outline"></i>
                </Button>*/}
                      </Col>
                      <Col xs="auto">
                        <div style={{ fontSize: 12 }}>
                          {this.state.messageLength ? (
                            <>
                              Chars:{" "}
                              {StringUtils.numberFormat(
                                this.state.messageLength?.length
                              )}{" "}
                              ({this.calculateSegments(this.state.message)}{" "}
                              segments - Cost: ~
                              {this.estimateMessageCost(
                                this.state.messageLength
                              )}
                              /sms)
                            </>
                          ) : (
                            <>Chars: 0</>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </FormGroup>
              </>
            ) : null}
            {this.state.channel == "push" ? (
              <>
                <FormGroup>
                  <Row className="align-items-center">
                    <Col xs="">
                      <h5 className="m-0">Notification Title</h5>
                    </Col>
                    <Col xs="auto">
                      <Checkbox
                        checked={this.state.dynamicTitle}
                        onChange={(e, v) => {
                          this.setState({
                            dynamicTitle: v,
                          });
                        }}
                      >
                        {" "}
                        Dynamic
                      </Checkbox>
                    </Col>
                  </Row>

                  {this.state.dynamicTitle ? (
                    <Input
                      bsSize="sm"
                      type="textarea"
                      name="dynamicTitleScript"
                      value={this.state.dynamicTitleScript}
                      onChange={(e) => {
                        this.setState({
                          dynamicTitleScript: e.target.value,
                        });
                      }}
                      rows={4}
                      placeholder="Javascript here"
                    ></Input>
                  ) : (
                    <Input
                      bsSize="sm"
                      type="text"
                      name="title"
                      placeholder="Title"
                      value={this.state.title}
                      onChange={this.handleInputChange.bind(this)}
                    ></Input>
                  )}
                </FormGroup>
                <FormGroup>
                  <Row className="align-items-center">
                    <Col xs="">
                      <h5 className="m-0">
                        Notification Body{" "}
                        <i
                          className="mdi mdi-octagram text-danger pl-1"
                          style={{
                            fontSize: "80%",
                            position: "relative",
                            top: -1,
                          }}
                        ></i>
                      </h5>
                    </Col>
                    <Col xs="auto">
                      <Checkbox
                        checked={this.state.dynamicBody}
                        onChange={(e, v) => {
                          this.setState({
                            dynamicBody: v,
                          });
                        }}
                      >
                        {" "}
                        Dynamic
                      </Checkbox>
                    </Col>
                  </Row>

                  {this.state.dynamicBody ? (
                    <Input
                      bsSize="sm"
                      type="textarea"
                      name="dynamicBodyScript"
                      value={this.state.dynamicBodyScript}
                      onChange={(e) => {
                        this.setState({
                          dynamicBodyScript: e.target.value,
                        });
                      }}
                      rows={4}
                      placeholder="Javascript here"
                    ></Input>
                  ) : (
                    <>
                      <div className="">
                        <textarea
                          className="form-control"
                          type="textarea"
                          id="message-textarea"
                          placeholder="Type something..."
                          ref={(c) => {
                            if (!this.state.textareaRef) {
                              this.setState({ textareaRef: c }, () => {
                                autosize(this.state.textareaRef);
                              });
                            }
                          }}
                          value={this.state.message}
                          style={{
                            minHeight: 82,
                            maxHeight: 248,
                            resize: "none",
                          }}
                          rows={1}
                          name="message"
                          onChange={this.handleInputChange.bind(this)}
                          required={true}
                        ></textarea>
                      </div>
                      <div className="p-2 rounded border mt-2 border-lighter">
                        <Row className="align-items-center">
                          <Col xs="">
                            <Whisper
                              trigger={["click"]}
                              ref={this.emojiRef}
                              onClose={() => {
                                this.setState({
                                  emojiOpen: false,
                                });
                              }}
                              placement={"autoVerticalStart"}
                              preventOverflow={true}
                              speaker={
                                <Popover
                                  full
                                  className="dropdown-popover quick-reply"
                                  style={{}}
                                >
                                  <EmojiPicker
                                    emojiStyle="apple"
                                    defaultSkinTone="neutral"
                                    skinTonesDisabled={true}
                                    theme="light"
                                    getEmojiUrl={(data, e) => {
                                      return `/emojis/apple/64/${data}.png`;
                                    }}
                                    onEmojiClick={(v) => {
                                      let b = this.state.message;

                                      b = b + v?.emoji;

                                      if (b?.length) {
                                        this.setState({
                                          messageLength: count(b),
                                        });
                                      } else {
                                        this.setState({
                                          messageLength: null,
                                        });
                                      }

                                      this.setState({ message: b });

                                      this.emojiRef.current.close();
                                    }}
                                  ></EmojiPicker>
                                </Popover>
                              }
                            >
                              <Button
                                size="sm"
                                outline
                                color="dark"
                                className="btn-icon-only border-0 mr-0"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();

                                  if (this.state.emojiOpen) {
                                    return this.setState({ emojiOpen: false });
                                  }

                                  this.setState({ emojiOpen: true });
                                }}
                              >
                                <i className="mdi mdi-emoticon-outline"></i>
                              </Button>
                            </Whisper>

                            {/*<Button
                          size="sm"
                          outline
                          color="dark"
                          className="btn-icon-only border-0"
                        >
                          <i className="mdi mdi-image"></i>
                    </Button>*/}

                            {/*<Button
                          size="sm"
                          outline
                          color="dark"
                          className="btn-icon-only border-0"
                        >
                          <i className="mdi mdi-note-plus-outline"></i>
                </Button>*/}
                          </Col>
                          <Col xs="auto">
                            <div style={{ fontSize: 12 }}>
                              {this.state.messageLength ? (
                                <>
                                  Chars:{" "}
                                  {StringUtils.numberFormat(
                                    this.state.messageLength?.length
                                  )}{" "}
                                </>
                              ) : (
                                <>Chars: 0</>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                </FormGroup>
                <FormGroup>
                  <Row className="align-items-center">
                    <Col xs="">
                      <h5 className="mb-0">
                        Image{" "}
                        <i
                          className="mdi mdi-octagram text-danger pl-1"
                          style={{
                            fontSize: "80%",
                            position: "relative",
                            top: -1,
                          }}
                        ></i>
                      </h5>
                    </Col>
                    <Col xs="auto">
                      <Checkbox
                        checked={this.state.dynamicImage}
                        onChange={(e, v) => {
                          this.setState({
                            dynamicImage: v,
                          });
                        }}
                      >
                        {" "}
                        Dynamic
                      </Checkbox>
                    </Col>
                  </Row>
                  {this.state.dynamicImage ? (
                    <Input
                      bsSize="sm"
                      type="textarea"
                      name="dynamicImageScript"
                      value={this.state.dynamicImageScript}
                      onChange={(e) => {
                        this.setState({
                          dynamicImageScript: e.target.value,
                        });
                      }}
                      rows={4}
                      placeholder="Javascript here"
                    ></Input>
                  ) : (
                    <>
                      {this.state.image ? (
                        <div
                          style={{
                            width: "100%",
                            height: 200,
                            position: "relative",
                          }}
                          className="border text-center border-lighter"
                        >
                          <div
                            style={{ position: "absolute", top: 10, right: 10 }}
                          >
                            <Button
                              size="sm"
                              outline
                              color="danger"
                              onClick={() => {
                                this.setState({
                                  image: null,
                                });
                              }}
                            >
                              <i className="mdi mdi-close"></i>
                            </Button>
                          </div>
                          <img
                            src={this.state.image?.url}
                            alt="Image"
                            style={{ height: "200px", width: "auto" }}
                          />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            this.setState({
                              showImagePicker: true,
                            });
                          }}
                          style={{ height: "200px" }}
                          className="cursor-pointer border border-lighter text-center d-flex align-items-center text-center"
                        >
                          <div
                            style={{ width: "100%" }}
                            className="text-dark text-center"
                          >
                            Click here to add an image
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </FormGroup>
                <FormGroup>
                  <Checkbox
                    checked={this.state.interactive}
                    onChange={(e, v) => {
                      this.setState({
                        interactive: v,
                      });
                    }}
                  >
                    {" "}
                    Add In-App Action
                  </Checkbox>
                </FormGroup>
                {this.state.interactive && (
                  <>
                    <FormGroup>
                      <Row className="align-items-center">
                        <Col xs="">
                          <h5 className="mb-0">
                            Action Button Text{" "}
                            <i
                              className="mdi mdi-octagram text-danger pl-1"
                              style={{
                                fontSize: "80%",
                                position: "relative",
                                top: -1,
                              }}
                            ></i>
                          </h5>
                        </Col>
                        <Col xs="auto">
                          <Checkbox
                            checked={this.state.dynamicInteractiveButtonText}
                            onChange={(e, v) => {
                              this.setState({
                                dynamicInteractiveButtonText: v,
                              });
                            }}
                          >
                            {" "}
                            Dynamic
                          </Checkbox>
                        </Col>
                      </Row>
                      {this.state.dynamicInteractiveButtonText ? (
                        <Input
                          bsSize="sm"
                          type="textarea"
                          name="interactiveButtonTextDynamicScript"
                          value={this.state.interactiveButtonTextDynamicScript}
                          onChange={(e) => {
                            this.setState({
                              interactiveButtonTextDynamicScript:
                                e.target.value,
                            });
                          }}
                          rows={4}
                          placeholder="Javascript here"
                        ></Input>
                      ) : (
                        <Input
                          bsSize="sm"
                          type="text"
                          name="interactiveButtonText"
                          placeholder="Interactive Button Text"
                          value={this.state.interactiveButtonText}
                          onChange={this.handleInputChange.bind(this)}
                        ></Input>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Row className="align-items-center">
                        <Col xs="">
                          <h5 className="mb-0">
                            Action Type{" "}
                            <i
                              className="mdi mdi-octagram text-danger pl-1"
                              style={{
                                fontSize: "80%",
                                position: "relative",
                                top: -1,
                              }}
                            ></i>
                          </h5>
                        </Col>
                      </Row>
                      <Input
                        bsSize="sm"
                        type="select"
                        name="interactiveEventType"
                        placeholder="InteractiveEventType"
                        value={this.state.interactiveEventType}
                        onChange={this.handleInputChange.bind(this)}
                      >
                        <option value="" disabled>
                          Select An Action
                        </option>
                        <option value="in-app-page">In-App Page Change</option>
                        <option value="in-app-event">In-App Event</option>
                        <option value="website-link">Website Link</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Row className="align-items-center">
                        <Col xs="">
                          <h5 className="mb-0">
                            {!this.state.interactiveEventType
                              ? "Event Data"
                              : ""}
                            {this.state.interactiveEventType == "in-app-page"
                              ? "In-App URL Path"
                              : ""}
                            {this.state.interactiveEventType == "in-app-event"
                              ? "In-App Event Name"
                              : ""}
                            {this.state.interactiveEventType == "website-link"
                              ? "Website URL"
                              : ""}{" "}
                            <i
                              className="mdi mdi-octagram text-danger pl-1"
                              style={{
                                fontSize: "80%",
                                position: "relative",
                                top: -1,
                              }}
                            ></i>
                          </h5>
                        </Col>
                        <Col xs="auto">
                          <Checkbox
                            checked={this.state.dynamicInteractiveEventData}
                            onChange={(e, v) => {
                              this.setState({
                                dynamicInteractiveEventData: v,
                              });
                            }}
                          >
                            {" "}
                            Dynamic
                          </Checkbox>
                        </Col>
                      </Row>
                      {this.state.dynamicInteractiveEventData ? (
                        <Input
                          bsSize="sm"
                          type="textarea"
                          name="interactiveEventDataDynamicScript"
                          value={this.state.interactiveEventDataDynamicScript}
                          onChange={(e) => {
                            this.setState({
                              interactiveEventDataDynamicScript: e.target.value,
                            });
                          }}
                          rows={4}
                          placeholder="Javascript here"
                        ></Input>
                      ) : (
                        <Input
                          bsSize="sm"
                          type="text"
                          name="interactiveEventData"
                          placeholder={`${
                            !this.state.interactiveEventType ? "Event Data" : ""
                          }${
                            this.state.interactiveEventType == "in-app-page"
                              ? "In-App URL Path"
                              : ""
                          }${
                            this.state.interactiveEventType == "in-app-event"
                              ? "In-App Event Name"
                              : ""
                          }${
                            this.state.interactiveEventType == "website-link"
                              ? "Website URL"
                              : ""
                          }`}
                          value={this.state.interactiveEventData}
                          onChange={this.handleInputChange.bind(this)}
                        ></Input>
                      )}
                    </FormGroup>
                    {this.state.interactiveEventType == "in-app-event" && (
                      <FormGroup>
                        <Row className="align-items-center">
                          <Col xs="">
                            <h5 className="mb-0">
                              In-App Action Metadata JSON{" "}
                              <i
                                className="mdi mdi-octagram text-danger pl-1"
                                style={{
                                  fontSize: "80%",
                                  position: "relative",
                                  top: -1,
                                }}
                              ></i>
                            </h5>
                          </Col>
                          <Col xs="auto">
                            <Checkbox
                              checked={
                                this.state.dynamicInteractiveEventMetadata
                              }
                              onChange={(e, v) => {
                                this.setState({
                                  dynamicInteractiveEventMetadata: v,
                                });
                              }}
                            >
                              {" "}
                              Dynamic
                            </Checkbox>
                          </Col>
                        </Row>
                        {this.state.dynamicInteractiveEventMetadata ? (
                          <Input
                            bsSize="sm"
                            type="textarea"
                            name="dynamicInteractiveEventMetadataScript"
                            value={
                              this.state.dynamicInteractiveEventMetadataScript
                            }
                            onChange={(e) => {
                              this.setState({
                                dynamicInteractiveEventMetadataScript:
                                  e.target.value,
                              });
                            }}
                            rows={4}
                            placeholder="Javascript here"
                          ></Input>
                        ) : (
                          <Input
                            bsSize="sm"
                            type="textarea"
                            name="interactiveEventMetadata"
                            placeholder="In-App Event Metaata JSON"
                            rows={4}
                            value={this.state.interactiveEventMetadata}
                            onChange={this.handleInputChange.bind(this)}
                          ></Input>
                        )}
                      </FormGroup>
                    )}
                  </>
                )}
              </>
            ) : null}
            {this.state.channel == "email" ? (
              <>
                <FormGroup>
                  <h5>
                    Email Type{" "}
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <SelectPicker
                    searchable={false}
                    data={[
                      {
                        value: "formatted",
                        label: "Formatted Email (Storefront Editor)",
                      },
                      {
                        value: "html",
                        label: "Custom HTML Code (From Postcards)",
                      },
                    ]}
                    value={this.state.emailType}
                    cleanable={false}
                    block
                    placement="autoVertical"
                    className="mb-2"
                    onChange={(v) => {
                      this.setState({
                        emailType: v,
                        message: v == "formatted" ? {} : "",
                      });
                    }}
                  ></SelectPicker>
                </FormGroup>
                <FormGroup>
                  <h5>
                    Email Subject{" "}
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="title"
                    placeholder="Subject"
                    value={this.state.title}
                    onChange={this.handleInputChange.bind(this)}
                  ></Input>
                </FormGroup>
                {this.state.emailType == "formatted" ? (
                  <>
                    <FormGroup>
                      <h5>
                        Email Preheader{" "}
                        <i
                          className="mdi mdi-octagram text-danger pl-1"
                          style={{
                            fontSize: "80%",
                            position: "relative",
                            top: -1,
                          }}
                        ></i>
                      </h5>
                      <Input
                        bsSize="sm"
                        type="text"
                        name="preheader"
                        placeholder="Subject"
                        value={this.state.preheader}
                        onChange={this.handleInputChange.bind(this)}
                      ></Input>
                    </FormGroup>
                  </>
                ) : null}
                <FormGroup>
                  <Row className="align-items-center mb-2">
                    <Col xs="">
                      <h5 className="mb-0">
                        Email Body{" "}
                        <i
                          className="mdi mdi-octagram text-danger pl-1"
                          style={{
                            fontSize: "80%",
                            position: "relative",
                            top: -1,
                          }}
                        ></i>
                      </h5>
                    </Col>
                    <Col xs="auto">
                      <Button
                        onClick={() => {
                          this.setState({
                            openTagDrawer: true,
                          });
                        }}
                        className="btn-icon-only"
                        color="dark"
                        outline
                        size="sm"
                      >
                        <i className="mdi mdi-tag-plus"></i>
                      </Button>
                    </Col>
                  </Row>

                  {this.state.emailType == "formatted" ? (
                    <>
                      <div
                        className="rounded p-3 border mb-3"
                        style={{ minHeight: 300 }}
                      >
                        <div className="text-center mt-4 mb-5">
                          <img
                            src={require("../../assets/img/brand/pln_primary_no_dot.svg")}
                            alt="Logo"
                            style={{ width: 180 }}
                          ></img>
                        </div>

                        <FormGroup>
                          {!this.state.image ? (
                            <>
                              <Row className="align-items-center mt--5">
                                <Col xs="" className="text-right">
                                  <Checkbox
                                    checked={this.state.dynamicImage}
                                    onChange={(e, v) => {
                                      this.setState({
                                        dynamicImage: v,
                                      });
                                    }}
                                  >
                                    {" "}
                                    Dynamic Image
                                  </Checkbox>
                                </Col>
                              </Row>
                            </>
                          ) : null}
                          {this.state.dynamicImage ? (
                            <Input
                              bsSize="sm"
                              type="textarea"
                              name="dynamicImageScript"
                              value={this.state.dynamicImageScript}
                              onChange={(e) => {
                                this.setState({
                                  dynamicImageScript: e.target.value,
                                });
                              }}
                              rows={4}
                              placeholder="Javascript here"
                            ></Input>
                          ) : (
                            <>
                              {this.state.image ? (
                                <div
                                  style={{
                                    width: "100%",
                                    height: 250,
                                    position: "relative",
                                    background: `url(${this.state.image?.url}) center center / cover`,
                                  }}
                                  className="border text-center border-lighter rounded"
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: 10,
                                      right: 10,
                                    }}
                                  >
                                    <Button
                                      size="sm"
                                      color="danger"
                                      className="btn-icon-only"
                                      onClick={() => {
                                        this.setState({
                                          image: null,
                                        });
                                      }}
                                    >
                                      <i className="mdi mdi-close"></i>
                                    </Button>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  onClick={() => {
                                    this.setState({
                                      showImagePicker: true,
                                    });
                                  }}
                                  style={{ height: "200px" }}
                                  className="cursor-pointer border border-lighter text-center d-flex align-items-center text-center"
                                >
                                  <div
                                    style={{ width: "100%" }}
                                    className="text-dark text-center"
                                  >
                                    Click here to add an image
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </FormGroup>
                        <div className="pt-3"></div>
                        <EditorV2
                          editable={true}
                          allowForceHydration={
                            this.state.message && this.state.templateLoad
                          }
                          id={"campaignEmailEditor"}
                          padding="p-0"
                          autoSave={false}
                          debounceRate={750}
                          cdnMode="public"
                          value={this.state?.message ? this.state?.message : {}}
                          onChange={(value) => {
                            console.log("CHANGE", value);

                            if (value?.clear) {
                              this.setState({
                                templateLoad: false,
                              });

                              return;
                            }

                            //console.log("CHANGE", value);

                            this.setState({
                              message: value,
                            });
                          }}
                          allowTaskLists={false}
                        ></EditorV2>
                      </div>
                    </>
                  ) : (
                    <>
                      <Input
                        bsSize="sm"
                        type="textarea"
                        rows={6}
                        name="message"
                        placeholder="Custom HTML Code"
                        value={this.state.message}
                        onChange={this.handleInputChange.bind(this)}
                      ></Input>
                    </>
                  )}
                </FormGroup>
                {this.state.emailType == "formatted" ? (
                  <FormGroup>
                    <Checkbox
                      checked={this.state.interactive}
                      onChange={(e, v) => {
                        this.setState({
                          interactive: v,
                          interactiveEventType: "website-link",
                        });
                      }}
                    >
                      {" "}
                      Add Call To Action Button
                    </Checkbox>
                  </FormGroup>
                ) : null}
                {this.state.interactive && (
                  <>
                    <FormGroup>
                      <Row className="align-items-center">
                        <Col xs="">
                          <h5 className="mb-0">
                            Button Text{" "}
                            <i
                              className="mdi mdi-octagram text-danger pl-1"
                              style={{
                                fontSize: "80%",
                                position: "relative",
                                top: -1,
                              }}
                            ></i>
                          </h5>
                        </Col>
                        <Col xs="auto">
                          <Checkbox
                            checked={this.state.dynamicInteractiveButtonText}
                            onChange={(e, v) => {
                              this.setState({
                                dynamicInteractiveButtonText: v,
                              });
                            }}
                          >
                            {" "}
                            Dynamic
                          </Checkbox>
                        </Col>
                      </Row>
                      {this.state.dynamicInteractiveButtonText ? (
                        <Input
                          bsSize="sm"
                          type="textarea"
                          name="interactiveButtonTextDynamicScript"
                          value={this.state.interactiveButtonTextDynamicScript}
                          onChange={(e) => {
                            this.setState({
                              interactiveButtonTextDynamicScript:
                                e.target.value,
                            });
                          }}
                          rows={4}
                          placeholder="Javascript here"
                        ></Input>
                      ) : (
                        <Input
                          bsSize="sm"
                          type="text"
                          name="interactiveButtonText"
                          placeholder="Interactive Button Text"
                          value={this.state.interactiveButtonText}
                          onChange={this.handleInputChange.bind(this)}
                        ></Input>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Row className="align-items-center">
                        <Col xs="">
                          <h5 className="mb-0">
                            Button Website Link
                            <i
                              className="mdi mdi-octagram text-danger pl-1"
                              style={{
                                fontSize: "80%",
                                position: "relative",
                                top: -1,
                              }}
                            ></i>
                          </h5>
                        </Col>
                        <Col xs="auto">
                          <Checkbox
                            checked={this.state.dynamicInteractiveEventData}
                            onChange={(e, v) => {
                              this.setState({
                                dynamicInteractiveEventData: v,
                              });
                            }}
                          >
                            {" "}
                            Dynamic
                          </Checkbox>
                        </Col>
                      </Row>
                      {this.state.dynamicInteractiveEventData ? (
                        <Input
                          bsSize="sm"
                          type="textarea"
                          name="interactiveEventDataDynamicScript"
                          value={this.state.interactiveEventDataDynamicScript}
                          onChange={(e) => {
                            this.setState({
                              interactiveEventDataDynamicScript: e.target.value,
                            });
                          }}
                          rows={4}
                          placeholder="Javascript here"
                        ></Input>
                      ) : (
                        <Input
                          bsSize="sm"
                          type="text"
                          name="interactiveEventData"
                          placeholder={`Website Link`}
                          value={this.state.interactiveEventData}
                          onChange={this.handleInputChange.bind(this)}
                        ></Input>
                      )}
                    </FormGroup>
                  </>
                )}
              </>
            ) : null}
            <FormGroup>
              <h5>
                Schedule{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <SelectPicker
                block
                placement="auto"
                value={this.state.scheduledSend}
                placeholder="Choose An Option"
                data={[
                  {
                    value: "now",
                    label: "Send Immediately",
                  },
                  {
                    value: "scheduled",
                    label: "Specify A Date & Time",
                  },
                ]}
                onChange={(v) => {
                  this.setState({
                    scheduledSend: v,
                  });
                }}
                cleanable={false}
                searchable={false}
              ></SelectPicker>
            </FormGroup>
            {this.state.scheduledSend == "scheduled" ? (
              <>
                <FormGroup>
                  <h5>
                    Date{" "}
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <DatePicker
                    block
                    placement="auto"
                    value={this.state.scheduledDate}
                    onChange={(v) => {
                      this.setState({
                        scheduledDate: v,
                      });
                    }}
                    disabledDate={(date) => {
                      return (
                        date <
                        moment()
                          .hours(0)
                          .minutes(0)
                          .seconds(0)
                          .millisecond(0)
                          .toDate()
                      );
                    }}
                    placeholder="MM/DD/YYYY"
                    format="MM/dd/yyyy"
                    oneTap
                  ></DatePicker>
                </FormGroup>
                <FormGroup>
                  <h5>
                    Time{" "}
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <DatePicker
                    block
                    placement="auto"
                    value={this.state.scheduledTime}
                    onChange={(v) => {
                      this.setState({
                        scheduledTime: v,
                      });
                    }}
                    placeholder="HH:MM"
                    format="hh:mm a"
                    showMeridian={true}
                  ></DatePicker>
                  <div>
                    <p className="m-0 text-muted" style={{ fontSize: 12 }}>
                      Timezone: America/New_York
                    </p>
                  </div>
                </FormGroup>
              </>
            ) : null}
          </Drawer.Body>
        </Drawer>
        <FileUploadDrawer
          open={this.state.showImagePicker}
          cdn="public"
          size="sm"
          singleFile={true}
          compressUploads={true}
          allowedFileTypes={["image/jpeg", "image/png", "image/jpg"]}
          onComplete={(files) => {
            console.log(files);

            this.setState({
              showImagePicker: false,
              image: files[0],
            });
          }}
        ></FileUploadDrawer>
        <Drawer
          open={this.state.openTagDrawer}
          onClose={() => {
            this.setState({
              openTagDrawer: false,
            });
          }}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Tags
            </h3>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            <p className="mb-3 text-dark">
              Tags allow you to easily add information stored as custom fields
              to your message content. For contacts with no data in a custom
              field, the tag will be blank.
            </p>
            <h3 className="mb-3">Lead & Member Tags:</h3>

            {Constant.LEAD_MEMBER_EMAIL_TAGS.map((tag, index) => (
              <div key={index} className="rounded px-2 py-1 border mb-2">
                <Row className="align-items-center">
                  <Col xs="">
                    <p className="m-0 text-dark font-weight-bold">
                      {tag?.name}
                    </p>
                    <p
                      className="m-0 text-dark small"
                      style={{ lineHeight: 1.2 }}
                    >
                      {tag?.description}
                    </p>
                  </Col>
                  <Col xs="auto">
                    <Button
                      size="sm"
                      color="dark"
                      outline
                      className="btn-icon-only"
                      disabled={this.state.copied === index}
                      onClick={() => {
                        navigator.clipboard.writeText(tag?.tag).then(() => {
                          this.setState({
                            copied: index,
                          });

                          setTimeout(() => {
                            this.setState({
                              copied: null,
                            });
                          }, 1500);
                        });
                      }}
                    >
                      {
                        <i
                          className={`mdi ${
                            this.state.copied === index
                              ? "mdi-check"
                              : "mdi-content-copy"
                          }`}
                        ></i>
                      }
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}

            <h3 className="mb-3 mt-4">Store Information Tags:</h3>
            {Constant.STORE_EMAIL_TAGS.map((tag, index) => (
              <div key={index} className="rounded px-2 py-1 border mb-2">
                <Row className="align-items-center">
                  <Col xs="">
                    <p className="m-0 text-dark font-weight-bold">
                      {tag?.name}
                    </p>
                    <p
                      className="m-0 text-dark small"
                      style={{ lineHeight: 1.2 }}
                    >
                      {tag?.description}
                    </p>
                  </Col>
                  <Col xs="auto">
                    <Button
                      size="sm"
                      color="dark"
                      outline
                      className="btn-icon-only"
                      disabled={this.state.copied === "s" + index}
                      onClick={() => {
                        navigator.clipboard.writeText(tag?.tag).then(() => {
                          this.setState({
                            copied: "s" + index,
                          });

                          setTimeout(() => {
                            this.setState({
                              copied: null,
                            });
                          }, 1500);
                        });
                      }}
                    >
                      {
                        <i
                          className={`mdi ${
                            this.state.copied === "s" + index
                              ? "mdi-check"
                              : "mdi-content-copy"
                          }`}
                        ></i>
                      }
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(CampaignDrawer);
